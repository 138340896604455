import React, { useContext, useCallback } from "react";

import ModalConfirm from "components/ModalConfirm";
import PackageSummary from "components/PackageSummary";
import { OutputRow as PackagesData } from "api/getPackages";
import { OutputRow as PackageRequestsData } from "api/getPackageRequests";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useSendData } from "hooks/useSendData";
import { markPackagePaid } from "api/markPackagePaid";
import terminology from "terminology.json";

import { ModalMarkPackagePaidContents } from "./ModalMarkPackagePaid.styles";

interface Props {
	product: PackagesData | PackageRequestsData | undefined;
	isOpen: boolean;
	onCancel: () => Promise<void>;
	onOK: () => Promise<void>;
}

const ModalMarkPackagePaid: React.FunctionComponent<Props> = ({
	product,
	isOpen,
	onCancel,
	onOK
}) => {
	const [{ uid }] = useContext(AuthContext);

	const doMarkPackagePaid = useSendData({ method: markPackagePaid });

	const { id: productId } = product ? product : { id: undefined };
	const handleOK = useCallback(async () => {
		if (!productId) {
			return;
		}

		await doMarkPackagePaid(
			{
				id: productId
			},
			{ uid }
		).ready;

		await onOK();
	}, [productId, doMarkPackagePaid, onOK, uid]);

	if (!uid) {
		return null;
	}

	if (!product) {
		return null;
	}

	return (
		<ModalConfirm
			isOpen={!!product}
			title={`Mark ${terminology.package} paid - please confirm`}
			onCancel={onCancel}
			onOK={handleOK}
			okText="Yes please"
		>
			<ModalMarkPackagePaidContents>
				<PackageSummary product={product} currentUid={uid} />
			</ModalMarkPackagePaidContents>
		</ModalConfirm>
	);
};

export default ModalMarkPackagePaid;
