import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	uid: string;
	code: string;
}

type Output = {};
const flagValidationErrors: ValidationCallback<Output> = () => {};

export function zoomFinishOAuth({ uid, code }: Params, auth: Auth) {
	return makeApiRequest("zoomFinishOAuth", {
		auth,
		data: {
			post: {
				uid,
				code
			}
		},
		flagValidationErrors
	});
}
