import React, { useCallback } from "react";

import { Output as GetPaymentMethodsOutput } from "api/getPaymentMethods";
import { ucFirst } from "utils/ucFirst";
import Button from "components/Button";

import { useDeleteCard } from "./useDeleteCard";

export const valueForNewCardOption = "new-payment-method";

export function useGenerateOptionsFromExistingPaymentMethods() {
	const {
		setPaymentMethodIdToDelete,
		paymentMethodIdToDelete,
		isDeleting
	} = useDeleteCard();
	const generateOptionsFromExistingPaymentMethods = useCallback(
		(output?: GetPaymentMethodsOutput) => {
			if (!output) {
				return [];
			}

			const existingCardOptions = output.paymentMethods.map(method => ({
				id: "payment-method-" + method.paymentMethodId,
				value: method.paymentMethodId + "",
				text: `${ucFirst(method.cardBrand)} ending in ${
					method.cardLastFour
				} (exp: ${method.cardExpMonth}/${method.cardExpYear})`,
				extra: (
					<React.Fragment>
						{" "}
						<Button
							isNarrow
							onClick={() => setPaymentMethodIdToDelete(method.paymentMethodId)}
							label="Delete"
						/>
					</React.Fragment>
				),
				disabled:
					isDeleting && paymentMethodIdToDelete === method.paymentMethodId
			}));

			const newCardOption = {
				id: valueForNewCardOption,
				value: valueForNewCardOption,
				text: "Use a new card"
			};

			return [...existingCardOptions, newCardOption];
		},
		[setPaymentMethodIdToDelete, paymentMethodIdToDelete, isDeleting]
	);

	return generateOptionsFromExistingPaymentMethods;
}
