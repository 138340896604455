export type StripeAccountOnboardingStatus =
	| "linked"
	| "unlinked"
	| "onboarding"
	| "account-requested";

export function isValidStripeAccountOnboardingStatus(
	input: unknown
): input is StripeAccountOnboardingStatus {
	return (
		input === "linked" ||
		input === "unlinked" ||
		input === "onboarding" ||
		input === "account-requested"
	);
}
