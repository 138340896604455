import React, { useContext, useState, useCallback } from "react";

import { AlertContext } from "components/AlertProvider/AlertProvider";
import ModalConfirm from "components/ModalConfirm";
import MeetingSummary from "components/MeetingSummary";
import FieldCheckbox from "components/FieldCheckbox";
import FieldText from "components/FieldText";
import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { OutputRow as MeetingRequestsData } from "api/getMeetingRequests";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useSendData } from "hooks/useSendData";
import { signMeetingOff } from "api/signMeetingOff";
import terminology from "terminology.json";

import {
	ModalSignMeetingOffContents,
	ModalSignMeetingOffSection,
	ModalSignOffMeetingColumnFieldContainer
} from "./ModalSignMeetingOff.styles";

export interface Props {
	meeting: MeetingsDataFlattened | MeetingRequestsData | undefined;
	isOpen: boolean;
	onCancel: () => Promise<void>;
	onOK: (arg: {
		requestedRefund: boolean;
		requestedRebook: boolean;
		hostUid: string;
	}) => Promise<void>;
}

const ModalSignMeetingOff: React.FunctionComponent<Props> = ({
	meeting,
	isOpen,
	onCancel,
	onOK
}) => {
	const [{ uid }] = useContext(AuthContext);
	const [addAlert] = useContext(AlertContext);

	const [meetingWasOK, setMeetingWasOK] = useState<boolean | undefined>();
	const [message, setMessage] = useState<string | undefined>();
	const [optInRebook, setOptInRebook] = useState<boolean | undefined>();

	const resetForm = useCallback(() => {
		setMeetingWasOK(undefined);
		setMessage(undefined);
		setOptInRebook(undefined);
	}, [setMeetingWasOK, setMessage, setOptInRebook]);

	const doSignMeetingOff = useSendData({
		method: signMeetingOff
	});

	const { id: meetingId, singleSessionPrice, credit, hostUid } = meeting
		? meeting
		: {
				id: undefined,
				singleSessionPrice: undefined,
				credit: undefined,
				hostUid: undefined
		  };
	const { price } = singleSessionPrice
		? singleSessionPrice
		: { price: undefined };
	const meetingWasPaidUsingCredit = credit !== undefined;
	const handleOK = useCallback(async () => {
		if (!(meetingId && hostUid)) {
			return;
		}

		// TODO:WV:20210510:Cancel this on unmount (possibly in the useSendData hook?)
		// TODO:WV:20210510:Needs a wait dialog?
		await doSignMeetingOff(
			{
				meetingId,
				meetingWasOK: !!meetingWasOK,
				problemMessage: message
			},
			{ uid }
		).ready;

		// TODO:WV:20200301:Implement refunding credits on the server
		const requestedRefund =
			!meetingWasOK && (price !== 0 || meetingWasPaidUsingCredit);

		addAlert({
			contents: `Thanks!  Your feedback has been recorded.${
				requestedRefund
					? `  We have noted your refund request and will be in touch shortly - thank you.`
					: ``
			}`
		});

		await onOK({ requestedRefund, requestedRebook: !!optInRebook, hostUid });
		resetForm();
	}, [
		addAlert,
		onOK,
		uid,
		meetingId,
		price,
		meetingWasOK,
		message,
		optInRebook,
		doSignMeetingOff,
		hostUid,
		resetForm,
		meetingWasPaidUsingCredit
	]);

	const handleCancel = useCallback(async () => {
		await onCancel();
		resetForm();
	}, [onCancel, resetForm]);

	if (!uid) {
		return null;
	}

	if (!meeting) {
		return null;
	}

	return (
		<ModalConfirm
			isOpen={isOpen}
			title={`Sign ${terminology.meeting} off`}
			onCancel={handleCancel}
			onOK={handleOK}
			okText={`Sign ${terminology.meeting} off`}
		>
			<ModalSignMeetingOffContents>
				<MeetingSummary event={meeting} currentUid={uid} />
				<form>
					<div className="row gtr-uniform">
						<div className="col-12">
							<h3>
								Did this {terminology.meeting} go ahead without a problem?
							</h3>
						</div>
					</div>
					<div className="row gtr-uniform">
						<div className="col-6 col-12-small">
							<ModalSignMeetingOffSection>
								<FieldCheckbox
									id="meetingok-response-yes"
									name="meetingok-response-yes"
									checked={meetingWasOK === true}
									labelText="Yes"
									onChange={e =>
										setMeetingWasOK(
											e.currentTarget.checked ? true : meetingWasOK
										)
									}
								/>
							</ModalSignMeetingOffSection>
						</div>

						<div className="col-6 col-12-small">
							<ModalSignMeetingOffSection>
								<FieldCheckbox
									id="meetingok-response-no"
									name="response-no"
									checked={meetingWasOK === false}
									labelText={`No ${price === 0 ? `` : `(request refund)`}`}
									onChange={e =>
										setMeetingWasOK(
											e.currentTarget.checked ? false : meetingWasOK
										)
									}
								/>
							</ModalSignMeetingOffSection>
						</div>
					</div>
					{meetingWasOK === true || meetingWasOK === undefined ? null : (
						<div className="row gtr-uniform">
							<ModalSignOffMeetingColumnFieldContainer className="col-12">
								<ModalSignMeetingOffSection>
									<FieldText
										placeholder="Problem details"
										value={message ? message : ""}
										onChange={e => setMessage(e.currentTarget.value)}
									/>
								</ModalSignMeetingOffSection>
							</ModalSignOffMeetingColumnFieldContainer>
						</div>
					)}

					<div className="row gtr-uniform">
						<div className="col-12">
							<h3>Would you like to book a follow-up?</h3>
						</div>
					</div>
					<div className="row gtr-uniform">
						<div className="col-12">
							<FieldCheckbox
								id="finished-meeting-book-followup"
								name="finished-meeting-book-followup"
								checked={optInRebook === true}
								labelText="Yes please"
								onChange={e => setOptInRebook(e.currentTarget.checked)}
							/>
						</div>
					</div>
				</form>
			</ModalSignMeetingOffContents>
		</ModalConfirm>
	);
};

export default ModalSignMeetingOff;
