import styled from "styled-components";

export const ShowMoreButton = styled.button`
	&.x-hidden {
		visibility: hidden;
	}
`;

export const FilterButtons = styled.ul`
	margin-top: -2.75em;
`;
