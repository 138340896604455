import React from "react";

import {
	IDCardWrapper,
	IDCardText,
	IDCardPhoto,
	IDCardVideo
} from "./IDCard.styles";

export interface IDCardProps {
	imageSrc: string;
	imageAlt: string;
	videoSrc?: string;
	videoPoster?: string;
	smallScreenStyle?: "stack" | "float";
}

const IDCard: React.FunctionComponent<IDCardProps> = ({
	imageSrc,
	imageAlt,
	smallScreenStyle = "stack",
	videoSrc,
	videoPoster,
	children
}) => {
	return (
		<IDCardWrapper>
			<IDCardPhoto
				src={imageSrc}
				alt={imageAlt}
				smallScreenStyle={smallScreenStyle}
			/>
			<IDCardText smallScreenStyle={smallScreenStyle}>{children}</IDCardText>
			{videoSrc ? (
				<IDCardVideo
					poster={videoPoster}
					controls
					src={videoSrc}
					smallScreenStyle={smallScreenStyle}
				/>
			) : null}
		</IDCardWrapper>
	);
};

export default IDCard;
