import React, {
	useCallback,
	useState,
	useEffect,
	useContext,
	useRef
} from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { deleteAccount } from "api/deleteAccount";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import Button from "components/Button";
import ModalConfirm from "components/ModalConfirm";
import FieldText from "components/FieldText";

const DeleteAccountSection: React.FunctionComponent = () => {
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const [textFieldValue, setTextFieldValue] = useState("");
	const textToTypeToConfirm =
		"Yes please go ahead and delete my Aldeia account";
	const handleChangeTextField = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) =>
			setTextFieldValue(e.currentTarget.value),
		[setTextFieldValue]
	);

	const handleClickDeleteAccount = useCallback(() => {
		setModalIsOpen(true);
	}, [setModalIsOpen]);

	const handleCancelDelete = useCallback(() => {
		setModalIsOpen(false);
	}, [setModalIsOpen]);

	const [isDeleting, setIsDeleting] = useState(false);
	const handleClickOK = useCallback(() => {
		if (textFieldValue.toLowerCase() === textToTypeToConfirm.toLowerCase()) {
			setIsDeleting(true);
		}
	}, [textFieldValue, textToTypeToConfirm, setIsDeleting]);

	const [{ uid, logOut }] = useContext(AuthContext);
	const lastIsDeletingRef = useRef<boolean | undefined>();
	useEffect(() => {
		if (!uid) {
			return;
		}
		const setLastIsDeletingRef = () => {
			lastIsDeletingRef.current = !!isDeleting;
		};
		if (isDeleting && isDeleting !== lastIsDeletingRef.current) {
			const req = deleteAccount({ uid }, { uid });
			req.ready.then(arg => {
				setIsDeleting(false);
				setModalIsOpen(false);
				logOut();
			});
			setLastIsDeletingRef();
			return () => req.abort();
		}
		setLastIsDeletingRef();
	}, [
		isDeleting,
		uid,
		lastIsDeletingRef,
		setIsDeleting,
		setModalIsOpen,
		logOut
	]);

	return (
		<>
			<p>To permanently delete your account, please click the button below.</p>
			<Button
				isDangerous
				icon={faTrash}
				onClick={handleClickDeleteAccount}
				label="Permanently delete my Aldeia account"
			/>
			<ModalConfirm
				isOpen={modalIsOpen}
				onCancel={handleCancelDelete}
				onOK={handleClickOK}
				title="Delete account"
			>
				<p>
					This is permanent. If you wish to proceed, please type '
					{textToTypeToConfirm}' in the box below, and then hit 'OK'.
				</p>
				<FieldText value={textFieldValue} onChange={handleChangeTextField} />
			</ModalConfirm>
		</>
	);
};

export default DeleteAccountSection;
