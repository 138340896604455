import styled from "styled-components";

interface Props {
	isWholeScreen?: boolean;
	includePaddingBottom?: boolean;
}

export const ContentsDiv = styled.div<Props>`
	${props =>
		props.includePaddingBottom === undefined && !!props.includePaddingBottom
			? `
		padding-bottom: 1em;
	`
			: ``}
	margin-bottom: 1em;

	${props => (!!props.isWholeScreen ? `margin-top:2.75em` : ``)};
	${props => (!!props.isWholeScreen ? `` : `max-height: 55vh`)};
	overflow-y: auto;

	p {
		margin-bottom: 1.5em;
	}

	/* Position: relative is to ensure that setting scrollTop to offsetTop of contained elements can be used to open with those elements scrolled-to. */
	position: relative;
`;
