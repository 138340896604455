import React, { useState } from "react";

import PageContent from "components/PageContent";
import AvailabilitySection from "components/PageDashboard/AvailabilitySection";
import ExternalCommitmentsSection from "components/PageDashboard/ExternalCommitmentsSection";
import SessionTypesSection from "components/PageDashboard/SessionTypesSection";
import SingleSessionPricesSection from "components/PageDashboard/SingleSessionPricesSection";
import PackagePricesSection from "components/PageDashboard/PackagePricesSection";
import ContactsAndMessagesSection from "components/PageDashboard/ContactsAndMessagesSection";
import ReceivingMoneySection from "components/PageDashboard/ReceivingMoneySection";
import MeetingRequestsSection from "components/PageDashboard/MeetingRequestsSection";
import PackageRequestsSection from "components/PageDashboard/PackageRequestsSection";
import MeetingsSection from "components/PageDashboard/MeetingsSection";
import PackagesSection from "components/PageDashboard/PackagesSection";
import AccountSettingsSection from "components/PageDashboard/AccountSettingsSection";
import ProfilePhotoSection from "components/PageDashboard/ProfilePhotoSection";
import ICalFeedsToImportSection from "components/PageDashboard/ICalFeedsToImportSection";
import ZoomApiSection from "components/PageDashboard/ZoomApiSection";
import DeleteAccountSection from "components/PageDashboard/DeleteAccountSection";
import ProfileUrlSection from "components/PageDashboard/ProfileUrlSection";
import ProfileVideoSection from "components/PageDashboard/ProfileVideoSection";
import SkypeIdSection from "components/PageDashboard/SkypeIdSection";
import DefaultGoogleMeetLinkSection from "components/PageDashboard/DefaultGoogleMeetLinkSection";
import ICalSection from "components/PageDashboard/ICalSection";
import AlertBox from "components/AlertBox";
import SectionBox from "components/SectionBox";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";
import { useAccountStatus } from "hooks/useAccountStatus";
import { useSaveDataAndRefreshAccountDetailsFromApi } from "hooks/useSaveDataAndRefreshAccountDetailsFromApi";
import ModalPleaseWait from "components/ModalPleaseWait";
import terminology from "terminology.json";
import { ucFirst } from "utils/ucFirst";

import NarrowSection from "./NarrowSection";
import WideSection from "./WideSection";

interface DashboardContextState {
	meetingsSectionRefreshNum: number;
	packagesSectionRefreshNum: number;
	sessionTypesRefreshNum: number;

	haveSessionTypes: boolean | undefined;
	haveSingleSessionPrices: boolean | undefined;
}

export const DashboardContext = React.createContext<
	[
		DashboardContextState,
		React.Dispatch<React.SetStateAction<DashboardContextState>>
	]
>([
	{
		meetingsSectionRefreshNum: 1,
		packagesSectionRefreshNum: 1,
		sessionTypesRefreshNum: 1,
		haveSessionTypes: undefined,
		haveSingleSessionPrices: undefined
	},
	arg => undefined
]);

const PageDashboard: React.FunctionComponent = () => {
	usePageTitlePrefix("Dashboard");
	const {
		isMentor,
		stripeAccountOnboardingStatus,
		stripeAccountPayoutsStatus,
		hasDismissedStripeAlert
	} = useAccountStatus();

	const [dashboardContextState, setDashboardContextState] = useState<
		DashboardContextState
	>({
		meetingsSectionRefreshNum: new Date().getTime(),
		packagesSectionRefreshNum: new Date().getTime(),
		sessionTypesRefreshNum: new Date().getTime(),
		haveSessionTypes: undefined,
		haveSingleSessionPrices: undefined
	});

	const needsToAddSessionTypes =
		isMentor && dashboardContextState.haveSessionTypes === false;
	const needsToAddSingleSessionPrices =
		isMentor && dashboardContextState.haveSingleSessionPrices === false;
	const yetToLinkStripe =
		isMentor &&
		(stripeAccountOnboardingStatus !== undefined &&
			stripeAccountOnboardingStatus !== "linked");
	const needsToAddPaymentInformation = isMentor && yetToLinkStripe;
	const stripeNeedsAttention =
		isMentor && stripeAccountPayoutsStatus === "disabled";

	const [isSaving, setIsSaving] = useState(false);
	const submitDataToAPI = useSaveDataAndRefreshAccountDetailsFromApi(
		"saveAccountSettings",
		"hiding the alert message",
		setIsSaving
	);

	// Contacts and Messages section collapsed-on-default state should be true for non-mentors and false for mentors.
	// The logic of this doesn't work if isMentor is allowed to be undefined while it is loading.  So, wait until
	// it has loaded before rendering this page.
	if (isMentor === undefined) {
		return null;
	}

	return (
		<DashboardContext.Provider
			value={[dashboardContextState, setDashboardContextState]}
		>
			<ModalPleaseWait isOpen={isSaving} title="Saving" />
			<PageContent type="admin">
				{(needsToAddSessionTypes ||
					needsToAddSingleSessionPrices ||
					needsToAddPaymentInformation ||
					stripeNeedsAttention) &&
				!hasDismissedStripeAlert ? (
					<AlertBox
						allowDismiss={
							needsToAddPaymentInformation &&
							!(
								needsToAddSessionTypes ||
								needsToAddSingleSessionPrices ||
								stripeNeedsAttention
							)
						}
						onDismiss={() =>
							submitDataToAPI({ data: { hasDismissedStripeAlert: true } })
						}
						contents={[
							...(needsToAddSessionTypes
								? [
										<>
											Until you add at least one{" "}
											<a href="#session-types-section">session type</a> your
											profile will show you as unavailable.
										</>
								  ]
								: []),
							...(needsToAddSingleSessionPrices
								? [
										<>
											You need to add some{" "}
											<a href="#session-types-section">prices</a> to go with
											your sessions.
										</>
								  ]
								: []),
							...(needsToAddPaymentInformation
								? [
										<>
											You need to{" "}
											<a href="#receiving-money-section">
												configure instructions for getting paid and / or create
												a Stripe <em>connected account</em>
											</a>{" "}
											otherwise users may not know how to pay you for{" "}
											{terminology.meetings}.
										</>
								  ]
								: []),
							...(stripeNeedsAttention
								? [
										<>
											Your Stripe account needs attention.{" "}
											<a href="#receiving-money-section">More information</a>.
										</>
								  ]
								: [])
						]}
					/>
				) : null}

				{isMentor ? null : (
					<WideSection
						title={`${ucFirst(terminology.mentors)}, contacts and messages`}
						isCollapsedByDefault={false}
					>
						<ContactsAndMessagesSection />
					</WideSection>
				)}

				{isMentor ? (
					<>
						<WideSection
							title={terminology.meetingRequests}
							isCollapsedByDefault={false}
						>
							<MeetingRequestsSection />
						</WideSection>
						<WideSection
							title={terminology.packageRequests}
							isCollapsedByDefault={false}
						>
							<PackageRequestsSection />
						</WideSection>
					</>
				) : null}
				<WideSection
					title={"Current " + terminology.meetings}
					isCollapsedByDefault={false}
				>
					<MeetingsSection />
				</WideSection>

				{isMentor ? (
					<WideSection
						title="Contacts and messages"
						isCollapsedByDefault={isMentor}
					>
						<ContactsAndMessagesSection />
					</WideSection>
				) : null}

				<WideSection
					title={"Active " + terminology.packages}
					isCollapsedByDefault={isMentor}
				>
					<PackagesSection />
				</WideSection>

				{isMentor ? (
					<WideSection title={`Availability for ${terminology.meetings}`}>
						<AvailabilitySection />
					</WideSection>
				) : null}
				{isMentor ? (
					<WideSection title="External commitments">
						<ExternalCommitmentsSection />
					</WideSection>
				) : null}
				{isMentor ? (
					<WideSection
						title={`${terminology.meeting} types`}
						anchorName="session-types-section"
					>
						<SessionTypesSection />
					</WideSection>
				) : null}
				{isMentor ? (
					<WideSection
						title={`Single ${terminology.meeting} prices`}
						anchorName="session-prices-section"
					>
						<SingleSessionPricesSection />
					</WideSection>
				) : null}
				{isMentor ? (
					<WideSection
						title={`${terminology.package} prices`}
						anchorName="package-prices-section"
					>
						<PackagePricesSection />
					</WideSection>
				) : null}
				{isMentor ? (
					<WideSection
						title="ICal import"
						anchorName="iCal-feeds-to-import-section"
					>
						<ICalFeedsToImportSection />
					</WideSection>
				) : null}
				<WideSection title="ICal export">
					<ICalSection />
				</WideSection>
				<WideSection title="Account settings">
					<AccountSettingsSection />
				</WideSection>

				{isMentor ? (
					<div className="row gtr-uniform">
						<NarrowSection title="Profile photo">
							<ProfilePhotoSection />
						</NarrowSection>
						<NarrowSection title="Profile video">
							<SectionBox>
								<ProfileVideoSection />
							</SectionBox>
						</NarrowSection>
					</div>
				) : null}

				{isMentor ? (
					<>
						<div className="row gtr-uniform">
							<NarrowSection title="Zoom API" anchorName="zoom-account-section">
								<SectionBox>
									<ZoomApiSection />
								</SectionBox>
							</NarrowSection>
							<NarrowSection title="Skype ID">
								<SectionBox>
									<SkypeIdSection />
								</SectionBox>
							</NarrowSection>
						</div>
						<div className="row gtr-uniform">
							<NarrowSection title="Default Google Meet link">
								<SectionBox>
									<DefaultGoogleMeetLinkSection />
								</SectionBox>
							</NarrowSection>
							<NarrowSection title="Profile URL">
								<SectionBox>
									<ProfileUrlSection />
								</SectionBox>
							</NarrowSection>
						</div>

						<WideSection
							title="Receiving money (payment providers)"
							anchorName="receiving-money-section"
						>
							<SectionBox
								highlight={needsToAddPaymentInformation || stripeNeedsAttention}
							>
								<ReceivingMoneySection />
							</SectionBox>
						</WideSection>
					</>
				) : (
					<WideSection title="Skype ID">
						<SectionBox>
							<SkypeIdSection />
						</SectionBox>
					</WideSection>
				)}

				<WideSection title="Delete account" anchorName="delete-account-section">
					<SectionBox>
						<DeleteAccountSection />
					</SectionBox>
				</WideSection>
			</PageContent>
		</DashboardContext.Provider>
	);
};

export default PageDashboard;
