import React, { useState, useCallback } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import FieldText from "components/FieldText";
import { DataInAnyRow } from "components/PageDashboard/ExternalCommitmentsSection/ExternalCommitmentsTable/ExternalCommitmentsTable";

function StepDescription({
	enableProgressToNextStep
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [currentValue, setCurrentValue] = useState("");

	const handleNewValue = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const description = e.currentTarget.value;
			setCurrentValue(description);
			enableProgressToNextStep({
				description
			});
		},
		[enableProgressToNextStep, setCurrentValue]
	);

	return <FieldText value={currentValue} onChange={handleNewValue} />;
}

export default StepDescription;
