export function dateRangesOverlap(slotA: DateRange, slotB: DateRange) {
	const aStartsDuringB =
		slotA.start.getTime() >= slotB.start.getTime() &&
		slotA.start.getTime() < slotB.end.getTime();
	if (aStartsDuringB) {
		return true;
	}
	const aEndsDuringB =
		slotA.end.getTime() > slotB.start.getTime() &&
		slotA.end.getTime() <= slotB.end.getTime();
	if (aEndsDuringB) {
		return true;
	}
	const aEncompassesB =
		slotA.start.getTime() <= slotB.start.getTime() &&
		slotA.end.getTime() >= slotB.end.getTime();
	if (aEncompassesB) {
		return true;
	}

	return false;
}

interface DateRange {
	start: Date;
	end: Date;
}
