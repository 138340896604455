import React, { useState, useEffect, useContext } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { CompletedWizardData, SessionType } from "../BookingWizard";

import { FieldOption } from "components/FieldSelect/FieldSelect";
import FieldSelect from "components/FieldSelect";
import SelectedEventSummary from "../SelectedEventSummary";
import { OutputRow as SessionTypesData } from "api/getSessionTypes";
import { useAccountStatus } from "hooks/useAccountStatus";
import { useCredits } from "hooks/useCredits";
import { formatSessionType } from "services/formatSessionType";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useSessionTypesAndPrices } from "hooks/useSessionTypesAndPrices";
import { FieldSelectContainer } from "./StepChooseSessionType.styles";

const defaultCountryCodeUntilAccountStatusLoads = "GB";

function StepChooseSessionType({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData: { eventToBook, mentorId }
}: ModalWizardStepRenderProps<CompletedWizardData>) {
	const {
		isWaiting: sessionTypesAndPricesWaiting,
		isLoading: sessionTypesAndPricesLoading,
		isError: sessionTypesAndPricesError,
		output: sessionTypesAndPrices
	} = useSessionTypesAndPrices(mentorId);

	const { locationCountryCode } = useAccountStatus();

	const [{ uid }] = useContext(AuthContext);

	const creditTypes = sessionTypesAndPrices.packagePrices
		.filter(
			/* Find one package price for each session ID (it is only necessary to know that the user has at least one credit for a session, to show it in this list if it is not available for general sale) */
			(value, index, self) =>
				index ===
				self.findIndex(element => element.sessionTypeId === value.sessionTypeId)
		)
		.map(({ sessionTypeId }) => ({
			sessionTypeId,
			spendingStatus: "available" as "available"
		}));

	const {
		isWaiting: creditsWaiting,
		isLoading: creditsLoading,
		isError: creditsError,
		output: credits
	} = useCredits({
		uid,
		creditTypes
	});

	const [currentOptions, setCurrentOptions] = useState<
		FieldOption<SessionType>[]
	>(
		generateOptionsFromSessionTypesData(
			getAvailableSessionTypes(sessionTypesAndPrices, credits),
			locationCountryCode
				? locationCountryCode
				: defaultCountryCodeUntilAccountStatusLoads
		)
	);
	const numCurrentOptions = currentOptions.length;

	// NB Must wait until sessionTypesAndPrices finishes waiting and loading,
	// because it is generated via several API requests, and if they are not
	// all complete, only the first will be used.  By similar reasoning it
	// is also necessary to wait for credits to arrive from the API
	const availableSessionTypesStr = JSON.stringify(
		sessionTypesAndPricesWaiting ||
			sessionTypesAndPricesLoading ||
			creditsWaiting ||
			creditsLoading
			? []
			: getAvailableSessionTypes(sessionTypesAndPrices, credits)
	);
	useEffect(() => {
		const sessionTypes = JSON.parse(availableSessionTypesStr);
		if (sessionTypes && numCurrentOptions === 0) {
			setCurrentOptions(
				generateOptionsFromSessionTypesData(
					sessionTypes,
					locationCountryCode
						? locationCountryCode
						: defaultCountryCodeUntilAccountStatusLoads
				)
			);
		}
	}, [
		availableSessionTypesStr,
		numCurrentOptions,
		setCurrentOptions,
		locationCountryCode
	]);

	useEffect(() => {
		const selectedOption = currentOptions.find(opt => !!opt.selected);
		const selectedOptionValue = selectedOption
			? selectedOption.value
			: undefined;

		if (selectedOptionValue !== undefined) {
			enableProgressToNextStep({ sessionType: selectedOptionValue });
		} else {
			disableProgressToNextStep();
		}
	}, [currentOptions, enableProgressToNextStep, disableProgressToNextStep]);

	return (
		<>
			{/* Don't show end-time because it is currently one hour after the start-time (but it should depend on what the user chooses in this step) */}
			<SelectedEventSummary eventToBook={eventToBook} showEndTime={false} />
			<FieldSelectContainer>
				<FieldSelect
					allowMultipleSelections={false}
					isLoading={
						sessionTypesAndPricesLoading || sessionTypesAndPricesWaiting
					}
					isError={sessionTypesAndPricesError || creditsError}
					currentOptions={currentOptions}
					onNewCurrentOptions={setCurrentOptions}
				/>
			</FieldSelectContainer>
		</>
	);
}

export default StepChooseSessionType;

// TODO:WV:20220118:Test this
function getAvailableSessionTypes(
	sessionTypesAndPrices: ReturnType<typeof useSessionTypesAndPrices>["output"],
	credits: ReturnType<typeof useCredits>["output"]
) {
	return sessionTypesAndPrices.sessionTypes.filter(sessionType => {
		const haveSingleSessionPrice = sessionTypesAndPrices.singleSessionPrices.some(
			singleSessionPrice => singleSessionPrice.sessionTypeId === sessionType.id
		);
		const haveCredit = credits
			? credits.some(
					output =>
						output &&
						output.page.some(credit => credit.sessionTypeId === sessionType.id)
			  )
			: false;
		return haveSingleSessionPrice || haveCredit;
	});
}

function generateOptionsFromSessionTypesData(
	data: SessionTypesData[],
	locationCountryCode: string
) {
	return data
		.sort((a, b) => a.sessionTitle.localeCompare(b.sessionTitle))
		.map(sessionType => {
			const value = getValueFromSessionType(sessionType);

			return {
				id: JSON.stringify(value),
				value,
				text: formatSessionType({ sessionType })
			};
		});
}

function getValueFromSessionType({
	id,
	sessionTitle,
	sessionDescription
}: SessionType) {
	return {
		id,
		sessionTitle,
		sessionDescription
	};
}
