import moment from "moment-timezone";

import { getOptions } from "./getOptions";

export function getTimezoneOptions(selectedValue?: string) {
	return getOptions(
		moment.tz.names().map(name => ({ value: name, text: name })),
		selectedValue ? [selectedValue] : undefined
	);
}
