import React, { useCallback, useMemo } from "react";
import moment from "moment-timezone";
import range from "lodash.range";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { captureException } from "services/captureException";
import { OutputRow as AvailabilityData } from "api/getAvailability";
import { ValidationCallback } from "validation/validate";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import ModalFieldSelect from "components/ModalFieldSelect";
import ModalFieldHourOfDay from "components/ModalFieldHourOfDay";
import { OnCompleteOutput } from "components/ModalWizard/ModalWizard";
import { getTimezoneOptions } from "services/getTimezoneOptions";
import EditableTable from "components/EditableTable";
import { useStickySort } from "hooks/useStickySort";
import StepDaysOfWeek from "./NewRowWizard/StepDaysOfWeek";
import StepTimeFrom from "./NewRowWizard/StepTimeFrom";
import StepTimeTo from "./NewRowWizard/StepTimeTo";
import StepTimezone from "./NewRowWizard/StepTimezone";

export type DataInAlreadyExistingRow = AvailabilityData;

export interface DataInFreshlyEnteredRow
	extends Omit<DataInAlreadyExistingRow, "dayOfWeekLocaltime"> {
	dayOfWeekLocaltime: DataInAlreadyExistingRow["dayOfWeekLocaltime"][];
}

interface AvailabilityTableProps {
	periodsOfAvailability: DataInAlreadyExistingRow[];
	isLoading: boolean;
	isWaiting: boolean;
	isError: boolean;
	waitModalTitle: string;
	waitModalIsShowing: boolean;
	onEdit: (
		rows: { old?: DataInAlreadyExistingRow; new?: DataInAlreadyExistingRow }[]
	) => OnCompleteOutput;
}

const AvailabilityTable: React.FunctionComponent<AvailabilityTableProps> = ({
	periodsOfAvailability,
	onEdit: onEditGlobal,
	isLoading,
	isWaiting,
	isError,
	waitModalTitle,
	waitModalIsShowing
}) => {
	const onNewRowWizardComplete = useCallback(
		(data: DataInFreshlyEnteredRow) => {
			if (!data.dayOfWeekLocaltime) {
				let hasBeenAborted = false;
				return {
					ready: Promise.resolve(),
					abort: () => {
						hasBeenAborted = true;
					},
					aborted: () => hasBeenAborted
				};
			}
			return onEditGlobal(
				data.dayOfWeekLocaltime.map(dayOfWeekLocaltime => {
					return {
						new: {
							...data,
							dayOfWeekLocaltime
						}
					};
				})
			);
		},
		[onEditGlobal]
	);

	const sortedAvailabilityPeriods = useStickySort(
		periodsOfAvailability,
		useCallback((a: DataInAlreadyExistingRow, b: DataInAlreadyExistingRow) => {
			if (a.dayOfWeekLocaltime !== b.dayOfWeekLocaltime) {
				return a.dayOfWeekLocaltime - b.dayOfWeekLocaltime;
			}

			const aStart = moment
				.duration(a.timeFirstAvailableLocaltime)
				.as("milliseconds");
			const bStart = moment
				.duration(b.timeFirstAvailableLocaltime)
				.as("milliseconds");

			if (aStart !== bStart) {
				return aStart - bStart;
			}

			const aEnd = moment
				.duration(a.timeLastAvailableJustBeforeLocaltime)
				.as("milliseconds");
			const bEnd = moment
				.duration(b.timeLastAvailableJustBeforeLocaltime)
				.as("milliseconds");

			return aEnd - bEnd;
		}, [])
	);

	const rows = useMemo(
		() =>
			sortedAvailabilityPeriods.map(data => ({
				key: data.id,
				data,
				actionWhenEditing: {
					icon: faTrash,
					label: "Delete",
					onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
						e.preventDefault();
						onEditGlobal([{ old: data }]);
					}
				}
			})),
		[sortedAvailabilityPeriods, onEditGlobal]
	);

	const newRowWizard = useMemo(() => {
		const steps = [
			{
				key: "timezone",
				title: "What timezone will you set the times in?",
				component: StepTimezone
			},
			{
				key: "days-of-week",
				title: "What days of the week?",
				component: StepDaysOfWeek
			},
			{
				key: "time-from",
				title: "What time are you available from?",
				component: StepTimeFrom,
				doScrollToInitialPosition: true
			},
			{
				key: "time-to",
				title: "What time are you available until?",
				component: StepTimeTo,
				doScrollToInitialPosition: true
			}
		];

		const validateNewRowData: ValidationCallback<DataInFreshlyEnteredRow> = (
			input,
			{ flag, checkField }
		) => {
			const {
				dayOfWeekLocaltime,
				timeFirstAvailableLocaltime,
				timeLastAvailableJustBeforeLocaltime
			} = input;
			checkField("timezone", { type: "string" });

			if (!dayOfWeekLocaltime) {
				flag("dayOfWeekLocaltime", "mising");
			} else if (!Array.isArray(dayOfWeekLocaltime)) {
				flag("dayOfWeekLocaltime", "not an array");
			} else {
				for (const day of dayOfWeekLocaltime) {
					if (typeof day !== "number") {
						flag("dayOfWeekLocaltime", "not all numbers");
					}

					if (parseInt(day + "") !== day) {
						flag("dayOfWeekLocaltime", "not all integers");
					}

					if (day < 1 || day > 7) {
						flag("dayOfWeekLocaltime", "not all in range");
					}
				}
			}

			if (!timeFirstAvailableLocaltime) {
				flag("timeFirstAvailableLocaltime", "mising");
			} else if (!moment.isDuration(timeFirstAvailableLocaltime)) {
				flag("timeFirstAvailableLocaltime", "not a moment duration");
			}

			if (!timeLastAvailableJustBeforeLocaltime) {
				flag("timeLastAvailableJustBeforeLocaltime", "mising");
			} else if (!moment.isDuration(timeLastAvailableJustBeforeLocaltime)) {
				flag("timeLastAvailableJustBeforeLocaltime", "not a moment duration");
			}
		};

		const initialData = {};

		return {
			steps,
			validateNewRowData,
			initialData
		};
	}, []);

	return (
		<EditableTable<DataInAlreadyExistingRow, DataInFreshlyEnteredRow>
			allowDeleteRows
			rows={rows}
			onEdit={onEditGlobal}
			dataDescriptionSingular="availability period"
			dataDescriptionPlural="availability periods"
			onNew={onNewRowWizardComplete}
			newRowWizard={newRowWizard}
			isLoading={isLoading}
			isWaiting={isWaiting}
			isError={isError}
			waitModalTitle={waitModalTitle}
			waitModalIsShowing={waitModalIsShowing}
			cols={[
				{
					key: "timezone",
					heading: "Timezone",
					field: ({ row, rowKey, isEditing, onEdit }) => (
						<ModalFieldSelect
							isUnlocked={isEditing}
							formatSelectedOption={formatSelectedTimezone}
							title="Timezone"
							requireSelection
							onOK={newOptions => {
								const selectedOption = newOptions.find(val => !!val.selected);
								if (!selectedOption) {
									throw captureException(new Error("No selected option"), {
										evtType: "noSelectedOption"
									});
								}

								const newTimezone = selectedOption.value + "";
								const newData = {
									...row,
									[rowKey]: newTimezone
								};
								onEdit([{ old: row, new: newData }]);
							}}
							options={getTimezoneOptions(row.timezone)}
							allowMultipleSelections={false}
						/>
					)
				},
				{
					key: "dayOfWeekLocaltime",
					heading: "Day",
					field: ({ row, rowKey, isEditing, onEdit }) => (
						<ModalFieldSelect
							isUnlocked={isEditing}
							formatSelectedOption={formatSelectedDayOfWeek}
							title="Weekday"
							requireSelection
							onOK={newOptions => {
								const selectedOption = newOptions.find(val => !!val.selected);
								if (!selectedOption) {
									throw captureException(new Error("No selected option"), {
										evtType: "noSelectedOption"
									});
								}
								if (typeof selectedOption.value !== "number") {
									throw captureException(
										new Error("Selected option not a number"),
										{
											evtType: "selectedOptionNotANumber",
											extra: { selectedOption, newOptions }
										}
									);
								}

								const newData = {
									...row,
									[rowKey]: selectedOption.value
								};
								onEdit([{ old: row, new: newData }]);
							}}
							options={getDayOfWeekOptions([row.dayOfWeekLocaltime])}
							allowMultipleSelections={false}
						/>
					)
				},
				{
					key: "timeFirstAvailableLocaltime",
					heading: "From",
					field: ({ row, rowKey, isEditing, onEdit }) => (
						<ModalFieldHourOfDay
							isUnlocked={isEditing}
							title="Available from"
							value={row.timeFirstAvailableLocaltime.hours()}
							requireSelectedTime
							isDisabled={(value: number | undefined) => {
								return row.timeLastAvailableJustBeforeLocaltime === undefined
									? false
									: value === undefined
									? false
									: value >= row.timeLastAvailableJustBeforeLocaltime.hours();
							}}
							onOK={(newValue: number | undefined) => {
								if (!newValue) {
									throw captureException(new Error("No new value"), {
										evtType: "noNewValue"
									});
								}

								const newData = {
									...row,
									[rowKey]: moment.duration({
										hours: newValue
									})
								};
								onEdit([{ old: row, new: newData }]);
							}}
						/>
					)
				},
				{
					key: "timeLastAvailableJustBeforeLocaltime",
					heading: "Until",
					field: ({ row, rowKey, isEditing, onEdit }) => (
						<ModalFieldHourOfDay
							isUnlocked={isEditing}
							title="Available until"
							value={row.timeLastAvailableJustBeforeLocaltime.hours()}
							requireSelectedTime
							isDisabled={(value: number | undefined) =>
								row.timeFirstAvailableLocaltime === undefined
									? false
									: value === undefined
									? false
									: value <= row.timeFirstAvailableLocaltime.hours()
							}
							onOK={(newValue: number | undefined) => {
								if (!newValue) {
									throw captureException(new Error("No new value"), {
										evtType: "noNewValue"
									});
								}

								const newData = {
									...row,
									[rowKey]: moment.duration({
										hours: newValue
									})
								};
								onEdit([{ old: row, new: newData }]);
							}}
						/>
					)
				}
			]}
		/>
	);
};

export default AvailabilityTable;

export function getDayOfWeekOptions(selectedValues?: number[]) {
	return range(1, 8).map(dayOfWeek => ({
		id: dayOfWeek + "",
		value: dayOfWeek,
		text: formatDayOfWeek(dayOfWeek),
		selected: !!selectedValues && selectedValues.includes(dayOfWeek)
	}));
}

function formatSelectedDayOfWeek(opt: FieldOption<number>) {
	return formatDayOfWeek(opt.value);
}

function formatSelectedTimezone(opt: FieldOption<string>) {
	return opt.value.indexOf("/") === -1 ? opt.value : opt.value.split("/")[1];
}

function formatDayOfWeek(dayOfWeek: number) {
	return moment()
		.isoWeekday(dayOfWeek)
		.format("ddd");
}
