import React from "react";

import { SectionBoxInner } from "./SectionBox.styles";

export interface Props {
	highlight?: boolean;
	containsFixedRightColumn?: boolean;
}

const SectionBox: React.FunctionComponent<Props> = ({
	children,
	highlight = false,
	containsFixedRightColumn = false
}) => {
	return (
		<SectionBoxInner
			containsFixedRightColumn={containsFixedRightColumn}
			highlight={highlight}
		>
			{children}
		</SectionBoxInner>
	);
};

export default SectionBox;
