interface Params {
	list: string[];
	isDisjunction?: boolean;
}

export function formatList({ list, isDisjunction = false }: Params) {
	if (!list.length) {
		return "";
	}
	if (list.length === 1) {
		return list.toString();
	}

	const finalSeparator = isDisjunction ? "or" : "and";

	if (list.length === 2) {
		return list.join(` ${finalSeparator} `);
	}

	return list.slice(0, -1).join(`, `) + `, ${finalSeparator} ` + list.slice(-1);
}
