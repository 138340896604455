import React, { useState, useCallback } from "react";

import { useAccountStatus } from "hooks/useAccountStatus";
import { useSaveDataAndRefreshAccountDetailsFromApi } from "hooks/useSaveDataAndRefreshAccountDetailsFromApi";
import ModalFieldText from "components/ModalFieldText";
import ModalPleaseWait from "components/ModalPleaseWait";

import { IntroText, FieldContainer } from "./SkypeIdSection.styles";

const SkypeIdSection: React.FunctionComponent = () => {
	const { skypeId } = useAccountStatus();

	const [isSaving, setIsSaving] = useState(false);

	const submitDataToAPI = useSaveDataAndRefreshAccountDetailsFromApi(
		"saveAccountSettings",
		"saving your settings",
		setIsSaving,
		async () => {}
	);

	const handleOKSkypeId = useCallback(
		(skypeId: string) => {
			submitDataToAPI({ data: { skypeId } });
		},
		[submitDataToAPI]
	);

	return (
		<>
			<ModalPleaseWait isOpen={isSaving} title="Saving" />

			<IntroText>
				You can set your Skype ID here if you want the option of holding your
				lessons over Skype.
			</IntroText>
			<IntroText>
				(The availability of Skype depends on the other participant also having
				entered their Skype ID).
			</IntroText>

			<FieldContainer>
				Current Skype Id:{" "}
				<ModalFieldText
					isUnlocked={true}
					title="Skype Id"
					onOK={handleOKSkypeId}
					value={skypeId ? skypeId : ""}
					formatValue={value => (value === "" ? "(not set)" : value)}
				/>
			</FieldContainer>
		</>
	);
};

export default SkypeIdSection;
