import { EmptySelection } from "components/FieldDate/FieldDateSelection";

import { ValidationCallback } from "validation/validate";

export const validateEmptySelection: ValidationCallback<EmptySelection> = (
	input,
	{ flag, checkField }
) => {
	if (input.year !== undefined) {
		flag("year", "must be undefined");
	}
	if (input.month !== undefined) {
		flag("month", "must be undefined");
	}
	if (input.date !== undefined) {
		flag("date", "must be undefined");
	}
};
