import React, { useCallback, useContext } from "react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import Button from "components/Button";
import { AlertContext } from "components/AlertProvider/AlertProvider";

interface Props {
	value: string;
	label?: string;
	disabled?: boolean;
	doneMessage?: string;
}

const ButtonCopyToClipboard: React.FunctionComponent<Props> = ({
	value,
	label,
	disabled = false,
	doneMessage = "Copied to clipboard"
}) => {
	const [addAlert] = useContext(AlertContext);
	const onClick = useCallback(() => {
		navigator.clipboard.writeText(value);
		addAlert({
			contents: doneMessage
		});
	}, [addAlert, value, doneMessage]);

	return (
		<Button
			onClick={onClick}
			icon={faCopy}
			label={label ? label : "Copy to clipboard"}
			disabled={disabled}
		/>
	);
};

export default ButtonCopyToClipboard;
