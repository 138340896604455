import React, { useEffect, useState, useCallback } from "react";
import moment from "moment-timezone";

import { DataInAnyRow } from "components/PageDashboard/ExternalCommitmentsSection/ExternalCommitmentsTable/ExternalCommitmentsTable";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import FieldHourOfDay from "components/FieldHourOfDay";
import { getDateFromSelection } from "components/FieldDate/FieldDateSelection";
import { isInteger } from "utils/isInteger";

function StepTimeTo({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [selectedTime, setSelectedTime] = useState<number | undefined>();

	useEffect(() => {
		if (selectedTime === undefined) {
			disableProgressToNextStep();
			return;
		}
		if (isNaN(selectedTime)) {
			disableProgressToNextStep();
			return;
		}
		if (!isInteger(selectedTime)) {
			disableProgressToNextStep();
			return;
		}

		enableProgressToNextStep({
			timeEndsJustBeforeInRowTimezone: moment.duration(selectedTime, "hours")
		});
	}, [enableProgressToNextStep, disableProgressToNextStep, selectedTime]);

	const {
		dateStartsInRowTimezone,
		timeStartsInRowTimezone,
		dateEndsJustBeforeInRowTimezone,
		timezone
	} = wizardData;

	const checkDisabled = useCallback(
		(time: number) => {
			if (dateStartsInRowTimezone === undefined) {
				return false;
			}
			if (timeStartsInRowTimezone === undefined) {
				return false;
			}
			if (dateEndsJustBeforeInRowTimezone === undefined) {
				return false;
			}

			if (timezone === undefined) {
				return false;
			}

			const dateTimeStarts = moment(
				getDateFromSelection(dateStartsInRowTimezone, timezone)
			)
				.hours(timeStartsInRowTimezone.hours())
				.startOf("hour");

			const thisDateTime = moment(
				getDateFromSelection(dateEndsJustBeforeInRowTimezone, timezone)
			)
				.hours(time)
				.startOf("hour");

			return thisDateTime.isSameOrBefore(dateTimeStarts);
		},
		[
			dateStartsInRowTimezone,
			timeStartsInRowTimezone,
			dateEndsJustBeforeInRowTimezone,
			timezone
		]
	);

	return (
		<FieldHourOfDay
			selectedTime={selectedTime}
			onChange={setSelectedTime}
			isDisabled={checkDisabled}
		/>
	);
}

export default StepTimeTo;
