import { useState } from "react";

export function useResettableState<T>(
	initial: T
): [
	T,
	React.Dispatch<React.SetStateAction<T>>,
	() => void,
	React.Dispatch<React.SetStateAction<T>>
] {
	const [resetValue, setResetValue] = useState<T>(initial);
	const [value, setValue] = useState<T>(initial);
	const reset = () => setValue(resetValue);

	return [value, setValue, reset, setResetValue];
}
