import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	senderUid: string;
	recipientUid: string;
	message: string;
}

type Output = {};
const flagValidationErrors: ValidationCallback<Output> = () => {};

export function sendPrivateMessage(
	{ senderUid, recipientUid, message }: Params,
	auth: Auth
) {
	return makeApiRequest("privateMessage", {
		auth,
		data: {
			post: {
				senderUid,
				recipientUid,
				message
			}
		},
		flagValidationErrors
	});
}
