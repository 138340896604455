import React from "react";

import LabelCheckBox from "./LabelCheckBox";
import { Label, LabelContents } from "./FieldCheckbox.styles";

interface FieldCheckboxProps {
	id: string;
	name: string;
	isScrollToDefault?: boolean;
	checked?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	labelText: string;
	extra?: React.ReactNode;
	disabled?: boolean;
	addHiddenFieldContainingState?: boolean;
	isRequired?: boolean;
	takeUpLessSpace?: boolean;
}

const FieldCheckbox: React.FunctionComponent<FieldCheckboxProps> = ({
	id,
	name,
	isScrollToDefault,
	checked,
	onChange,
	labelText,
	extra,
	disabled,
	addHiddenFieldContainingState = true,
	isRequired = false,
	takeUpLessSpace = false
}) => {
	return (
		<>
			<input
				type="checkbox"
				id={id}
				data-testid={id}
				name={name}
				{...(!!isScrollToDefault ? { "data-is-scroll-to-default": true } : {})}
				checked={!!checked}
				onChange={onChange}
				disabled={!!disabled}
				required={!!isRequired}
			/>
			<Label htmlFor={id} disabled={!!disabled} data-testid={`label-for-${id}`}>
				<LabelCheckBox
					checked={!!checked}
					disabled={!!disabled}
					takeUpLessSpace={takeUpLessSpace}
				/>
				<LabelContents takeUpLessSpace={takeUpLessSpace} checked={!!checked}>
					{labelText}
					{extra ? extra : null}
				</LabelContents>
			</Label>
			{addHiddenFieldContainingState ? (
				<input
					type="hidden"
					name={`${name}-checked`}
					value={!!checked ? "yes" : "no"}
				/>
			) : null}
		</>
	);
};

export default FieldCheckbox;
