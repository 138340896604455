interface Params {
	sessionDurationMinutes: number;
	alwaysUseSingularUnit?: boolean;
	hyphenate?: boolean;
	suffix?: string;
}

export function formatSessionDuration({
	sessionDurationMinutes,
	alwaysUseSingularUnit = false,
	hyphenate = false,
	suffix
}: Params) {
	const numHours = Math.floor(sessionDurationMinutes / 60);
	const numMinutes = sessionDurationMinutes % 60;
	return `${
		numHours
			? `${numHours}${hyphenate ? `-` : ` `}hour${
					numHours > 1 && !alwaysUseSingularUnit ? `s` : ``
			  } `
			: ``
	}${
		numMinutes
			? `${numMinutes}${hyphenate ? `-` : ` `}minute${
					numMinutes > 1 && !alwaysUseSingularUnit ? `s` : ``
			  }`
			: ``
	}${suffix ? suffix : ``}`;
}
