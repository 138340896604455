import { useCallback } from "react";
import flatten from "lodash.flatten";

import { useRemoteResource } from "hooks/useRemoteResource";
import {
	getPackagePrices,
	OutputRow as GetPackagePricesOutput
} from "api/getPackagePrices";
import {
	getCurrencyConversion,
	Output as CurrencyConversionOutput
} from "api/getCurrencyConversion";
import { makeDummyRequest } from "api/makeDummyRequest";
import { SearchResults } from "api/SearchResults";
import { ApiRequest } from "api/ApiRequest";

interface Params {
	mentorId?: string;
	sessionTypeIds: string[];
	showDisabled?: boolean;
	doCurrencyConversion?: boolean;
	doWait?: boolean;
	repeatNumber?: number;
	persistBetweenLoads?: boolean;
	defaultCurrencyCode?: string;
	includePackagePricesForGuestUid?: string;
}

export interface OutputRow extends GetPackagePricesOutput {
	approximateAmountInDefaultCurrency?: number | undefined;
}

export function usePackagePrices({
	mentorId,
	sessionTypeIds,
	showDisabled = false,
	doCurrencyConversion = true,
	doWait = false,
	repeatNumber = 1,
	persistBetweenLoads = false,
	defaultCurrencyCode,
	includePackagePricesForGuestUid
}: Params) {
	// NB!  The following JSON.stringify is very important to prevent an infinite loop of re-running this callback repeatedly on the user profile page
	const sessionTypeIdsStr = JSON.stringify(sessionTypeIds);

	const cb = useCallback(
		(arg, auth) => {
			if (mentorId === undefined) {
				return makeDummyRequest(
					{ page: [] } as SearchResults<OutputRow>,
					"packageprices"
				);
			}

			const ids: string[] = JSON.parse(sessionTypeIdsStr);
			const reqs = ids.map((sessionTypeId: string) =>
				getPackagePrices(
					{
						uid: mentorId,
						sessionTypeId,
						showDisabled,
						includePackagePricesForGuestUid
					},
					auth
				)
			);

			let currencyConversionRequests: ApiRequest<
				CurrencyConversionOutput
			>[] = [];

			let aborted = false;

			return {
				ready: (async () => {
					const allOutputs = (await Promise.all(
						reqs.map(async req => {
							const packagePrices = await req.ready;
							if (!packagePrices) {
								return packagePrices;
							}

							return {
								...packagePrices,
								page: await Promise.all(
									packagePrices.page.map(async entry => {
										const subReq =
											doCurrencyConversion &&
											defaultCurrencyCode &&
											defaultCurrencyCode !== entry.currency
												? getCurrencyConversion(
														{
															sourceCurrency: entry.currency,
															sourceAmount: entry.price,
															destinationCurrency: defaultCurrencyCode
														},
														auth
												  )
												: undefined;

										if (subReq) {
											currencyConversionRequests.push(subReq);
										}

										const convertedAmount = subReq
											? await subReq.ready
											: undefined;

										return {
											...entry,
											approximateAmountInDefaultCurrency: convertedAmount
												? convertedAmount.destinationAmount
												: undefined
										};
									})
								)
							};
						})
					)).map(response => (response ? response.page : []));

					return { page: flatten(allOutputs) };
				})(),
				abort: () => {
					for (const req of reqs) {
						req.abort();
					}
					for (const currencyConversionRequest of currencyConversionRequests) {
						currencyConversionRequest.abort();
					}
					aborted = true;
				},
				aborted: () => !!aborted,
				type: "getMultiplePackagePrices"
			};
		},
		[
			mentorId,
			defaultCurrencyCode,
			sessionTypeIdsStr,
			doCurrencyConversion,
			showDisabled,
			includePackagePricesForGuestUid
		]
	);

	return useRemoteResource(cb, doWait, repeatNumber, persistBetweenLoads);
}
