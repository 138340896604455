export function getBooleanOptions(selectedValue?: boolean) {
	return [
		{
			id: "no",
			value: false,
			text: "No",
			selected: selectedValue === false
		},
		{
			id: "yes",
			value: true,
			text: "Yes",
			selected: selectedValue === true
		}
	];
}
