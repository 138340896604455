import React, { useState, useCallback, useRef, useContext } from "react";

import Modal from "components/Modal";
import ActionButtons from "components/ActionButtons";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { AlertContext } from "components/AlertProvider/AlertProvider";
import { getFormData } from "services/getFormData";
import { sendPrivateMessage } from "api/sendPrivateMessage";
import { useModalStyles } from "hooks/useModalStyles";
import { useSendData } from "hooks/useSendData";

import { useProfileData } from "hooks/useProfileData";

interface MessageDialogProps {
	mentorId: string;
	isInProgress: boolean;
	onCancel: () => void;
	onSent: () => void;
	vanityUrl: string;
	uid: string;
}

// TODO:WV:20240303:If logged out, replace with a wizard that allows deferred
// log in until after the message has been written and an explanation shown
const MessageDialog: React.FunctionComponent<MessageDialogProps> = ({
	mentorId,
	isInProgress,
	onCancel,
	onSent,
	vanityUrl,
	uid: profileUid
}) => {
	const [message, setMessage] = useState<string>("");
	const [addAlert] = useContext(AlertContext);
	const [{ uid: currentUserUid }] = useContext(AuthContext);

	const formRef = useRef(null);

	const sendMessage = useSendData({ method: sendPrivateMessage });
	const handleSubmit = useCallback(
		async e => {
			e.preventDefault();
			const form = e.currentTarget;
			const wizardData = getFormData(form);

			try {
				const req = sendMessage(
					{
						...wizardData
					},
					{ uid: currentUserUid }
				);

				await req.ready;

				addAlert({ contents: "Message received - thank you." });
				setMessage("");
				onSent();
			} catch (e) {
				// TODO:WV:20230502:Report it properly
				console.log("error", e);

				addAlert({
					contents: `Sorry - unfortunately there was an error.\n\nPlease try emailing us directly via ${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}`
				});
			}
		},
		[setMessage, onSent, addAlert, sendMessage, currentUserUid]
	);

	const cancelMessage = useCallback(() => {
		setMessage("");
		onCancel();
	}, [setMessage, onCancel]);

	const modalStyles = useModalStyles("big");

	const { output: profileData } = useProfileData(vanityUrl, profileUid);

	if (
		!(profileData && "uid" in profileData && profileData.uid && currentUserUid)
	) {
		return null;
	}

	return (
		<Modal
			isOpen={isInProgress}
			style={modalStyles}
			onRequestClose={e => {
				cancelMessage();
			}}
		>
			<>
				<h2>Please enter your message</h2>
				<form ref={formRef} onSubmit={handleSubmit}>
					<div className="row gtr-uniform">
						<div className="col-12">
							<input type="hidden" name="senderUid" value={currentUserUid} />
							<input type="hidden" name="recipientUid" value={mentorId} />
							<textarea
								name="message"
								placeholder="Your message"
								rows={4}
								value={message}
								onChange={e => {
									setMessage(e.currentTarget.value);
								}}
							/>
						</div>
						<div className="col-12">
							<ActionButtons
								primary={{
									label: "Send message"
								}}
								secondary={{
									label: "Cancel",
									onClick: cancelMessage
								}}
							/>
						</div>
					</div>
				</form>
			</>
		</Modal>
	);
};

export default MessageDialog;
