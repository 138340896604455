import React, { useCallback, useState } from "react";

import FieldText from "components/FieldText";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { DataInAnyRow } from "components/PageDashboard/PackagePricesSection/PackagePricesTable/PackagePricesTable";
import { MinSessionsNote } from "./StepNumMeetings.styles";

function StepNumMeetings({
	enableProgressToNextStep,
	disableProgressToNextStep
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [currentValue, setCurrentValue] = useState<number>(0);

	const onHaveNewValue = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = e.currentTarget.value
				? parseFloat(e.currentTarget.value)
				: 0;

			setCurrentValue(newValue);

			if (!Number.isInteger(newValue)) {
				disableProgressToNextStep();
				return;
			}

			if (newValue < 1) {
				disableProgressToNextStep();
				return;
			}

			enableProgressToNextStep({
				numMeetings: newValue
			});
		},
		[enableProgressToNextStep, disableProgressToNextStep, setCurrentValue]
	);

	return (
		<>
			<MinSessionsNote>
				There must be at least one session in a package
			</MinSessionsNote>

			<FieldText
				subType="number"
				value={currentValue}
				onChange={onHaveNewValue}
				isInline
			/>
		</>
	);
}

export default StepNumMeetings;
