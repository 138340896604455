import React, { useCallback, useContext } from "react";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import ModalWizard from "components/ModalWizard";
import {
	ModalWizardStep,
	OnCompleteOutput
} from "components/ModalWizard/ModalWizard";
import { captureException } from "services/captureException";
import { validate, ValidationCallback } from "validation/validate";

type NewRowWizardProps<CompletedWizardData> = {
	isInProgress: boolean;
	onCancel: () => void;
	onComplete: (newRow: CompletedWizardData) => OnCompleteOutput;
	steps: ModalWizardStep<CompletedWizardData>[];
	validateNewRowData: ValidationCallback<CompletedWizardData>;
	initialData: Partial<CompletedWizardData>;
};

function NewRowWizard<CompletedWizardData>({
	isInProgress,
	onCancel,
	onComplete,
	steps,
	validateNewRowData,
	initialData
}: NewRowWizardProps<CompletedWizardData>) {
	const [{ uid }] = useContext(AuthContext);

	const handleSubmit = useCallback(
		(data: any) => {
			if (
				validate(data, {
					doValidate: validateNewRowData,
					withErrors: validationErrors => {
						throw captureException(new Error("Invalid new row data"), {
							evtType: "invalidNewRowData",
							extra: {
								validationErrors
							}
						});
					},
					auth: { uid }
				})
			) {
				const req = onComplete(data);
				return req;
			}

			let hasBeenAborted = false;
			return {
				ready: Promise.resolve(),
				abort: () => {
					hasBeenAborted = true;
				},
				aborted: () => hasBeenAborted
			};
		},
		[onComplete, validateNewRowData, uid]
	);

	return (
		<ModalWizard
			isInProgress={isInProgress}
			cancelButtonText="Cancel"
			onCancel={onCancel}
			validateCompletedData={validateNewRowData}
			onComplete={handleSubmit}
			steps={steps}
			initialData={initialData}
		/>
	);
}

export default NewRowWizard;
