import React, { useEffect, useContext, useState, useCallback } from "react";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import AcceptTerms from "components/AcceptTerms";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { useOnClickNext } from "components/ModalWizard/Step/useOnClickNext";
import { useSaveAcceptance } from "components/AcceptTerms/useSaveAcceptance";

function StepAcceptTerms<CompletedWizardData>({
	progressToNextStepNow,
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData,
	setOnClickNext
}: ModalWizardStepRenderProps<CompletedWizardData>) {
	const [{ hasAcceptedTerms, setTermsWallIsDisabled }] = useContext(
		AuthContext
	);

	useEffect(() => {
		if (hasAcceptedTerms) {
			setTermsWallIsDisabled(false);
			progressToNextStepNow(wizardData);
		}
	}, [
		hasAcceptedTerms,
		wizardData,
		progressToNextStepNow,
		setTermsWallIsDisabled
	]);

	const [fieldIsChecked, setFieldIsChecked] = useState(false);

	const saveAcceptance = useSaveAcceptance();
	useOnClickNext(setOnClickNext, saveAcceptance);

	const handleChange = useCallback(
		(newValue: boolean) => {
			if (newValue === true) {
				setFieldIsChecked(true);
				enableProgressToNextStep(wizardData);
			} else {
				setFieldIsChecked(false);
				disableProgressToNextStep();
			}
		},
		[
			setFieldIsChecked,
			enableProgressToNextStep,
			wizardData,
			disableProgressToNextStep
		]
	);

	return <AcceptTerms isChecked={fieldIsChecked} onChange={handleChange} />;
}

export default StepAcceptTerms;
