import React, { useState, useCallback, useContext, useEffect } from "react";

import SessionTypesTable from "components/PageDashboard/SessionTypesSection/SessionTypesTable";
import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { useSendData } from "hooks/useSendData";
import { useSessionTypes } from "hooks/useSessionTypes";
import { saveSessionTypes as sst } from "api/saveSessionTypes";
import { OutputRow as SessionTypesData } from "api/getSessionTypes";
import { AuthContext } from "components/AuthProvider/AuthProvider";

const SessionTypesSection: React.FunctionComponent = () => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);

	const [dashboardContextState, setDashboardContextState] = useContext(
		DashboardContext
	);

	const {
		isWaiting,
		isLoading,
		isError,
		output: sessionTypes
	} = useSessionTypes({
		mentorId: uid,
		showDisabled: true,
		doWait: isSignedIn === undefined || !uid,
		repeatNumber: dashboardContextState.sessionTypesRefreshNum,
		persistBetweenLoads: true
	});

	const [waitModalIsShowing, setWaitModalIsShowing] = useState(false);
	const [waitModalTitle, setWaitModalTitle] = useState("");
	const saveSessionTypes = useSendData({ method: sst });

	const haveSessionTypes =
		sessionTypes === undefined ? undefined : sessionTypes.page.length !== 0;

	useEffect(() => {
		setDashboardContextState(oldState => ({ ...oldState, haveSessionTypes }));
	}, [haveSessionTypes, setDashboardContextState]);

	const onEditSessionTypes = useCallback(
		(rows: { old?: SessionTypesData; new?: SessionTypesData }[]) => {
			const title = rows.every(row => !row.new) ? "Deleting" : "Saving";

			setWaitModalTitle(title);
			setWaitModalIsShowing(true);

			if (!uid) {
				throw new Error("No Uid");
			}

			const req = saveSessionTypes(
				{
					uid,
					rows
				},
				{ uid }
			);

			const ready = req.ready.then(response => {
				setDashboardContextState(oldState => ({
					...oldState,
					sessionTypesRefreshNum: oldState.sessionTypesRefreshNum + 1
				}));
				setWaitModalIsShowing(false);
			});

			return {
				ready,
				abort: () => req.abort(),
				aborted: () => req.aborted()
			};
		},
		[
			uid,
			setWaitModalTitle,
			setWaitModalIsShowing,
			setDashboardContextState,
			saveSessionTypes
		]
	);

	// TODO:WV:20201009:Get the user started, maybe by asking for price during onboarding and / or adding a tutorial / walkthrough of how to set them up
	return (
		<SessionTypesTable
			onEdit={onEditSessionTypes}
			sessionTypes={sessionTypes ? sessionTypes.page : []}
			isLoading={isLoading}
			isWaiting={isWaiting || !sessionTypes}
			isError={isError}
			waitModalTitle={waitModalTitle}
			waitModalIsShowing={waitModalIsShowing}
		/>
	);
};

export default SessionTypesSection;
