import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";
import { SearchResults } from "api/SearchResults";
import { flagValidationErrorsInSearchResults } from "validation/flagValidationErrorsInSearchResults";

export interface OutputRow {
	id: string;
	url: string;
	containsExternallyBookedMeetings: boolean;
}

const flagValidationErrorsInRow: ValidationCallback<OutputRow> = (
	input,
	{ flag, checkField },
	{ uid }
) => {
	checkField("id", { type: "string" });
	checkField("url", { type: "url" });
	checkField("containsExternallyBookedMeetings", { type: "boolean" });
};

const flagValidationErrors: ValidationCallback<SearchResults<OutputRow>> = (
	input,
	flag,
	auth
) =>
	flagValidationErrorsInSearchResults(
		flagValidationErrorsInRow,
		input,
		flag,
		auth
	);

interface GetICalFeedsToImportParams {
	uid?: string;
	cacheInMemory?: boolean;
}

export function getICalFeedsToImport(
	{ uid, cacheInMemory }: GetICalFeedsToImportParams,
	auth: Auth
): ApiRequest<SearchResults<OutputRow>> {
	const req = makeApiRequest("iCalFeedsToImport", {
		cacheInMemory,
		auth,
		data: {
			get: {
				...(uid ? { uid } : {})
			}
		},
		flagValidationErrors
	});

	return {
		ready: req.ready.then(results => {
			return {
				page: results ? results.page : []
			};
		}),
		abort: () => req.abort(),
		aborted: () => req.aborted(),
		type: req.type
	};
}
