import { useEffect } from "react";
import queryString from "query-string";

import { ApiRequest } from "api/ApiRequest";
import { SearchResults } from "api/SearchResults";
import { captureException } from "services/captureException";

type Params<T> = {
	key: string;
	itemsList: {
		isWaiting: boolean;
		isLoading: boolean;
		isError: boolean;
		items: T[] | undefined;
	};
	handleFoundItem: (item: T) => void;
	loadItembyId?: (id: string) => ApiRequest<SearchResults<T>>;
};

export function useHandleItemFromURL<T extends { id: string }>({
	key,
	itemsList: { isWaiting, isLoading, isError, items },
	handleFoundItem,
	loadItembyId
}: Params<T>) {
	const params = queryString.parse(window.location.search);
	const itemFromURLBase64 = params[key];
	const numItems = items ? items.length : undefined;

	useEffect(() => {
		let fetcher: ApiRequest<SearchResults<T>> | undefined;

		async function go() {
			if (isWaiting || isLoading || isError || !items) {
				return;
			}

			if (!(itemFromURLBase64 && typeof itemFromURLBase64 === "string")) {
				return;
			}

			try {
				const { id } = JSON.parse(atob(itemFromURLBase64));

				if (id && typeof id === "string") {
					let itemToAutoSelect: T | undefined;

					// TODO:WV:20201121:Handle the case where there is >1 page of items waiting for a response, e.g. by allowing API requests for meetings by ID in more situations (perhaps with additional parameters, e.g. date range)
					itemToAutoSelect = items.find(item => item.id === id);

					if (loadItembyId) {
						if (!itemToAutoSelect) {
							fetcher = loadItembyId(id);
							const results = await fetcher.ready;
							const [loadedItem] = results ? results.page : [];
							itemToAutoSelect = loadedItem;
						}
					}

					if (itemToAutoSelect) {
						handleFoundItem(itemToAutoSelect);
					}
				}
			} catch (e) {
				captureException(
					new Error(
						"Error decoding item details from query string and selecting appropriate item"
					),
					{
						evtType: "errorFindingItemFromQueryString",
						extra: {
							originalError: e
						}
					}
				);
			}
		}
		go();

		return () => (fetcher ? fetcher.abort() : undefined);
	}, [
		itemFromURLBase64,
		isWaiting,
		isLoading,
		isError,
		numItems,
		items,
		handleFoundItem,
		loadItembyId
	]);
}
