import { useWindowWidth } from "@react-hook/window-size/throttled";

export const thresholds = {
	/* the grid system considers 'x-small' to be narrower than this (i.e. <=480) */
	small: 481,

	/* the grid system consider 'small' to be narrower than this (i.e. <=736) */
	tablet: 737,

	medium: 812,
	large: 980,
	largest: Infinity
};

export function useNextScreenSizeThreshold(): keyof typeof thresholds {
	const windowWidth = useWindowWidth();

	const thresholdEntries = Object.entries(thresholds);
	if (!isThresholdEntries(thresholdEntries)) {
		throw new Error("Invalid threshold entries");
	}

	for (const [name, thresholdWidth] of thresholdEntries.sort(sortKeyValues)) {
		if (windowWidth < thresholdWidth) {
			return name;
		}
	}

	throw new Error("No screen size found");
}

function sortKeyValues([, a]: [string, number], [, b]: [string, number]) {
	return a - b;
}

function isThresholdEntries(
	values: [string, number][]
): values is [keyof typeof thresholds, number][] {
	return values.every(([v]) => v in thresholds);
}
