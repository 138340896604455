import React from "react";

import TextWithLineBreaks from "components/TextWithLineBreaks";
import { useAccountStatus } from "hooks/useAccountStatus";

import { useSessionTypesAndPrices } from "hooks/useSessionTypesAndPrices";
import {
	SessionType,
	SessionTypeVariantsHeading,
	SessionTypeVariantsListOfSessionTypes,
	SessionTypeVariantsListOfPrices,
	SessionTypeVariantsSessionType,
	SessionTypeVariantsPrice
} from "./SessionsOffered.styles";
import SessionTypeVariant from "./SessionTypeVariant";

import { formatSessionDuration } from "services/formatSessionDuration";

interface Props {
	mentorId?: string;
}

const SessionsOffered: React.FunctionComponent<Props> = ({ mentorId }) => {
	const {
		output: sessionTypesAndPrices,
		isWaiting: sessionTypesAndPricesIsWaiting,
		isLoading: sessionTypesAndPricesIsLoading
	} = useSessionTypesAndPrices(mentorId);

	const { sessionTypes } = sessionTypesAndPrices;

	const { locationCountryCode, defaultCurrencyCode } = useAccountStatus();
	const alternateCurrencyCode = defaultCurrencyCode
		? defaultCurrencyCode
		: "GBP";

	if (!(sessionTypes && sessionTypes.length !== 0)) {
		return null;
	}

	// It is necessary to wait for the session types and prices to finish loading before
	// continuing to prevent a race condition when sorting the displayed session types
	// to have free sessions first.  As and when those are changed to be sorted by a
	// teacher-defined sort-index, this may no longer be possible.
	if (sessionTypesAndPricesIsWaiting || sessionTypesAndPricesIsLoading) {
		return null;
	}

	const calculateAvailableSessionLengths = (sessionTypeId: string) =>
		Array.from(
			new Set([
				...sessionTypesAndPrices.packagePrices
					.filter(price => price.sessionTypeId === sessionTypeId)
					.map(price => price.sessionDurationMinutes),
				...sessionTypesAndPrices.singleSessionPrices
					.filter(price => price.sessionTypeId === sessionTypeId)
					.map(price => price.sessionDurationMinutes)
			])
		).sort((a, b) => b - a);

	const sessionLengthsCache: { [k: string]: number[] } = {};
	const sessionLengthsAvailable = (sessionTypeId: string) => {
		if (sessionLengthsCache[sessionTypeId] !== undefined) {
			return sessionLengthsCache[sessionTypeId];
		}
		sessionLengthsCache[sessionTypeId] = calculateAvailableSessionLengths(
			sessionTypeId
		);
		return sessionLengthsCache[sessionTypeId];
	};

	const calculateAvailableSessionPrices = (sessionTypeId: string) =>
		Array.from(
			new Set([
				...sessionTypesAndPrices.packagePrices
					.filter(price => price.sessionTypeId === sessionTypeId)
					.map(price => price.price),
				...sessionTypesAndPrices.singleSessionPrices
					.filter(price => price.sessionTypeId === sessionTypeId)
					.map(price => price.price)
			])
		);

	//  BODGE:WV:20240324:Done as a quick way to get the free initial meeting to the top of the list
	//  TODO:WV:20240324:Implement the sort order properly with a "sort index" parameter on the server
	function isFreeSession(sessionTypeId: string) {
		const prices = calculateAvailableSessionPrices(sessionTypeId);
		return prices.length !== 0 && prices.every(price => price === 0);
	}
	const sortedSessionTypes = sessionTypes.sort((a, b) => {
		const aIsFree = isFreeSession(a.id);
		const bIsFree = isFreeSession(b.id);

		if (aIsFree && !bIsFree) {
			return -1;
		}
		if (bIsFree && !aIsFree) {
			return 1;
		}
		return a.sessionTitle.localeCompare(b.sessionTitle);
	});

	return (
		<>
			<h2>Sessions offered</h2>

			{sortedSessionTypes.map(sessionType => (
				<SessionType key={sessionType.id}>
					<h3>{sessionType.sessionTitle}</h3>
					<TextWithLineBreaks>
						{sessionType.sessionDescription}
					</TextWithLineBreaks>
					{sessionLengthsAvailable(sessionType.id).length ? (
						<>
							<SessionTypeVariantsHeading>
								Durations and prices
							</SessionTypeVariantsHeading>
							<SessionTypeVariantsListOfSessionTypes>
								{sessionLengthsAvailable(sessionType.id).map(
									(sessionDurationMinutes, i) => (
										<SessionTypeVariantsSessionType key={i}>
											{formatSessionDuration({
												sessionDurationMinutes,
												alwaysUseSingularUnit: true,
												hyphenate: true,
												suffix: " classes"
											})}
											<SessionTypeVariantsListOfPrices>
												{[
													...sessionTypesAndPrices.packagePrices.filter(
														price =>
															price.sessionTypeId === sessionType.id &&
															price.sessionDurationMinutes ===
																sessionDurationMinutes
													),
													...sessionTypesAndPrices.singleSessionPrices.filter(
														price =>
															price.sessionTypeId === sessionType.id &&
															price.sessionDurationMinutes ===
																sessionDurationMinutes
													)
												]
													.sort((a, b) => b.price - a.price)
													.map((price, i) => (
														<SessionTypeVariantsPrice key={i}>
															<SessionTypeVariant
																key={price.id}
																currency={price.currency}
																price={price.price}
																locationCountryCode={
																	locationCountryCode
																		? locationCountryCode
																		: "GB"
																}
																approxPriceInAlternateCurrency={
																	price.approximateAmountInDefaultCurrency
																}
																alternateCurrency={alternateCurrencyCode}
																isMenteeSpecific={
																	price.guestUid !== undefined &&
																	price.guestUid !== null
																}
																isPackage={hasNumMeetings(price)}
																numMeetingsInPackage={
																	hasNumMeetings(price)
																		? price.numMeetings
																		: undefined
																}
															/>
														</SessionTypeVariantsPrice>
													))}
											</SessionTypeVariantsListOfPrices>
										</SessionTypeVariantsSessionType>
									)
								)}
							</SessionTypeVariantsListOfSessionTypes>
						</>
					) : null}
				</SessionType>
			))}
		</>
	);
};

export default SessionsOffered;

const hasProp = <T extends object>(
	obj: T,
	key: string
): obj is T & { [key: string]: unknown } => Object.hasOwn(obj, key);

const hasNumMeetings = <T extends object>(
	obj: T
): obj is T & { numMeetings: number } => {
	if (hasProp(obj, "numMeetings")) {
		return typeof obj.numMeetings === "number";
	}
	return false;
};
