import styled from "styled-components";

import { ButtonProps } from "./Button";

import {
	iconWidthInEm,
	iconRightMarginInEm
} from "components/ButtonIcon/ButtonIcon.styles";

const cssForDanger = `
	&, &:hover {
		color: white !important;
		box-shadow: none;
	}
	background-color: #ff6961;
	&: hover {
		background-color: #ff766f;
	}
	
`;

const cssForPrimary = `
	&, &:hover {
		color: white !important;
		box-shadow: none;
	}
	background-color: #0070b2;
	&: hover {
		background-color: #0b81c7;
	}	
`;

const cssForSecondary = `
	&, &:hover {
		color: white !important;
		box-shadow: none;
	}
	background-color: #70d5b2;
	&: hover {
		background-color: #7ed5b7;
	}	
`;

const cssForTertiary = `
	&, &:hover {
		color: white !important;
		box-shadow: none;
	}
	background-color: #d5b570;
	color: #aaaaaa !important;
	font-weight: 300;
`;

const cssForHangingIndent = (
	usualLeftPadding: number,
	hangingIndentWidth: number
) => `
	text-indent: -${hangingIndentWidth}em;
	padding-left: ${usualLeftPadding + hangingIndentWidth}em;

`;

interface Props extends Omit<ButtonProps, "label"> {
	hasIcon: boolean;
	hasLabel: boolean;
}

const horizontalPaddingInEm = 1;
const cssForPadding = ({ isTall }: Props) => `
	padding:${isTall ? 1 : 0.5}em ${horizontalPaddingInEm}em;
`;

const cssForAllowingTextToWrap = ({ hasIcon, hasLabel }: Props) => `
	white-space:normal;
	height: auto;

	${
		!!hasIcon && !!hasLabel
			? cssForHangingIndent(
					horizontalPaddingInEm,
					iconWidthInEm + iconRightMarginInEm
			  )
			: ``
	}
`;

const cssForNotAllowingTextToWrap = `
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const cssForLineHeight = `
	line-height: 2em;
`;

const cssForColourScheme = ({
	isPrimary,
	isSecondary,
	isTertiary,
	isDangerous
}: Props) => {
	if (isDangerous) {
		return cssForDanger;
	}
	if (isPrimary) {
		return cssForPrimary;
	}
	if (isSecondary) {
		return cssForSecondary;
	}
	if (isTertiary) {
		return cssForTertiary;
	}
};

// TODO:WV:20231118:Different colours for primary, secondary, etc.
const cssForActive = ({ isActive }: Props) => {
	return isActive ? "border: 1px solid #8e8e8e" : "";
};

export const WideButton = styled.button<Props>`
	${cssForLineHeight};
	${props => cssForActive(props)};
	${props => cssForPadding(props)};
	${props =>
		props.allowWrap
			? cssForAllowingTextToWrap(props)
			: cssForNotAllowingTextToWrap};
	${props => (props.leftAlign ? `text-align: left` : ``)};
	${props => cssForColourScheme(props)};
`;

const cssForNarrowness = `
	padding: 0 1em;
	height: auto;
	vertical-align: middle;
`;

export const NarrowButton = styled.button<Props>`
	${cssForLineHeight};
	${props => cssForActive(props)};
	${cssForNarrowness};
	${props => cssForPadding(props)};
	${props =>
		props.allowWrap
			? cssForAllowingTextToWrap(props)
			: cssForNotAllowingTextToWrap};
	${props => (props.leftAlign ? `text-align: left` : ``)};
	${props => cssForColourScheme(props)};
`;

export const CollapsibleSectionHeading = styled.button<Props>`
	&,
	&:hover,
	&:active,
	&:focus {
		padding: 0;
		border: none;
		box-shadow: none;
		background: transparent;
	}

	font-size: 1.25em;
	font-family: "Oswald", sans-serif;
	font-weight: 400;
	letter-spacing: 0.15em;
	line-height: 1.5;
	margin: 0 0 1em 0;
	text-transform: uppercase;
	border: none;
	vertical-align: top;
	border: none;
	height: auto;

	text-align: left;

	svg {
		vertical-align: top;
		float: right;
	}
`;

export const BorderlessIconOnlyButtonButton = styled.button<Props>`
	&,
	&:hover,
	&:active,
	&:focus {
		padding: 0;
		border: none;
		box-shadow: none;
		background: transparent;
	}

	height: auto;
	vertical-align: middle;
	${props => (props.isDangerous ? cssForDanger : ``)};
	${props => cssForActive(props)};
`;
