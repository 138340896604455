import { ValidationCallback } from "validation/validate";

// TODO:WV:20230118:De-dup this
export interface Credit {
	id: string;
	sessionDurationMinutes: number;
	sessionTypeId: string;
}

export const flagValidationErrorsInCredit: ValidationCallback<Credit> = (
	input,
	{ checkField }
) => {
	checkField("id", { type: "string" });
	checkField("sessionDurationMinutes", { type: "number" });
	checkField("sessionTypeId", { type: "string" });
};
