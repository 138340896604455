import { makeApiRequest, Auth } from "api/makeApiRequest";
import { SearchResults } from "api/SearchResults";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";
import { flagValidationErrorsInSearchResults } from "validation/flagValidationErrorsInSearchResults";

export interface OutputRow {
	id: string;
	sessionTypeId: string;
	price: number;
	currency: string;
	sessionDurationMinutes: number;
	isEnabled: boolean;
	series: string;
	guestUid: string | null;
}

interface Params {
	uid: string;
	sessionTypeId: string;

	showDisabled?: boolean;
	includePricesForGuestUid?: string;
}

export const flagValidationErrorsInRow: ValidationCallback<OutputRow> = (
	input,
	{ flag, checkField }
) => {
	checkField("id", { type: "string" });
	checkField("sessionTypeId", { type: "string" });
	checkField("price", { type: "number" });
	checkField("currency", { type: "string" });
	checkField("sessionDurationMinutes", { type: "number" });
	checkField("isEnabled", { type: "boolean" });
	checkField("series", { type: "string" });

	// Not using checkField to check guestUid because "null" is a meaningful value, and checkField does not support string-or-null
	if (input.guestUid && typeof input.guestUid !== "string") {
		flag("guestUid", "not a string or null");
	}
};

export const flagValidationErrors: ValidationCallback<
	SearchResults<OutputRow>
> = (input, flag, auth) =>
	flagValidationErrorsInSearchResults(
		flagValidationErrorsInRow,
		input,
		flag,
		auth
	);

export function getSingleSessionPrices(
	{ uid, sessionTypeId, showDisabled, includePricesForGuestUid }: Params,
	auth: Auth
): ApiRequest<SearchResults<OutputRow>> {
	return makeApiRequest("singleSessionPrices", {
		auth,
		data: {
			get: {
				uid,
				sessionTypeId,
				...(showDisabled ? { showDisabled: "1" } : {}),
				...(includePricesForGuestUid ? { includePricesForGuestUid } : {})
			}
		},
		flagValidationErrors
	});
}
