import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	formData: { [k: string]: string };
	recaptchaToken: string;
}

type Output = {};
const flagValidationErrors: ValidationCallback<Output> = (input, flag) =>
	typeof input === "object" && Object.keys(input).length === 0;

export function saveContactFormSubmission(
	{ formData, recaptchaToken }: Params,
	auth: Auth
): ApiRequest<Output> {
	const { email, enquiryType, ...data } = formData;

	return makeApiRequest("contactForm", {
		auth,
		data: {
			post: {
				enquiryType,
				email,
				data,
				_recaptcha: recaptchaToken
			}
		},
		flagValidationErrors
	});
}
