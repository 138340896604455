import { useCallback, useContext } from "react";
import { XOR } from "ts-xor";
import { useStripe } from "@stripe/react-stripe-js";

import { ApiRequest } from "api/ApiRequest";
import { makePaymentIntent } from "api/makePaymentIntent";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { Output } from "./usePayWithNewCard";

interface ParamsBasic {
	stripePaymentMethodId: string;
}

interface ParamsWithMeetingId extends ParamsBasic {
	meetingId: string;
}

interface ParamsWithPackageId extends ParamsBasic {
	packageId: string;
}

type Params = XOR<ParamsWithMeetingId, ParamsWithPackageId>;

export function usePayWithSavedPaymentMethod() {
	const stripe = useStripe();

	const [{ uid }] = useContext(AuthContext);

	const payWithSavedPaymentMethod = useCallback(
		({
			stripePaymentMethodId,
			meetingId,
			packageId
		}: Params): ApiRequest<Output> => {
			if (!uid) {
				throw new Error("No authenticated user");
			}
			if (!stripe) {
				throw new Error("Stripe not loaded yet");
			}

			const req = makePaymentIntent(
				{
					uid,
					intendToSaveForFutureUse: false,
					stripePaymentMethodId,
					...(meetingId ? { meetingId } : {}),
					...(packageId ? { packageId } : {})
				},
				{ uid }
			);

			const ready = (async () => {
				const responseData = await req.ready;

				if (!responseData) {
					throw new Error("No response data");
				}

				const { clientSecret } = responseData;

				// TODO:WV:20201015:Disable the form until this completes, as per instructions https://stripe.com/docs/payments/save-and-reuse
				const result = await stripe.confirmCardPayment(clientSecret, {
					payment_method: stripePaymentMethodId
				});

				return {
					stripeError: result.error,
					stripePaymentIntent: result.paymentIntent
				};
			})();

			return {
				ready,
				abort: () => req.abort(),
				aborted: () => req.aborted(),
				type: "payWithSavedPaymentMethod"
			};
		},
		[stripe, uid]
	);

	return payWithSavedPaymentMethod;
}
