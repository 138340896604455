import React, { useState, useCallback, useContext, useEffect } from "react";
import flatten from "lodash.flatten";

import SingleSessionPricesTable from "components/PageDashboard/SingleSessionPricesSection/SingleSessionPricesTable";
import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { useSendData } from "hooks/useSendData";
import { useSessionTypes } from "hooks/useSessionTypes";
import { useSingleSessionPrices } from "hooks/useSingleSessionPrices";
import { saveSingleSessionPrices as ssp } from "api/saveSingleSessionPrices";
import { OutputRow as SingleSessionPricesData } from "api/getSingleSessionPrices";
import { AuthContext } from "components/AuthProvider/AuthProvider";

export interface SingleSessionPriceWithSessionType {
	id: string;
	price: number;
	currency: string;
	sessionDurationMinutes: number;
	isEnabled: boolean;
	sessionTypeId: string;
	sessionDescription: string;
	sessionTitle: string;
	series: string;
	guestUid: string | null;
}

const SingleSessionPricesSection: React.FunctionComponent = () => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);

	const [dashboardContextState, setDashboardContextState] = useContext(
		DashboardContext
	);

	const { sessionTypesRefreshNum } = dashboardContextState;
	const {
		isWaiting: sessionTypesWaiting,
		isLoading: sessionTypesLoading,
		isError: sessionTypesError,
		output: sessionTypes
	} = useSessionTypes({
		mentorId: uid,
		showDisabled: true,
		doWait: isSignedIn === undefined || !uid,
		repeatNumber: sessionTypesRefreshNum,
		persistBetweenLoads: true
	});

	// We should refetch prices whever the session-types change, or the prices are edited.
	// So, set up a repeatNum for prices, which can be changed within this script, but also
	// update it whenever the sessionTypesRefreshNum changes.
	const [pricesRefreshNum, setPricesRefreshNum] = useState(
		sessionTypesRefreshNum
	);

	const {
		isWaiting: singleSessionPricesWaiting,
		isLoading: singleSessionPricesLoading,
		isError: singleSessionPricesError,
		output: singleSessionPrices
	} = useSingleSessionPrices({
		mentorId: uid,
		showDisabled: true,
		doCurrencyConversion: false,
		doWait: isSignedIn === undefined || !uid,

		// NB! This depends on both pricesRefreshNum and sessionTypesRefreshNum so that this list
		// of session-prices updates whenever either type of object is edited.
		repeatNumber: pricesRefreshNum + sessionTypesRefreshNum,

		persistBetweenLoads: true,
		sessionTypeIds: sessionTypes
			? sessionTypes.page.map(sessionType => sessionType.id)
			: [],
		includePricesForGuestUid: "all"
	});

	const [waitModalIsShowing, setWaitModalIsShowing] = useState(false);
	const [waitModalTitle, setWaitModalTitle] = useState("");
	const saveSingleSessionPrices = useSendData({ method: ssp });

	const haveSingleSessionPrices =
		singleSessionPrices === undefined
			? undefined
			: singleSessionPrices.page.length !== 0;

	useEffect(() => {
		setDashboardContextState(oldState => ({
			...oldState,
			haveSingleSessionPrices
		}));
	}, [haveSingleSessionPrices, setDashboardContextState]);

	const onEditSingleSessionPrices = useCallback(
		(
			rows: { old?: SingleSessionPricesData; new?: SingleSessionPricesData }[]
		) => {
			const title = rows.every(row => !row.new) ? "Deleting" : "Saving";

			setWaitModalTitle(title);
			setWaitModalIsShowing(true);

			if (!uid) {
				throw new Error("No Uid");
			}

			const req = saveSingleSessionPrices(
				{
					uid,
					rows
				},
				{ uid }
			);

			const ready = req.ready.then(response => {
				setPricesRefreshNum(oldState => oldState + 1);
				setWaitModalIsShowing(false);
			});

			return {
				ready,
				abort: () => req.abort(),
				aborted: () => req.aborted()
			};
		},
		[
			uid,
			setWaitModalTitle,
			setWaitModalIsShowing,
			setPricesRefreshNum,
			saveSingleSessionPrices
		]
	);

	// NB this should be done this way round (find singleSessionPrices for each sessionType, rather than iterate through singleSessionPrices and add the appropriate sessionType)
	// because otherwise there is a risk of a problem if a singleSessionPrice has been loaded, but not its corresponding sessionType.
	const singleSessionPricesWithSessionTypes = sessionTypes
		? flatten(
				sessionTypes.page.map(sessionType => {
					const {
						id: sessionTypeId,
						sessionDescription,
						sessionTitle
					} = sessionType;
					const singleSessionPricesWithThisSessionType = (singleSessionPrices
						? singleSessionPrices.page
						: []
					).filter(
						singleSessionPrice =>
							singleSessionPrice.sessionTypeId === sessionTypeId
					);

					return singleSessionPricesWithThisSessionType.map(
						singleSessionPrice => {
							const {
								id,
								price,
								currency,
								sessionDurationMinutes,
								isEnabled,
								sessionTypeId,
								series,
								guestUid
							} = singleSessionPrice;

							return {
								id,
								price,
								currency,
								sessionDurationMinutes,
								isEnabled,
								sessionTypeId,
								sessionDescription,
								sessionTitle,
								series,
								guestUid
							};
						}
					);
				})
		  )
		: [];

	// TODO:WV:20201009:Get the user started, maybe by asking for price during onboarding and / or adding a tutorial / walkthrough of how to set them up
	return (
		<SingleSessionPricesTable
			onEdit={onEditSingleSessionPrices}
			singleSessionPricesWithSessionTypes={
				singleSessionPricesWithSessionTypes
					? singleSessionPricesWithSessionTypes
					: []
			}
			isLoading={sessionTypesLoading || singleSessionPricesLoading}
			isWaiting={sessionTypesWaiting || singleSessionPricesWaiting}
			isError={sessionTypesError || singleSessionPricesError}
			waitModalTitle={waitModalTitle}
			waitModalIsShowing={waitModalIsShowing}
		/>
	);
};

export default SingleSessionPricesSection;
