import React from "react";

export interface StepHeaderProps {
	anyVisibleLaterStepsMightBeSkippable: boolean;
	stepNumber: number;
	totalNumSteps: number;
	title: string;
	wizardDescription: string | undefined;
}

const StepHeader: React.FunctionComponent<StepHeaderProps> = ({
	anyVisibleLaterStepsMightBeSkippable,
	stepNumber,
	totalNumSteps,
	title,
	wizardDescription
}) => {
	return (
		<h2>
			Step {stepNumber} of {anyVisibleLaterStepsMightBeSkippable ? "max. " : ""}
			{totalNumSteps} : {title}
			{wizardDescription ? ` | (${wizardDescription})` : ``}
		</h2>
	);
};

export default StepHeader;
