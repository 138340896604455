import React from "react";

import ButtonCopyToClipboard from "components/ButtonCopyToClipboard";
import { useAccountStatus } from "hooks/useAccountStatus";
import terminology from "terminology.json";

import { IntroText, FeedURLContainer } from "./ICalSection.styles";

const ICalSection: React.FunctionComponent = () => {
	const { icalKey } = useAccountStatus();

	const feedURL = `${process.env.REACT_APP_API_URL}/api/ical/meetings/user/${icalKey}`;

	return (
		<>
			<IntroText>
				You can use your iCal feed to import a list of your{" "}
				{terminology.siteName} {terminology.meetings} into other apps, such as
				(for example) Google Calendar.
			</IntroText>
			<FeedURLContainer>
				<ButtonCopyToClipboard
					value={feedURL}
					label="Copy feed URL to clipboard"
					doneMessage="Feed URL copied to clipboard"
				/>
			</FeedURLContainer>
		</>
	);
};

export default ICalSection;
