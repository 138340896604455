import styled from "styled-components";

export const FieldDateDayOfMonth = styled.span`
	margin-right: 1em;
	display: inline-block;
	width: 2em;
`;

export const FieldDateDropDown = styled.select<{ isNotValid?: boolean }>`
	width: auto;
	display: inline;
	margin-right: 1em;
	${props => (props.isNotValid ? `border: 1px solid #ff6666;` : ``)};
`;

export const DatePartOption = styled.option<{ disabled?: boolean }>`
	color: #${props => (props.disabled ? `aaaaaa` : `777777`)};
`;
