import React, { useState, useEffect, useContext } from "react";
import moment from "moment-timezone";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import {
	CompletedWizardData,
	SingleSessionPrice,
	SessionType,
	SelectedDurationAndPrice
} from "../BookingWizard";

import { FieldOption } from "components/FieldSelect/FieldSelect";
import FieldSelect from "components/FieldSelect";
import SelectedEventSummary from "../SelectedEventSummary";
import { useAccountStatus } from "hooks/useAccountStatus";
import { formatSessionPriceAndDuration } from "services/formatSessionPriceAndDuration";
import { formatSessionDuration } from "services/formatSessionDuration";
import { useCredits } from "hooks/useCredits";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import terminology from "terminology.json";

import { useSessionTypesAndPrices } from "hooks/useSessionTypesAndPrices";
import { FieldSelectContainer } from "./StepChooseSessionDuration.styles";

const defaultCountryCodeUntilAccountStatusLoads = "GB";

function StepChooseSessionDuration({
	wizardData: { mentorId, sessionType, eventToBook },
	enableProgressToNextStep,
	disableProgressToNextStep
}: ModalWizardStepRenderProps<CompletedWizardData>) {
	const {
		isWaiting: sessionTypesAndPricesWaiting,
		isLoading: sessionTypesAndPricesLoading,
		isError: sessionTypesAndPricesError,
		output: sessionTypesAndPrices
	} = useSessionTypesAndPrices(mentorId);

	const { locationCountryCode } = useAccountStatus();

	const [{ uid }] = useContext(AuthContext);

	const creditTypes =
		sessionType === undefined
			? []
			: sessionTypesAndPrices.packagePrices
					.filter(packagePrice => (packagePrice.sessionTypeId = sessionType.id))
					.filter(
						/* Filter out package-prices for identical sessionDurationMinutes, but different numbers of sessions */
						(value, index, self) =>
							index ===
							self.findIndex(
								element =>
									element.sessionDurationMinutes ===
									value.sessionDurationMinutes
							)
					)
					.map(({ sessionTypeId, sessionDurationMinutes }) => ({
						sessionTypeId,
						sessionDurationMinutes,
						spendingStatus: "available" as "available"
					}));

	const {
		isWaiting: creditsWaiting,
		isLoading: creditsLoading,
		isError: creditsError,
		output: credits
	} = useCredits({
		uid,
		creditTypes
	});

	if (!sessionType) {
		throw new Error("No session type");
	}

	const [currentOptions, setCurrentOptions] = useState<
		FieldOption<SelectedDurationAndPrice>[]
	>(
		generateOptions(
			sessionType,
			sessionTypesAndPrices,
			credits,
			locationCountryCode
				? locationCountryCode
				: defaultCountryCodeUntilAccountStatusLoads
		)
	);
	const numCurrentOptions = currentOptions.length;
	const pricesAndCreditsStr = JSON.stringify({
		sessionTypesAndPrices,
		credits
	});
	const sessionTypeStr = sessionType ? JSON.stringify(sessionType) : undefined;
	useEffect(() => {
		const { sessionTypesAndPrices, credits } = JSON.parse(pricesAndCreditsStr);
		if (numCurrentOptions === 0) {
			const sessionTypeDecoded = sessionTypeStr
				? JSON.parse(sessionTypeStr)
				: undefined;

			if (!sessionTypeDecoded) {
				throw new Error("No session type available");
			}

			setCurrentOptions(
				generateOptions(
					sessionTypeDecoded,
					sessionTypesAndPrices,
					credits,
					locationCountryCode
						? locationCountryCode
						: defaultCountryCodeUntilAccountStatusLoads
				)
			);
		}
	}, [
		pricesAndCreditsStr,
		numCurrentOptions,
		setCurrentOptions,
		locationCountryCode,
		sessionTypeStr
	]);

	useEffect(() => {
		const selectedOption = currentOptions.find(opt => !!opt.selected);
		const selectedOptionValue = selectedOption
			? selectedOption.value
			: undefined;

		if (selectedOptionValue) {
			if (!eventToBook) {
				throw new Error("No eventToBook");
			}

			/* Update end-time of the session to account for what the user chose
		       in this step (otherwise, it would be one-hour after the start-time) */
			eventToBook.end = moment(eventToBook.start)
				.clone()
				.add(selectedOptionValue.sessionDurationMinutes, "minutes")
				.toDate();

			enableProgressToNextStep({
				selectedDurationAndPrice: selectedOptionValue,
				eventToBook
			});
		} else {
			disableProgressToNextStep();
		}
	}, [
		enableProgressToNextStep,
		disableProgressToNextStep,
		currentOptions,
		eventToBook
	]);

	return (
		<>
			{/* Don't show end-time because it is currently one hour after the start-time (but it should depend on what the user chooses in this step) */}
			<SelectedEventSummary eventToBook={eventToBook} showEndTime={false} />
			<FieldSelectContainer>
				<FieldSelect
					allowMultipleSelections={false}
					isLoading={
						sessionTypesAndPricesLoading ||
						sessionTypesAndPricesWaiting ||
						creditsLoading ||
						creditsWaiting
					}
					isError={sessionTypesAndPricesError || creditsError}
					currentOptions={currentOptions}
					onNewCurrentOptions={setCurrentOptions}
				/>
			</FieldSelectContainer>
		</>
	);
}

export default StepChooseSessionDuration;

function generateOptions(
	sessionType: SessionType,
	sessionTypesAndPrices: ReturnType<typeof useSessionTypesAndPrices>["output"],
	credits: ReturnType<typeof useCredits>["output"],
	locationCountryCode: string
) {
	const creditOptions = [];
	for (const response of credits ? credits : []) {
		if (!response) {
			continue;
		}
		if (response.page.length === 0) {
			continue;
		}
		const credit = response.page[0];
		const numCredits = response.count;
		creditOptions.push({ ...credit, type: "credit" as "credit", numCredits });
	}

	const cashOptions = sessionTypesAndPrices.singleSessionPrices
		.filter(
			singleSessionPrice => singleSessionPrice.sessionTypeId === sessionType.id
		)
		.map(opt => ({ ...opt, type: "cash" as "cash" }));
	const allOptions = [...creditOptions, ...cashOptions].sort(
		(a, b) => a.sessionDurationMinutes - b.sessionDurationMinutes
	);

	const output = allOptions.map(option => {
		const value =
			option.type === "credit"
				? getValueFromCredit(option)
				: getValueFromSingleSessionPrice(option);
		const text =
			option.type === "credit"
				? formatSessionDuration({
						sessionDurationMinutes: option.sessionDurationMinutes
				  }) + ` (pay with credit - ${option.numCredits} remaining)`
				: formatSessionPriceAndDuration({
						singleSessionPrice: option,
						locationCountryCode
				  }) +
				  (option.guestUid ? ` - ${terminology.mentee}-specific price` : "");
		const id = JSON.stringify(value);
		return {
			id,
			value,
			text
		};
	});

	return output;
}

function getValueFromSingleSessionPrice({
	id,
	price,
	currency,
	sessionDurationMinutes
}: SingleSessionPrice): SelectedDurationAndPrice {
	return {
		id,
		price,
		currency,
		sessionDurationMinutes,
		type: "cash" as "cash"
	};
}

interface Credit {
	id: string;
	sessionDurationMinutes: number;
}

function getValueFromCredit({
	id,
	sessionDurationMinutes
}: Credit): SelectedDurationAndPrice {
	return {
		id,
		sessionDurationMinutes,
		price: 0,
		currency: "-",
		type: "credit" as "credit"
	};
}
