import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
	&:hover,
	&:visited,
	&:active {
		color: inherit !important;
	}
`;
