import { OutputRow as PackagesData } from "api/getPackages";
import { OutputRow as PackageRequestsData } from "api/getPackageRequests";

export function formatPackageDetails(
	packageRequest: PackagesData | PackageRequestsData,
	currentUid: string,
	useShortFormat: boolean = false
) {
	const { id, hostUid, guestUid, sessionType, packagePrice } = packageRequest;

	const partnersUid = hostUid === currentUid ? guestUid : hostUid;
	const partnersName =
		hostUid === currentUid
			? packageRequest.displayNameGuest
			: packageRequest.displayNameHost;

	const sessions = `${sessionType.sessionTitle}, ${packagePrice.sessionDurationMinutes} mins, x ${packagePrice.numMeetings}`;

	return {
		hostUid,
		guestUid,
		partnersName: partnersName ? partnersName : "(unknown)",
		identifier: "package-request-" + id,
		partnersUid,
		sessions
	};
}
