import React, { useCallback, useContext } from "react";

import { AlertContext } from "components/AlertProvider/AlertProvider";
import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { OutputRow as MeetingRescheduleRequestsData } from "api/getMeetingRescheduleRequests";
import { useStripQueryStringFromAddressBar } from "hooks/useStripQueryStringFromAddressBar";
import { useHandleItemFromURL } from "hooks/useHandleItemFromURL";

import ModalRespondToRescheduleRequest from "./ModalRespondToRescheduleRequest";

interface Props {
	respondingToRescheduleRequest: MeetingRescheduleRequestsData | undefined;
	setRespondingToRescheduleRequest: React.Dispatch<
		React.SetStateAction<MeetingRescheduleRequestsData | undefined>
	>;
	isWaiting: boolean;
	isLoading: boolean;
	isError: boolean;
	events: MeetingRescheduleRequestsData[];
}

const RescheduleResponses: React.FunctionComponent<Props> = ({
	setRespondingToRescheduleRequest,
	respondingToRescheduleRequest,
	isWaiting,
	isLoading,
	isError,
	events
}) => {
	const handleCancel = useCallback(async () => {
		setRespondingToRescheduleRequest(undefined);
	}, [setRespondingToRescheduleRequest]);

	const [, setDashboardContextState] = useContext(DashboardContext);

	const [addAlert] = useContext(AlertContext);

	const handleResponseSentOK = useCallback(async () => {
		addAlert({ contents: "Response received!  Thank you" });
		setRespondingToRescheduleRequest(undefined);
		setDashboardContextState(oldState => ({
			...oldState,
			meetingsSectionRefreshNum: new Date().getTime()
		}));
	}, [setRespondingToRescheduleRequest, setDashboardContextState, addAlert]);

	const stripQueryStringFromAddressBar = useStripQueryStringFromAddressBar();
	useHandleItemFromURL<MeetingRescheduleRequestsData>({
		key: "meetingRescheduleRequest",
		itemsList: {
			isWaiting,
			isLoading,
			isError,
			items: events
		},
		handleFoundItem: useCallback(
			event => {
				setRespondingToRescheduleRequest(event);
				stripQueryStringFromAddressBar();
			},
			[setRespondingToRescheduleRequest, stripQueryStringFromAddressBar]
		)
	});

	return (
		<ModalRespondToRescheduleRequest
			rescheduleRequest={respondingToRescheduleRequest}
			isOpen={!!respondingToRescheduleRequest}
			onCancel={handleCancel}
			onOK={handleResponseSentOK}
		/>
	);
};

export default RescheduleResponses;
