import { getOptions } from "./getOptions";
import { getAvailableCurrencies } from "./getAvailableCurrencies";

// TODO:WV:20201012:Choose suitable default selectedValue based on the user's location
export function getCurrencyOptions(selectedValue: string = "GBP") {
	const currencies = getAvailableCurrencies();

	return getOptions(
		Object.entries(currencies).map(([currencyCode, currencyDetails]) => ({
			value: currencyCode,
			text: currencyDetails.name
		})),
		selectedValue ? [selectedValue] : undefined
	);
}
