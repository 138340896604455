import React, { useEffect, useContext } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";

import { AuthContext } from "components/AuthProvider/AuthProvider";

interface PublicOnlyRouteProps {
	exact?: boolean;
	path: string;
}

const PublicOnlyRoute: React.FunctionComponent<PublicOnlyRouteProps> = ({
	path,
	exact = false,
	children
}) => {
	const [{ isSignedIn }] = useContext(AuthContext);

	const history = useHistory();

	useEffect(() => {
		if (isSignedIn) {
			history.push("/dashboard");
		}
	}, [isSignedIn, history]);

	return (
		<Route exact={exact} path={path}>
			{isSignedIn === true ? (
				<Redirect to="/dashboard" />
			) : isSignedIn === false ? (
				children
			) : null}
		</Route>
	);
};

export default PublicOnlyRoute;
