import { useState, useCallback } from "react";

import {
	validateMeetingCalendarEvent,
	MeetingCalendarEvent,
	SlotDurationInMinutes
} from "components/MeetingCalendar/MeetingCalendar";
import { useAvailabilityCalendarData } from "hooks/useAvailabilityCalendarData";
import { useStartWizard } from "components/PageUserProfile/wizards/sharedHooks/useStartWizard";

import { useProfileData } from "hooks/useProfileData";
import terminology from "terminology.json";

export const useBookingWizard = (
	slotDurationInMinutes: SlotDurationInMinutes,
	vanityUrl?: string,
	uid?: string
) => {
	const { output: profileData } = useProfileData(vanityUrl, uid);

	const mentorId =
		profileData && profileData.isMentor ? profileData.uid : undefined;

	const {
		update: updateAvailabilityCalendarData
	} = useAvailabilityCalendarData({
		mentorId,
		slotDurationInMinutes
	});

	const [bookingWizardInProgress, setBookingWizardInProgress] = useState<{
		isInProgress: boolean;
		selectedItem?: MeetingCalendarEvent;
		currentUserUid?: string;
	}>({ isInProgress: false });

	const startBookingWizard = useStartWizard<MeetingCalendarEvent>(
		terminology.meeting,
		setBookingWizardInProgress,
		vanityUrl,
		uid,
		validateMeetingCalendarEvent
	);

	const handleEndBookingWizard = useCallback(() => {
		setBookingWizardInProgress({ isInProgress: false });
		updateAvailabilityCalendarData();
	}, [setBookingWizardInProgress, updateAvailabilityCalendarData]);

	return {
		bookingWizardInProgress,
		setBookingWizardInProgress,
		startBookingWizard,
		handleEndBookingWizard
	};
};
