import React, { useState, useCallback } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import ActionButtons from "components/ActionButtons";
import ModalFieldText from "components/ModalFieldText";
import { useAccountStatus } from "hooks/useAccountStatus";
import { StripeAccountOnboardingStatus } from "services/stripe/isValidStripeAccountOnboardingStatus";
import { StripeAccountPayoutsStatus } from "services/stripe/isValidStripeAccountPayoutsStatus";
import StripeLinker from "components/StripeLinker";
import KeyValueTable from "components/KeyValueTable";
import Button from "components/Button";
import { useSaveDataAndRefreshAccountDetailsFromApi } from "hooks/useSaveDataAndRefreshAccountDetailsFromApi";
import ModalPleaseWait from "components/ModalPleaseWait";
import { captureMessage } from "services/captureMessage";
import { useOpenStripeDashboard } from "hooks/useOpenStripeDashboard";
import terminology from "terminology.json";

import {
	PaymentMethodsWrapper,
	ActionButtonsWrapper
} from "./ReceivingMoneySection.styles";

const ReceivingMoneySection: React.FunctionComponent = () => {
	const {
		stripeAccountOnboardingStatus,
		stripeAccountPayoutsStatus,
		paymentProvidersMBWay,
		paymentProvidersWiseEmail,
		paymentProvidersWiseTelephone,
		paymentProvidersCustom
	} = useAccountStatus();

	const [isSaving, setIsSaving] = useState(false);

	const submitDataToAPI = useSaveDataAndRefreshAccountDetailsFromApi(
		"saveAccountSettings",
		"saving your settings",
		setIsSaving,
		async () => {}
	);

	const openStripeDashboard = useOpenStripeDashboard();

	const getStripeDescription = useCallback(
		(
			stripeAccountOnboardingStatus?: StripeAccountOnboardingStatus,
			stripeAccountPayoutsStatus?: StripeAccountPayoutsStatus
		) => {
			if (
				stripeAccountOnboardingStatus === undefined ||
				stripeAccountPayoutsStatus === undefined
			) {
				return "loading...";
			}

			const actionRequired = (
				<>
					Action required{" "}
					<Button
						onClick={openStripeDashboard}
						label="Open Stripe dashboard"
						isNarrow
					/>
				</>
			);

			switch (stripeAccountOnboardingStatus) {
				case "unlinked":
					return "(not configured)";
				case "linked":
					if (stripeAccountPayoutsStatus === "disabled") {
						return actionRequired;
					}
					return "Ready to accept payments";
				case "account-requested":
					return "Application pending";
				case "onboarding":
					return actionRequired;
				default:
					captureMessage("Unknown stripe status", {
						evtType: "unknownStripeStatus",
						extra: {
							stripeAccountOnboardingStatus,
							stripeAccountPayoutsStatus
						}
					});
					return "Unknown status";
			}
		},
		[openStripeDashboard]
	);

	const [isEditing, setIsEditing] = useState(false);

	const rgxTelephoneNumber = /^\s*\+?[0-9]+\s*$/;
	const rgxEmailSimple = /^\s*\S+@\S+\s*$/;

	const handleOKMBWay = useCallback(
		(value: string) => {
			submitDataToAPI({ data: { paymentProvidersMBWay: value.trim() } });
		},
		[submitDataToAPI]
	);
	const handleOKWiseEmail = useCallback(
		(value: string) => {
			submitDataToAPI({ data: { paymentProvidersWiseEmail: value.trim() } });
		},
		[submitDataToAPI]
	);
	const handleOKWiseTelephone = useCallback(
		(value: string) => {
			submitDataToAPI({
				data: { paymentProvidersWiseTelephone: value.trim() }
			});
		},
		[submitDataToAPI]
	);
	const handleOKCustom = useCallback(
		(value: string) => {
			submitDataToAPI({ data: { paymentProvidersCustom: value.trim() } });
		},
		[submitDataToAPI]
	);

	return (
		<>
			<ModalPleaseWait isOpen={isSaving} title="Saving" />

			<h2>Payment methods</h2>

			<PaymentMethodsWrapper>
				<KeyValueTable
					supportsLongEntries
					data={[
						{
							row: [
								"Card",
								getStripeDescription(
									stripeAccountOnboardingStatus,
									stripeAccountPayoutsStatus
								)
							],
							key: "card"
						},
						{
							row: [
								"MBWay",
								<ModalFieldText
									isUnlocked={isEditing}
									title="How to pay you by MBWay"
									helptext="Enter your MBWay number below"
									onOK={handleOKMBWay}
									value={paymentProvidersMBWay ? paymentProvidersMBWay : ""}
									validate={value => rgxTelephoneNumber.test(value)}
								/>
							],
							key: "mbway"
						},
						{
							row: [
								"Wise (email)",
								<ModalFieldText
									isUnlocked={isEditing}
									title="How to pay you by Wise using your email address"
									helptext="If you have enabled pay-with-email on Wise, enter the relevant email address below"
									onOK={handleOKWiseEmail}
									value={
										paymentProvidersWiseEmail ? paymentProvidersWiseEmail : ""
									}
									validate={value => rgxEmailSimple.test(value)}
								/>
							],
							key: "wise-email"
						},
						{
							row: [
								"Wise (telephone)",
								<ModalFieldText
									isUnlocked={isEditing}
									title="How to pay you by Wise using your telephone number"
									helptext="If you have enabled pay-with-telephone-number on Wise, enter the relevant number below"
									onOK={handleOKWiseTelephone}
									value={
										paymentProvidersWiseTelephone
											? paymentProvidersWiseTelephone
											: ""
									}
									validate={value => rgxTelephoneNumber.test(value)}
								/>
							],
							key: "wise-telephone"
						},
						{
							row: [
								"Custom",
								<ModalFieldText
									isUnlocked={isEditing}
									title="Any custom payment instructions"
									helptext={`If you want to be able to receive money by any other payment method, please add brief instructions for your ${terminology.mentees} here`}
									onOK={handleOKCustom}
									value={paymentProvidersCustom ? paymentProvidersCustom : ""}
								/>
							],
							key: "custom"
						}
					]}
				/>
			</PaymentMethodsWrapper>

			<ActionButtonsWrapper>
				<ActionButtons
					secondary={{
						onClick: () => setIsEditing(isEditing ? false : true),
						icon: faPen,
						label: isEditing ? "Done" : "Edit"
					}}
				/>
			</ActionButtonsWrapper>

			<h2>Online card payments via Stripe</h2>
			<StripeLinker />
		</>
	);
};

export default ReceivingMoneySection;
