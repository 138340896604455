import { useMemo } from "react";

import { useNextScreenSizeThreshold } from "hooks/useNextScreenSizeThreshold";
import { useScreenSizeIsMax } from "hooks/useScreenSizeIsMax";
import { thresholds } from "hooks/useNextScreenSizeThreshold";

export const wholeScreenWindowSizeThreshold = "large";
export type ModalSize = "big" | "medium" | "small";

export function useModalStyles(size: ModalSize, isHidden: boolean = false) {
	const nextScreenSizeThreshold = useNextScreenSizeThreshold();
	const useWholeScreen = useScreenSizeIsMax(wholeScreenWindowSizeThreshold);
	const modalStyles = useMemo(
		() =>
			getModalStyles(size, nextScreenSizeThreshold, useWholeScreen, isHidden),
		[size, nextScreenSizeThreshold, useWholeScreen, isHidden]
	);

	return modalStyles;
}

// The login modal has zIndex 10 so choose a smaller number to other modals behind it
const zIndex = 9;

function getModalStyles(
	modalSize: ModalSize,
	nextScreenSizeThreshold: keyof typeof thresholds,
	useWholeScreen: boolean = false,
	isHidden: boolean = false
) {
	if (useWholeScreen) {
		return getStylesForWholeScreenReplacement();
	}

	const overlay = {
		zIndex,
		...(isHidden ? { display: "none" } : {})
	};

	const content = {
		...getContentStyles(modalSize, nextScreenSizeThreshold),
		...(isHidden ? { display: "none" } : {})
	};

	return {
		overlay,
		content
	};
}

function getContentStyles(
	modalSize: ModalSize,
	nextScreenSizeThreshold: keyof typeof thresholds
) {
	if (modalSize === "medium" && nextScreenSizeThreshold === "small") {
		return {
			marginTop: "4em"
		};
	}
	switch (modalSize) {
		case "big":
			return {
				marginTop: "4em"
			};
		case "medium":
			return {
				margin: "4em 16% 1em 16%",
				overflow: "hidden"
			};
		case "small":
			return {
				top: "50%",
				left: "50%",
				right: "auto",
				bottom: "auto",
				marginRight: "-50%",
				transform: "translate(-50%, -50%)"
			};
	}
}

function getStylesForWholeScreenReplacement() {
	return {
		overlay: {
			zIndex,
			top: "2.75em"
		},
		content: {
			top: 0,
			left: 0,
			right: 0,
			bottom: 0
		}
	};
}
