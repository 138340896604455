import React, { useCallback, useContext } from "react";

import { ChatContext } from "components/Chat/ChatContext";
import ContactsAndMessagesTable from "components/PageDashboard/ContactsAndMessagesSection/ContactsAndMessagesTable";
import { useRemoteResource } from "hooks/useRemoteResource";
import { getContacts } from "api/getContacts";
import { AuthContext } from "components/AuthProvider/AuthProvider";

const ContactsAndMessagesSection: React.FunctionComponent = () => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);

	const fetchContacts = useCallback(() => {
		if (!uid) {
			throw new Error("No uid");
		}

		// TODO:WV:20230425:Allow pagination (this and others)
		return getContacts(
			{
				uid
			},
			{ uid }
		);
	}, [uid]);

	const [, setChatDetails] = useContext(ChatContext);

	const doWait = isSignedIn === undefined || !uid;

	const { isWaiting, isLoading, isError, output: contacts } = useRemoteResource(
		fetchContacts,
		doWait,
		1,
		true
	);

	// TODO:WV:20240425:Add most recent chat message
	const contactsAndMessages = contacts ? contacts.page : [];

	// TODO:WV:20201009:Get the user started, maybe by asking for price during onboarding and / or adding a tutorial / walkthrough of how to set them up
	return (
		<>
			<ContactsAndMessagesTable
				contactsAndMessages={contactsAndMessages}
				isLoading={isLoading}
				isWaiting={isWaiting}
				isError={isError}
				onClickChat={setChatDetails}
			/>
		</>
	);
};

export default ContactsAndMessagesSection;
