import moment from "moment-timezone";

import { ErrorFlagger } from "./validate";

type Field = {
	type:
		| "string"
		| "url"
		| "integerstring"
		| "floatstring"
		| "number"
		| "datestring"
		| "completedatefieldselection"
		| "boolean"
		| "1"
		| "date"
		| "function"
		| "enum"
		| "absent";
	isRequired?: boolean;
	canBeNull?: boolean;
	allowedValues?: (string | number)[];
	followUpChecks?: ({ findInvalid, value }: FollowUpChecksParams) => void;
};

interface FollowUpChecksParams {
	findInvalid: (errMsg: string) => void;
	value: any;
}

export type FieldChecker<T> = (fieldName: keyof T, fieldConfig: Field) => void;

export function getFieldChecker<T>(data: any, flag: ErrorFlagger<T>) {
	return function checkField(
		fieldName: keyof T,
		{
			type,
			isRequired = true,
			canBeNull = false,
			allowedValues = [],
			followUpChecks = () => undefined
		}: Field
	) {
		if (allowedValues.length !== 0 && type !== "enum") {
			throw new Error("allowedValues only applies to enum fields");
		}

		if (allowedValues.length === 0 && type === "enum") {
			throw new Error("enum field has no allowed values");
		}

		let wasFoundInvalid = false;
		const findInvalid = (errMsg: string) => {
			wasFoundInvalid = true;
			flag(fieldName, errMsg);
		};

		if (data[fieldName] === undefined) {
			if (type === "absent") {
				return;
			}
			if (isRequired) {
				findInvalid("missing");
			}
		} else if (data[fieldName] === null) {
			if (canBeNull === false) {
				findInvalid("null values are not allowed");
			}
		} else {
			switch (type) {
				case "string":
					if (typeof data[fieldName] !== "string") {
						findInvalid("not a string");
					}
					break;
				case "number":
					if (typeof data[fieldName] !== "number") {
						findInvalid("not a number");
					}
					break;
				case "url":
					if (typeof data[fieldName] !== "string") {
						findInvalid("not a string");
						break;
					}
					if (
						!/^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/.test(
							data[fieldName]
						)
					) {
						findInvalid("not an url");
					}
					break;
				case "datestring":
					if (
						typeof data[fieldName] !== "string" ||
						!moment(data[fieldName]).isValid()
					) {
						flag(fieldName, "not a string representing a valid date");
					}
					break;
				case "completedatefieldselection":
					if (typeof data[fieldName].year !== "number") {
						findInvalid("year component was not a number");
					}

					if (typeof data[fieldName].month !== "number") {
						findInvalid("month component was not a number");
					}

					if (typeof data[fieldName].date !== "number") {
						findInvalid("date component was not a number");
					}

					if (!moment(data[fieldName]).isValid()) {
						flag(fieldName, "does not represent a valid date");
					}

					break;
				case "boolean":
					if (typeof data[fieldName] !== "boolean") {
						findInvalid("not a boolean");
					}
					break;
				case "1":
					if (data[fieldName] !== "1") {
						findInvalid("not the string '1'");
					}
					break;
				case "date":
					if (!(data[fieldName] instanceof Date)) {
						findInvalid("not a date");
					}
					break;
				case "function":
					if (typeof data[fieldName] !== "function") {
						findInvalid("not a function");
					}
					break;
				case "enum":
					if (!allowedValues.includes(data[fieldName])) {
						findInvalid(`invalid value`);
					}
					break;
				case "absent":
					if (data[fieldName] !== undefined) {
						findInvalid("should not be present");
					}
					break;
				default:
					throw new Error("Invalid field type");
			}
		}

		if (data[fieldName] !== undefined && !wasFoundInvalid) {
			followUpChecks({ findInvalid, value: data[fieldName] });
		}
	};
}
