import { useEffect, useState, useContext, useRef } from "react";

import { Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { captureException } from "services/captureException";

export type UseRemoteResourceCallback<T> = (
	arg: {},
	auth: Auth
) => ApiRequest<T>;

export function useRemoteResource<T>(
	cb: UseRemoteResourceCallback<T>,
	doWait: boolean = false,

	/* Change the following parameter to repeat the request, e.g. by passing in (new Date()).getTime() */
	repeat: number = 1,

	persistDataBetweenLoads: boolean = false
) {
	const [isWaiting, setIsWaiting] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [output, setOutput] = useState<T | undefined>(undefined);

	// Pass in the current auth uid as a ref, rather than directly
	// to prevent the API requests aborting when the user logs in or
	// out (due the useEffect cleanup function running) which can cause
	// UX errors (e.g. white screens of death).
	const [{ uid }] = useContext(AuthContext);
	const uidRef = useRef(uid);
	uidRef.current = uid;

	useEffect(() => {
		if (doWait) {
			setIsWaiting(true);
			setIsLoading(false);
			setIsError(false);

			if (!persistDataBetweenLoads) {
				setOutput(undefined);
			}

			return;
		}

		setIsWaiting(false);
		setIsLoading(true);
		setIsError(false);

		if (!persistDataBetweenLoads) {
			setOutput(undefined);
		}

		const request = cb({}, { uid: uidRef.current });
		const abort = () => request.abort();

		async function go() {
			try {
				const data = await request.ready;
				if (request && request.aborted()) {
					// 'dummy' requests will end up here if aborted rather than in the 'catch' block, so handle them appropriately
					return;
				}
				setIsError(false);
				setOutput(data);
			} catch (e) {
				captureException(new Error("Error fetching remote resources"), {
					evtType: "errorFetchingRemoteResource",
					extra: {
						originalError: e,
						request
					}
				});
				if (request && request.aborted()) {
					// Do not update any state if aborted - assume this was due to useEffect cleanup and the component is not longer mounted
					// (to prevent React error about updating state on unmounted components / memory leaks)
					return;
				}
				setIsError(true);
				if (!persistDataBetweenLoads) {
					setOutput(undefined);
				}
			}

			// Only update state if the request was not aborted.  This will prevent React errors about updating state of unmounted components
			// (assume abort was due to component unmounting)
			if (!request.aborted()) {
				setIsWaiting(false);
				setIsLoading(false);
			}
		}

		go();

		return abort;
	}, [doWait, cb, repeat, persistDataBetweenLoads, uidRef]);

	return {
		isWaiting,
		isLoading,
		isError,
		output
	};
}
