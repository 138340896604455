import React, { useEffect, useCallback, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { faStripeS } from "@fortawesome/free-brands-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";

import ActionButtons from "components/ActionButtons";
import { useSaveDataAndRefreshAccountDetailsFromApi } from "hooks/useSaveDataAndRefreshAccountDetailsFromApi";
import { AlertContext } from "components/AlertProvider/AlertProvider";
import ModalPleaseWait from "components/ModalPleaseWait";
import { useAccountStatus } from "hooks/useAccountStatus";
import { useScreenSizeIsMax } from "hooks/useScreenSizeIsMax";
import { useOpenStripeDashboard } from "hooks/useOpenStripeDashboard";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { captureException } from "services/captureException";

const StripeLinker: React.FunctionComponent = () => {
	const [isRequesting, setIsRequesting] = useState(false);
	const handleClickLinkStripeAccount = useCallback(() => {
		setIsRequesting(true);
	}, [setIsRequesting]);

	const [{ isSignedIn, uid }] = useContext(AuthContext);

	const [addAlert] = useContext(AlertContext);
	const { pathname } = useLocation();

	const requestConnectedAccount = useSaveDataAndRefreshAccountDetailsFromApi(
		"stripeRequestConnectedAccount",
		"requesting your connected account",
		setIsRequesting
	);

	useEffect(() => {
		if (!isSignedIn) {
			return;
		}
		if (!isRequesting) {
			return;
		}

		if (!uid) {
			captureException(new Error("No uid"), {
				evtType: "noUid"
			});
			return;
		}

		return requestConnectedAccount({ data: { uid } });
	}, [
		isSignedIn,
		isRequesting,
		pathname,
		addAlert,
		uid,
		requestConnectedAccount
	]);

	const {
		stripeAccountOnboardingStatus,
		stripeAccountPayoutsStatus
	} = useAccountStatus();

	const isSmallScreen = useScreenSizeIsMax("small");
	const openDashboard = useOpenStripeDashboard();

	// TODO:WV:20200814:Improve this help text.  Handle stripe failures (e.g. user disabled their account).  Provide detailed small text about our system having access to read and write all their stripe meetings (not just this-service-related ones)
	return (
		<>
			<ModalPleaseWait isOpen={isRequesting} title="Requesting" />

			{stripeAccountOnboardingStatus === "linked" ? (
				<>
					<p>
						Your Stripe account is set up to receive payments.
						{stripeAccountPayoutsStatus === "disabled" ? (
							<>
								{" "}
								<strong>
									Before Stripe can pay money out to your Bank account there may
									be a few final steps to complete.
								</strong>
							</>
						) : null}{" "}
						Please view your Stripe dashboard for more information.
					</p>
				</>
			) : null}

			{stripeAccountOnboardingStatus === "account-requested" ? (
				<>
					<p>
						We have received your request for a Stripe{" "}
						<em>connected account</em>.
					</p>
					<p>
						Thank you for that; we're working on your application and will email
						you with the next step shortly.
					</p>
					<p>
						If you change your mind about wanting a connected account please
						email us at {process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}.
					</p>
				</>
			) : null}

			{stripeAccountOnboardingStatus === "linked" ||
			stripeAccountOnboardingStatus === "account-requested" ? null : (
				<>
					<p>We use Stripe to process payments.</p>
					<p>
						<strong>
							You need to create what Stripe calls a <em>connected account</em>{" "}
							before users will be able to pay you online by credit or debit
							card.
						</strong>
					</p>
					{stripeAccountOnboardingStatus === "unlinked" ? (
						<>
							<p>
								For security reasons, this process needs to be initiated by an
								admin. Please use the button below to send a message to an admin
								asking them to start the process.
							</p>
							<p>
								Afterwards, the admin will email you a link to click to finish
								the process off by providing stripe with your ID, contact
								details, and bank details. If you don't receive it, please send
								us an email at {process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}.
							</p>
						</>
					) : null}
					{stripeAccountOnboardingStatus === "onboarding" ? (
						<p>
							You have started the process of creating a connected account, but
							have not yet completed it. To finish the rest of it, please use
							the button below.
						</p>
					) : null}
				</>
			)}

			{stripeAccountOnboardingStatus === "account-requested" ? null : (
				<ActionButtons
					highlightPrimary={false}
					secondary={
						stripeAccountOnboardingStatus === "linked"
							? {
									onClick: openDashboard,
									icon: faList,
									label: "Stripe dashboard"
							  }
							: {
									onClick: handleClickLinkStripeAccount,
									icon: faStripeS,
									label: `${
										stripeAccountOnboardingStatus === "onboarding"
											? "Finish setting up"
											: "Request connected"
									} Stripe ${isSmallScreen ? "" : "Account"}`
							  }
					}
				/>
			)}
		</>
	);
};

export default StripeLinker;
