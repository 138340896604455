import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { useOnClickNext } from "components/ModalWizard/Step/useOnClickNext";
import {
	ExplanationParagraph,
	ExplanationParagraphs
} from "./StepLogin.styles";

function StepLogin<CompletedWizardData>({
	progressToNextStepNow,
	enableProgressToNextStep,
	wizardData,
	setOnClickNext
}: ModalWizardStepRenderProps<CompletedWizardData>) {
	const [
		{ isSignedIn, promptForLogin, termsWallIsDisabled, setTermsWallIsDisabled }
	] = useContext(AuthContext);

	if (!termsWallIsDisabled) {
		setTermsWallIsDisabled(true);
	}

	useEffect(() => {
		if (isSignedIn) {
			progressToNextStepNow(wizardData);
		}
	}, [isSignedIn, wizardData, progressToNextStepNow]);

	useOnClickNext(setOnClickNext, promptForLogin);

	return (
		<ExplanationParagraphs>
			<ExplanationParagraph>
				Please use the button below to login or register.
			</ExplanationParagraph>
			<ExplanationParagraph>
				We need your name and email address for communication regarding this and
				any future bookings.
			</ExplanationParagraph>
			<ExplanationParagraph>
				<Link to="/privacy-policy" target="_blank">
					They will not be used for marketing
				</Link>
				.
			</ExplanationParagraph>
		</ExplanationParagraphs>
	);
}

export default StepLogin;
