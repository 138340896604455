import React from "react";

import { OutputRow as MeetingRescheduleRequestsData } from "api/getMeetingRescheduleRequests";
import { formatMeetingRescheduleRequestDetails } from "services/formatMeetingRescheduleRequestDetails";

import SimpleTable from "components/SimpleTable";

interface MeetingSummaryProps {
	event: MeetingRescheduleRequestsData;
	currentUid: string;
}

const RescheduleRequestSummary: React.FunctionComponent<
	MeetingSummaryProps
> = ({ event, currentUid }) => {
	const {
		startDate,
		startTime,
		endTime,
		originalStartDate,
		originalStartTime,
		originalEndTime,
		partnersName
	} = formatMeetingRescheduleRequestDetails(event, currentUid);

	const rows = [
		[
			<strong>Old date and time</strong>,
			`${originalStartDate} at ${originalStartTime} until ${originalEndTime}`
		],
		[
			<strong>New date and time</strong>,
			`${startDate} at ${startTime} until ${endTime}`
		],
		[<strong>With</strong>, partnersName]
	];

	return <SimpleTable rows={rows} />;
};

export default RescheduleRequestSummary;
