import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useUserExists } from "hooks/useUserExists";
import { useUserProfile } from "hooks/useUserProfile";

export const useRedirectToVanityURL = (vanityUrl: string, uid: string) => {
	const { output: userExistenceData } = useUserExists({ uid, vanityUrl });
	const userKnownToExist = userExistenceData && userExistenceData.userExists;

	const userProfile = useUserProfile(
		userKnownToExist ? { vanityUrl, uid } : {}
	);

	const { output: profileData } = userProfile;

	const uidFromAPI = profileData ? profileData.uid : undefined;
	const vanityURLFromAPI = profileData ? profileData.vanityUrl : undefined;
	const history = useHistory();
	const location = useLocation();
	useEffect(() => {
		if (!uid) {
			return;
		}
		if (!uidFromAPI) {
			return;
		}

		if (uid && !vanityUrl && vanityURLFromAPI) {
			history.replace({
				pathname: `/user/${vanityURLFromAPI}`,
				...(location.search ? { search: location.search } : {})
			});
		}
	}, [history, location, uidFromAPI, vanityURLFromAPI, uid, vanityUrl]);
};
