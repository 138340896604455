import React, { useState, useCallback, useContext } from "react";

import AvailabilityTable from "components/PageDashboard/AvailabilitySection/AvailabilityTable";
import { DataInAlreadyExistingRow } from "components/PageDashboard/AvailabilitySection/AvailabilityTable/AvailabilityTable";
import { useRemoteResource } from "hooks/useRemoteResource";
import { useSendData } from "hooks/useSendData";
import { SearchResults } from "api/SearchResults";
import { saveMentorAvailabilityPeriods } from "api/saveMentorAvailabilityPeriods";
import {
	getAvailability,
	OutputRow as AvailabilityData
} from "api/getAvailability";
import { AuthContext } from "components/AuthProvider/AuthProvider";

const AvailabilitySection: React.FunctionComponent = () => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);

	const fetchAvailability = useCallback(() => {
		if (!uid) {
			throw new Error("No uid");
		}
		return getAvailability(
			{
				uid
			},
			{ uid }
		);
	}, [uid]);

	const doWait = isSignedIn === undefined || !uid;

	const [fetchIterationNumber, setFetchIterationNumber] = useState(1);

	const {
		isWaiting,
		isLoading,
		isError,
		output: periodsOfAvailability
	} = useRemoteResource<SearchResults<AvailabilityData>>(
		fetchAvailability,
		doWait,
		fetchIterationNumber,
		true
	);

	const [waitModalIsShowing, setWaitModalIsShowing] = useState(false);
	const [waitModalTitle, setWaitModalTitle] = useState("");

	const doSaveAvailabilityPeriods = useSendData({
		method: saveMentorAvailabilityPeriods
	});

	const onEditAvailability = useCallback(
		(
			rows: { old?: DataInAlreadyExistingRow; new?: DataInAlreadyExistingRow }[]
		) => {
			const title = rows.every(row => !row.new) ? "Deleting" : "Saving";

			setWaitModalTitle(title);
			setWaitModalIsShowing(true);

			if (!uid) {
				throw new Error("No UID");
			}

			const req = doSaveAvailabilityPeriods(
				{
					uid,
					rows: rows.map(row => {
						const { old, new: newRow } = row;

						return {
							old: old ? old : undefined,
							new: newRow ? newRow : undefined
						};
					})
				},
				{ uid }
			);

			const ready = req.ready.then(response => {
				setFetchIterationNumber(new Date().getTime());
				setWaitModalIsShowing(false);
			});

			return {
				ready,
				abort: () => req.abort(),
				aborted: () => req.aborted()
			};
		},
		[
			uid,
			setWaitModalTitle,
			setWaitModalIsShowing,
			setFetchIterationNumber,
			doSaveAvailabilityPeriods
		]
	);

	const dataForTable = periodsOfAvailability ? periodsOfAvailability.page : [];

	return (
		<AvailabilityTable
			onEdit={onEditAvailability}
			periodsOfAvailability={dataForTable}
			isLoading={isLoading}
			isWaiting={isWaiting || !periodsOfAvailability}
			isError={isError}
			waitModalTitle={waitModalTitle}
			waitModalIsShowing={waitModalIsShowing}
		/>
	);
};

export default AvailabilitySection;
