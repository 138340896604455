import React, {
	useEffect,
	useState,
	useCallback,
	useMemo,
	useRef,
	useContext
} from "react";
import moment from "moment-timezone";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import ModalConfirm from "components/ModalConfirm";
import ToggleableButton from "components/ToggleableButton";
import FieldDate from "components/FieldDate";
import { FieldDateProps } from "components/FieldDate/FieldDate";
import { validateCompleteSelection } from "components/FieldDate/validateCompleteSelection";
import { validateEmptySelection } from "components/FieldDate/validateEmptySelection";
import { validate } from "validation/validate";
import { EditButtonRenderProps } from "components/ModalField/ModalField";
import {
	isEmpty,
	Selection,
	equals
} from "components/FieldDate/FieldDateSelection";

interface ModalFieldDateProps
	extends Omit<Omit<FieldDateProps, "currentSelection">, "onNewSelection"> {
	onCancel?: () => void;
	onOK: (newValue: Selection) => void;
	value: Selection;
	title: string;
	isUnlocked?: boolean;
	formatSelection?: (selection: Selection) => string;
	requireSelectedDate?: boolean;
	editButton?: (props: EditButtonRenderProps) => React.ReactNode;
	allowEmptyValue: boolean;
}

const ModalFieldDate: React.FunctionComponent<ModalFieldDateProps> = ({
	onCancel = () => undefined,
	onOK,
	title,
	value,
	allowEmptyValue,
	isUnlocked = true,
	formatSelection = selection => moment(selection).format(`DD/MMM/YYYY`),
	requireSelectedDate = false,
	editButton,
	...rest
}) => {
	const [currentSelection, setCurrentSelection] = useState<Selection>(value);
	const [{ uid }] = useContext(AuthContext);

	const reset = useCallback(() => {
		setCurrentSelection(value);
	}, [value, setCurrentSelection]);

	const [isOpen, setIsOpen] = useState(false);
	const toggleIsOpen = useCallback(() => {
		setIsOpen(oldIsOpen => !oldIsOpen);
	}, [setIsOpen]);
	useEffect(() => {
		reset();
	}, [isOpen, reset]);

	const [isNotValid, setIsNotValid] = useState(false);

	const previousSelection = useRef<Selection>();
	useEffect(() => {
		if (
			!previousSelection.current ||
			!equals(currentSelection, previousSelection.current)
		) {
			setIsNotValid(false);
		}
		previousSelection.current = currentSelection;
	}, [currentSelection, previousSelection]);

	const handleOK = useCallback(() => {
		if (requireSelectedDate && isEmpty(currentSelection)) {
			setIsNotValid(true);
			return;
		}

		if (
			!validate(currentSelection, {
				doValidate: validateCompleteSelection,
				auth: { uid }
			})
		) {
			if (
				allowEmptyValue &&
				validate(currentSelection, {
					doValidate: validateEmptySelection,
					auth: { uid }
				})
			) {
				// Do nothing
			} else {
				setIsNotValid(true);
				return;
			}
		}

		onOK(currentSelection);
		setIsOpen(false);
	}, [
		currentSelection,
		allowEmptyValue,
		onOK,
		setIsOpen,
		requireSelectedDate,
		setIsNotValid,
		uid
	]);

	const handleCancel = useCallback(() => {
		onCancel();
		setIsOpen(false);
	}, [onCancel, setIsOpen]);

	const displayValue = useMemo(() => formatSelection(value), [
		value,
		formatSelection
	]);

	return (
		<>
			{editButton ? (
				editButton({ isUnlocked, onClick: toggleIsOpen })
			) : (
				<ToggleableButton
					isEditing={isUnlocked}
					solidIcon={false}
					icon={faCalendar}
					onClick={toggleIsOpen}
					label={displayValue}
				/>
			)}
			<ModalConfirm
				isOpen={isOpen}
				onOK={handleOK}
				onCancel={handleCancel}
				title={title}
			>
				<FieldDate
					{...rest}
					currentSelection={currentSelection}
					onNewSelection={setCurrentSelection}
					isNotValid={isNotValid}
				/>
			</ModalConfirm>
		</>
	);
};

export default ModalFieldDate;
