import React from "react";
import moment from "moment-timezone";

import { MeetingCalendarEvent } from "components/MeetingCalendar/MeetingCalendar";

interface SelectedEventSummaryProps {
	eventToBook?: MeetingCalendarEvent;
	showEndTime?: boolean;
}

function SelectedEventSummary({
	eventToBook,
	showEndTime = true
}: SelectedEventSummaryProps) {
	if (!eventToBook) {
		return null;
	}
	return (
		<ul className="alt">
			<li>
				Start:{" "}
				{eventToBook
					? moment(eventToBook.start).format("dddd, MMMM Do YYYY, h:mm:ss a")
					: ""}
			</li>
			{showEndTime ? (
				<li>
					End:{" "}
					{eventToBook
						? moment(eventToBook.end).format("dddd, MMMM Do YYYY, h:mm:ss a")
						: ""}
				</li>
			) : null}
		</ul>
	);
}

export default SelectedEventSummary;
