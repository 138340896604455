import React from "react";

import { ModalButtonsInner, ModalButtonsWrapper } from "./ModalButtons.styles";

interface ModalButtonsProps {
	onOK: () => void;
	onCancel: () => void;
	okText?: string | null;
	cancelText?: string | null;
	fitWidth?: boolean;
	OKButtonIsDisabled?: boolean;
}

const ModalButtons: React.FunctionComponent<ModalButtonsProps> = ({
	okText = "OK",
	cancelText = "Cancel",
	onOK,
	onCancel,
	fitWidth = false,
	OKButtonIsDisabled = false
}) => {
	return (
		<ModalButtonsWrapper>
			<ModalButtonsInner
				primary={
					okText === null
						? undefined
						: {
								onClick: e => {
									if (OKButtonIsDisabled) {
										return;
									}
									onOK();
								},
								label: okText,
								isDisabled: OKButtonIsDisabled
						  }
				}
				secondary={
					cancelText === null
						? undefined
						: { onClick: e => onCancel(), label: "Cancel" }
				}
				fitWidth={fitWidth}
			/>
		</ModalButtonsWrapper>
	);
};

export default ModalButtons;
