import React, { useState, useCallback } from "react";
import uniqid from "uniqid";

interface Alert {
	title?: string;
	contents?: React.ReactNode;
	id: string;
}

interface AlertState {
	alerts: Alert[];
}

export type AddAlertFunction = (alert: Omit<Alert, "id">) => void;
type RemoveAlertFunction = (alert: Alert) => void;

export const AlertContext = React.createContext<
	[AddAlertFunction, RemoveAlertFunction, AlertState]
>([arg => undefined, arg => undefined, { alerts: [] }]);

const AlertProvider: React.FunctionComponent = ({ children }) => {
	const [state, setState] = useState<AlertState>({ alerts: [] });

	const addAlert: AddAlertFunction = useCallback(
		(alert: Omit<Alert, "id">) => {
			setState(oldState => ({
				alerts: [
					...oldState.alerts,
					{
						...alert,
						id: uniqid()
					}
				]
			}));
		},
		[setState]
	);

	const removeAlert: RemoveAlertFunction = useCallback(
		(alert: Alert) =>
			setState(state => ({
				...state,
				alerts: state.alerts.filter(a => a.id !== alert.id)
			})),
		[setState]
	);

	return (
		<AlertContext.Provider value={[addAlert, removeAlert, state]}>
			{children}
		</AlertContext.Provider>
	);
};

export default AlertProvider;
