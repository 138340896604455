import React, { useContext } from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
	faComment,
	faIdBadge,
	faPen,
	faTimes,
	faCheck,
	faSignInAlt
} from "@fortawesome/free-solid-svg-icons";

import {
	AlertContext,
	AddAlertFunction
} from "components/AlertProvider/AlertProvider";
import { formatMedium } from "services/formatMedium";
import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { OutputRow as MeetingRequestsData } from "api/getMeetingRequests";
import { OutputRow as MeetingRescheduleRequestsData } from "api/getMeetingRescheduleRequests";
import { convertToUsersTimezone } from "utils/datesAndTimes/convertToUsersTimezone";
import { ChatDetails } from "components/Chat/Chat";
import Table from "components/Table";
import terminology from "terminology.json";

import { isMeeting } from "./isMeeting";
import { isMeetingRequest } from "./isMeetingRequest";
import { isMeetingRescheduleRequest } from "./isMeetingRescheduleRequest";
import { MeetingsTableContainer } from "./MeetingsSection.styles";

type Event =
	| MeetingsDataFlattened
	| MeetingRequestsData
	| MeetingRescheduleRequestsData;

interface Props {
	isMentor: boolean | undefined;
	events: Event[] | undefined;
	uid: string | undefined;

	isWaiting: boolean;
	isLoading: boolean;

	onClickSignOff: (arg: MeetingsDataFlattened) => void;
	onClickPay: (arg: MeetingsDataFlattened) => void;
	onClickMarkPaid: (arg: MeetingsDataFlattened) => void;
	onClickChat: (arg: ChatDetails | undefined) => void;
	onClickCancel: (arg: MeetingsDataFlattened) => void;
	onClickRequestReschedule: (arg: MeetingsDataFlattened) => void;
	onClickRespondToMeetingRescheduleRequest: (
		arg: MeetingRescheduleRequestsData
	) => void;
}

const MeetingsTable: React.FunctionComponent<Props> = ({
	isMentor,
	events,
	uid,
	isWaiting,
	isLoading,
	onClickSignOff,
	onClickPay,
	onClickMarkPaid,
	onClickChat,
	onClickCancel,
	onClickRequestReschedule,
	onClickRespondToMeetingRescheduleRequest
}) => {
	const history = useHistory();
	const [addAlert] = useContext(AlertContext);

	const cols = [
		{ key: "startDate", heading: "Date" },
		{ key: "startTime", heading: "From" },
		{ key: "endTime", heading: "Until" },
		{ key: "partner", heading: "With" },
		{ key: "medium", heading: "Using" },
		...(isMentor ? [{ key: "role", heading: "Role" }] : []),
		{ key: "status", heading: "Status" }
	];

	const rescheduleRequests = events
		? events.filter(isMeetingRescheduleRequest)
		: [];

	const rows =
		events && events.length
			? events
					.filter(isMeetingOrMeetingRequest)
					.sort((a, b) => b.dateStartsUTC.getTime() - a.dateStartsUTC.getTime())
					.map(event => {
						const {
							dateStartsUTC,
							displayNameGuest,
							displayNameHost,
							hostUid,
							guestUid,
							medium
						} = event;

						const { isSignedOffByGuest, isSignedOffBySystem } =
							"isSignedOffByGuest" in event
								? event
								: {
										isSignedOffByGuest: undefined,
										isSignedOffBySystem: undefined
								  };
						const isSignedOff = !!isSignedOffByGuest || !!isSignedOffBySystem;

						const awaitingPayment =
							"paymentStatus" in event
								? event.paymentStatus === "pending"
								: false;
						const isAccepted = "isAccepted" in event ? event.isAccepted : true;

						if (!uid) {
							throw new Error("Not logged in");
						}

						const isHost = hostUid === uid;
						const isGuest = !isHost;

						const partnersName = isHost ? displayNameGuest : displayNameHost;
						const partnersUid = isHost ? guestUid : hostUid;

						const start = convertToUsersTimezone(moment.utc(dateStartsUTC));
						const endJustBefore = convertToUsersTimezone(
							moment.utc(event.dateEndsJustBeforeUTC)
						);

						const awaitingSignOff =
							!isSignedOff && endJustBefore.isBefore(new Date());

						// TODO:WV:20230409:Memoise this
						const foundRescheduleRequest = rescheduleRequests.find(
							req => req.meetingId === event.id
						);
						const currentUserIsRescheduler = foundRescheduleRequest
							? foundRescheduleRequest.reschedulerUid === uid
							: false;

						const action = (() => {
							if (
								foundRescheduleRequest !== undefined &&
								!currentUserIsRescheduler
							) {
								return {
									onClick: () =>
										onClickRespondToMeetingRescheduleRequest(
											foundRescheduleRequest
										),
									label: "Respond"
								};
							}
							if (guestUid === uid) {
								if (isMeeting(event)) {
									if (awaitingSignOff) {
										return {
											onClick: () => onClickSignOff(event),
											label: "Sign off"
										};
									}
									if (awaitingPayment) {
										return {
											onClick: () => onClickPay(event),
											label: "Pay"
										};
									}
								}
							}
							if (hostUid === uid) {
								if (isMeeting(event)) {
									if (awaitingPayment) {
										return {
											onClick: () => onClickMarkPaid(event),
											label: "Mark paid"
										};
									}
								}
							}

							if (isMeeting(event) && endJustBefore.isAfter(new Date())) {
								const { start, join } = getStartAndJoinButtons({
									meeting: event,
									isHost,
									isGuest,
									addAlert,
									includeIcon: false
								});

								if (start) {
									return start;
								}

								if (join) {
									return join;
								}
							}

							return undefined;
						})();

						return {
							data: {
								startDate: start.format("YYYY/MM/DD"),
								startTime: start.format("HH:mm"),
								endTime: endJustBefore.format("HH:mm"),
								partner: (
									<Link to={`/user-id/${partnersUid}`}>
										{partnersName ? partnersName : "(unknown)"}
									</Link>
								),
								medium: formatMedium(medium),
								...(isMentor ? { role: isHost ? "Host" : "Guest" } : {}),
								status:
									foundRescheduleRequest !== undefined
										? "Reschedule requested"
										: awaitingPayment
										? "Awaiting payment"
										: isAccepted
										? "Confirmed"
										: "Awaiting confirmation"
							},
							action,
							menu: [
								{
									onClick: () =>
										onClickChat({
											partner: { uid: partnersUid, displayName: partnersName },
											pagination: { cursorType: "after" }
										}),
									label: "Open chat",
									icon: faComment
								},
								...(isGuest
									? [
											{
												onClick: () => {
													history.push(`/user-id/${partnersUid}`);
												},
												label: "View profile",
												icon: faIdBadge
											}
									  ]
									: []),
								...(awaitingSignOff && guestUid === uid && isMeeting(event)
									? [
											{
												onClick: () => onClickSignOff(event),
												label: "Sign off",
												icon: faCheck
											}
									  ]
									: []),
								...(start.isAfter(new Date()) &&
								isMeeting(event) &&
								foundRescheduleRequest === undefined
									? [
											{
												onClick: () => onClickRequestReschedule(event),
												label: "Request reschedule",
												icon: faPen
											}
									  ]
									: []),
								...(start.isAfter(new Date()) && isMeeting(event)
									? [
											{
												onClick: () => onClickCancel(event),
												label: `Cancel ${terminology.meeting}`,
												icon: faTimes
											}
									  ]
									: []),
								...(isMeeting(event) && endJustBefore.isAfter(new Date())
									? Object.values(
											getStartAndJoinButtons({
												meeting: event,
												isHost,
												isGuest,
												addAlert,
												includeIcon: true
											})
									  )
									: [])
							]
						};
					})
			: [];

	return (
		<MeetingsTableContainer isLoading={isWaiting || isLoading}>
			<Table
				cols={cols}
				rows={rows}
				emptyText={`You have not scheduled any ${terminology.meetings}`}
				isLoading={isWaiting || isLoading}
			/>
		</MeetingsTableContainer>
	);
};

export default MeetingsTable;

function isMeetingOrMeetingRequest(
	event: Event
): event is MeetingsDataFlattened | MeetingRequestsData {
	return isMeeting(event) || isMeetingRequest(event);
}

function getStartAndJoinButtons({
	meeting,
	isHost,
	isGuest,
	addAlert,
	includeIcon
}: {
	meeting: MeetingsDataFlattened;
	isHost: boolean;
	isGuest: boolean;
	addAlert: AddAlertFunction;
	includeIcon: boolean;
}) {
	const { startURL, joinURL, hostSkypeId, guestSkypeId } = meeting;

	const start = startURL
		? {
				onClick: () => window.open(startURL),
				label: "Start",
				icon: includeIcon ? faSignInAlt : undefined
		  }
		: undefined;

	const join = joinURL
		? {
				onClick: () => window.open(joinURL),
				label: "Join",
				icon: includeIcon ? faSignInAlt : undefined
		  }
		: (isGuest && hostSkypeId) || (isHost && guestSkypeId)
		? {
				onClick: () =>
					addAlert({
						title: `Join Skype ${terminology.meeting}`,
						contents: (
							<>
								Please open the Skype app and start a call with the Skype user{" "}
								<em>{isHost ? guestSkypeId : hostSkypeId}</em>.
							</>
						)
					}),
				label: "Join",
				icon: includeIcon ? faSignInAlt : undefined
		  }
		: undefined;

	return {
		...(start ? { start } : {}),
		...(join ? { join } : {})
	};
}
