import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";
import { MentorProfileData } from "api/getUserProfile";

interface Params {
	uid: string;
	timezone: string;
	displayName: string;
	locationDistrict?: string;
	locationCountryCode?: string;
	mentorProfileAboutMe?: string;
	terms?: MentorProfileData["terms"];
	hasDismissedStripeAlert?: boolean;
	defaultCurrencyCode?: string;
	skypeId?: string;
	defaultGoogleMeetLink?: string;
	hasPaidZoomAccount?: boolean;

	paymentProvidersMBWay?: string;
	paymentProvidersWiseEmail?: string;
	paymentProvidersWiseTelephone?: string;
	paymentProvidersCustom?: string;
}

type Output = {};
const flagValidationErrors: ValidationCallback<Output> = (input, flag) =>
	typeof input === "object" && Object.keys(input).length === 0;

export function saveAccountSettings(
	{
		uid,
		timezone,
		displayName,
		locationDistrict,
		locationCountryCode,
		mentorProfileAboutMe,
		terms,
		hasDismissedStripeAlert,
		defaultCurrencyCode,
		skypeId,
		defaultGoogleMeetLink,
		hasPaidZoomAccount,
		paymentProvidersMBWay,
		paymentProvidersWiseEmail,
		paymentProvidersWiseTelephone,
		paymentProvidersCustom
	}: Params,
	auth: Auth
): ApiRequest<Output> {
	return makeApiRequest("saveAccountSettings", {
		auth,
		data: {
			post: {
				uid,
				timezone,
				displayName,
				locationDistrict,
				locationCountryCode,
				mentorProfileAboutMe,
				terms,
				hasDismissedStripeAlert,
				defaultCurrencyCode,
				skypeId,
				defaultGoogleMeetLink,
				hasPaidZoomAccount,
				paymentProvidersMBWay,
				paymentProvidersWiseEmail,
				paymentProvidersWiseTelephone,
				paymentProvidersCustom
			}
		},
		flagValidationErrors
	});
}
