import { useState, useCallback, useContext, useRef } from "react";

import { AlertContext } from "components/AlertProvider/AlertProvider";
import { ModalWizardStepRenderProps, ModalWizardStep } from "./ModalWizard";

import { getVisibleSteps } from "./getVisibleSteps";
import { ModalWizardFormState } from "./useModalWizard";

export function useStepNavigation<CompletedWizardData>(
	allVisibleSteps: ModalWizardStep<CompletedWizardData>[],
	currentStepIndex: number,
	setFormState: React.Dispatch<
		React.SetStateAction<ModalWizardFormState<CompletedWizardData>>
	>
) {
	const [dataForNextStep, setDataForNextStep] = useState<
		Partial<CompletedWizardData> | undefined
	>();
	const [isReadyToProgress, setIsReadyToProgress] = useState<boolean>(false);
	const [haveSystemError, setHaveSystemError] = useState(false);
	const [alertOnProgressToNextStep, setAlertOnProgressToNextStep] = useState<
		| {
				title: string;
				contents: string;
		  }
		| undefined
	>();

	const resetStepState = useCallback(() => {
		setDataForNextStep(undefined);
		setIsReadyToProgress(false);
		setHaveSystemError(false);
		setAlertOnProgressToNextStep(undefined);
	}, [setDataForNextStep]);

	/* NB:WV:20231110:Important notes about handleMoveToNextStep.

	   1) It is important to pass allVisibleSteps in via a ref rather than JSON.stringify because the latter will strip all the functions associated with its properties.
	   2) It is necessary to pass handleMoveToNextStep through useCallback to prevent the wizard from hanging (the precise reason for the hanging is currently unclear). */
	const allVisibleStepsRef = useRef(allVisibleSteps);
	allVisibleStepsRef.current = allVisibleSteps;
	const handleMoveToNextStep = useCallback(
		(stepData: Partial<CompletedWizardData> | undefined) => {
			const visibleSteps = allVisibleStepsRef.current;

			resetStepState();

			setFormState(oldState => {
				const newWizardData = {
					...oldState.wizardData,
					...stepData
				};

				const remainingVisibleSteps = getVisibleSteps(
					visibleSteps.slice(currentStepIndex + 1),
					newWizardData
				);

				const formIsComplete = remainingVisibleSteps.length === 0;

				const newCompletedSteps = [
					...oldState.completedSteps,
					oldState.currentStepKey
				];

				const currentStepKey = remainingVisibleSteps.length
					? remainingVisibleSteps[0].key
					: undefined;

				return {
					...oldState,
					wizardData: newWizardData,
					completedSteps: newCompletedSteps,
					...(currentStepKey ? { currentStepKey } : {}),
					formIsComplete
				};
			});
		},
		[setFormState, currentStepIndex, resetStepState, allVisibleStepsRef]
	);

	const enableProgressToNextStep = useCallback<
		ModalWizardStepRenderProps<
			Partial<CompletedWizardData>
		>["enableProgressToNextStep"]
	>(
		newData => {
			setAlertOnProgressToNextStep(undefined);
			setDataForNextStep(newData);
			setIsReadyToProgress(true);
		},
		[setAlertOnProgressToNextStep, setDataForNextStep, setIsReadyToProgress]
	);

	const disableProgressToNextStep = useCallback<
		ModalWizardStepRenderProps<CompletedWizardData>["disableProgressToNextStep"]
	>(
		(arg?: { alert?: { title: string; contents: string } }) => {
			setAlertOnProgressToNextStep(arg && arg.alert ? arg.alert : undefined);
			setDataForNextStep(undefined);
			setIsReadyToProgress(false);
		},
		[setAlertOnProgressToNextStep, setDataForNextStep, setIsReadyToProgress]
	);

	const [addAlert] = useContext(AlertContext);
	const onOK = useCallback(() => {
		if (alertOnProgressToNextStep) {
			addAlert(alertOnProgressToNextStep);
		}
		if (isReadyToProgress) {
			handleMoveToNextStep(dataForNextStep);
		}
	}, [
		dataForNextStep,
		isReadyToProgress,
		handleMoveToNextStep,
		alertOnProgressToNextStep,
		addAlert
	]);

	return {
		handleMoveToNextStep,
		alertOnProgressToNextStep,
		enableProgressToNextStep,
		disableProgressToNextStep,
		haveSystemError,
		setHaveSystemError,
		onOK
	};
}
