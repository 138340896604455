import styled from "styled-components";

export const iconWidthInEm = 1;
export const iconRightMarginInEm = 0.75;

export const IconWrapper = styled.span<{ hasLabel: boolean }>`
	${props =>
		props.hasLabel
			? `
		margin-right: ${iconRightMarginInEm}em;
	`
			: ``};
`;
