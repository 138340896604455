import { makeApiRequest, Auth } from "api/makeApiRequest";
import { OutputRow as SessionTypesData } from "api/getSessionTypes";
import { ValidationCallback } from "validation/validate";

interface SaveSessionTypesParams {
	uid: string;
	rows: {
		old?: SessionTypesData | undefined;
		new?: SessionTypesData | undefined;
	}[];
}

// TODO:WV:20200103:Enforce limit of 50 session types
type Output = {};
const flagValidationErrors: ValidationCallback<Output> = (input, flag) =>
	typeof input === "object" && Object.keys(input).length === 0;

export function saveSessionTypes(
	{ uid, rows }: SaveSessionTypesParams,
	auth: Auth
) {
	return makeApiRequest("sessionTypes", {
		auth,
		data: {
			post: {
				uid,
				rows
			}
		},
		flagValidationErrors
	});
}
