import React, { useCallback } from "react";

import ActionButtons from "components/ActionButtons";

export interface StepFooterProps {
	onCancel: () => void;
	nextButtonText: string | null;
	cancelButtonText: string | undefined;
	onOK: () => void;
}

const StepFooter: React.FunctionComponent<StepFooterProps> = ({
	onCancel,
	nextButtonText,
	cancelButtonText = "Cancel",
	onOK
}) => {
	const onSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			onOK();
		},
		[onOK]
	);

	return (
		<form onSubmit={onSubmit}>
			<ActionButtons
				primary={
					nextButtonText
						? {
								label: nextButtonText
						  }
						: undefined
				}
				secondary={{ label: cancelButtonText, onClick: e => onCancel() }}
			/>
		</form>
	);
};

export default StepFooter;
