import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	meetingId: string;
	meetingWasOK: boolean;
	problemMessage?: string;
}

type Output = {};

const flagValidationErrors: ValidationCallback<Output> = (input, flag) => {};

export function signMeetingOff(
	{ meetingId, meetingWasOK, problemMessage }: Params,
	auth: Auth
): ApiRequest<{}> {
	const req = makeApiRequest("signMeetingOff", {
		data: {
			post: {
				meetingId,
				meetingWasOK,
				...(problemMessage ? { problemMessage } : {})
			}
		},
		flagValidationErrors,
		auth
	});

	return req;
}
