import { useCallback } from "react";

import { useRemoteResource } from "hooks/useRemoteResource";
import {
	getSessionTypes,
	OutputRow as GetSessionTypesOutput
} from "api/getSessionTypes";
import { makeDummyRequest } from "api/makeDummyRequest";
import { SearchResults } from "api/SearchResults";

interface Params {
	mentorId?: string;
	doWait?: boolean;
	showDisabled?: boolean;
	repeatNumber?: number;
	persistBetweenLoads?: boolean;
}

export type OutputRow = GetSessionTypesOutput;

export function useSessionTypes({
	mentorId,
	showDisabled = false,
	doWait = false,
	repeatNumber = 1,
	persistBetweenLoads
}: Params) {
	const cb = useCallback(
		(arg, auth) => {
			if (mentorId === undefined) {
				return makeDummyRequest(
					{ page: [] } as SearchResults<OutputRow>,
					"sessiontypes"
				);
			}

			return getSessionTypes({ uid: mentorId, showDisabled }, auth);
		},
		[mentorId, showDisabled]
	);

	return useRemoteResource(cb, doWait, repeatNumber, persistBetweenLoads);
}
