import React, { useState, useEffect } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { WizardData } from "../PaymentWizard";

import { Output as ProfileData } from "api/getUserProfile";
import { useUserProfile } from "hooks/useUserProfile";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import FieldSelect from "components/FieldSelect";
import { FieldSelectContainer } from "./StepChoosePaymentProvider.styles";

import { PaymentProvider } from "../PaymentWizard";

function StepChoosePaymentProvider({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData: { mentorId }
}: ModalWizardStepRenderProps<WizardData>) {
	const { isWaiting, isLoading, isError, output } = useUserProfile({
		uid: mentorId
	});

	const availableOptions = generateOptionsFromUserProfile(output);

	const [currentOptions, setCurrentOptions] = useState<
		FieldOption<PaymentProvider>[]
	>(availableOptions);

	const numCurrentOptions = currentOptions.length;
	useEffect(() => {
		if (output && numCurrentOptions === 0) {
			setCurrentOptions(generateOptionsFromUserProfile(output));
		}
	}, [output, numCurrentOptions, setCurrentOptions]);

	useEffect(() => {
		const selectedOption = currentOptions.find(opt => !!opt.selected);
		const selectedOptionValue = selectedOption
			? selectedOption.value
			: undefined;

		if (selectedOptionValue !== undefined) {
			enableProgressToNextStep({
				paymentProvider: selectedOptionValue
			});
		} else {
			disableProgressToNextStep();
		}
	}, [enableProgressToNextStep, disableProgressToNextStep, currentOptions]);

	return (
		<>
			<FieldSelectContainer>
				<FieldSelect
					allowMultipleSelections={false}
					isLoading={isLoading || isWaiting}
					isError={isError}
					currentOptions={currentOptions}
					onNewCurrentOptions={setCurrentOptions}
				/>
			</FieldSelectContainer>
		</>
	);
}

export default StepChoosePaymentProvider;

function generateOptionsFromUserProfile(output?: ProfileData) {
	if (!output) {
		return [];
	}

	const mentorCanAcceptCardPayments = !!output.canAcceptPayments;

	// TODO:WV:20230430:Logos.
	const availablePaymentProviders = [
		...(output.paymentProvidersMBWay ? [["mbway", "MBWay"]] : []),
		...(output.paymentProvidersWiseEmail || output.paymentProvidersWiseTelephone
			? [["wise", "Wise"]]
			: []),
		...(mentorCanAcceptCardPayments ? [["card", "Credit / debit card"]] : []),
		...(output.paymentProvidersCustom ? [["custom", "Other"]] : [])
	];

	return availablePaymentProviders.map(([key, text]) => {
		const id = key;
		return {
			id,
			value: id as PaymentProvider,
			text
		};
	});
}
