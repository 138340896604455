import { useRef, useEffect } from "react";

export function useEffectWithCustomComparator<T>(
	callback: (value: T) => void,
	currentValue: T,
	generateHash: (arg: T) => string
) {
	const ref = useRef<T>(currentValue);
	ref.current = currentValue;
	const hash = generateHash(currentValue);

	useEffect(() => {
		return callback(ref.current);
	}, [callback, ref, hash]);
}
