import React, { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import PageContent from "components/PageContent";
import ErrorBanner from "components/ErrorBanner";
import KeyValueTable from "components/KeyValueTable";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";
import { useRemoteResource } from "hooks/useRemoteResource";
import { getContactFormSubmission } from "api/getContactFormSubmission";
import { ucFirst } from "utils/ucFirst";

import { Data } from "./PageContactFormSubmission.styles";

const PageContactFormSubmission: React.FunctionComponent = () => {
	usePageTitlePrefix("View contact form submission");

	const { id } = useParams<{ id: string }>();
	const [{ uid }] = useContext(AuthContext);

	const {
		isWaiting,
		isLoading,
		isError,
		output: contactFormSubmission
	} = useRemoteResource(
		useCallback(() => {
			return getContactFormSubmission(
				{
					id
				},
				{ uid }
			);
		}, [id, uid])
	);

	const data = contactFormSubmission
		? Object.entries(contactFormSubmission)
				.filter(([key, value]) => key !== "id")
				.map(([key, value]) => {
					const label =
						key === "dateReceivedUTC"
							? "Date received"
							: key === "enquiryType"
							? "Enquiry type"
							: ucFirst(key);
					const row: [string, string] = [
						label,
						typeof value === "object" && !(value instanceof Date) ? (
							<Data>{JSON.stringify(value, undefined, 2)}</Data>
						) : value instanceof Date ? (
							moment(value).format(`DD/MMM/YYYY H:mm:ss UTC`)
						) : (
							value
						)
					];
					return {
						row,
						key
					};
				})
				.sort((a, b) => {
					const positions = ["dateReceivedUTC", "enquiryType", "email"];
					return positions.indexOf(b.key) - positions.indexOf(a.key);
				})
		: [];

	return (
		<PageContent>
			{isError ? <ErrorBanner /> : null}
			{isLoading || isWaiting ? <p>...</p> : null}
			{contactFormSubmission ? (
				<KeyValueTable supportsLongEntries data={data} />
			) : null}
		</PageContent>
	);
};

export default PageContactFormSubmission;
