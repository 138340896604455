import React from "react";
import styled from "styled-components";

interface Props {
	isCentered?: boolean;
	title: string;

	id?: string;

	// NB image and video dimensions should be 320x340 or at least the same aspect ratio (16:17)
	imageSRC?: string;
	videoSRC?: string;

	_instanceNumber?: number;
}

const DecorativeImage = styled.span<{ src: string }>`
	background-image: url(${props => props.src});
	background-size: cover;
	background-repeat: no-repeat;
	background-color: transparent;
`;

const Section: React.FunctionComponent<Props> = ({
	isCentered = false,
	imageSRC,
	videoSRC,
	_instanceNumber = 1,
	title,
	id,
	children
}) => {
	const isRightAligned = isCentered ? false : _instanceNumber % 2 === 1;

	return (
		<section
			id={id ? id : ""}
			className={`main ${isCentered ? "special" : ""}`}
		>
			<div className={`inner spotlight style1 ${isRightAligned ? "alt" : ""}`}>
				<div className="content">
					<header>
						<h2>{title}</h2>
					</header>
					{children}
				</div>

				{imageSRC ? (
					<DecorativeImage className="image tall-image" src={imageSRC} />
				) : null}

				{videoSRC ? (
					<span className="image tall-image">
						<video
							src={videoSRC}
							muted
							autoPlay
							loop
							playsInline
							style={{ height: "100%" }}
						></video>
					</span>
				) : null}
			</div>
		</section>
	);
};

export default Section;
