import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useUserProfile } from "hooks/useUserProfile";

import { Video } from "./ProfileVideoSection.styles";

const ProfileVideoSection: React.FunctionComponent = () => {
	const [{ uid }] = useContext(AuthContext);

	const { output: profileData } = useUserProfile({ uid });
	const videoUrl =
		profileData && profileData.videoUrl ? profileData.videoUrl : undefined;

	return (
		<>
			{videoUrl ? <Video src={videoUrl} controls /> : null}
			<p>
				To upload a new profile video, please{" "}
				<Link to="/contact">contact an admin</Link>.
			</p>
		</>
	);
};

export default ProfileVideoSection;
