import React from "react";

import PageContent from "components/PageContent";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";

const PageSupport: React.FunctionComponent = () => {
	usePageTitlePrefix("Support");

	return (
		<PageContent>
			<header className="major">
				<h1>Support</h1>
			</header>
			<p>
				If you need help with Aldeia, have encountered a bug, or need any other
				form of support, we are happy to help.
			</p>
			<p>
				Please send an email to{" "}
				<a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}`}>
					{process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}
				</a>{" "}
				detailing your problems and we will reply as soon as possible.
			</p>
		</PageContent>
	);
};

export default PageSupport;
