import styled from "styled-components";

export const StyledTable = styled.table`
	margin-bottom: 0;

	width: 100%;

	table-layout: auto;
	& tbody {
		tr,
		tr:nth-child(2n + 1),
		th,
		td {
			background-color: transparent;
			border: none;
		}
	}

	td {
		padding: 0.5em 0;
	}
	tr:not(:first-child) td {
		border-top: 1px solid #eaeaea;
	}
	tr td:first-child {
		color: #777777;
		font-weight: 500;
		white-space: nowrap;
	}
	tr td:not(:first-child) {
		width: 80%;
		padding-left: 1em;

		overflow-wrap: break-word;
		word-wrap: break-word;

		-ms-word-break: break-word;
		word-break: break-word;
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
`;

export const KeyValueBlock = styled.div`
	margin-bottom: 0.5em;
`;

export const KeyValueBlockList = styled.div`
	margin-bottom: 2em;
`;
