import React from "react";

import { StyledParagraph } from "./ErrorBanner.styles";

export const defaultErrorText =
	"Sorry, there has been an error loading this content.  Please try again later.";

const ErrorBanner: React.FunctionComponent = ({ children }) => {
	// NB the className "error-banner" is necessary for errors in the chat widget
	return (
		<StyledParagraph className="error-banner">
			{React.Children.count(children) ? children : defaultErrorText}
		</StyledParagraph>
	);
};

export default ErrorBanner;
