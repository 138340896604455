import React, { useState, useCallback, useContext } from "react";
import {
	isWidgetOpened,
	toggleWidget,
	renderCustomComponent
} from "react-chat-widget";
import moment from "moment-timezone";

import { captureException } from "services/captureException";
import { formatDateForApi } from "services/formatDateForApi";
import ChatWidgetErrorSendingLastMessage from "components/ChatWidget/ChatWidgetErrorSendingLastMessage";
import { useChatSocket } from "./useChatSocket";
import { useAddMessageToChat } from "components/ChatWidget/useAddMessageToChat";
import { useAddChatContentsToWidget } from "components/ChatWidget/useAddChatContentsToWidget";
import { useRemoteChatMessages } from "./useRemoteChatMessages";
import { useInitialiseChatFromQueryString } from "./useInitialiseChatFromQueryString";
import { useSetMessageSendingFieldsDisabled } from "./useSetMessageSendingFieldsDisabled";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { Pagination } from "hooks/usePaginatedOptions";
import { ChatContext } from "./ChatContext";
import ChatWidget from "components/ChatWidget/ChatWidget";

interface ChatPartner {
	uid: string;
	displayName: string;
}

export interface ChatDetails {
	partner?: ChatPartner;
	highlightedMessageId?: string;
	pagination: Pagination<Date>;
}

const Chat: React.FunctionComponent = ({ children }) => {
	const [chatDetails, setChatDetails] = useState<ChatDetails>({
		pagination: { cursorType: "after" }
	});
	const { partner, highlightedMessageId, pagination } = chatDetails;

	const setChatDetailsAndOpenOrCloseCorrespondingly = useCallback(
		(details: ChatDetails | undefined) => {
			setChatDetails(
				details ? details : { pagination: { cursorType: "after" } }
			);

			if (details && details.partner) {
				ensureWidgetOpen();
			} else {
				ensureWidgetClosed();
			}
		},
		[setChatDetails]
	);

	const closeAndReset = useCallback(() => {
		setChatDetailsAndOpenOrCloseCorrespondingly(undefined);
	}, [setChatDetailsAndOpenOrCloseCorrespondingly]);

	const [{ uid }] = useContext(AuthContext);
	const { uid: partnerUID } = partner ? partner : { uid: undefined };

	const addMessageToChat = useAddMessageToChat(uid);
	const socket = useChatSocket({
		uid,
		partnerUID,
		onMessageReceived: useCallback(
			(message: any, socket) => {
				if (message.senderUid === partnerUID) {
					addMessageToChat(
						{
							...message,
							dateSentUTC: moment.utc(message.dateSentUTC).toDate()
						},
						socket
					);
				}
			},
			[addMessageToChat, partnerUID]
		)
	});

	const { isError, output: chatContents } = useRemoteChatMessages(
		uid,
		partnerUID,
		pagination,
		highlightedMessageId,
		!!socket
	);

	const setMessageSendingFieldDisabled = useSetMessageSendingFieldsDisabled();

	useAddChatContentsToWidget(
		chatContents,
		addMessageToChat,
		chatDetails,
		setChatDetails,
		setMessageSendingFieldDisabled,
		socket
	);

	useInitialiseChatFromQueryString(setChatDetailsAndOpenOrCloseCorrespondingly);

	const handleNewChatMessage = useCallback(
		(message: any) => {
			if (!uid) {
				throw new Error("Could not determine user ID");
			}

			if (!partnerUID) {
				throw new Error("Could not determine partner ID");
			}

			if (!socket) {
				captureException(new Error("No chat socket"), {
					evtType: "noChatSocket"
				});
				return;
			}

			try {
				socket.emit("chatMessage", {
					senderUid: uid,
					recipientUid: partnerUID,
					message,
					dateSentUTC: formatDateForApi(new Date())
				});
			} catch (e) {
				captureException(e, { evtType: "errorSendingMessage" });
				renderCustomComponent(ChatWidgetErrorSendingLastMessage, {});
			}
		},
		[uid, partnerUID, socket]
	);

	return (
		<ChatContext.Provider
			value={[chatDetails, setChatDetailsAndOpenOrCloseCorrespondingly]}
		>
			<ChatWidget
				partner={partner}
				isError={isError}
				closeAndReset={closeAndReset}
				handleNewUserMessage={handleNewChatMessage}
			/>

			{/* NB:WV:20231028 it is necessary to render 'children' because it contains the entire app! */}
			{children}
		</ChatContext.Provider>
	);
};

export default Chat;

function ensureWidgetOpen() {
	if (!isWidgetOpened()) {
		toggleWidget();
	}
}

function ensureWidgetClosed() {
	if (isWidgetOpened()) {
		toggleWidget();
	}
}
