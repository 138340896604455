import React, { useCallback, useMemo } from "react";
import range from "lodash.range";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { OutputRow as SessionTypesData } from "api/getSessionTypes";
import ModalFieldSelect from "components/ModalFieldSelect";
import ModalFieldText from "components/ModalFieldText";
import { OnCompleteOutput } from "components/ModalWizard/ModalWizard";
import ModalFieldTextArea from "components/ModalFieldTextArea";
import EditableTable from "components/EditableTable";
import { formatSessionDuration } from "services/formatSessionDuration";
import { ValidationCallback } from "validation/validate";
import { useStickySort } from "hooks/useStickySort";
import StepSessionDescription from "./NewRowWizard/StepSessionDescription";
import StepSessionTitle from "./NewRowWizard/StepSessionTitle";

interface SessionTypesTableProps {
	sessionTypes: SessionTypesData[];
	isLoading: boolean;
	isWaiting: boolean;
	isError: boolean;
	waitModalTitle: string;
	waitModalIsShowing: boolean;
	onEdit: (
		rows: { old?: SessionTypesData; new?: SessionTypesData }[]
	) => OnCompleteOutput;
}

export type DataInAnyRow = SessionTypesData;

const SessionTypesTable: React.FunctionComponent<SessionTypesTableProps> = ({
	sessionTypes,
	isLoading,
	isWaiting,
	isError,
	onEdit: onEditGlobal,
	waitModalTitle,
	waitModalIsShowing
}) => {
	const onNewRowWizardComplete = useCallback(
		(data: SessionTypesData) => {
			return onEditGlobal([
				{
					new: {
						...data,
						isEnabled: true
					}
				}
			]);
		},
		[onEditGlobal]
	);

	const sortedSessionTypes = useStickySort(
		sessionTypes,
		useCallback((a: SessionTypesData, b: SessionTypesData) => {
			return a.sessionTitle.localeCompare(b.sessionTitle);
		}, []),
		"series"
	);

	const rows = useMemo(
		() =>
			sortedSessionTypes.map(data => ({
				key: data.id,
				data,
				actionWhenEditing: {
					icon: faTrash,
					label: "Delete",
					onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
						e.preventDefault();
						onEditGlobal([{ old: data }]);
					}
				}
			})),
		[sortedSessionTypes, onEditGlobal]
	);

	const newRowWizard = useMemo(() => {
		const steps = [
			{
				key: "session-title",
				title: "Please give the session a name (e.g. 'conversation practise').",
				component: StepSessionTitle
			},
			{
				key: "session-description",
				title:
					"Please write a few words to describe what will happen at the session.",
				component: StepSessionDescription
			}
		];
		const validateNewRowData: ValidationCallback<DataInAnyRow> = (
			data,
			{ checkField }
		) => {
			checkField("sessionTitle", { type: "string" });
			checkField("sessionDescription", { type: "string" });
		};
		return {
			steps,
			validateNewRowData,
			initialData: {}
		};
	}, []);

	return (
		<>
			<EditableTable<DataInAnyRow>
				allowDeleteRows
				rows={rows}
				onEdit={onEditGlobal}
				dataDescriptionSingular="session type"
				dataDescriptionPlural="session sessionTypes"
				onNew={onNewRowWizardComplete}
				newRowWizard={newRowWizard}
				rowIsDisabled={row => !row.isEnabled}
				isLoading={isLoading}
				isWaiting={isWaiting}
				isError={isError}
				waitModalTitle={waitModalTitle}
				waitModalIsShowing={waitModalIsShowing}
				cols={[
					{
						key: "sessionTitle",
						heading: "Title",
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<ModalFieldText
								isUnlocked={isEditing}
								title="Session title"
								onOK={newValue => {
									const newData = {
										...row,
										[rowKey]: newValue
									};
									onEdit([{ old: row, new: newData }]);
								}}
								value={row.sessionTitle}
							/>
						)
					},
					{
						key: "sessionDescription",
						heading: "Description",
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<ModalFieldTextArea
								isUnlocked={isEditing}
								title="Session description"
								onOK={newValue => {
									const newData = {
										...row,
										[rowKey]: newValue
									};
									onEdit([{ old: row, new: newData }]);
								}}
								value={row.sessionDescription}
							/>
						)
					},
					{
						key: "isEnabled",
						heading: "Enabled",
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<ModalFieldSelect
								isUnlocked={isEditing}
								title="Enabled"
								requireSelection
								onOK={newOptions => {
									const selectedOption = newOptions.find(val => !!val.selected);
									if (!selectedOption) {
										throw new Error("No selected option");
									}

									const newData = {
										...row,
										[rowKey]: selectedOption.value !== 0
									};

									onEdit([{ old: row, new: newData }]);
								}}
								options={[
									{
										id: "no",
										value: 0,
										text: "No",
										selected: !row.isEnabled
									},
									{
										id: "yes",
										value: 1,
										text: "Yes",
										selected: !!row.isEnabled
									}
								]}
								allowMultipleSelections={false}
							/>
						)
					}
				]}
			/>
		</>
	);
};

export default SessionTypesTable;

export function getSingleSessionPriceOptions(selectedValue?: number) {
	return range(3, 7).map(step => {
		const sessionDurationMinutes = step * 10;
		return {
			id: sessionDurationMinutes + "",
			value: sessionDurationMinutes,
			text: formatSessionDuration({ sessionDurationMinutes }),
			selected: !!selectedValue && selectedValue === sessionDurationMinutes
		};
	});
}
