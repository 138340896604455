import React from "react";

import Modal from "components/Modal";
import LoginOrSignup from "components/LoginOrSignup";
import { ExplanationParagraph } from "./ModalLoginOrSignup.styles";

const modalStyles = {
	overlay: {
		zIndex: 10
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)"
	}
};

interface Props {
	isOpen: boolean;
	onRequestClose?(
		event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
	): void;
}

const ModalLoginOrSignup: React.FunctionComponent<Props> = ({
	isOpen,
	onRequestClose
}: Props) => {
	return (
		<Modal isOpen={isOpen} style={modalStyles} onRequestClose={onRequestClose}>
			<h2>Log in / Sign up</h2>
			<ExplanationParagraph>
				To sign up, just log in as if you already had an account.
				<br />
				<a href="/privacy-policy" target="_blank">
					We will not abuse your personal data.
				</a>
			</ExplanationParagraph>
			<LoginOrSignup />
		</Modal>
	);
};

export default ModalLoginOrSignup;
