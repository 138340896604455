import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

import { DataInFreshlyEnteredRow } from "components/PageDashboard/AvailabilitySection/AvailabilityTable/AvailabilityTable";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import FieldHourOfDay from "components/FieldHourOfDay";

function StepTimeTo({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData
}: ModalWizardStepRenderProps<DataInFreshlyEnteredRow>) {
	const [selectedTime, setSelectedTime] = useState<number | undefined>();

	useEffect(() => {
		if (selectedTime === undefined) {
			disableProgressToNextStep();
		} else {
			enableProgressToNextStep({
				timeLastAvailableJustBeforeLocaltime: moment.duration({
					hours: selectedTime
				})
			});
		}
	}, [enableProgressToNextStep, disableProgressToNextStep, selectedTime]);

	return (
		<FieldHourOfDay
			selectedTime={selectedTime}
			onChange={setSelectedTime}
			isDisabled={time =>
				wizardData.timeFirstAvailableLocaltime
					? time <= wizardData.timeFirstAvailableLocaltime.hours()
					: false
			}
		/>
	);
}

export default StepTimeTo;
