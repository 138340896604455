import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import PageContent from "components/PageContent";
import { AuthContext } from "components/AuthProvider/AuthProvider";

interface ProtectedRouteProps {
	exact?: boolean;
	path: string;
	failureStyle?: "prompt" | "redirect";
}

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
	path,
	exact = false,
	failureStyle = "redirect",
	children
}) => {
	const [{ isSignedIn, promptForLogin }] = useContext(AuthContext);

	useEffect(() => {
		if (isSignedIn === false && failureStyle === "prompt") {
			promptForLogin();
		}
	}, [isSignedIn, failureStyle, promptForLogin]);

	return (
		<Route exact={exact} path={path}>
			{isSignedIn === true ? children : null}
			{isSignedIn === false ? (
				failureStyle === "redirect" ? (
					<Redirect to="/" />
				) : (
					<PageContent type="authwall" />
				)
			) : null}
		</Route>
	);
};

export default ProtectedRoute;
