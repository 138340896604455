import React, { useState, useCallback } from "react";

import { useAccountStatus } from "hooks/useAccountStatus";
import { useSaveDataAndRefreshAccountDetailsFromApi } from "hooks/useSaveDataAndRefreshAccountDetailsFromApi";
import ModalFieldText from "components/ModalFieldText";
import ModalPleaseWait from "components/ModalPleaseWait";
import { isValidGoogleMeetLink } from "services/isValidGoogleMeetLink";
import terminology from "terminology.json";

import {
	IntroText,
	FieldContainer
} from "./DefaultGoogleMeetLinkSection.styles";

const DefaultGoogleMeetLinkSection: React.FunctionComponent = () => {
	const { defaultGoogleMeetLink } = useAccountStatus();

	const [isSaving, setIsSaving] = useState(false);

	const submitDataToAPI = useSaveDataAndRefreshAccountDetailsFromApi(
		"saveAccountSettings",
		"saving your settings",
		setIsSaving,
		async () => {}
	);

	const handleOKDefaultGoogleMeetLink = useCallback(
		(defaultGoogleMeetLink: string) => {
			submitDataToAPI({ data: { defaultGoogleMeetLink } });
		},
		[submitDataToAPI]
	);

	return (
		<>
			<ModalPleaseWait isOpen={isSaving} title="Saving" />

			<IntroText>
				You can set a default Google Meet link here, to save you the annoyance
				of having to enter a Google Meet link whenever you accept a request for
				a {terminology.meeting} that will take place over Google Meet (Google
				Meet allows repeated use of the same meeting URL).
			</IntroText>
			<IntroText>
				The value you save here will be pre-populated in the relevant field when
				you accept {terminology.meetingRequests}.
			</IntroText>

			<FieldContainer>
				Current default Google Meet link:{" "}
				<ModalFieldText
					isUnlocked={true}
					title="Default Google Meet link"
					onOK={handleOKDefaultGoogleMeetLink}
					value={defaultGoogleMeetLink ? defaultGoogleMeetLink : ""}
					formatValue={value => (value === "" ? "(not set)" : value)}
					validate={isValidGoogleMeetLink}
					placeholder="https://meet.google.com/..."
				/>
			</FieldContainer>
		</>
	);
};

export default DefaultGoogleMeetLinkSection;
