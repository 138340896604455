import styled from "styled-components";

export const CardSection = styled.div.attrs((props: { testid: string }) => ({
	"data-testid": props.testid
}))<{ testid: string }>`
	border: 1px solid #aaa;
	margin-bottom: 1em;
	padding: 1em;
	max-width: 400px;
`;

export const CardDetailsFieldGroup = styled.div`
	margin-bottom: 2em;
`;

export const CardDetailsFieldGroupField = styled.div`
	margin-bottom: 1.5em;
`;
