import React from "react";

// @ts-ignore
import bannerVideo from "resources/banner.mp4";

import { useScreenSizeIsMax } from "hooks/useScreenSizeIsMax";

import { BannerSection } from "./Banner.styles";

interface Props {
	mainHeading: string;
	subHeading?: string;
	moreLinkText: string;
	moreArrowAltText?: string;
	moreLinksTargetId: string;
}

const Banner: React.FunctionComponent<Props> = ({
	mainHeading,
	subHeading,
	moreLinkText,
	moreArrowAltText,
	moreLinksTargetId
}) => {
	const isSmallScreen = useScreenSizeIsMax("small");

	return (
		<BannerSection id="banner">
			<div className="inner">
				<header>
					<h1>{mainHeading}</h1>
					{subHeading ? <p>{subHeading}</p> : null}
				</header>

				<ul className="actions special">
					<li>
						<a
							href={`#${moreLinksTargetId}`}
							className="button primary large scrolly"
						>
							{moreLinkText}
						</a>
					</li>
				</ul>
			</div>
			<a href={`#${moreLinksTargetId}`} className="more">
				{moreArrowAltText ? moreArrowAltText : moreLinkText}
			</a>

			{isSmallScreen ? null : (
				<video src={bannerVideo} muted autoPlay playsInline></video>
			)}
		</BannerSection>
	);
};

export default Banner;
