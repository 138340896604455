import React from "react";
import PaymentWizardProvider from "components/PaymentWizard/PaymentWizardProvider";

export function withPaymentWizardProvider<PublicProps>(
	Component: React.FunctionComponent<PublicProps>
) {
	const Inner: React.FunctionComponent<PublicProps> = props => {
		return (
			<PaymentWizardProvider>
				<Component {...props} />
			</PaymentWizardProvider>
		);
	};

	return Inner;
}
