import { useEffect, useContext, useCallback, useState } from "react";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { acceptTerms } from "api/acceptTerms";
import { usePrevious } from "hooks/usePrevious";
import { useSendData } from "hooks/useSendData";

export function useSaveAcceptance() {
	const [{ uid, setHasAcceptedTerms }] = useContext(AuthContext);

	const [doSave, setDoSave] = useState(false);
	const previousDoSave = usePrevious(doSave);

	const doRequest = useSendData({
		method: acceptTerms,
		onFailure: e => {
			// Do nothing here
		}
	});

	useEffect(() => {
		if (!uid) {
			return;
		}

		if (doSave && doSave !== previousDoSave) {
			const req = doRequest({ uid }, { uid });
			req.ready.then(arg => {
				setHasAcceptedTerms(true);
				setDoSave(false);
			});
			return () => {
				setDoSave(false);
				req.abort();
			};
		}
	}, [doSave, uid, previousDoSave, setHasAcceptedTerms, doRequest]);

	const go = useCallback(() => {
		setDoSave(true);
	}, [setDoSave]);

	return go;
}
