import React, { useState } from "react";

import { useProfileData } from "hooks/useProfileData";
import TextWithLineBreaks from "components/TextWithLineBreaks";

interface Props {
	vanityUrl: string;
	uid: string;
	numParagraphsInSummary?: number;
}

const Bio: React.FunctionComponent<Props> = ({
	vanityUrl,
	uid,
	numParagraphsInSummary = 3
}) => {
	const [isLongFormat, setIsLongFormat] = useState(false);

	const { output: profileData } = useProfileData(vanityUrl, uid);

	return (
		<TextWithLineBreaks
			truncate={!isLongFormat}
			maxParagraphsToShow={numParagraphsInSummary}
			onClickReadMore={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
				e.preventDefault();
				setIsLongFormat(true);
			}}
		>
			{profileData && profileData.mentorProfileAboutMe
				? profileData.mentorProfileAboutMe
				: null}
		</TextWithLineBreaks>
	);
};

export default Bio;
