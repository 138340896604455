import React, { useState, useCallback } from "react";
import { useElements, CardElement } from "@stripe/react-stripe-js";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { WizardData } from "../PaymentWizard";

import FieldCheckbox from "components/FieldCheckbox";
import PayWithNewCardForm from "components/PayWithNewCardForm";
import { isValidCardDetailsFields } from "hooks/usePayWithNewCard";
import terminology from "terminology.json";

import {
	CardDetailsFormSection,
	ImportantNote
} from "./StepCardDetails.styles";

function StepCardDetails({
	enableProgressToNextStep,
	disableProgressToNextStep
}: ModalWizardStepRenderProps<WizardData>) {
	const elements = useElements();

	const [shouldSaveCardDetails, setShouldSaveCardDetails] = useState(false);

	const handleNewBillingDetails = useCallback(
		newBillingDetails => {
			if (!newBillingDetails) {
				disableProgressToNextStep();
				return;
			}

			if (!newBillingDetails.cardDetailsAreComplete) {
				disableProgressToNextStep({
					alert: {
						title: "Card details incomplete",
						contents:
							"Please make sure you have completed all card details (including card number, expiry date, and any security code)"
					}
				});
				return;
			}

			if (!isValidCardDetailsFields(newBillingDetails)) {
				disableProgressToNextStep({
					alert: {
						title: "Please fill in all fields",
						contents:
							"The only optional field is line two of the street address.  The others must all be completed."
					}
				});
				return;
			}

			if (!elements) {
				throw new Error("Elements not loaded yet");
			}

			// Get a reference to a mounted CardElement. Elements knows how
			// to find your CardElement because there can only ever be one of
			// each type of element.
			const card = elements.getElement(CardElement);
			if (card === null) {
				throw new Error("Could not find card element");
			}

			enableProgressToNextStep({
				card,
				cardBillingDetails: newBillingDetails,
				shouldSaveCardDetails
			});
		},
		[
			shouldSaveCardDetails,
			disableProgressToNextStep,
			enableProgressToNextStep,
			elements
		]
	);

	const toggleCheckbox = useCallback(
		() => setShouldSaveCardDetails(oldCheckboxChecked => !oldCheckboxChecked),
		[setShouldSaveCardDetails]
	);

	return (
		<>
			<CardDetailsFormSection>
				<PayWithNewCardForm onChange={handleNewBillingDetails} />
			</CardDetailsFormSection>
			<ImportantNote>
				<p>
					<strong>Saving your card details</strong>
				</p>
				<p>
					Would you like to store your card details securely with our payment
					provider, to save time during future purposes?
				</p>
				<p>
					<FieldCheckbox
						checked={shouldSaveCardDetails}
						onChange={toggleCheckbox}
						id="request-card-storage"
						name="request-card-storage"
						labelText={`Yes please, I authorise ${terminology.companyName} to store my card details, in accordance with their terms and conditions.`}
					/>
				</p>
			</ImportantNote>
		</>
	);
}

export default StepCardDetails;
