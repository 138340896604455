import { useState, useContext } from "react";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { OutputRow as GetContactsOutputRow } from "api/getContacts";
import { useContacts } from "hooks/useContacts";
import { usePaginatedOptions, Pagination } from "./usePaginatedOptions";

export const usePaginatedContacts = (
	selectedValue: string | null,
	selectedItemLabel?: string,
	contactType?: string
) => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);
	const authenticatedUid = isSignedIn ? uid : undefined;

	const [pagination, setPagination] = useState<Pagination>({
		cursor: undefined,
		cursorType: "after"
	});

	const cursorValue = (entry: GetContactsOutputRow) =>
		entry.dateOfLastActivityUTC.getTime() + "";

	const getOptionsFromPage = getContactOptions;

	const isSelectedEntry = (entry: GetContactsOutputRow, value: string) =>
		entry.contactUid === value;

	const { cursor, cursorType } = pagination;
	const curPage = useContacts({
		uid: authenticatedUid,
		doWait: !authenticatedUid,
		repeatNumber: 1,
		cursor,
		cursorType
	});

	const { isWaiting, isLoading, isError, output } = curPage;

	const paginatedOptions = usePaginatedOptions<GetContactsOutputRow>(
		selectedValue,
		output,
		isSelectedEntry,
		cursorValue,
		pagination,
		setPagination,
		getOptionsFromPage(selectedItemLabel)
	);

	return {
		isWaiting,
		isLoading,
		isError,
		...paginatedOptions
	};
};

function getContactOptions(selectedItemLabel?: string) {
	return function(
		contacts: GetContactsOutputRow[],
		selectedValue?: string | null
	) {
		const nullOption = {
			id: "null",
			value: null,
			text: "(any)",
			selected: selectedValue === null
		};

		const options = contacts
			.sort(
				(a, b) =>
					b.dateOfLastActivityUTC.getTime() - a.dateOfLastActivityUTC.getTime()
			)
			.map(contact => {
				return {
					id: contact.contactUid,
					value: contact.contactUid,
					text: contact.displayNameContact,
					selected: !!selectedValue && selectedValue === contact.contactUid
				};
			});

		// TODO:WV:20221203:Stop this jumping to the middle of the page when paginating
		const selctedValueOption =
			!selectedValue || options.some(option => option.selected)
				? undefined
				: {
						id: selectedValue,
						value: selectedValue,
						text: selectedItemLabel ? selectedItemLabel : "(Current selection)",
						selected: true
				  };

		return [
			nullOption,
			...(selctedValueOption ? [selctedValueOption] : []),
			...options
		];
	};
}
