import React from "react";

import { useAvailabilityCalendarData } from "hooks/useAvailabilityCalendarData";
import ErrorBanner from "components/ErrorBanner";
import MeetingCalendar from "components/MeetingCalendar";
import {
	MeetingCalendarEvent,
	MeetingCalendarProps
} from "components/MeetingCalendar/MeetingCalendar";

import { useProfileData } from "hooks/useProfileData";
import { useMentorIsOfferingMeetings } from "hooks/useMentorIsOfferingMeetings";

import terminology from "terminology.json";

type StartBookingWizard = (e?: MeetingCalendarEvent) => void;

interface Props {
	vanityUrl: string;
	uid: string;
	mentorId?: string;
	startBookingWizard: StartBookingWizard;
	handleEndBookingWizard: () => void;
	isMiniFormat?: boolean;
	availabilityCalendarData?: MeetingCalendarProps;
	includeHeadingBar?: boolean;
}

const Availability: React.FunctionComponent<Props> = ({
	vanityUrl,
	uid,
	mentorId,
	startBookingWizard,
	handleEndBookingWizard,
	isMiniFormat = false,
	availabilityCalendarData: suppliedAvailabilityCalendarData,
	includeHeadingBar = true
}) => {
	const { output: profileData } = useProfileData(vanityUrl, uid);

	const mentorIsOfferingMeetings = useMentorIsOfferingMeetings(
		vanityUrl,
		uid,
		mentorId
	);

	const {
		availabilityCalendarData: fetchedAvailabilityCalendarData
	} = useAvailabilityCalendarData({
		mentorId,
		dontFetch: !!suppliedAvailabilityCalendarData,
		slotDurationInMinutes: 60
	});

	const availabilityCalendarData = !!suppliedAvailabilityCalendarData
		? suppliedAvailabilityCalendarData
		: fetchedAvailabilityCalendarData;

	if (
		!(
			profileData &&
			"uid" in profileData &&
			profileData.uid &&
			profileData.isMentor
		)
	) {
		return null;
	}

	if (
		mentorIsOfferingMeetings.output === false ||
		availabilityCalendarData.isError
	) {
		return (
			<>
				{includeHeadingBar ? (
					<h2>
						Availability{isMiniFormat ? "" : ` for ${terminology.meetings}`}
					</h2>
				) : null}
				{availabilityCalendarData.isError ? (
					<ErrorBanner>
						Unfortunately there was an error loading availability for this{" "}
						{terminology.mentor} / date range.
						<br />
						Please try again later, and contact us if the problem remains.
					</ErrorBanner>
				) : mentorIsOfferingMeetings.output === false ? (
					<p>
						<strong>
							Unfortunately, this {terminology.mentor} is not currently
							available for {terminology.meetings}.
						</strong>
					</p>
				) : null}
			</>
		);
	}

	return (
		<MeetingCalendar
			{...availabilityCalendarData}
			isClickable
			onEventClick={startBookingWizard}
			displaymode={isMiniFormat ? "compact" : "full"}
			includeHeadingBar={includeHeadingBar}
		/>
	);
};

export default Availability;
