export function getFormData(form: any) {
	return [
		...form.querySelectorAll(
			"input[type=hidden], input[type=text], input[type=email], input[type=hidden], input[type=tel], textarea"
		)
	].reduce(
		(agg, cur) => ({
			...agg,
			[cur.name]: cur.value
		}),
		{}
	);
}
