import { makeApiRequest, Auth } from "api/makeApiRequest";
import { SearchResults } from "api/SearchResults";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";
import { flagValidationErrorsInSearchResults } from "validation/flagValidationErrorsInSearchResults";

export interface OutputRow {
	id: string;
	sessionDescription: string;
	sessionTitle: string;
	isEnabled: boolean;
	series: string;
}

interface Params {
	uid: string;
	showDisabled?: boolean;
}

export const flagValidationErrorsInRow: ValidationCallback<OutputRow> = (
	input,
	{ flag, checkField }
) => {
	checkField("id", { type: "string" });
	checkField("sessionDescription", { type: "string" });
	checkField("sessionTitle", { type: "string" });
	checkField("isEnabled", { type: "boolean" });
	checkField("series", { type: "string" });
};

export const flagValidationErrors: ValidationCallback<
	SearchResults<OutputRow>
> = (input, flag, auth) =>
	flagValidationErrorsInSearchResults(
		flagValidationErrorsInRow,
		input,
		flag,
		auth
	);

export function getSessionTypes(
	{ uid, showDisabled }: Params,
	auth: Auth
): ApiRequest<SearchResults<OutputRow>> {
	return makeApiRequest("sessionTypes", {
		auth,
		data: {
			get: {
				uid,
				...(showDisabled ? { showDisabled: "1" } : {})
			}
		},
		flagValidationErrors
	});
}
