import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ValidationCallback } from "validation/validate";

export type Output = { userExists: boolean };

export const flagValidationErrors: ValidationCallback<Output> = (
	input,
	{ flag, checkField },
	auth
) => {
	checkField("userExists", { type: "boolean" });
};

interface GetUserExistsParams {
	vanityUrl?: string;
	uid?: string;
	cacheInMemory: boolean;
}

export function getUserExists(
	{ vanityUrl, uid, cacheInMemory }: GetUserExistsParams,
	auth: Auth
) {
	return makeApiRequest<Output>("userExists", {
		auth,
		data: {
			get: {
				...(uid ? { uid } : {}),
				...(vanityUrl ? { vanityUrl } : {})
			}
		},
		cacheInMemory,
		flagValidationErrors
	});
}
