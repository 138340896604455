import { useRef, useCallback } from "react";

interface Row {
	id: string;
	series?: string;
}

export function useStickySort<T extends Row>(
	data: T[],
	sorter: (a: T, b: T) => number,
	referenceField: "id" | "series" = "id"
) {
	const sortPositions = useRef<{ [key: string]: number }>({});

	const sortData = useCallback(
		(a: T, b: T) => {
			const aRef = getReferenceValue(a, referenceField);
			const bRef = getReferenceValue(b, referenceField);

			if (
				sortPositions.current[aRef] !== undefined &&
				sortPositions.current[bRef] !== undefined
			) {
				return sortPositions.current[aRef] - sortPositions.current[bRef];
			}

			return sorter(a, b);
		},
		[sortPositions, sorter, referenceField]
	);
	const sortedData = [...data].sort(sortData);
	sortPositions.current = sortedData.reduce(
		(agg, cur, i) => ({
			...agg,
			[getReferenceValue(cur, referenceField)]: i
		}),
		{}
	);

	return sortedData;
}

function getReferenceValue<T extends Row>(
	row: T,
	referenceField: "id" | "series"
) {
	if (referenceField === "series") {
		if (row.series === undefined) {
			// TODO:WV:20221118:Report to Sentry
			throw new Error("No series field");
		}
		return row.series;
	}

	return row.id;
}
