import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	uid: string;
	stripePaymentMethodId?: string;
	intendToSaveForFutureUse: boolean;
	meetingId?: string;
	packageId?: string;
}

interface Output {
	clientSecret: string;
}

export const flagValidationErrors: ValidationCallback<Output> = (
	input,
	{ checkField }
) => {
	checkField("clientSecret", { type: "string" });
};

export function makePaymentIntent(
	{
		uid,
		meetingId,
		packageId,
		stripePaymentMethodId,
		intendToSaveForFutureUse
	}: Params,
	auth: Auth
): ApiRequest<Output> {
	if (!(meetingId || packageId)) {
		// TODO:WV:20221224:Send to Sentry
		throw new Error("Must have either meetingId or packageId");
	}

	return makeApiRequest("makePaymentIntent", {
		auth,
		data: {
			post: {
				uid,
				meetingId,
				packageId,
				stripePaymentMethodId,
				intendToSaveForFutureUse
			}
		},
		flagValidationErrors
	});
}
