import moment from "moment";
import { XOR } from "ts-xor";

import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import {
	SearchResultsWithCount,
	SearchResultsWithCountAndPagination
} from "api/SearchResults";
import { validate, ValidationCallback } from "validation/validate";
import { formatDateForApi } from "services/formatDateForApi";

interface BaseParams {
	cursor?: Date;
	cursorType?: "after" | "before";
}

interface ParamsWithUid extends BaseParams {
	uid: string;
}

interface ParamsWithContactUid extends BaseParams {
	contactUid: string;
}

type Params = XOR<ParamsWithUid, ParamsWithContactUid> & {
	contactType?: string;
};

interface RawApiOutputRow {
	uid: string;
	displayNameUser: string;
	contactUid: string;
	displayNameContact: string;
	contactType: string;
	dateOfLastActivityUTC: string;
}

export interface OutputRow
	extends Omit<RawApiOutputRow, "dateOfLastActivityUTC"> {
	dateOfLastActivityUTC: Date;
}

const flagValidationErrors: ValidationCallback<
	SearchResultsWithCount<RawApiOutputRow>
> = (input, { checkField, flag }, auth) => {
	checkField("count", { type: "number" });

	const validatePage = (fieldName: "page" | "nextPage" | "prevPage") => {
		if (input[fieldName] === undefined) {
			flag(fieldName, "missing");
		} else if (!Array.isArray(input[fieldName])) {
			flag(fieldName, "not an array");
		} else if (
			input[fieldName].some((entry: unknown) => typeof entry !== "object")
		) {
			flag(fieldName, "not all objects");
		} else {
			for (const entry of input[fieldName]) {
				validate(entry, {
					doValidate: flagValidationErrorsInRow,
					auth,
					withErrors: errors => {
						flag(fieldName, "At least one page: " + JSON.stringify(errors));
					}
				});
			}
		}
	};

	validatePage("page");
	validatePage("nextPage");
	validatePage("prevPage");
};

const flagValidationErrorsInRow: ValidationCallback<RawApiOutputRow> = (
	input,
	{ checkField }
) => {
	checkField("uid", { type: "string" });
	checkField("displayNameUser", { type: "string" });
	checkField("contactUid", { type: "string" });
	checkField("displayNameContact", { type: "string" });
	checkField("contactType", { type: "string" });
	checkField("dateOfLastActivityUTC", { type: "datestring" });
};

export function getContacts(
	{ uid, contactUid, contactType, cursor, cursorType = "after" }: Params,
	auth: Auth
): ApiRequest<SearchResultsWithCountAndPagination<OutputRow, Date>> {
	const req = makeApiRequest("contacts", {
		auth,
		data: {
			get: {
				uid,
				contactUid,
				contactType,
				cursorType,
				...(cursor ? { cursor: formatDateForApi(cursor) } : {})
			}
		},
		flagValidationErrors,
		cacheInMemory: true
	});

	return {
		ready: req.ready.then(results => {
			return {
				count: results ? results.count : 0,
				page: results ? importPage(results.page) : [],
				nextPage:
					results && results.nextPage ? importPage(results.nextPage) : [],
				prevPage:
					results && results.prevPage ? importPage(results.prevPage) : [],
				cursor,
				cursorType
			};
		}),
		abort: () => req.abort(),
		aborted: () => req.aborted(),
		type: req.type
	};
}

function importPage(page: RawApiOutputRow[]) {
	return page.map(row => {
		const { dateOfLastActivityUTC, ...rest } = row;

		return {
			...rest,
			dateOfLastActivityUTC: moment.utc(dateOfLastActivityUTC).toDate()
		};
	});
}
