import styled from "styled-components";

export const ModalSignMeetingOffContents = styled.div`
	padding: 0 1em;
	h3 {
		margin-bottom: 1em !important;
	}
`;

export const ModalSignMeetingOffSection = styled.div`
	margin-bottom: 1em !important;
`;

export const ModalSignOffMeetingColumnFieldContainer = styled.div`
	padding-top: 1.5em;
	padding-bottom: 1.5em;
`;
