export function isValidTime(time: string) {
	const matches = time.match(/^([0-9]{1,2}):([0-9]{2}):([0-9]{2})$/);
	if (matches === null) {
		return false;
	}

	const [, hours, minutes, seconds] = matches;

	if (parseInt(hours) < 0 || parseInt(hours) > 23) {
		return false;
	}

	if (parseInt(minutes) < 0 || parseInt(minutes) > 59) {
		return false;
	}

	if (parseInt(seconds) < 0 || parseInt(seconds) > 59) {
		return false;
	}

	return true;
}
