import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	uid: string;
	profilePictureDataURI: string;
}

type Output = {};

// TODO:WV:20210409:The following function was copied from saveAccountSettings.  Is it out of date?  Does it do anything useful?
const flagValidationErrors: ValidationCallback<Output> = (input, flag) =>
	typeof input === "object" && Object.keys(input).length === 0;

export function saveProfilePicture(
	{ uid, profilePictureDataURI }: Params,
	auth: Auth
): ApiRequest<Output> {
	return makeApiRequest("saveProfilePicture", {
		auth,
		data: {
			post: {
				uid,
				profilePictureDataURI
			}
		},
		flagValidationErrors
	});
}
