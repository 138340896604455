import React, { useCallback, useState, useEffect } from "react";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { useLocationCountryOptions } from "hooks/useLocationCountryOptions";
import { useAccountStatus } from "hooks/useAccountStatus";
import { CardBillingDetails } from "hooks/usePayWithNewCard";
import FieldText from "components/FieldText";
import FieldSelect from "components/FieldSelect";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import {
	CardSection,
	CardDetailsFieldGroup,
	CardDetailsFieldGroupField
} from "./PayWithNewCardForm.styles";

type PayWithNewCardFormProps = {
	onChange: (arg: Partial<CardBillingDetails>) => void;
};

const PayWithNewCardForm: React.FunctionComponent<PayWithNewCardFormProps> = ({
	onChange
}) => {
	const stripe = useStripe();
	const elements = useElements();

	const {
		_populated: accountStatusPopulated,
		locationCountryCode: defaultBillingAddressCountry
	} = useAccountStatus();

	const [nameOnCard, setNameOnCard] = useState("");
	const handleChangeNameOnCard = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => setNameOnCard(e.target.value),
		[setNameOnCard]
	);

	const [billingAddressLine1, setBillingAddressLine1] = useState("");
	const handleChangeBillingAddressLine1 = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) =>
			setBillingAddressLine1(e.target.value),
		[setBillingAddressLine1]
	);

	const [billingAddressLine2, setBillingAddressLine2] = useState("");
	const handleChangeBillingAddressLine2 = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) =>
			setBillingAddressLine2(e.target.value),
		[setBillingAddressLine2]
	);

	const [billingAddressCity, setBillingAddressCity] = useState("");
	const handleChangeBillingAddressCity = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) =>
			setBillingAddressCity(e.target.value),
		[setBillingAddressCity]
	);

	const [billingAddressState, setBillingAddressState] = useState("");
	const handleChangeBillingAddressState = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) =>
			setBillingAddressState(e.target.value),
		[setBillingAddressState]
	);

	const [billingAddressPostalCode, setBillingAddressPostalCode] = useState("");
	const handleChangeBillingAddressPostalCode = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) =>
			setBillingAddressPostalCode(e.target.value),
		[setBillingAddressPostalCode]
	);

	const countryOptions = useLocationCountryOptions();
	const [currentCountryOptions, setCurrentCountryOptions] = useState<
		FieldOption<string>[]
	>(countryOptions.output ? countryOptions.output : []);
	const numCurrentCountryOptions = currentCountryOptions.length;

	const numCountryOptionsFromAPI =
		countryOptions.output !== undefined ? countryOptions.output.length : 0;
	const countryOptionsFromAPIJSON =
		countryOptions.output !== undefined
			? JSON.stringify(countryOptions.output)
			: "null";
	useEffect(() => {
		if (
			numCurrentCountryOptions === 0 &&
			accountStatusPopulated === true &&
			numCountryOptionsFromAPI !== 0
		) {
			const countryOptionsFromAPI: FieldOption<string>[] | null = JSON.parse(
				countryOptionsFromAPIJSON
			);
			if (countryOptionsFromAPI !== null) {
				const options = countryOptionsFromAPI.map(opt =>
					opt.value === defaultBillingAddressCountry
						? { ...opt, selected: true }
						: opt
				);
				setCurrentCountryOptions(options);
			}
		}
	}, [
		numCountryOptionsFromAPI,
		countryOptionsFromAPIJSON,
		numCurrentCountryOptions,
		accountStatusPopulated,
		setCurrentCountryOptions,
		defaultBillingAddressCountry
	]);

	const [billingAddressCountryCode, setBillingAddressCountryCode] = useState(
		""
	);
	useEffect(() => {
		const selectedOption = currentCountryOptions.find(opt => !!opt.selected);
		setBillingAddressCountryCode(selectedOption ? selectedOption.value : "");
	}, [currentCountryOptions, setBillingAddressCountryCode]);

	const [cardDetailsAreComplete, setCardDetailsAreComplete] = useState(false);
	const handleChangeCardDetails = useCallback(
		(e: StripeCardElementChangeEvent) =>
			setCardDetailsAreComplete(e.complete === true),
		[setCardDetailsAreComplete]
	);

	useEffect(() => {
		onChange({
			nameOnCard,
			billingAddressLine1,
			billingAddressLine2,
			billingAddressCity,
			billingAddressState,
			billingAddressPostalCode,
			billingAddressCountryCode,
			cardDetailsAreComplete
		});
	}, [
		nameOnCard,
		billingAddressLine1,
		billingAddressLine2,
		billingAddressCity,
		billingAddressState,
		billingAddressPostalCode,
		billingAddressCountryCode,
		cardDetailsAreComplete,
		onChange
	]);

	const stripeLoaded = !!(stripe && elements);

	return (
		<>
			<div>
				<label htmlFor="stripe-card-element">Credit or debit card</label>
				{stripeLoaded ? (
					<CardSection testid="card-section">
						<CardElement
							id="stripe-card-element"
							onChange={handleChangeCardDetails}
						/>
					</CardSection>
				) : (
					<p>Please wait - loading payment provider</p>
				)}
			</div>

			{stripeLoaded &&
			!(
				countryOptions.isLoading ||
				countryOptions.isWaiting ||
				countryOptions.isError
			) ? (
				<form autoComplete="on">
					<CardDetailsFieldGroup>
						<label htmlFor="name-on-card">Name on card</label>
						<FieldText
							id="name-on-card"
							testid="name-on-card"
							value={nameOnCard}
							onChange={handleChangeNameOnCard}
							autoComplete="billing name"
						/>
					</CardDetailsFieldGroup>
					<CardDetailsFieldGroup>
						<label htmlFor="billing-address-line1">Billing address</label>
						<CardDetailsFieldGroupField>
							<FieldText
								id="billing-address-line1"
								testid="billing-address-line1"
								name="address-line1"
								value={billingAddressLine1}
								onChange={handleChangeBillingAddressLine1}
								placeholder="Line 1"
								autoComplete="billing address-line1"
							/>
						</CardDetailsFieldGroupField>
						<CardDetailsFieldGroupField>
							<FieldText
								id="billing-address-line2"
								testid="billing-address-line2"
								name="address-line2"
								value={billingAddressLine2}
								onChange={handleChangeBillingAddressLine2}
								placeholder="Line 2"
								autoComplete="billing address-line2"
							/>
						</CardDetailsFieldGroupField>
						<CardDetailsFieldGroupField>
							<FieldText
								id="billing-address-city"
								testid="billing-address-city"
								name="city"
								value={billingAddressCity}
								onChange={handleChangeBillingAddressCity}
								placeholder="City"
								autoComplete="billing locality"
							/>
						</CardDetailsFieldGroupField>
						<CardDetailsFieldGroupField>
							<FieldText
								id="billing-address-state"
								testid="billing-address-state"
								name="state"
								value={billingAddressState}
								onChange={handleChangeBillingAddressState}
								placeholder="State, county, or region"
								autoComplete="billing region"
							/>
						</CardDetailsFieldGroupField>
						<CardDetailsFieldGroupField>
							<FieldText
								id="billing-address-postal-code"
								testid="billing-address-postal-code"
								name="zip"
								value={billingAddressPostalCode}
								onChange={handleChangeBillingAddressPostalCode}
								placeholder="Postal / ZIP code"
								autoComplete="billing postal-code"
							/>
						</CardDetailsFieldGroupField>
						<CardDetailsFieldGroupField>
							<FieldSelect
								allowMultipleSelections={false}
								isLoading={countryOptions.isLoading}
								isError={countryOptions.isError}
								currentOptions={currentCountryOptions}
								onNewCurrentOptions={setCurrentCountryOptions}
								useCheckboxes={false}
								placeholder="Country"
								testid="billing-address-country"
							/>
						</CardDetailsFieldGroupField>
					</CardDetailsFieldGroup>
				</form>
			) : null}
		</>
	);
};

export default PayWithNewCardForm;
