import styled from "styled-components";

import { IDCardProps } from "./IDCard";

const screenThreshold = 620;

export const IDCardWrapper = styled.div<Partial<IDCardProps>>`
	@media (min-width: ${screenThreshold + 1}px) {
		display: flex;
		align-items: flex-start;
		justify-content: ${props => (props.videoSrc ? "space-between" : "start")};
		width: 100%;

		img {
			min-width: 200px;
			max-width: 200px;
		}
		video {
			min-width: 200px;
			max-width: 500px;
			margin-left: 2em;
		}
	}
`;

export const IDCardText = styled.div<Partial<IDCardProps>>`
	text-align: left;
	display: inline-block;
	margin-left: 3%;

	p {
		margin: 0 0 1em 0;
	}

	ul {
		margin-bottom: 0;
	}

	@media (max-width: ${screenThreshold}px) {
		${props =>
			props.smallScreenStyle === "stack"
				? `
      margin-left:0;
			width: 100%;
		`
				: `
			width:auto;
			margin-left:0;
			display: inline;
		`}
	}
`;

export const IDCardPhoto = styled.img<Partial<IDCardProps>>`
	display: inline-block;
	vertical-align: top;

	@media (max-width: ${screenThreshold}px) {
		${props =>
			props.smallScreenStyle === "stack"
				? `
			width: 100%;
			margin-bottom:1.5em;
		`
				: `
			width: 30%;
			float: left;
			margin-right: 1em;
		`}
	}
`;

export const IDCardVideo = styled.video<Partial<IDCardProps>>`
	display: inline-block;
	vertical-align: top;

	@media (max-width: ${screenThreshold}px) {
		${props =>
			props.smallScreenStyle === "stack"
				? `
			width: 100%;
			margin:1.5em 0;
		`
				: `
			width: 30%;
			float: right;
			margin-left: 1em;
		`}
	}
`;
