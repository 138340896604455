import { useState, useEffect } from "react";
import queryString from "query-string";
import moment from "moment-timezone";

import { captureException } from "services/captureException";
import { useUserProfile } from "hooks/useUserProfile";
import { ChatDetails } from "./Chat";

export function useInitialiseChatFromQueryString(
	setChatDetailsAndOpenOrCloseCorrespondingly: (
		details: ChatDetails | undefined
	) => void
) {
	const { search: qs } = window.location;
	const [chatPresetFromQueryString, setChatPresetFromQueryString] = useState<
		{ uid: string; dateSentUTC?: Date; id?: string } | undefined
	>(undefined);
	useEffect(() => {
		const parsed = queryString.parse(qs);
		if (parsed.chat && typeof parsed.chat === "string" && parsed.chat !== "") {
			let decoded: any;
			try {
				decoded = JSON.parse(atob(parsed.chat));
			} catch (e) {
				captureException(new Error("Error decoding query string"), {
					evtType: "chatURLErrorDecodingQueryString"
				});
				return;
			}

			if (typeof decoded !== "object") {
				captureException(new Error("Invalid chat details in URL"), {
					evtType: "chatURLInvalidChatDetails"
				});
				return;
			}

			if (decoded.dateSentUTC && !moment.utc(decoded.dateSentUTC).isValid()) {
				captureException(new Error("Invalid date-sent"), {
					evtType: "chatURLInvalidDateSent"
				});
				return;
			}

			if (!decoded.uid) {
				captureException(new Error("No UID in supplied chat details"), {
					evtType: "chatURLNoUID"
				});
				return;
			}

			const { uid, dateSentUTC, id } = decoded;

			const chatPreset = {
				uid,
				dateSentUTC: moment.utc(dateSentUTC).toDate(),
				id
			};

			setChatPresetFromQueryString(chatPreset);
			return;
		}

		setChatPresetFromQueryString(undefined);
	}, [setChatPresetFromQueryString, qs]);

	const {
		uid: chatPresetUID,
		dateSentUTC: chatPresetDateSentUTC,
		id: chatPresetMessageID
	} = chatPresetFromQueryString
		? chatPresetFromQueryString
		: { uid: undefined, dateSentUTC: undefined, id: undefined };
	const chatPresetDateSentUnixMs = chatPresetDateSentUTC
		? chatPresetDateSentUTC.getTime()
		: undefined;

	const { output: userProfile } = useUserProfile({ uid: chatPresetUID });
	const {
		uid: userProfileUid,
		displayName: userProfileDisplayName
	} = userProfile ? userProfile : { uid: undefined, displayName: undefined };

	useEffect(() => {
		if (userProfileUid && userProfileDisplayName) {
			setChatDetailsAndOpenOrCloseCorrespondingly({
				partner: {
					uid: userProfileUid,
					displayName: userProfileDisplayName
				},
				pagination: {
					cursorType: "after",
					cursor: chatPresetDateSentUnixMs
						? new Date(chatPresetDateSentUnixMs + 1)
						: undefined
				},
				highlightedMessageId: chatPresetMessageID
			});
		} else {
			setChatDetailsAndOpenOrCloseCorrespondingly({
				pagination: {
					cursorType: "after"
				}
			});
		}
	}, [
		userProfileUid,
		userProfileDisplayName,
		chatPresetDateSentUnixMs,
		chatPresetMessageID,
		setChatDetailsAndOpenOrCloseCorrespondingly
	]);
}
