import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import ButtonIcon from "components/ButtonIcon";
import {
	WideButton,
	NarrowButton,
	BorderlessIconOnlyButtonButton,
	CollapsibleSectionHeading
} from "./Button.styles";

export interface ButtonProps {
	isNarrow?: boolean;
	isTall?: boolean;
	isBorderlessIconOnlyButton?: boolean;
	isCollapsibleSectionHeading?: boolean;
	isPrimary?: boolean;
	isDangerous?: boolean;
	isSecondary?: boolean;
	isTertiary?: boolean;
	isActive?: boolean;
	disabled?: boolean;
	fitWidth?: boolean;
	onClick: (e: any) => void;
	blurOnClick?: boolean;
	allowWrap?: boolean;
	leftAlign?: boolean;
	icon?: IconProp;
	label?: string;
	iconOnLeft?: boolean;
}

const Button: React.FunctionComponent<ButtonProps> = ({
	isNarrow = false,
	isTall = false,
	isBorderlessIconOnlyButton = false,
	isCollapsibleSectionHeading = false,
	isPrimary = false,
	isDangerous = false,
	isSecondary = false,
	isTertiary = false,
	isActive = false,
	disabled = false,
	fitWidth: suppliedFitWidth = false,
	onClick,
	blurOnClick = true,
	allowWrap = true,
	leftAlign = true,
	iconOnLeft = true,
	icon,
	label
}) => {
	const Component = isBorderlessIconOnlyButton
		? BorderlessIconOnlyButtonButton
		: isCollapsibleSectionHeading
		? CollapsibleSectionHeading
		: isNarrow
		? NarrowButton
		: WideButton;

	if (!(label || icon)) {
		throw new Error("Neither a label nor an icon was provided");
	}

	if (isBorderlessIconOnlyButton && icon === undefined) {
		throw new Error("Borderless icon-only buttons must have an icon");
	}

	if (isBorderlessIconOnlyButton && label !== undefined) {
		throw new Error("Borderless icon-only buttons must not have a label");
	}

	const fitWidth = suppliedFitWidth || isCollapsibleSectionHeading;

	return (
		<Component
			className={`button ${fitWidth ? `fit` : ``}`}
			disabled={disabled}
			onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				if (blurOnClick) {
					e.currentTarget.blur();
				}
				onClick(e);
			}}
			allowWrap={allowWrap}
			leftAlign={leftAlign}
			hasIcon={!!icon}
			hasLabel={!!label}
			isDangerous={isDangerous}
			isPrimary={isPrimary}
			isSecondary={isSecondary}
			isTertiary={isTertiary}
			isActive={isActive}
			isTall={isTall}
		>
			{iconOnLeft ? null : label ? label : null}
			{!!icon ? (
				<ButtonIcon
					icon={icon}
					hasLabel={!!label}
					size={
						isBorderlessIconOnlyButton || isCollapsibleSectionHeading
							? "lg"
							: "sm"
					}
				/>
			) : null}
			{iconOnLeft ? (label ? label : null) : null}
		</Component>
	);
};

export default Button;
