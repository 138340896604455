import styled from "styled-components";

export const PaymentMethodsWrapper = styled.div`
	margin-bottom: 2em;
`;

export const ActionButtonsWrapper = styled.div`
	margin-bottom: 2em;
`;

export const IntroText = styled.p`
	margin-bottom: 1em;
`;

export const FieldContainer = styled.p``;
