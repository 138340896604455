import { ApiRequest } from "api/ApiRequest";

export function combineAPIRequests<T>(reqs: ApiRequest<T>[], type?: string) {
	const ready = Promise.all(reqs.map(req => req.ready));

	return {
		ready,
		abort: () => {
			for (const req of reqs) {
				req.abort();
			}
		},
		aborted: () => {
			return reqs.some(req => req.aborted());
		},
		type: type ? type : "_auto_typed_" + reqs[0].type + "_combined"
	};
}
