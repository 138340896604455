import firebase from "setupFirebase";

export async function getAuthToken() {
	const firebaseUser = firebase.auth().currentUser;
	if (!firebaseUser) {
		return null;
	}

	return await firebaseUser.getIdToken();
}
