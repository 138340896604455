import React, { useCallback, useContext } from "react";
import { useHistory } from "react-router";

import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { useStripQueryStringFromAddressBar } from "hooks/useStripQueryStringFromAddressBar";
import { useHandleItemFromURL } from "hooks/useHandleItemFromURL";

import ModalCancelMeeting, {
	Props as ModalCancelMeetingProps
} from "./ModalCancelMeeting";

interface Props {
	cancellingEvent: MeetingsDataFlattened | undefined;
	setCancellingEvent: React.Dispatch<
		React.SetStateAction<MeetingsDataFlattened | undefined>
	>;
	isWaiting: boolean;
	isLoading: boolean;
	isError: boolean;
	events: MeetingsDataFlattened[];
}

const Cancellation: React.FunctionComponent<Props> = ({
	setCancellingEvent,
	cancellingEvent,
	isWaiting,
	isLoading,
	isError,
	events
}) => {
	const handleCancelCancelMeeting = useCallback(async () => {
		setCancellingEvent(undefined);
	}, [setCancellingEvent]);

	const [, setDashboardContextState] = useContext(DashboardContext);

	const history = useHistory();

	const handleMeetingCancelledOK = useCallback(
		async ({
			requestedRebook,
			hostUid
		}: Parameters<ModalCancelMeetingProps["onOK"]>[0]) => {
			setCancellingEvent(undefined);
			setDashboardContextState(oldState => ({
				...oldState,
				meetingsSectionRefreshNum: new Date().getTime()
			}));
			if (requestedRebook) {
				// TODO:WV:20200819:Before doing the following, show a message explaining what is happening (and perhaps add a heading to the booking modal to make it clearer as well)
				history.push(`/user-id/${hostUid}?showBookingInterface=1`);
			}
		},
		[setCancellingEvent, setDashboardContextState, history]
	);

	const stripQueryStringFromAddressBar = useStripQueryStringFromAddressBar();
	useHandleItemFromURL<MeetingsDataFlattened>({
		key: "meetingCancel",
		itemsList: {
			isWaiting,
			isLoading,
			isError,
			items: events
		},
		handleFoundItem: useCallback(
			event => {
				const { paymentStatus } = event;
				if (paymentStatus !== "pending") {
					setCancellingEvent(event);
				}
				stripQueryStringFromAddressBar();
			},
			[setCancellingEvent, stripQueryStringFromAddressBar]
		)
	});

	return (
		<ModalCancelMeeting
			event={cancellingEvent}
			isOpen={!!cancellingEvent}
			onCancel={handleCancelCancelMeeting}
			onOK={handleMeetingCancelledOK}
		/>
	);
};

export default Cancellation;
