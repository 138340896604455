import styled from "styled-components";

export const FooterIconLinkElement = styled.a<{ linkstyle: string }>`
	text-decoration: none;
	border-bottom: none;
	position: relative;
	text-align: center;

	-moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	-webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	-ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	border-radius: 100%;
	display: inline-block;
	font-size: 1.5rem;
	height: 2.25em;
	line-height: 2.25em;
	text-align: center;
	width: 2.25em;

	@media screen and (max-width: 736px) {
		font-size: 1rem;
	}

	& > .label {
		display: none;
	}

	opacity: 0.9;
	&,
	&:hover {
		color: #ffffff !important;
	}
	&:hover {
		opacity: 1;
	}

	background-color: ${({ linkstyle }) => getBackgroundColour(linkstyle)};
`;

function getBackgroundColour(linkstyle: string) {
	switch (linkstyle) {
		case "twitter":
			return "#8cd4e9";
		case "facebook":
			return "#83bbde";
		case "youtube":
			return "#e98ca4";
		case "instagram":
			return "#83bbde";
		case "email":
			return "#8cd4e9";
		default:
			return "#8cd4e9";
	}
}
