import React from "react";

import { getUsersLocalTimezoneForDisplay } from "utils/datesAndTimes/getUsersLocalTimezoneForDisplay";
import terminology from "terminology.json";

import { MeetingCalendarProps } from "./MeetingCalendar";
import {
	KeyOuter,
	KeyAvailable,
	KeyBooked,
	KeyUnavailable,
	KeyUserBusy,
	KeyUserBooked,
	KeyAwaitingMentorConfirmation,
	KeyTimezone
} from "./Key.styles";

interface Props {
	displaymode: MeetingCalendarProps["displaymode"];
	isSignedIn: MeetingCalendarProps["isSignedIn"];
}

const Key: React.FunctionComponent<Props> = ({ displaymode, isSignedIn }) => {
	return (
		<KeyOuter>
			<KeyAvailable>available</KeyAvailable>
			{displaymode === "compact" ? null : (
				<>
					<KeyBooked>booked</KeyBooked>
					<KeyUnavailable>not available</KeyUnavailable>
					{isSignedIn ? <KeyUserBusy>you are busy</KeyUserBusy> : null}
					{isSignedIn ? <KeyUserBooked>booked by you</KeyUserBooked> : null}
					{isSignedIn ? (
						<KeyAwaitingMentorConfirmation>
							awaiting {terminology.mentor} confirmation
						</KeyAwaitingMentorConfirmation>
					) : null}
				</>
			)}
			<KeyTimezone>
				{displaymode === "compact" ? null : `Times as per: `}
				{getUsersLocalTimezoneForDisplay()}
			</KeyTimezone>
		</KeyOuter>
	);
};

export default Key;
