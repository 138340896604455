import { ValidationCallback } from "validation/validate";

export interface SessionType {
	id: string;
	sessionTitle: string;
	sessionDescription: string;
}

export const flagValidationErrorsInSessionType: ValidationCallback<
	SessionType
> = (input, { checkField }) => {
	checkField("id", { type: "string" });
	checkField("sessionTitle", { type: "string" });
	checkField("sessionDescription", { type: "string" });
};
