import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

import { IconWrapper } from "./ButtonIcon.styles";

interface ButtonIconProps {
	icon: IconProp;
	hasLabel?: boolean;
	size?: SizeProp;
}

const ButtonIcon: React.FunctionComponent<ButtonIconProps> = ({
	icon,
	hasLabel = true,
	size = "sm"
}) => {
	return (
		<IconWrapper hasLabel={hasLabel}>
			<FontAwesomeIcon icon={icon} size={size} />
		</IconWrapper>
	);
};

export default ButtonIcon;
