import { Link } from "react-router-dom";

import styled from "styled-components";

export const IntroText = styled.span`
	display: inline-block;
`;

export const ProfileLink = styled(Link)`
	display: inline-block;
`;
