import { useCallback, useContext } from "react";
import flatten from "lodash.flatten";

import { combineAPIRequests } from "api/combineAPIRequests";
import { SearchResults } from "api/SearchResults";
import {
	getMeetingsFlattened,
	OutputRow as MeetingsDataFlattened
} from "api/getMeetingsFlattened";
import {
	getMeetingRequests,
	OutputRow as MeetingRequestsData
} from "api/getMeetingRequests";
import {
	getMeetingRescheduleRequests,
	OutputRow as MeetingRescheduleRequestsData
} from "api/getMeetingRescheduleRequests";
import { useAccountStatus } from "hooks/useAccountStatus";
import { AuthContext } from "components/AuthProvider/AuthProvider";

export function useFetchEvents() {
	const { isMentor } = useAccountStatus();
	const [{ uid }] = useContext(AuthContext);

	const fetchEvents = useCallback(() => {
		if (!uid) {
			throw new Error("No UID");
		}

		const earliestStartDateUTC = new Date();

		const req = combineAPIRequests<
			SearchResults<
				| MeetingsDataFlattened
				| MeetingRequestsData
				| MeetingRescheduleRequestsData
			>
		>([
			// Upcoming meetings, fully paid (user is guest)
			getMeetingsFlattened(
				{
					guestUid: uid,
					earliestStartDateUTC,
					paymentStatus: "ok"
				},
				{ uid }
			),
			getMeetingsFlattened(
				{
					guestUid: uid,
					earliestStartDateUTC,
					paymentStatus: "free"
				},
				{ uid }
			),

			// Upcoming meetings, fully paid but awaiting confirmation from Stripe via webhook (user is guest)
			getMeetingsFlattened(
				{
					guestUid: uid,
					earliestStartDateUTC,
					paymentStatus: "awaiting-webhook"
				},
				{ uid }
			),

			// Upcoming meetings, fully paid (user is host)
			...(!!isMentor
				? [
						getMeetingsFlattened(
							{
								hostUid: uid,
								earliestStartDateUTC,
								paymentStatus: "ok"
							},
							{ uid }
						),
						getMeetingsFlattened(
							{
								hostUid: uid,
								earliestStartDateUTC,
								paymentStatus: "free"
							},
							{ uid }
						),

						/* Fully paid but awaiting confirmation from Stripe via webhook */
						getMeetingsFlattened(
							{
								hostUid: uid,
								earliestStartDateUTC,
								paymentStatus: "awaiting-webhook"
							},
							{ uid }
						)
				  ]
				: []),

			// Any meetings that require payment (user is guest)
			getMeetingsFlattened(
				{
					guestUid: uid,
					paymentStatus: "pending"
				},
				{ uid }
			),

			// Any meetings that require payment (user is host)
			...(isMentor
				? [
						getMeetingsFlattened(
							{
								hostUid: uid,
								paymentStatus: "pending"
							},
							{ uid }
						)
				  ]
				: []),

			// Any finished meetings that require guest confirmation
			getMeetingsFlattened(
				{
					guestUid: uid,
					onlyMeetingsThatNeedSignOff: true
				},
				{ uid }
			),

			// Upcoming meetings awaiting host confirmation
			getMeetingRequests(
				{
					guestUid: uid,
					earliestStartDateUTC,
					isAccepted: false,
					isRejected: false
				},
				{ uid }
			),

			// Meetings with reschedule requested  (user is host)
			getMeetingRescheduleRequests(
				{
					hostUid: uid,
					earliestStartDateUTC,
					isAccepted: false,
					isRejected: false
				},
				{ uid }
			),

			// Meetings with reschedule requested  (user is guest)
			getMeetingRescheduleRequests(
				{
					guestUid: uid,
					earliestStartDateUTC,
					isAccepted: false,
					isRejected: false
				},
				{ uid }
			)
		]);

		return {
			...req,
			ready: req.ready.then(allresults =>
				flatten(
					allresults.map(result => {
						if (!result) {
							return [];
						}
						return result.page;
					})
				)
			),
			type: "meetings-combined"
		};
	}, [uid, isMentor]);

	return fetchEvents;
}
