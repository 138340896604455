import * as moment from "moment-timezone";
import "moment-duration-format";

interface Params {
	includeSeconds?: boolean;
}

export function formatAsTime(
	timeElapsedSinceMidnight: moment.Duration,
	config: Params = {}
) {
	const { includeSeconds = false } = config;
	return timeElapsedSinceMidnight.format(
		`HH:mm${includeSeconds ? `:ss` : ``}`,
		0,
		{
			forceLength: false,
			precision: 0,
			trim: false
		}
	);
}
