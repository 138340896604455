type Lookup = {
	[k: string]: string | undefined;
};

export function removeUndefined(obj: Lookup) {
	return Object.keys(obj).reduce(
		(agg, cur) => ({
			...agg,
			...(obj[cur] === undefined ? {} : { [cur]: obj[cur] })
		}),
		{}
	);
}
