import { useCallback, useContext, useMemo } from "react";

import { AlertContext } from "components/AlertProvider/AlertProvider";
import { ApiRequest } from "api/ApiRequest";
import { refreshFromApi } from "services/accountStatus";
import { AccountStatus } from "services/accountStatus/accountStatus";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { zoomFinishOAuth } from "api/zoomFinishOAuth";
import { zoomUnlink } from "api/zoomUnlink";
import { stripeRequestConnectedAccount } from "api/stripeRequestConnectedAccount";
import { saveAccountSettings } from "api/saveAccountSettings";
import { useAccountStatus } from "hooks/useAccountStatus";

type SaveChangeAndRefreshParams<T> = {
	data: T;
	onComplete?: () => Promise<void>;
};

type SenderName =
	| "zoomFinishOAuth"
	| "zoomUnlink"
	| "stripeRequestConnectedAccount"
	| "saveAccountSettings";

type SenderParams<T> = {
	data: T;
};

type Sender<T> = (args: SenderParams<T>) => ApiRequest<unknown>;

export function useSaveDataAndRefreshAccountDetailsFromApi<T>(
	senderName: SenderName,
	description: string,
	setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
	afterwards?: () => Promise<void>,
	beforeRefreshing?: () => Promise<void>
) {
	const [{ isSignedIn, uid }] = useContext(AuthContext);
	const [addAlert] = useContext(AlertContext);

	/* Work out the sender here rather than passing it in as a dependency, because (for reasons that are unknown as of 2020-11-26)
	   doing the latter seems to cause this function to run far too many times */

	const senderZoomFinishOAuth = useCallback(
		({ data: { code } }) => {
			if (!uid) {
				throw new Error("No current user");
			}
			return zoomFinishOAuth({ uid, code }, { uid });
		},
		[uid]
	);

	const senderZoomUnlink = useCallback(() => {
		if (!uid) {
			throw new Error("No current user");
		}
		return zoomUnlink({ uid }, { uid });
	}, [uid]);

	const senderStripeRequestConnectedAccount = useCallback(() => {
		if (!uid) {
			throw new Error("No current user");
		}
		return stripeRequestConnectedAccount({ uid }, { uid });
	}, [uid]);

	const oldSettings = useAccountStatus();
	const senderAccountSettings = useCallback(
		({ data: newSettings }: { data: Partial<AccountStatus> }) => {
			if (!uid) {
				throw new Error("No current user");
			}

			const finalData = {
				...oldSettings,
				...newSettings
			};

			// Prevent typescript complaining about the possibility of including
			// undefined in the submission for timezone and displayName
			const { timezone, displayName } = finalData;
			if (!timezone) {
				throw new Error("No timezone");
			}
			if (!displayName) {
				throw new Error("No displayName");
			}

			return saveAccountSettings(
				{
					uid,
					timezone,
					displayName,
					...finalData
				},
				{ uid }
			);
		},
		[uid, oldSettings]
	);

	// TODO:WV:20230501:Try to avoid the following 'any'
	const sender: Sender<any> = useMemo(() => {
		switch (senderName) {
			case "zoomFinishOAuth":
				return senderZoomFinishOAuth;
			case "zoomUnlink":
				return senderZoomUnlink;
			case "stripeRequestConnectedAccount":
				return senderStripeRequestConnectedAccount;
			case "saveAccountSettings":
				return senderAccountSettings;
			default:
				throw new Error("Unknown sender");
		}
	}, [
		senderName,
		senderZoomFinishOAuth,
		senderZoomUnlink,
		senderStripeRequestConnectedAccount,
		senderAccountSettings
	]);

	const saveChangeAndRefresh = useCallback(
		({ data, onComplete }: SaveChangeAndRefreshParams<T>) => {
			if (!isSignedIn) {
				return;
			}
			const errAlert = {
				contents: `Sorry, there was an error ${description}.  Please try again later.`
			};
			if (!uid) {
				addAlert(errAlert);
				return;
			}
			setIsSaving(true);
			let refreshReq: ApiRequest<Omit<AccountStatus, "_populated">> | undefined;

			// TODO:WV:20210603:Supply second (auth) parameter?
			const req = sender({ data });
			req.ready
				.then(async response => {
					if (beforeRefreshing) {
						await beforeRefreshing();
					}
					refreshReq = refreshFromApi(uid, { uid });
					return await refreshReq.ready;
				})
				.then(response => {
					setIsSaving(false);
				})
				.then(() => {
					if (afterwards) {
						return afterwards();
					}
				})
				.then(() => {
					if (onComplete) {
						return onComplete();
					}
				})
				.catch(e => {
					// TODO:WV:20210603:Report to Sentry (and above)
					setIsSaving(false);
					addAlert(errAlert);
				});
			return () => {
				req.abort();
				setIsSaving(false);
				if (refreshReq) {
					refreshReq.abort();
				}
			};
		},
		[
			isSignedIn,
			setIsSaving,
			addAlert,
			sender,
			description,
			uid,
			afterwards,
			beforeRefreshing
		]
	);

	return saveChangeAndRefresh;
}
