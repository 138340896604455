import { useCallback } from "react";

import { useRemoteResource } from "hooks/useRemoteResource";
import {
	getCredits,
	Params as GetCreditsParams,
	OutputRow
} from "api/getCredits";
import { makeDummyRequest } from "api/makeDummyRequest";
import { combineAPIRequests } from "api/combineAPIRequests";
import { SearchResultsWithCountAndPagination } from "api/SearchResults";

interface Params {
	uid?: string;

	creditTypes: {
		sessionTypeId?: GetCreditsParams["sessionTypeId"];
		sessionDurationMinutes?: GetCreditsParams["sessionDurationMinutes"];
		spendingStatus?: GetCreditsParams["spendingStatus"];
	}[];

	doWait?: boolean;
	repeatNumber?: number;
	cursor?: string;
	cursorType?: "after" | "before";
}

export function useCredits({
	uid,

	creditTypes,

	doWait = false,
	repeatNumber = 1,
	cursor,
	cursorType = "after"
}: Params) {
	const creditTypesStr = JSON.stringify(creditTypes);
	const cb = useCallback(
		(arg, auth) => {
			if (uid === undefined) {
				const dummyResponse: SearchResultsWithCountAndPagination<
					OutputRow,
					string
				>[] = [
					{
						page: [],
						count: 0,
						cursor: undefined,
						cursorType: "after"
					}
				];

				return makeDummyRequest(dummyResponse, "credits");
			}

			const cred: Params["creditTypes"] = JSON.parse(creditTypesStr);
			const reqs = cred.map(creditType =>
				getCredits(
					{
						uid,
						...creditType,
						cursor,
						cursorType
					},
					auth
				)
			);

			return combineAPIRequests(reqs, "credits_combined");
		},
		[uid, creditTypesStr, cursor, cursorType]
	);

	return useRemoteResource(cb, doWait, repeatNumber, true);
}
