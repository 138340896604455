import React, { useCallback } from "react";

import { wholeScreenWindowSizeThreshold } from "hooks/useModalStyles";
import { useScreenSizeIsMax } from "hooks/useScreenSizeIsMax";
import { ContentsDiv } from "./ModalScrollingContents.styles";

type Props = React.ComponentProps<typeof ContentsDiv> & {
	onContents?: (node: any) => void;
};

const ModalScrollingContents: React.FunctionComponent<Props> = ({
	children,
	onContents,
	...rest
}) => {
	const isWholeScreen = useScreenSizeIsMax(wholeScreenWindowSizeThreshold);

	const onContentsMountUnMount = useCallback(
		ref => {
			if (isWholeScreen) {
				// Prevent body scroll when the contents are mounted (i.e. ref is not null).  Iinstead, the ContentsDiv will scroll,
				document.body.style.overflow = ref ? "hidden" : "auto";
			}

			if (onContents) {
				onContents(ref);
			}
		},
		[isWholeScreen, onContents]
	);

	return (
		<ContentsDiv
			ref={onContentsMountUnMount}
			isWholeScreen={isWholeScreen}
			{...rest}
		>
			{children}
		</ContentsDiv>
	);
};

export default ModalScrollingContents;
