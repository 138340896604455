import { useEffect } from "react";

import { isWidgetOpened } from "react-chat-widget";

import { ChatDetails } from "components/Chat/Chat";

export function useWorkAroundFullScreenChatWidgetBug(
	partnerUID: string | undefined,
	setChatDetails: (details: ChatDetails | undefined) => void
) {
	// Unfortunately, due to a bug in the library, the full-screen version of the widget prevents any interaction
	// with the rest of the app, even when it has been toggled shut by the user (and there is no obvious way to remove
	// the mobile version of the toggle-shut button).  So, in mobile view, check every 100ms if the widget is open
	// and if so, set partner to undefined, which will remove the widget from the component tree + DOM entirely.
	const isFullScreenInterface = true;

	useEffect(() => {
		if (!isFullScreenInterface) {
			return;
		}
		const interval = window.setInterval(() => {
			if (!!partnerUID && !isWidgetOpened()) {
				setChatDetails({ pagination: { cursorType: "after" } });
			}
		}, 100);

		return () => window.clearInterval(interval);
	}, [isFullScreenInterface, partnerUID, setChatDetails]);
}
