import React from "react";

import PageContent from "components/PageContent";
import ContactForm from "components/ContactForm";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";
import welcomeImage from "resources/welcome.jpg";
import terminology from "terminology.json";

const PageBecomingAMentor: React.FunctionComponent = () => {
	usePageTitlePrefix(`Becoming a ${terminology.mentor}`);

	return (
		<PageContent>
			<header className="major">
				<h1>Request a demo</h1>
			</header>
			<span className="image main">
				<img src={welcomeImage} alt="Welcome" />
			</span>

			<p>
				If you are a language teacher, and are interested in using Aldeia,
				please get in touch to request a demo! Just fill out the form below. It
				will be great to meet you.
			</p>

			<ContactForm>
				<div className="row gtr-uniform">
					<div className="col-6 col-12-xsmall">
						<input
							type="hidden"
							name="enquiryType"
							id="enquiryType"
							value={`Enquiry about becoming a ${terminology.mentor} / request for a demo`}
						/>
						<input
							type="text"
							name="name"
							id="name"
							defaultValue=""
							placeholder="Name"
						/>
					</div>
					<div className="col-6 col-12-xsmall">
						<input
							type="email"
							name="email"
							id="email"
							defaultValue=""
							placeholder="Email"
						/>
					</div>
					<div className="col-12">
						<textarea
							name="message"
							id="message"
							placeholder={`Comments, questions, or anything else`}
							rows={6}
						></textarea>
					</div>
					{/* Break */}
					<div className="col-12">
						<ul className="actions">
							<li>
								<input type="submit" value="Send Message" className="primary" />
							</li>
							<li>
								<input type="reset" value="Reset" />
							</li>
						</ul>
					</div>
				</div>
			</ContactForm>
		</PageContent>
	);
};

export default PageBecomingAMentor;
