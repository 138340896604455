import React, { useEffect, useContext } from "react";

import { WizardData } from "../RescheduleMeetingWizard";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import MeetingSummary from "components/MeetingSummary";
import { AuthContext } from "components/AuthProvider/AuthProvider";

function StepChooseTimeSlot({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData: { meeting }
}: ModalWizardStepRenderProps<WizardData>) {
	const [{ uid }] = useContext(AuthContext);

	useEffect(() => {
		if (!meeting) {
			disableProgressToNextStep();
			return;
		}
		enableProgressToNextStep({ meeting });
	}, [enableProgressToNextStep, disableProgressToNextStep, meeting]);

	if (!uid) {
		return null;
	}
	if (!meeting) {
		return null;
	}

	return <MeetingSummary event={meeting} currentUid={uid} />;
}

export default StepChooseTimeSlot;
