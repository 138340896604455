import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import Button from "components/Button";

interface ToggleableButtonProps {
	icon: IconProp;
	isEditing: boolean;
	solidIcon?: boolean;
	onClick: (e: any) => void;
	allowWrap?: boolean;
	isTall?: boolean;
	label?: string;
	isTertiary?: boolean;
}

const ToggleableButton: React.FunctionComponent<ToggleableButtonProps> = ({
	icon,
	isEditing,
	solidIcon = true,
	onClick,
	allowWrap,
	isTall,
	label,
	isTertiary
}) => {
	if (isEditing) {
		return (
			<Button
				isNarrow
				onClick={onClick}
				allowWrap={allowWrap}
				isTall={isTall}
				icon={icon}
				label={label}
				isTertiary={isTertiary}
			/>
		);
	}

	return <>{label}</>;
};

export default ToggleableButton;
