import styled from "styled-components";

export const FieldOptions = styled.ul<{ isNotValid?: boolean }>`
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		padding: 0 0 1em 0;
	}
	li:last-child {
		padding-bottom: 0;
	}
	label {
		margin-bottom: 0;
		${props => (props.isNotValid ? `color #ff6666;` : ``)};
	}
`;

export const ErrorMessage = styled.span`
	color: #ff6666;
`;

export const SelectDropDown = styled.select<{
	isInline?: boolean;
	addRightMargin?: boolean;
	addLeftMargin?: boolean;
	isNotValid?: boolean;
}>`
	${props =>
		!!props.isInline
			? `
		width:auto;
		display:inline;
	`
			: ``}
	${props =>
		!!props.addRightMargin
			? `
		margin-right:1em;
	`
			: ``}
	${props =>
		!!props.addLeftMargin
			? `
		margin-left:1em;
	`
			: ``}
	${props => (props.isNotValid ? `border: 1px solid #ff6666;` : ``)};
`;

export const Option = styled.option<{ isPlaceholder?: boolean }>`
	${props => (props.isPlaceholder ? `color:gray;` : ``)};
`;
export const PaginationButtonContainer = styled.div`
	margin: 1em 0;
	padding: 0;
`;
