import React, { useState, useCallback } from "react";

import PageContent from "components/PageContent";
import ContactForm from "components/ContactForm";
import ModalFieldSelect from "components/ModalFieldSelect";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";
import terminology from "terminology.json";

import interestImage from "resources/interest.jpg";

const PageInterest: React.FunctionComponent = () => {
	usePageTitlePrefix("Register Interest");

	const [interestedIn, setInterestedIn] = useState<string[]>([]);

	const resetInterestedIn = useCallback(() => {
		setInterestedIn([]);
	}, [setInterestedIn]);

	return (
		<PageContent>
			<header className="major">
				<h1>Register Interest</h1>
			</header>

			<span className="image main">
				<img src={interestImage} alt="Postbox" />
			</span>

			<p>
				Thank you for your interest in Aldeia. Please fill out your details
				below, and expect a reply very soon (the only required field is email -
				all other fields optional).
			</p>

			<ContactForm onSubmit={resetInterestedIn}>
				<div className="row gtr-uniform">
					<div className="col-6 col-12-xsmall">
						<input
							type="hidden"
							name="enquiryType"
							id="enquiryType"
							value="Register-interest form enquiry"
						/>
						<input
							type="text"
							name="name"
							id="name"
							defaultValue=""
							placeholder="Name"
						/>
					</div>
					<div className="col-6 col-12-xsmall">
						<input
							type="email"
							name="email"
							id="email"
							defaultValue=""
							placeholder="Email*"
							required
						/>
					</div>

					<div className="col-12">
						<input
							type="hidden"
							name="interestedin"
							value={interestedIn.join(", ")}
						/>

						<ModalFieldSelect
							onOK={newOptions => {
								setInterestedIn(
									newOptions
										.filter(opt => opt.selected)
										.map(opt => opt.value + "")
								);
							}}
							title="Interest in Aldeia"
							options={[
								"Being a " + terminology.mentor,
								"Being a student",
								"Job opportunities (please specify what type)",
								"Other"
							].map(opt => {
								const value = opt.toLowerCase();
								return {
									id: value,
									value,
									text: opt,
									selected: interestedIn.includes(value)
								};
							})}
							allowMultipleSelections
							allowWrapTextInButton={false}
							useTallButton
							useTertiaryButton
						/>
					</div>

					<div className="col-12">
						<textarea
							name="message"
							id="message"
							placeholder={`Do you have any questions to ask or comments to make?`}
							rows={6}
						></textarea>
					</div>
					{/* Break */}
					<div className="col-12">
						<ul className="actions">
							<li>
								<input type="submit" value="Send Message" className="primary" />
							</li>
							<li>
								<input type="reset" value="Reset" onClick={resetInterestedIn} />
							</li>
						</ul>
					</div>
				</div>
			</ContactForm>
		</PageContent>
	);
};

export default PageInterest;
