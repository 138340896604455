import { useEffect, useCallback, useRef } from "react";
import { dropMessages, renderCustomComponent } from "react-chat-widget";

import { useEffectWithCustomComparator } from "hooks/useEffectWithCustomComparator";
import { SearchResults } from "api/SearchResults";
import { OutputRow } from "api/getRecentChatHistory";
import { MessageForChat } from "./useAddMessageToChat";
import ChatWidgetMoreButton from "components/ChatWidget/ChatWidgetMoreButton";

import { ChatDetails } from "components/Chat/Chat";

export function useAddChatContentsToWidget(
	chatContents: SearchResults<OutputRow> | undefined,
	addMessageToChat: (
		message: MessageForChat,
		socket: SocketIOClient.Socket
	) => void,
	chatDetails: ChatDetails,
	setChatDetails: (value: React.SetStateAction<ChatDetails>) => void,
	setMessageSendingFieldDisabled: (value: boolean) => void,
	socket: SocketIOClient.Socket | undefined
) {
	const messageCache = useRef<OutputRow[]>([]);

	// Reset the widget when the chat partner changes
	// TODO:WV:20231027:Change the highlighted message in the cache when it changes in the chat details as well
	const partnerUid = chatDetails.partner ? chatDetails.partner.uid : undefined;
	useEffect(() => {
		messageCache.current.splice(0, messageCache.current.length);
		scrollHeightBeforeAddingOlderMessages.current = undefined;
	}, [messageCache, partnerUid]);

	const { highlightedMessageId } = chatDetails;

	const scrollHeightBeforeAddingOlderMessages = useRef<number>();
	useEffectWithCustomComparator<
		[SearchResults<OutputRow>, SocketIOClient.Socket | undefined]
	>(
		useCallback(
			([{ page, nextPage, prevPage }, socket]) => {
				dropMessages();

				if (!socket) {
					return;
				}

				const haveOlderMessages = nextPage ? nextPage.length !== 0 : false;
				if (haveOlderMessages) {
					if (!(nextPage && nextPage.length)) {
						throw new Error("No next page");
					}
					const nextCursor = nextPage.slice(-1)[0].dateSentUTC;

					renderCustomComponent(ChatWidgetMoreButton, {
						label: "Older messages...",
						id: "older-messages-button",
						onClick: () => {
							const messagesContainer = document.getElementById("messages");
							if (messagesContainer) {
								messagesContainer.classList.add("scrolling");
							}
							scrollHeightBeforeAddingOlderMessages.current = messagesContainer
								? messagesContainer.scrollHeight
								: undefined;
							setChatDetails(currentDetails => ({
								...currentDetails,
								pagination: {
									cursor: nextCursor,
									cursorType: "after"
								}
							}));
						}
					});
				}

				// Add the page of messages to the cache
				for (const message of page) {
					if (!messageIsInCache(message, messageCache.current)) {
						messageCache.current.push(message);
					}
				}
				messageCache.current.sort(
					(a, b) => a.dateSentUTC.getTime() - b.dateSentUTC.getTime()
				);

				// Add the entire message cache to the chat
				for (const message of messageCache.current) {
					addMessageToChat(
						{ ...message, isHighlighted: message.id === highlightedMessageId },
						socket
					);
				}

				// If there are somehow any newer messages that are not in the cache
				// give the option of loading them.
				const haveNewerMessages = prevPage
					? prevPage.length !== 0 &&
					  prevPage.some(
							messageInPreviousPage =>
								!messageIsInCache(messageInPreviousPage, messageCache.current)
					  )
					: false;
				if (haveNewerMessages) {
					if (!(prevPage && prevPage.length)) {
						throw new Error("No previous page");
					}
					const prevCursor = prevPage[0].dateSentUTC;

					renderCustomComponent(ChatWidgetMoreButton, {
						label: "Newer messages...",
						onClick: () => {
							// Clear 'scroll height before adding older messages' to prevent any
							// jumping around on the part of the scroll bar after the newer messages load
							scrollHeightBeforeAddingOlderMessages.current = undefined;
							setChatDetails(currentDetails => ({
								...currentDetails,
								pagination: {
									cursor: prevCursor,
									cursorType: "before"
								}
							}));
						}
					});
				}

				setMessageSendingFieldDisabled(haveNewerMessages);

				// Give all the messages half a second to render, and then scroll the widget
				// back to where it was before the "older messages" button was clicked
				setTimeout(() => {
					const messagesContainer = document.getElementById("messages");
					const currentScrollHeight = messagesContainer
						? messagesContainer.scrollHeight
						: undefined;
					if (
						messagesContainer !== null &&
						scrollHeightBeforeAddingOlderMessages.current !== undefined &&
						currentScrollHeight !== undefined
					) {
						const diff =
							currentScrollHeight -
							scrollHeightBeforeAddingOlderMessages.current;
						messagesContainer.scrollTo(0, diff);
						if (messagesContainer) {
							messagesContainer.classList.remove("scrolling");
						}
					}

					/* TODO:WV:20231028:Confirm that 25ms per message is a suitable delay to wait for the messages to finish being added and the window to finish scrolling */
				}, messageCache.current.length * 25);
			},
			[
				addMessageToChat,
				setChatDetails,
				setMessageSendingFieldDisabled,
				messageCache,
				highlightedMessageId,
				scrollHeightBeforeAddingOlderMessages
			]
		),
		[chatContents ? chatContents : { page: [], nextPage: [] }, socket],
		([chatContents, socket]) =>
			(chatContents
				? [
						...chatContents.page,
						...(chatContents.nextPage ? chatContents.nextPage : []),
						...(chatContents.prevPage ? chatContents.prevPage : [])
				  ]
						.map(message => message.id)
						.join("-")
				: "") + (!!socket ? "1" : "0")
	);
}

function messageIsInCache(message: OutputRow, messagesInCache: OutputRow[]) {
	return messagesInCache.some(
		messageInCache => messageInCache.id === message.id
	);
}
