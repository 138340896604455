import { useEffect, useState } from "react";

import { on } from "services/pubSub";
import { AccountStatus } from "services/accountStatus/accountStatus";
import { accountStatus } from "services/accountStatus";

export function useAccountStatus(): Partial<AccountStatus> {
	const [latestSettings, setLatestSettings] = useState<Partial<AccountStatus>>(
		accountStatus
	);

	useEffect(() => {
		return on("accountStatusChanged", (newSettings: Partial<AccountStatus>) => {
			setLatestSettings({ ...newSettings });
		});
	}, [setLatestSettings]);

	return latestSettings;
}
