import React, { useCallback, useContext } from "react";

import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";

import ModalMarkMeetingPaid from "./ModalMarkMeetingPaid";

interface Props {
	markingPaid: MeetingsDataFlattened | undefined;
	setMarkingPaid: React.Dispatch<
		React.SetStateAction<MeetingsDataFlattened | undefined>
	>;
}

const MarkingPaid: React.FunctionComponent<Props> = ({
	markingPaid,
	setMarkingPaid
}) => {
	const handleCancelMarkingPaid = useCallback(async () => {
		setMarkingPaid(undefined);
	}, [setMarkingPaid]);

	const [, setDashboardContextState] = useContext(DashboardContext);

	const handleMeetingMarkedPaidOK = useCallback(async () => {
		setMarkingPaid(undefined);
		setDashboardContextState(oldState => ({
			...oldState,
			meetingsSectionRefreshNum: new Date().getTime()
		}));
	}, [setMarkingPaid, setDashboardContextState]);

	return (
		<ModalMarkMeetingPaid
			meeting={markingPaid}
			isOpen={!!markingPaid}
			onCancel={handleCancelMarkingPaid}
			onOK={handleMeetingMarkedPaidOK}
		/>
	);
};

export default MarkingPaid;
