import React, { useState, useCallback } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import FieldText from "components/FieldText";
import { DataInAnyRow } from "components/PageDashboard/ICalFeedsToImportSection/ICalFeedsToImportTable/ICalFeedsToImportTable";
import { validate } from "validation/validate";

function StepUrl({
	disableProgressToNextStep,
	enableProgressToNextStep
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [currentValue, setCurrentValue] = useState("");

	const handleNewValue = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const url = e.currentTarget.value;

			setCurrentValue(url);

			if (!url) {
				disableProgressToNextStep({
					alert: {
						title: "One thing missing",
						contents: "Please enter the feed URL"
					}
				});
				return;
			}

			if (
				!validate<{ url: string }>(
					{ url },
					{
						doValidate: (input, { checkField }) =>
							checkField("url", { type: "url" }),
						auth: {}
					}
				)
			) {
				disableProgressToNextStep();
				return;
			}

			enableProgressToNextStep({
				url
			});
		},
		[enableProgressToNextStep, disableProgressToNextStep, setCurrentValue]
	);

	return <FieldText value={currentValue} onChange={handleNewValue} />;
}

export default StepUrl;
