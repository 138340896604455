import moment from "moment-timezone";

import { formatDateForApi } from "services/formatDateForApi";

import { makeApiRequest, Auth } from "api/makeApiRequest";
import {
	flagValidationErrorsInSingleSessionPrice,
	SingleSessionPrice
} from "./validators/flagValidationErrorsInSingleSessionPrice";
import {
	flagValidationErrorsInCredit,
	Credit
} from "./validators/flagValidationErrorsInCredit";
import {
	flagValidationErrorsInSessionType,
	SessionType
} from "./validators/flagValidationErrorsInSessionType";
import { ApiRequest } from "api/ApiRequest";
import {
	ValidationCallback,
	validate,
	validationErrorsToString
} from "validation/validate";
import { SearchResults } from "api/SearchResults";
import { flagValidationErrorsInSearchResults } from "validation/flagValidationErrorsInSearchResults";
import { Medium } from "hooks/useAvailableMediums";

interface RawApiOutputRow {
	id: string;
	hostUid: string;
	guestUid: string;
	dateStartsUTC: string;
	dateEndsJustBeforeUTC: string;
	privateMessageContents?: string;
	privateMessageDatesentUTC?: string;
	privateMessageId?: string;
	isAccepted: boolean;
	isRejected: boolean;
	displayNameGuest: string;
	displayNameHost: string;
	singleSessionPrice?: SingleSessionPrice;
	credit?: Credit;
	sessionType: SessionType;
	medium: Medium;
}

const flagValidationErrorsInRow: ValidationCallback<RawApiOutputRow> = (
	input,
	{ flag, checkField },
	auth
) => {
	checkField("id", { type: "string" });
	checkField("dateStartsUTC", { type: "datestring" });
	checkField("dateEndsJustBeforeUTC", { type: "datestring" });

	const { uid } = auth;
	const userIsHost = !!uid && (!!input.hostUid && input.hostUid === uid);
	const userIsGuest = !!uid && (!!input.guestUid && input.guestUid === uid);
	const isOwnMeeting = !!userIsHost || !!userIsGuest;

	if (isOwnMeeting) {
		const {
			privateMessageDatesentUTC,
			privateMessageId,
			isAccepted,
			isRejected,
			displayNameGuest,
			displayNameHost
		} = input;

		checkField("hostUid", { type: "string" });
		checkField("guestUid", { type: "string" });
		checkField("privateMessageContents", { type: "string", isRequired: false });
		checkField("medium", {
			type: "string",
			followUpChecks: ({ findInvalid, value }) => {
				if (!["google-meet", "skype", "zoom"].includes(value)) {
					findInvalid("Invalid value");
				}
			}
		});

		if (
			privateMessageDatesentUTC &&
			!(typeof privateMessageDatesentUTC === "string")
		) {
			flag("privateMessageDatesentUTC", "invalid");
		}

		if (privateMessageId && !(typeof privateMessageId === "string")) {
			flag("privateMessageId", "invalid");
		}

		if (isAccepted === undefined) {
			flag("isAccepted", "missing");
		} else if (!(typeof isAccepted === "boolean")) {
			flag("isAccepted", "invalid");
		}

		if (isRejected === undefined) {
			flag("isRejected", "missing");
		} else if (!(typeof isRejected === "boolean")) {
			flag("isRejected", "invalid");
		}

		if (displayNameGuest === undefined) {
			flag("displayNameGuest", "missing");
		} else if (!(typeof displayNameGuest === "string")) {
			flag("displayNameGuest", "invalid");
		}

		if (displayNameHost === undefined) {
			flag("displayNameHost", "missing");
		} else if (!(typeof displayNameHost === "string")) {
			flag("displayNameHost", "invalid");
		}

		if (input.singleSessionPrice) {
			validate(input.singleSessionPrice, {
				doValidate: flagValidationErrorsInSingleSessionPrice,
				withErrors: errors => {
					flag("singleSessionPrice", validationErrorsToString(errors));
				},
				auth
			});
		}

		if (input.credit) {
			validate(input.credit, {
				doValidate: flagValidationErrorsInCredit,
				withErrors: errors => {
					flag("credit", validationErrorsToString(errors));
				},
				auth
			});
		}

		if (input.sessionType) {
			validate(input.sessionType, {
				doValidate: flagValidationErrorsInSessionType,
				withErrors: errors => {
					flag("sessionType", validationErrorsToString(errors));
				},
				auth
			});
		} else {
			flag("sessionType", "missing");
		}
	}
};

const flagValidationErrors: ValidationCallback<
	SearchResults<RawApiOutputRow>
> = (input, flag, auth) =>
	flagValidationErrorsInSearchResults(
		flagValidationErrorsInRow,
		input,
		flag,
		auth
	);

export interface OutputRow
	extends Omit<
		RawApiOutputRow,
		"dateStartsUTC" | "dateEndsJustBeforeUTC" | "privateMessageDatesentUTC"
	> {
	dateStartsUTC: Date;
	dateEndsJustBeforeUTC: Date;
	privateMessageDatesentUTC?: Date;
}

interface Params {
	hostUid?: string;
	guestUid?: string;
	earliestStartDateUTC?: Date;
	mustStartBeforeUTC?: Date;
	mustEndAfterUTC?: Date;
	mustEndBeforeUTC?: Date;
	isAccepted?: boolean;
	isRejected?: boolean;

	cacheInMemory?: boolean;
}

export function getMeetingRequests(
	{
		hostUid,
		guestUid,
		earliestStartDateUTC,
		mustStartBeforeUTC,
		mustEndAfterUTC,
		mustEndBeforeUTC,
		cacheInMemory,
		isAccepted = true,
		isRejected
	}: Params,
	auth: Auth
): ApiRequest<SearchResults<OutputRow>> {
	const req = makeApiRequest("meetingRequests", {
		auth,
		cacheInMemory,
		data: {
			get: {
				hostUid,
				guestUid,
				...(earliestStartDateUTC
					? { earliestStartDateUTC: formatDateForApi(earliestStartDateUTC) }
					: {}),
				...(mustEndAfterUTC
					? { mustEndAfterUTC: formatDateForApi(mustEndAfterUTC) }
					: {}),
				...(mustEndBeforeUTC
					? { mustEndBeforeUTC: formatDateForApi(mustEndBeforeUTC) }
					: {}),
				...(mustStartBeforeUTC
					? { mustStartBeforeUTC: formatDateForApi(mustStartBeforeUTC) }
					: {}),
				...(isAccepted !== undefined
					? { isAccepted: isAccepted ? "1" : "0" }
					: {}),
				...(isRejected !== undefined
					? { isRejected: isRejected ? "1" : "0" }
					: {})
			}
		},
		flagValidationErrors
	});

	// TODO:WV:20230408:Implement a way of fetching pages other than page 1 (for this and all other paginated API requests)
	return {
		ready: req.ready.then(response => {
			return {
				page: response
					? response.page.map(row => {
							const {
								dateStartsUTC,
								privateMessageDatesentUTC,
								dateEndsJustBeforeUTC,
								isAccepted,
								isRejected,
								...rest
							} = row;
							return {
								...rest,
								dateStartsUTC: moment.utc(dateStartsUTC).toDate(),
								...(privateMessageDatesentUTC
									? {
											privateMessageDatesentUTC: moment
												.utc(privateMessageDatesentUTC)
												.toDate()
									  }
									: {}),
								dateEndsJustBeforeUTC: moment
									.utc(dateEndsJustBeforeUTC)
									.toDate(),
								isAccepted: !!isAccepted,
								isRejected: !!isRejected
							};
					  })
					: []
			};
		}),
		abort: () => req.abort(),
		aborted: () => req.aborted(),
		type: req.type
	};
}
