import React, { useState } from "react";

interface ZoomState {
	code?: string;
	bookingWizard?: {
		eventToBook: string;
	};
}

export const ZoomContext = React.createContext<
	[
		ZoomState | undefined,
		React.Dispatch<React.SetStateAction<ZoomState | undefined>>
	]
>([undefined, arg => undefined]);

const ZoomProvider: React.FunctionComponent = ({ children }) => {
	const [state, setState] = useState<ZoomState>();

	return (
		<ZoomContext.Provider value={[state, setState]}>
			{children}
		</ZoomContext.Provider>
	);
};

export default ZoomProvider;
