import React from "react";

import PageContent from "components/PageContent";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";
import Markdown from "components/Markdown";

// @ts-ignore
import privacyPolicy from "./privacy-policy.md";

const PagePrivacyPolicy: React.FunctionComponent = () => {
	usePageTitlePrefix("Privacy Policy");

	return (
		<PageContent type="printabledocument" bypassTermsWall>
			<header className="major">
				<h1>Privacy Policy</h1>
			</header>
			<Markdown
				source={privacyPolicy}
				sourceType="file"
				replaceTokens
				encodeRawHtmlEntities={false}
			/>
		</PageContent>
	);
};

export default PagePrivacyPolicy;
