import React, { useContext } from "react";
import "react-chat-widget/lib/styles.css";

import { ChatContext } from "components/Chat/ChatContext";
import ChatWidgetInner from "./ChatWidgetInner";
import { useWorkAroundFullScreenChatWidgetBug } from "./useWorkAroundFullScreenChatWidgetBug";

interface Props {
	partner?: {
		uid: string;
		displayName: string;
	};
	isError: boolean;
	closeAndReset: () => void;
	handleNewUserMessage: (message: any) => void;
}

const ChatWidget: React.FunctionComponent<Props> = ({
	partner,
	isError,
	closeAndReset,
	handleNewUserMessage
}) => {
	const partnerUID = partner ? partner.uid : undefined;
	const [, setChatDetails] = useContext(ChatContext);
	useWorkAroundFullScreenChatWidgetBug(partnerUID, setChatDetails);

	return partner ? (
		<ChatWidgetInner
			partner={partner}
			isError={isError}
			closeAndReset={closeAndReset}
			handleNewUserMessage={handleNewUserMessage}
		/>
	) : null;
};

export default ChatWidget;
