import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	uid: string;
}

export interface Output {
	url: string;
	created: number;
}

const flagValidationErrors: ValidationCallback<Output> = (
	input,
	{ checkField }
) => {
	checkField("url", { type: "string" });
	checkField("created", { type: "number" });
};

export function getStripeDashboardUrl(
	{ uid }: Params,
	auth: Auth
): ApiRequest<Output> {
	return makeApiRequest("stripeDashboardUrl", {
		auth,
		data: {
			post: {
				uid
			}
		},
		flagValidationErrors
	});
}
