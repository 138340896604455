import React, { useEffect, useCallback } from "react";

type Handler = () => boolean | undefined | void;

export const useOnClickNext = (
	setOnClickNext: React.Dispatch<React.SetStateAction<Handler | undefined>>,
	handler: Handler,
	preventDefaultNavigation: boolean = true
) => {
	const handleClick = useCallback(() => {
		setOnClickNext(() => () => {
			handler();
			return !preventDefaultNavigation;
		});
	}, [setOnClickNext, handler, preventDefaultNavigation]);

	useEffect(() => {
		handleClick();
	}, [handleClick]);
};
