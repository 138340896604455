import React from "react";
import { useParams, Redirect } from "react-router-dom";

import PageContent from "components/PageContent";
import ErrorBanner from "components/ErrorBanner";
import { useUserExists } from "hooks/useUserExists";
import { useAvailabilityCalendarData } from "hooks/useAvailabilityCalendarData";
import { useProfileData } from "hooks/useProfileData";
import { useScreenSizeIsMin } from "hooks/useScreenSizeIsMin";
import MeetingCalendar from "components/MeetingCalendar";

const PageMentorAvailability: React.FunctionComponent = () => {
	const { vanityUrl, uid } = useParams<{ vanityUrl: string; uid: string }>();

	const { isWaiting, isLoading, isError, output: profileData } = useProfileData(
		vanityUrl,
		uid
	);

	const mentorId =
		profileData && profileData.isMentor ? profileData.uid : undefined;

	const slotDurationInMinutes = 30;

	const { availabilityCalendarData } = useAvailabilityCalendarData({
		mentorId,
		slotDurationInMinutes
	});

	const screenIsTabletOrWider = useScreenSizeIsMin("tablet");

	const { output: userExistenceData } = useUserExists({ uid, vanityUrl });
	if (userExistenceData && userExistenceData.userExists === false) {
		return <Redirect to="/" />;
	}

	if (isWaiting || isLoading || !profileData) {
		return <PageContent></PageContent>;
	}

	if (isError) {
		return (
			<PageContent>
				<ErrorBanner />
			</PageContent>
		);
	}

	const title = profileData ? (
		<>
			Availability:{" "}
			{profileData && profileData.displayName ? profileData.displayName : null}
		</>
	) : (
		undefined
	);

	return (
		<PageContent>
			<MeetingCalendar
				{...availabilityCalendarData}
				isClickable={false}
				displaymode={screenIsTabletOrWider ? "full" : "compact"}
				includeHeadingBar={true}
				titleShort={title}
				titleLong={title}
			/>
		</PageContent>
	);
};

export default PageMentorAvailability;
