import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	uid: string;
}

export interface Output {
	paymentMethods: {
		paymentMethodId: string;
		cardBrand: string;
		cardLastFour: string;
		cardExpMonth: number;
		cardExpYear: number;
	}[];
}

const flagValidationErrors: ValidationCallback<Output> = (input, { flag }) => {
	if (input.paymentMethods === undefined) {
		flag("paymentMethods", "missing");
	} else if (!Array.isArray(input.paymentMethods)) {
		flag("paymentMethods", "invalid");
	} else {
		for (const [
			paymentMethodOffset,
			paymentMethod
		] of input.paymentMethods.entries()) {
			const errorMessagePrefix = `Payment method ${paymentMethodOffset}`;

			if (typeof paymentMethod !== "object") {
				flag("paymentMethods", `${errorMessagePrefix}: not an object`);
				continue;
			}

			const {
				paymentMethodId,
				cardBrand,
				cardLastFour,
				cardExpMonth,
				cardExpYear
			} = paymentMethod;

			if (paymentMethodId === undefined) {
				flag("paymentMethods", `${errorMessagePrefix}: no paymentMethodId`);
			} else if (typeof paymentMethodId !== "string") {
				flag(
					"paymentMethods",
					`${errorMessagePrefix}: paymentMethodId not a string`
				);
			}

			if (cardBrand === undefined) {
				flag("paymentMethods", `${errorMessagePrefix}: no cardBrand`);
			} else if (typeof cardBrand !== "string") {
				flag("paymentMethods", `${errorMessagePrefix}: cardBrand not a string`);
			}

			if (cardLastFour === undefined) {
				flag("paymentMethods", `${errorMessagePrefix}: no cardLastFour`);
			} else if (typeof cardLastFour !== "string") {
				flag(
					"paymentMethods",
					`${errorMessagePrefix}: cardLastFour not a string`
				);
			}

			if (cardExpMonth === undefined) {
				flag("paymentMethods", `${errorMessagePrefix}: no cardExpMonth`);
			} else if (typeof cardExpMonth !== "number") {
				flag(
					"paymentMethods",
					`${errorMessagePrefix}: cardExpMonth not a number`
				);
			}

			if (cardExpYear === undefined) {
				flag("paymentMethods", `${errorMessagePrefix}: no cardExpYear`);
			} else if (typeof cardExpYear !== "number") {
				flag(
					"paymentMethods",
					`${errorMessagePrefix}: cardExpYear not a number`
				);
			}
		}
	}
};

export function getPaymentMethods(
	{ uid }: Params,
	auth: Auth
): ApiRequest<Output> {
	return makeApiRequest("paymentMethods", {
		auth,
		data: {
			get: {
				uid
			}
		},
		flagValidationErrors
	});
}
