import React, { useCallback, useMemo } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { captureException } from "services/captureException";
import { getBooleanOptions } from "services/getBooleanOptions";
import { OutputRow as ICalFeedsToImportData } from "api/getICalFeedsToImport";
import ModalFieldText from "components/ModalFieldText";
import ModalFieldSelect from "components/ModalFieldSelect";
import { OnCompleteOutput } from "components/ModalWizard/ModalWizard";
import EditableTable from "components/EditableTable";
import { ValidationCallback } from "validation/validate";
import { useStickySort } from "hooks/useStickySort";
import StepUrl from "./NewRowWizard/StepUrl";
import StepContainsExternallyBookedMeetings from "./NewRowWizard/StepContainsExternallyBookedMeetings";

interface ICalFeedsToImportTableProps {
	iCalFeedsToImport: ICalFeedsToImportData[];
	isLoading: boolean;
	isWaiting: boolean;
	isError: boolean;
	waitModalTitle: string;
	waitModalIsShowing: boolean;
	onEdit: (
		rows: { old?: DataInAnyRow; new?: DataInAnyRow }[]
	) => OnCompleteOutput;
}

export type DataInAnyRow = ICalFeedsToImportData;

const ICalFeedsToImportTable: React.FunctionComponent<
	ICalFeedsToImportTableProps
> = ({
	iCalFeedsToImport,
	isLoading,
	isWaiting,
	isError,
	onEdit: onEditGlobal,
	waitModalTitle,
	waitModalIsShowing
}) => {
	const onNewRowWizardComplete = useCallback(
		(data: ICalFeedsToImportData) => {
			return onEditGlobal([
				{
					new: {
						...data
					}
				}
			]);
		},
		[onEditGlobal]
	);

	const sortedICalFeedsToImport = useStickySort(
		iCalFeedsToImport,
		useCallback((a: ICalFeedsToImportData, b: ICalFeedsToImportData) => {
			return a.url.localeCompare(b.url);
		}, [])
	);

	const rows = useMemo(
		() =>
			sortedICalFeedsToImport.map(data => ({
				key: data.id,
				data,
				actionWhenEditing: {
					icon: faTrash,
					label: "Delete",
					onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
						e.preventDefault();
						onEditGlobal([{ old: data }]);
					}
				}
			})),
		[sortedICalFeedsToImport, onEditGlobal]
	);

	const newRowWizard = useMemo(() => {
		const steps = [
			{
				key: "url",
				title: "What is the URL of this ICal feed?",
				component: StepUrl
			},
			{
				key: "containsExternallyBookedMeetings",
				title: `Does this feed only contain teaching appointments?`,
				component: StepContainsExternallyBookedMeetings
			}
		];
		const validateNewRowData: ValidationCallback<DataInAnyRow> = (
			data,
			{ checkField }
		) => {
			checkField("url", { type: "url" });
			checkField("containsExternallyBookedMeetings", { type: "boolean" });
		};
		return {
			steps,
			validateNewRowData,
			initialData: {}
		};
	}, []);

	return (
		<>
			<EditableTable<DataInAnyRow>
				allowDeleteRows
				rows={rows}
				onEdit={onEditGlobal}
				dataDescriptionSingular="iCal feed"
				dataDescriptionPlural="iCal feeds"
				onNew={onNewRowWizardComplete}
				newRowWizard={newRowWizard}
				isLoading={isLoading}
				isWaiting={isWaiting}
				isError={isError}
				explanation="You can add external iCal feed URLs here, to import your availability from other systems, so that it is reflected on your profile."
				waitModalTitle={waitModalTitle}
				waitModalIsShowing={waitModalIsShowing}
				cols={[
					{
						key: "url",
						heading: "URL",
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<ModalFieldText
								isUnlocked={isEditing}
								title="URL"
								helptext="Please enter the full URL of the feed (including https://)"
								onOK={newValue => {
									const newData = {
										...row,
										[rowKey]: newValue
									};
									onEdit([{ old: row, new: newData }]);
								}}
								value={row.url}
							/>
						)
					},
					{
						key: "containsExternallyBookedMeetings",
						heading: `Feed contents`,
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<ModalFieldSelect
								isUnlocked={isEditing}
								title={`Does this feed only contain teaching appointments?`}
								requireSelection
								onOK={newOptions => {
									const selectedOption = newOptions.find(val => !!val.selected);
									if (!selectedOption) {
										throw captureException(new Error("No selected option"), {
											evtType: "noSelectedOption"
										});
									}

									const newData = {
										...row,
										[rowKey]: !!selectedOption.value
									};
									onEdit([{ old: row, new: newData }]);
								}}
								options={getBooleanOptions(
									!!row.containsExternallyBookedMeetings
								)}
								allowMultipleSelections={false}
								useCheckboxes={true}
							/>
						)
					}
				]}
			/>
		</>
	);
};

export default ICalFeedsToImportTable;
