import { getAvailableCurrencies } from "./getAvailableCurrencies";

interface Params {
	currencyCode: string;
	amount: number;
	textIfAmountIsZero?: string;
	locationCountryCode: string;
}

export function formatPrice({
	currencyCode,
	amount,
	textIfAmountIsZero,
	locationCountryCode
}: Params) {
	if (amount === 0 && textIfAmountIsZero !== undefined) {
		return textIfAmountIsZero;
	}
	const availableCurrencies = getAvailableCurrencies();
	if (!(currencyCode in availableCurrencies)) {
		throw new Error(
			`Currency code '${currencyCode}' not recognised.  Check capitalization.`
		);
	}
	const {
		symbol: { character, position },
		isZeroDecimalCurrency
	} = availableCurrencies[currencyCode];
	switch (position({ locationCountryCode })) {
		case "before":
			return `${character}${amount.toFixed(isZeroDecimalCurrency ? 0 : 2)}`;
		case "after":
			return `${amount.toFixed(isZeroDecimalCurrency ? 0 : 2)}${character}`;
		default:
			throw new Error("Unknown character position");
	}
}
