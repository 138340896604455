import React from "react";
import { Link } from "react-router-dom";

import FooterIconLink from "components/FooterIconLink";

import terminology from "terminology.json";

import { Props as PageContentProps } from "./PageContent";

import { TermsAndConditions } from "./PageContent.styles";

interface Props {
	pageType: PageContentProps["type"];
}

const Footer: React.FunctionComponent<Props> = ({ pageType }) => {
	return (
		<footer id="footer">
			<>
				<div className="inner">
					<ul className="icons">
						<li>
							<FooterIconLink
								href={`mailto:${process.env.REACT_APP_ENQUIRIES_EMAIL_ADDRESS}`}
								linkstyle="email"
							/>
						</li>
					</ul>
				</div>
				<p className="copyright">
					&copy; {terminology.companyName}. All rights reserved.
				</p>
				<TermsAndConditions>
					<Link to="/terms">Terms of Use</Link> |{" "}
					<Link to="/privacy-policy">Privacy Policy</Link> |{" "}
					<Link to="/safeguarding-policy">Safeguarding Policy</Link> |{" "}
					<Link to="/cookie-policy">Cookie Policy</Link>
				</TermsAndConditions>
			</>
		</footer>
	);
};

export default Footer;
