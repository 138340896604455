import React, { useState, useCallback, useContext } from "react";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import ModalConfirm from "components/ModalConfirm";
import AcceptTerms from "components/AcceptTerms";
import { useSaveAcceptance } from "components/AcceptTerms/useSaveAcceptance";

const ModalAcceptTerms: React.FunctionComponent = () => {
	const [fieldIsChecked, setFieldIsChecked] = useState(false);

	const [modalIsOpen, setModalIsOpen] = useState(true);

	const [{ logOut, hasAcceptedTerms }] = useContext(AuthContext);

	const saveAcceptance = useSaveAcceptance();

	const handleOK = useCallback(() => {
		if (fieldIsChecked) {
			saveAcceptance();
		} else {
			// TODO:WV:20240316:Highlight the field
		}
	}, [saveAcceptance, fieldIsChecked]);

	const handleCancel = useCallback(() => {
		logOut();
	}, [logOut]);

	if (hasAcceptedTerms) {
		setModalIsOpen(false);
	}

	return (
		<>
			<ModalConfirm
				isOpen={modalIsOpen}
				onOK={handleOK}
				onCancel={handleCancel}
				title="Terms of use and privacy policy"
			>
				<AcceptTerms isChecked={fieldIsChecked} onChange={setFieldIsChecked} />
			</ModalConfirm>
		</>
	);
};

export default ModalAcceptTerms;
