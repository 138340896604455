import React, { useState, useEffect, useRef, useCallback } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import { readLocalFile } from "utils/readLocalFile";
import { captureException } from "services/captureException";
import { cropImage } from "services/cropImage";
import ModalCropper from "components/ModalCropper";
import { EditButtonRenderProps } from "components/ModalField/ModalField";
import ToggleableButton from "components/ToggleableButton";
import { PreviewImage } from "./ImageUploader.styles";

// TODO:WV:20210409:Edit-button option
interface ImageUploaderProps {
	currentImageSrc: string;
	onChange: (dateURI: string) => void;
	onFail?: (reason: string) => void;
	maxFileSizeInBytes?: number;
	width?: number;
	height?: number;
	cropToAspectRatio?: number | false;
	isUnlocked?: boolean;
	editButton?: (props: EditButtonRenderProps) => React.ReactNode;
}

const ImageUploader: React.FunctionComponent<ImageUploaderProps> = ({
	currentImageSrc,
	onChange,
	onFail = reason => undefined,
	maxFileSizeInBytes = 7340032,
	width = 200,
	height = 200,
	cropToAspectRatio = false,
	isUnlocked = true,
	editButton
}) => {
	const uploadFieldRef = useRef<HTMLInputElement | null>(null);
	const [imageToCrop, setImageToCrop] = useState<string | undefined>();

	const [finalImage, setFinalImage] = useState<string | undefined>();
	const lastFinalImage = useRef<string | undefined>();
	useEffect(() => {
		if (finalImage && finalImage !== lastFinalImage.current) {
			onChange(finalImage);
		}
		lastFinalImage.current = finalImage + "";
	}, [finalImage, lastFinalImage, onChange]);

	const onClickToEdit = useCallback(() => {
		if (!isUnlocked) {
			return;
		}
		if (uploadFieldRef.current) {
			uploadFieldRef.current.click();
		}
	}, [isUnlocked, uploadFieldRef]);

	return (
		<>
			<input
				type="file"
				ref={uploadFieldRef}
				style={{ display: "none" }}
				onChange={async e => {
					if (e && e.target && e.target.files && e.target.files.length !== 0) {
						const file = e.target.files[0];
						if (file.size === 0) {
							onFail("File empty");
						} else if (file.size < 0) {
							onFail("File somehow smaller than empty");
						} else if (file.size > maxFileSizeInBytes) {
							onFail("File too big");
						} else if (
							!["image/jpeg", "image/gif", "image/png"].includes(file.type)
						) {
							onFail("Invalid file type");
						} else {
							try {
								const fileContents = await readLocalFile(file);

								if (cropToAspectRatio === false) {
									setFinalImage(fileContents);
								} else {
									setImageToCrop(fileContents);
								}
							} catch (e) {
								if (
									!(e instanceof Error && e.name === "AlreadyCapturedError")
								) {
									throw captureException(e, {
										evtType: "ErrorUploadingImage"
									});
								}
								onFail("Error uploading");
							}
						}
					}
				}}
			/>
			<PreviewImage
				isUnlocked={isUnlocked}
				width={width}
				height={height}
				src={currentImageSrc}
				onClick={onClickToEdit}
			/>

			{editButton ? (
				editButton({ isUnlocked, onClick: onClickToEdit })
			) : (
				<div>
					<ToggleableButton
						isEditing={isUnlocked}
						icon={faPen}
						onClick={onClickToEdit}
						label="Edit"
					/>
				</div>
			)}

			{imageToCrop && typeof cropToAspectRatio === "number" ? (
				<ModalCropper
					title="Crop photo"
					helptext={`Please choose a ${
						cropToAspectRatio === 1 ? `square ` : ``
					}region`}
					onOK={async cropDetails => {
						const { x, y, width, height } = cropDetails.absolute;
						const croppedImage = await cropImage({
							uri: imageToCrop,
							x,
							y,
							width,
							height
						});
						setFinalImage(croppedImage);
						setImageToCrop(undefined);
					}}
					onCancel={() => setImageToCrop(undefined)}
					imageDataURI={imageToCrop}
					cropToAspectRatio={cropToAspectRatio}
				/>
			) : null}
		</>
	);
};

export default ImageUploader;
