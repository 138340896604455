import React from "react";
import styled from "styled-components";

const HighlightedMessageWrapper = styled.div`
	.rcw-message-text {
		border: 2px solid #8e8e8e;
	}
`;

interface ChatWidgetHighlightedMessageProps {
	messageText: string;
	isUserMessage: boolean;
}

const ChatWidgetHighlightedMessage: React.FunctionComponent<
	ChatWidgetHighlightedMessageProps
> = ({ messageText, isUserMessage }) => {
	return (
		<HighlightedMessageWrapper>
			<div className={`rcw-${isUserMessage ? `client` : `response`}`}>
				<div className="rcw-message-text">
					<p>{messageText}</p>
				</div>
			</div>
		</HighlightedMessageWrapper>
	);
};

export default ChatWidgetHighlightedMessage;
