import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import {
	SearchResultsWithCount,
	SearchResultsWithCountAndPagination
} from "api/SearchResults";
import { validate, ValidationCallback } from "validation/validate";

export interface Params {
	uid: string;

	sessionTypeId?: string;
	sessionDurationMinutes?: number;
	spendingStatus?: "available" | "pending" | "spent";

	cursor?: string;
	cursorType?: "after" | "before";
}

export interface OutputRow {
	id: string;
	sessionTypeId: string;
	sessionDurationMinutes: number;
}

// TODO:WV:20230118:De-dup this with other places there are similar functions e.g. getContacts and flagValidationErrorsInSearchResults
const flagValidationErrors: ValidationCallback<
	SearchResultsWithCount<OutputRow>
> = (input, { checkField, flag }, auth) => {
	checkField("count", { type: "number" });

	const validatePage = (fieldName: "page" | "nextPage" | "prevPage") => {
		if (input[fieldName] === undefined) {
			flag(fieldName, "missing");
		} else if (!Array.isArray(input[fieldName])) {
			flag(fieldName, "not an array");
		} else if (
			input[fieldName].some((entry: unknown) => typeof entry !== "object")
		) {
			flag(fieldName, "not all objects");
		} else {
			for (const entry of input[fieldName]) {
				validate(entry, {
					doValidate: flagValidationErrorsInRow,
					auth,
					withErrors: errors => {
						flag(fieldName, "At least one page: " + JSON.stringify(errors));
					}
				});
			}
		}
	};

	validatePage("page");
	validatePage("nextPage");
	validatePage("prevPage");
};

const flagValidationErrorsInRow: ValidationCallback<OutputRow> = (
	input,
	{ checkField }
) => {
	checkField("id", { type: "string" });
	checkField("sessionTypeId", { type: "string" });
	checkField("sessionDurationMinutes", { type: "number" });
};

export function getCredits(
	{
		uid,
		sessionTypeId,
		sessionDurationMinutes,
		spendingStatus,
		cursor,
		cursorType = "after"
	}: Params,
	auth: Auth
): ApiRequest<SearchResultsWithCountAndPagination<OutputRow, string>> {
	const req = makeApiRequest("credits", {
		auth,
		data: {
			get: {
				uid,
				...(sessionTypeId ? { sessionTypeId } : {}),
				...(sessionDurationMinutes
					? { sessionDurationMinutes: sessionDurationMinutes + "" }
					: {}),
				...(spendingStatus ? { spendingStatus } : {}),
				cursorType,
				...(cursor ? { cursor } : {})
			}
		},
		flagValidationErrors,

		// TODO:WV:20220118:Allow this to be 'true' and provide a means to flush the cache after a booking is completed.  This would result in fewer requests to the server while going through the booking process.
		cacheInMemory: false
	});

	return {
		ready: req.ready.then(results => {
			return {
				count: results ? results.count : 0,
				page: results ? results.page : [],
				nextPage: results && results.nextPage ? results.nextPage : [],
				prevPage: results && results.prevPage ? results.prevPage : [],
				cursor,
				cursorType
			};
		}),
		abort: () => req.abort(),
		aborted: () => req.aborted(),
		type: req.type
	};
}
