import {
	Params as GetMeetingsParams,
	OutputRow as GetMeetingsOutputRow,
	AttendeeData,
	HostData,
	getMeetings
} from "./getMeetings";
import { Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { SearchResults } from "api/SearchResults";

export type OutputRow = Omit<
	GetMeetingsOutputRow,
	"attendeeData" | "hostData"
> &
	AttendeeData &
	Partial<HostData>;

/*
 * This function wraps getMeetings, but transforms the output into a form compatible with
 * getMeetingRequests and getMeetingRescheduleRequests, for use in the dashboard MeetingsTable
 * (and potentially elsewhere).  This is only possible for meetings that the user is attending.
 */
export function getMeetingsFlattened(
	args: GetMeetingsParams,
	auth: Auth
): ApiRequest<SearchResults<OutputRow>> {
	const { uid } = auth;

	if (!uid) {
		throw new Error("No authenticated user");
	}

	if (!(uid === args.hostUid || uid === args.guestUid)) {
		throw new Error(
			"Current user can only request meetings where they are the host or the guest"
		);
	}

	const req = getMeetings(args, auth);

	const ready = req.ready.then(results => {
		if (!results) {
			return results;
		}
		const { page, nextPage, prevPage, ...rest } = results;

		if (!pageHasAttendeeData(page)) {
			throw new Error("page was missing attendeeData");
		}

		if (nextPage && !pageHasAttendeeData(nextPage)) {
			throw new Error("nextPage was missing attendeeData");
		}

		if (prevPage && !pageHasAttendeeData(prevPage)) {
			throw new Error("prevPage was missing attendeeData");
		}

		const newResults = {
			page: page.map(flattenItem),
			nextPage: nextPage ? nextPage.map(flattenItem) : undefined,
			prevPage: prevPage ? prevPage.map(flattenItem) : undefined,
			...rest
		};

		return newResults;
	});

	const abort = () => req.abort();
	const aborted = () => req.aborted();

	return {
		ready,
		abort,
		aborted,
		type: "getMeetingsFlattened"
	};
}

function pageHasAttendeeData(
	page: GetMeetingsOutputRow[]
): page is ItemWithAttendeeData[] {
	return page.every(item => item.attendeeData !== undefined);
}

interface ItemWithAttendeeData
	extends Omit<GetMeetingsOutputRow, "attendeeData" | "hostData"> {
	attendeeData: AttendeeData;
	hostData?: HostData;
}

const flattenItem = (item: ItemWithAttendeeData) => {
	const { attendeeData, hostData, ...rest } = item;

	return {
		...attendeeData,
		...hostData,
		...rest
	};
};
