import React from "react";

import { useScreenSizeIsMax } from "hooks/useScreenSizeIsMax";

import {
	StyledTable,
	KeyValueBlock,
	KeyValueBlockList
} from "./KeyValueTable.styles";

type Row = [React.ReactNode, React.ReactNode];

interface KeyValueTableProps {
	data: {
		row: Row;
		key: string;
	}[];
	supportsLongEntries?: boolean;
}

const KeyValueTable: React.FunctionComponent<KeyValueTableProps> = ({
	data,
	supportsLongEntries = true
}) => {
	const isMediumScreen = useScreenSizeIsMax("medium");

	if (supportsLongEntries && isMediumScreen) {
		return (
			<KeyValueBlockList>
				{data.map(({ row: [k, v], key }) => (
					<KeyValueBlock key={key}>
						<div>
							<strong>{k}</strong>
						</div>
						<div>{v}</div>
					</KeyValueBlock>
				))}
			</KeyValueBlockList>
		);
	}

	return (
		<StyledTable>
			<tbody>
				{data.map(({ row: [k, v], key }) => (
					<tr key={key}>
						{k === null ? null : <td>{k}</td>}
						<td colSpan={k === null ? 2 : 1}>{v}</td>
					</tr>
				))}
			</tbody>
		</StyledTable>
	);
};

export default KeyValueTable;
