import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	cacheInMemory: boolean;
}

type Output = {
	code: string;
	name: string;
}[];
const flagValidationErrors: ValidationCallback<Output> = (input, { flag }) => {
	if (!Array.isArray(input)) {
		flag(0, "not an array");
	}

	for (const [rowOffset, value] of input.entries()) {
		const { code, name } = value;

		if (code === undefined) {
			flag(rowOffset, "code is missing");
		} else if (!(typeof code === "string")) {
			flag(rowOffset, "code is invalid");
		}

		if (name === undefined) {
			flag(rowOffset, "name is missing");
		} else if (!(typeof name === "string")) {
			flag(rowOffset, "name is invalid");
		}
	}
};

export function getLocationCountries(
	{ cacheInMemory }: Params,
	auth: Auth
): ApiRequest<Output> {
	return makeApiRequest("countries", {
		cacheInMemory,
		flagValidationErrors,
		auth
	});
}
