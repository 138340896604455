import { getAvailableCurrencies } from "services/getAvailableCurrencies";

export const priceForTooltip = (price: number, currency: string) => {
	const currencyDetails = getAvailableCurrencies()[currency];
	return `${price.toFixed(
		currencyDetails && currencyDetails.isZeroDecimalCurrency ? 0 : 2
	)} ${
		currencyDetails ? `${getAvailableCurrencies()[currency].name}` : currency
	}`;
};
