import React, { useCallback, useRef } from "react";
import uniqid from "uniqid";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

import Button from "components/Button";
import { defaultErrorText } from "components/ErrorBanner/ErrorBanner";
import FieldCheckbox from "components/FieldCheckbox";
import {
	FieldOptions,
	ErrorMessage,
	SelectDropDown,
	Option,
	PaginationButtonContainer
} from "./FieldSelect.styles";

export type FieldSelectProps<T> = {
	allowMultipleSelections?: boolean;
	isLoading?: boolean;
	isError?: boolean;
	currentOptions: FieldOption<T>[];
	onNewCurrentOptions: React.Dispatch<React.SetStateAction<FieldOption<T>[]>>;
	isNotValid?: boolean;
	useCheckboxes?: boolean;
	isInline?: boolean;
	addRightMargin?: boolean;
	addLeftMargin?: boolean;
	placeholder?: string;
	pagination?: {
		haveNextPage: boolean;
		onClickNextPage: () => void;
		havePreviousPage: boolean;
		onClickPreviousPage: () => void;
	};
	testid?: string;
};

export type FieldOption<T> = {
	id: string;
	value: T;
	text: string;
	extra?: React.ReactNode;
	selected?: boolean;
	isScrollToDefault?: boolean;
	disabled?: boolean;
};

function FieldSelect<T>({
	allowMultipleSelections = true,
	isLoading,
	isError,
	currentOptions,
	onNewCurrentOptions,
	isNotValid = false,
	useCheckboxes = true,
	isInline = false,
	addRightMargin = false,
	addLeftMargin = false,
	placeholder,
	pagination,
	testid
}: FieldSelectProps<T>) {
	const instanceId = useRef(uniqid());

	const placeholderFieldId = "_placeholder";
	const toggleOptMulti = useCallback(
		(id: string) => {
			if (id === placeholderFieldId) {
				return;
			}
			onNewCurrentOptions(currentOptions =>
				currentOptions.map(opt =>
					opt.id === id ? { ...opt, selected: !opt.selected } : opt
				)
			);
		},
		[onNewCurrentOptions, placeholderFieldId]
	);

	const toggleOptMaxOne = useCallback(
		(id: string) => {
			if (id === placeholderFieldId) {
				return;
			}
			onNewCurrentOptions(currentOptions =>
				currentOptions.map(opt => ({
					...opt,
					selected: opt.id === id ? !opt.selected : false
				}))
			);
		},
		[onNewCurrentOptions, placeholderFieldId]
	);

	if (placeholder !== undefined && !!useCheckboxes) {
		throw new Error("Placeholders only apply to drop-down style select fields");
	}

	return (
		<>
			{pagination && pagination.havePreviousPage ? (
				<PaginationButtonContainer>
					<Button
						onClick={pagination.onClickPreviousPage}
						isNarrow
						icon={faArrowUp}
						label="Previous page"
					/>
				</PaginationButtonContainer>
			) : null}

			{isError ? (
				<ErrorMessage>{defaultErrorText}</ErrorMessage>
			) : isLoading ? (
				<p>Loading...</p>
			) : useCheckboxes ? (
				<FieldOptions isNotValid={isNotValid}>
					{currentOptions.map(opt => {
						const fieldId = `checkboxopt-${opt.id}`;
						return (
							<li key={`wrapper-${fieldId}`}>
								<FieldCheckbox
									id={fieldId}
									name={
										allowMultipleSelections
											? fieldId
											: `checkbox-${instanceId.current}`
									}
									isScrollToDefault={!!opt.isScrollToDefault}
									checked={!!opt.selected}
									onChange={
										allowMultipleSelections
											? () => toggleOptMulti(opt.id)
											: () => toggleOptMaxOne(opt.id)
									}
									labelText={opt.text}
									extra={opt.extra}
									disabled={opt.disabled}
								/>
							</li>
						);
					})}
				</FieldOptions>
			) : (
				<SelectDropDown
					isInline={isInline}
					addRightMargin={addRightMargin}
					addLeftMargin={addLeftMargin}
					multiple={allowMultipleSelections}
					value={getSelectValue<T>(currentOptions, allowMultipleSelections)}
					onChange={e =>
						allowMultipleSelections
							? toggleOptMulti(e.currentTarget.value)
							: toggleOptMaxOne(e.currentTarget.value)
					}
					isNotValid={isNotValid}
					data-testid={testid}
				>
					{(placeholder
						? addPlaceholder<T>({
								currentOptions,
								placeholder,
								placeholderFieldId
						  })
						: currentOptions
					).map(opt => {
						if (opt.extra && typeof opt.extra !== "string") {
							throw new Error(
								"For DropDown-type selecf fields, any 'extra' content in options must be a string"
							);
						}
						return (
							<Option
								value={opt.id}
								key={`selectopt-${opt.value}`}
								disabled={opt.disabled}
								isPlaceholder={opt.id === placeholderFieldId}
								data-testid={testid ? `${testid}-${opt.id}` : undefined}
							>
								{opt.text}
								{opt.extra ? opt.extra : null}
							</Option>
						);
					})}
				</SelectDropDown>
			)}
			{pagination && pagination.haveNextPage ? (
				<PaginationButtonContainer>
					<Button
						onClick={pagination.onClickNextPage}
						isNarrow
						icon={faArrowDown}
						label="Next page"
					/>
				</PaginationButtonContainer>
			) : null}
		</>
	);
}

export default FieldSelect;

function getSelectValue<T>(
	currentOptions: FieldOption<T>[],
	allowMultipleSelections: boolean
) {
	const selectedOptionValues = currentOptions
		.filter(opt => !!opt.selected)
		.map(opt => opt.value + "");
	if (allowMultipleSelections) {
		return selectedOptionValues;
	}
	return selectedOptionValues.length ? selectedOptionValues[0] : undefined;
}

interface AddPlaceholderParams<T> {
	currentOptions: FieldOption<T>[];
	placeholder: string;
	placeholderFieldId: string;
}

function addPlaceholder<T>({
	currentOptions,
	placeholder,
	placeholderFieldId
}: AddPlaceholderParams<T>) {
	const placeholderFieldValue = placeholderFieldId;
	return [
		{
			text: placeholder,
			value: placeholderFieldValue,
			id: placeholderFieldId,
			disabled: false,
			extra: null
		},
		...currentOptions
	];
}
