import React from "react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "components/Button";

import {
	AlertInner,
	IconWrapper,
	ContentsWrapper,
	AlertInnerInner,
	ContentsItem,
	DismissButtonContainer
} from "./AlertBox.styles";

interface Props {
	contents: React.ReactNode[];
	allowDismiss?: boolean;
	onDismiss?: () => void;
}

const AlertBox: React.FunctionComponent<Props> = ({
	contents,
	allowDismiss = false,
	onDismiss
}) => {
	if (allowDismiss && !onDismiss) {
		throw new Error(
			"Please supply both of allowDismiss and onDismiss or neither"
		);
	}
	return (
		<AlertInner>
			<AlertInnerInner>
				<IconWrapper>
					<FontAwesomeIcon icon={faExclamationCircle} size="lg" />
				</IconWrapper>
				<ContentsWrapper>
					{contents.map((content, i) => (
						<ContentsItem key={i} isLast={i === contents.length - 1}>
							{content}
							{allowDismiss && onDismiss ? (
								<DismissButtonContainer>
									<Button
										isNarrow
										onClick={onDismiss}
										label="Dismiss this message"
									/>
								</DismissButtonContainer>
							) : null}
						</ContentsItem>
					))}
				</ContentsWrapper>
			</AlertInnerInner>
		</AlertInner>
	);
};

export default AlertBox;
