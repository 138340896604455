import React, {
	useEffect,
	useCallback,
	useState,
	useContext,
	useMemo
} from "react";

import PaymentWizard from "components/PaymentWizard";
import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { OutputRow as PackagesData } from "api/getPackages";
import { OutputRow as PackageRequestsData } from "api/getPackageRequests";
import { markPackageAsAwaitingWebhook } from "api/markPackageAsAwaitingWebhook";
import { ApiRequest } from "api/ApiRequest";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useStripQueryStringFromAddressBar } from "hooks/useStripQueryStringFromAddressBar";
import { useHandleItemFromURL } from "hooks/useHandleItemFromURL";

interface Props {
	payingForProduct: PackagesData | PackageRequestsData | undefined;
	setPayingForProduct: React.Dispatch<
		React.SetStateAction<PackagesData | PackageRequestsData | undefined>
	>;
	isWaiting: boolean;
	isLoading: boolean;
	isError: boolean;
	products: (PackagesData | PackageRequestsData)[] | undefined;
}

const Payment: React.FunctionComponent<Props> = ({
	setPayingForProduct,
	payingForProduct,
	isWaiting,
	isLoading,
	isError,
	products
}) => {
	const {
		id: payingForProductId,
		packagePrice: { price, currency },
		hostUid
	} = payingForProduct
		? payingForProduct
		: {
				id: undefined,
				packagePrice: {
					price: undefined,
					currency: undefined
				},
				hostUid: undefined
		  };
	const paymentDetailsForPaymentWizard = useMemo(
		() =>
			price && currency && payingForProductId
				? {
						price,
						currency,
						productId: payingForProductId
				  }
				: undefined,
		[price, currency, payingForProductId]
	);

	const handleCancelPaymentWizard = useCallback(
		() => setPayingForProduct(undefined),
		[setPayingForProduct]
	);

	const [
		markPackageAsAwaitingWebhookRequest,
		setMarkProductAsAwaitingWebhookRequest
	] = useState<ApiRequest<{}>>();
	useEffect(() => {
		return () => {
			if (markPackageAsAwaitingWebhookRequest) {
				markPackageAsAwaitingWebhookRequest.abort();
			}
		};
	}, [markPackageAsAwaitingWebhookRequest]);

	const [{ uid }] = useContext(AuthContext);
	const [, setDashboardContextState] = useContext(DashboardContext);

	const handlePaymentSuccess = useCallback(async () => {
		if (!(payingForProductId && typeof payingForProductId === "string")) {
			throw new Error("No valid payingForProductId");
		}

		const req = markPackageAsAwaitingWebhook(
			{ id: payingForProductId },
			{ uid }
		);
		setMarkProductAsAwaitingWebhookRequest(req);
		await req.ready;

		setDashboardContextState(oldState => ({
			...oldState,
			productsSectionRefreshNum: new Date().getTime()
		}));
		setPayingForProduct(undefined);
	}, [setDashboardContextState, setPayingForProduct, payingForProductId, uid]);

	const handlePaymentFailed = useCallback(async () => {
		setDashboardContextState(oldState => ({
			...oldState,
			productsSectionRefreshNum: new Date().getTime()
		}));
		setPayingForProduct(undefined);
	}, [setDashboardContextState, setPayingForProduct]);

	const stripQueryStringFromAddressBar = useStripQueryStringFromAddressBar();
	useHandleItemFromURL<PackagesData | PackageRequestsData>({
		key: "productPayFor",
		itemsList: {
			isWaiting,
			isLoading,
			isError,
			items: products
		},
		handleFoundItem: useCallback(
			Product => {
				if ("paymentStatus" in Product && Product.paymentStatus === "pending") {
					setPayingForProduct(Product);
				}
				stripQueryStringFromAddressBar();
			},
			[setPayingForProduct, stripQueryStringFromAddressBar]
		)
	});

	return (
		<>
			{" "}
			<PaymentWizard
				mentorId={hostUid}
				metadata={
					payingForProductId ? { packageId: payingForProductId } : undefined
				}
				paymentDetails={paymentDetailsForPaymentWizard}
				onCancel={handleCancelPaymentWizard}
				onSuccess={handlePaymentSuccess}
				onFailed={handlePaymentFailed}
			/>
		</>
	);
};

export default Payment;
