import React, { useCallback, useContext } from "react";

import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { OutputRow as PackageRequestsData } from "api/getPackageRequests";
import { useStripQueryStringFromAddressBar } from "hooks/useStripQueryStringFromAddressBar";
import { useHandleItemFromURL } from "hooks/useHandleItemFromURL";

import ModalCancelPackageRequest, {
	Props as ModalCancelMeetingProps
} from "./ModalCancelPackageRequest";

interface Props {
	cancellingPackageRequest: PackageRequestsData | undefined;
	setCancellingPackageRequest: React.Dispatch<
		React.SetStateAction<PackageRequestsData | undefined>
	>;
	isWaiting: boolean;
	isLoading: boolean;
	isError: boolean;
	packageRequests: (PackageRequestsData)[] | undefined;
}

const Cancellation: React.FunctionComponent<Props> = ({
	setCancellingPackageRequest,
	cancellingPackageRequest,
	isWaiting,
	isLoading,
	isError,
	packageRequests
}) => {
	const handleCancelCancelMeeting = useCallback(async () => {
		setCancellingPackageRequest(undefined);
	}, [setCancellingPackageRequest]);

	const [, setDashboardContextState] = useContext(DashboardContext);
	const handleMeetingCancelledOK = useCallback(
		async ({ hostUid }: Parameters<ModalCancelMeetingProps["onOK"]>[0]) => {
			setCancellingPackageRequest(undefined);
			setDashboardContextState(oldState => ({
				...oldState,
				meetingsSectionRefreshNum: new Date().getTime()
			}));
		},
		[setCancellingPackageRequest, setDashboardContextState]
	);

	const stripQueryStringFromAddressBar = useStripQueryStringFromAddressBar();
	useHandleItemFromURL<PackageRequestsData>({
		key: "meetingAdjust",
		itemsList: {
			isWaiting,
			isLoading,
			isError,
			items: packageRequests
		},
		handleFoundItem: useCallback(
			packageRequest => {
				setCancellingPackageRequest(packageRequest);
				stripQueryStringFromAddressBar();
			},
			[setCancellingPackageRequest, stripQueryStringFromAddressBar]
		)
	});

	return (
		<ModalCancelPackageRequest
			packageRequest={cancellingPackageRequest}
			isOpen={!!cancellingPackageRequest}
			onCancel={handleCancelCancelMeeting}
			onOK={handleMeetingCancelledOK}
		/>
	);
};

export default Cancellation;
