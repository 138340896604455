import { useCallback, useContext } from "react";
import { XOR } from "ts-xor";
import {
	StripeError,
	PaymentIntent,
	StripeCardElement
} from "@stripe/stripe-js";
import { useStripe } from "@stripe/react-stripe-js";

import { ApiRequest } from "api/ApiRequest";
import { makePaymentIntent } from "api/makePaymentIntent";
import { AuthContext } from "components/AuthProvider/AuthProvider";

export interface CardBillingDetails {
	nameOnCard: string;
	billingAddressLine1: string;
	billingAddressLine2?: string;
	billingAddressCity: string;
	billingAddressState: string;
	billingAddressPostalCode: string;
	billingAddressCountryCode: string;
	cardDetailsAreComplete: boolean;
}

interface ParamsBasic {
	card: StripeCardElement;
	cardBillingDetails: CardBillingDetails;
	shouldSaveCardDetails: boolean;
}

interface ParamsWithMeetingId extends ParamsBasic {
	meetingId: string;
}

interface ParamsWithPackageId extends ParamsBasic {
	packageId: string;
}

type Params = XOR<ParamsWithMeetingId, ParamsWithPackageId>;

export interface Output {
	stripeError?: StripeError;
	errorMessage?: string;
	stripePaymentIntent?: PaymentIntent;
}

export function isValidCardDetailsFields(
	suppliedCardBillingDetails: Partial<CardBillingDetails>
): suppliedCardBillingDetails is CardBillingDetails {
	const emptyFields = Object.entries(suppliedCardBillingDetails).filter(
		([fieldName, fieldContents]) =>
			fieldContents === undefined || fieldContents === ""
	);

	return (
		emptyFields.length === 0 ||
		(emptyFields.length === 1 && emptyFields[0][0] === "billingAddressLine2")
	);
}

// TODO:WV:20200103:Enforce our API's limit of 50 payment methods per user
export function usePayWithNewCard() {
	const stripe = useStripe();

	const [{ uid }] = useContext(AuthContext);

	const payWithNewCard = useCallback(
		({
			card,
			cardBillingDetails: {
				nameOnCard,
				billingAddressLine1,
				billingAddressLine2,
				billingAddressCity,
				billingAddressState,
				billingAddressPostalCode,
				billingAddressCountryCode
			},
			shouldSaveCardDetails,
			meetingId,
			packageId
		}: Params): ApiRequest<Output> => {
			if (!uid) {
				throw new Error("No authenticated user");
			}
			if (!stripe) {
				throw new Error("Stripe not loaded yet");
			}

			if (
				!isValidCardDetailsFields({
					nameOnCard,
					billingAddressLine1,
					billingAddressLine2,
					billingAddressCity,
					billingAddressState,
					billingAddressPostalCode,
					billingAddressCountryCode
				})
			) {
				throw new Error("Invalid card details");
			}

			const payment_method = {
				card,
				billing_details: {
					name: nameOnCard,
					address: {
						line1: billingAddressLine1,
						line2: billingAddressLine2,
						city: billingAddressCity,
						state: billingAddressState,
						postal_code: billingAddressPostalCode,
						country: billingAddressCountryCode
					}
				}
			};

			const req = makePaymentIntent(
				{
					uid,
					meetingId,
					packageId,
					intendToSaveForFutureUse: shouldSaveCardDetails
				},
				{ uid }
			);

			const ready = (async () => {
				const responseData = await req.ready;

				if (!responseData) {
					throw new Error("No response data");
				}

				const { clientSecret } = responseData;

				// TODO:WV:20201015:Disable the form until this completes, as per instructions https://stripe.com/docs/payments/save-and-reuse
				const result = await stripe.confirmCardPayment(clientSecret, {
					payment_method,
					...(shouldSaveCardDetails ? { setup_future_usage: "on_session" } : {})
				});

				return {
					stripeError: result.error,
					stripePaymentIntent: result.paymentIntent
				};
			})();

			return {
				ready,
				abort: () => req.abort(),
				aborted: () => req.aborted(),
				type: "payWithNewCard"
			};
		},
		[stripe, uid]
	);

	return payWithNewCard;
}
