import React, { useMemo, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { faComment, faUser } from "@fortawesome/free-solid-svg-icons";

import { ChatDetails } from "components/Chat/Chat";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import Table from "components/Table";
import { OutputRow as ContactsData } from "api/getContacts";

interface Props {
	// TODO:WV:20240425:Add most recent message
	contactsAndMessages: ContactsData[];
	isLoading: boolean;
	isWaiting: boolean;
	isError: boolean;
	onClickChat: (arg: ChatDetails | undefined) => void;
}

export type NewRowWizardData = Partial<Props>;

const ContactsAndMessagesTable: React.FunctionComponent<Props> = ({
	contactsAndMessages,
	isLoading,
	isWaiting,
	isError,
	onClickChat
}) => {
	const [{ uid }] = useContext(AuthContext);

	const history = useHistory();

	// TODO:WV:20240425:I'm not sure that this useMemo will actually achieve anything, because the contactsAndMessages array might be a new variable each render.  Check this.
	const rows = useMemo(() => {
		if (!uid) {
			throw new Error("No uid");
		}

		// TODO:WV:20240120:Add option to book classes with mentors here
		return contactsAndMessages
			.sort(
				(a, b) =>
					b.dateOfLastActivityUTC.getTime() - a.dateOfLastActivityUTC.getTime()
			)
			.map(connection => {
				const contact = {
					uid: connection.contactUid,
					displayName: connection.displayNameContact,
					contactType: connection.contactType
				};

				return {
					data: {
						uid: contact.uid,
						displayName: (
							<Link to={`/user-id/${contact.uid}`}>{contact.displayName}</Link>
						)
					},
					action:
						contact.contactType === "mentor"
							? {
									onClick: () => history.push(`/user-id/${contact.uid}`),
									label: "Book a lesson",
									icon: faUser
							  }
							: {
									onClick: () =>
										onClickChat({
											partner: contact,
											pagination: { cursorType: "after" }
										}),
									label: "Open chat",
									icon: faComment
							  },
					menu: [
						...(contact.contactType === "mentor"
							? [
									{
										onClick: () => history.push(`/user-id/${contact.uid}`),
										label: "Book a lesson",
										icon: faUser
									},
									{
										onClick: () =>
											onClickChat({
												partner: contact,
												pagination: { cursorType: "after" }
											}),
										label: "Open chat",
										icon: faComment
									}
							  ]
							: []),
						...(contact.contactType !== "mentor"
							? [
									{
										onClick: () => history.push(`/user-id/${contact.uid}`),
										label: "Profile",
										icon: faUser
									}
							  ]
							: [])
					]
				};
			});
	}, [uid, contactsAndMessages, onClickChat, history]);

	// TODO:WV:20240425:Add actions (e.g. book a lesson, send a message etc.)
	return (
		<>
			<Table
				rows={rows}
				emptyText="You do not have any contacts yet"
				isLoading={isLoading}
				cols={[
					{
						key: "displayName",
						heading: "Name"
					}
				]}
				actionColumnWidth={16}
			/>
		</>
	);
};

export default ContactsAndMessagesTable;
