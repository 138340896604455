import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	id: string;
	response: boolean;
	message?: string;
}

type Output = {};
const flagValidationErrors: ValidationCallback<Output> = (input, flag) =>
	typeof input === "object" && Object.keys(input).length === 0;

export function respondToPackageRequest(
	{ id, response, message }: Params,
	auth: Auth
): ApiRequest<Output> {
	return makeApiRequest("packageRequestResponse", {
		auth,
		data: {
			post: {
				id,
				response,
				...(message ? { message } : {})
			}
		},
		flagValidationErrors
	});
}
