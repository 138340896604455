import React, { useCallback, useContext } from "react";
import { load } from "recaptcha-v3";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { AlertContext } from "components/AlertProvider/AlertProvider";
import { getFormData } from "services/getFormData";
import { captureException } from "services/captureException";
import { useSendData } from "hooks/useSendData";
import { saveContactFormSubmission } from "api/saveContactFormSubmission";

const recaptchaSiteKey = "6LcNCbEZAAAAADtQNaOpbOVafcOnhvoY5PBl6Jiq";

interface ContactFormProps {
	onSubmit?: () => void;
}

const ContactForm: React.FunctionComponent<ContactFormProps> = ({
	onSubmit,
	children
}) => {
	const [addAlert] = useContext(AlertContext);
	const [{ uid }] = useContext(AuthContext);

	const onFailure = useCallback(
		e => {
			// TODO:WV:20201220:Try to capture the sibmitted form data as well
			captureException(new Error("Error submitting contact form"), {
				evtType: "errorSubmittingContactForm",
				extra: { originalException: e }
			});
			addAlert({
				contents: `Sorry - unfortunately there was an error.\n\nPlease try emailing us directly via ${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}`
			});
		},
		[addAlert]
	);

	const doSaveSubmission = useSendData({
		method: saveContactFormSubmission,
		onFailure
	});

	const handleSubmit = useCallback(
		async e => {
			e.preventDefault();
			const form = e.currentTarget;
			const wizardData = getFormData(form);

			const recaptcha = await load(recaptchaSiteKey);
			const token = await recaptcha.execute("submit");

			try {
				const req = doSaveSubmission(
					{
						formData: { ...wizardData },
						recaptchaToken: token
					},
					{ uid }
				);

				await req.ready;

				addAlert({ contents: "Message received - thank you." });
				form.reset();
				if (onSubmit) {
					onSubmit();
				}
			} catch (e) {
				onFailure(e);
			}
		},
		[addAlert, doSaveSubmission, onFailure, onSubmit, uid]
	);

	return (
		<form method="post" action="#" onSubmit={handleSubmit}>
			{children}
		</form>
	);
};

export default ContactForm;
