import styled from "styled-components";

export const ChatWidgetContainer = styled.div`
	.rcw-close-button {
		padding: 0;
		img {
			margin: 0 auto;
			vertical-align: middle;
		}
	}

	.rcw-header {
		padding: 1em;
		span {
			padding: 0 1em;
			margin: 0;
		}
	}

	.rcw-messages-container.scrolling {
		/* Hide scrollbar  without disabling scroll */
		-ms-overflow-style: none; /* IE */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}

		/* Hide contents while scrolling */
		* {
			visibility: hidden;
		}
	}
`;
