import styled from "styled-components";

import { MeetingCalendarProps } from "./MeetingCalendar";

export const eventColorAvailable = "#84dbbd";
export const eventColorPending = "#dbd6d0";
export const eventColorBooked = "#ccc";
export const eventColorUserBusy = "#bdb0d0";

export const stripedBackground = (color: string) => `
    background: 
      /* On "top" */
      repeating-linear-gradient(
        45deg,
        transparent,
        transparent 5px,
        ${color} 5px,
        ${color} 10px
      ),
      /* on "bottom" */
      linear-gradient(
        to bottom,
        white,
        white
      );
`;

interface CalendarWrapperProps {
	isClickable: boolean;
	isLoading: boolean;
	isError: boolean;
	displaymode: MeetingCalendarProps["displaymode"];
}

export const CalendarWrapper = styled.div<CalendarWrapperProps>`
	${props =>
		props.isLoading
			? `
    opacity:0.5;
  `
			: ``}

	${props =>
		props.isError
			? `
    opacity:0.5;
  `
			: ``}

  margin-bottom: ${({ displaymode }) => (displaymode === "compact" ? 1 : 2)}em;

	.fc .fc-timegrid-col.fc-day-today {
		background-color: transparent;
	}

	table {
		margin: 0;
	}

	a {
		border-bottom: none;
	}
	button {
		height: auto;
		box-shadow: none;
		color: white !important;
	}
	table tbody tr {
		background-color: transparent;
	}

	.fc-direction-ltr .fc-timegrid-col-events {
		margin: 0;
	}

	.fc-media-screen .fc-timegrid-event {
		bottom: 0;
		border-radius: 0;
		opacity: 0.4;
	}

	.available {
		background-color: ${eventColorAvailable};
		border-color: ${eventColorAvailable};
		&:hover {
			opacity: ${props => (props.isClickable ? `1` : `0.4`)};
		}
	}

	.booked {
		background-color: ${eventColorBooked};
		border-color: ${eventColorBooked};
	}

	.userbooked {
		${stripedBackground(eventColorAvailable)};
		border: none;
	}

	.awaitingmentorconfirmation {
		${stripedBackground(eventColorPending)};
		border: none;
	}

	.userbusy {
		background-color: ${eventColorUserBusy};
		border-color: ${eventColorUserBusy};
	}
`;
