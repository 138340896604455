import React, { useState, useContext } from "react";

import { ChatContext } from "components/Chat/ChatContext";
import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { OutputRow as MeetingRequestsData } from "api/getMeetingRequests";
import { OutputRow as MeetingRescheduleRequestsData } from "api/getMeetingRescheduleRequests";
import { useRemoteResource } from "hooks/useRemoteResource";
import { useAccountStatus } from "hooks/useAccountStatus";
import ErrorBanner from "components/ErrorBanner";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { withStripeElements } from "hocs/withStripeElements";

import Cancellation from "./Cancellation";
import Rescheduling from "./Rescheduling";
import RescheduleResponses from "./RescheduleResponses";
import SigningOff from "./SigningOff";
import MarkingPaid from "./MarkingPaid";
import Payment from "./Payment";
import MeetingsTable from "./MeetingsTable";

import { isMeeting } from "./isMeeting";
import { isMeetingRescheduleRequest } from "./isMeetingRescheduleRequest";
import { useFetchEvents } from "./useFetchEvents";

const MeetingsSection: React.FunctionComponent = () => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);
	const [{ meetingsSectionRefreshNum: refreshNum }] = useContext(
		DashboardContext
	);
	const { isMentor } = useAccountStatus();
	const [, setChatDetails] = useContext(ChatContext);

	const fetchEvents = useFetchEvents();

	const doWait = isSignedIn === undefined;

	const { isWaiting, isLoading, isError, output: events } = useRemoteResource<
		(
			| MeetingsDataFlattened
			| MeetingRequestsData
			| MeetingRescheduleRequestsData)[]
	>(fetchEvents, doWait, refreshNum, true);

	const [cancellingEvent, setCancellingEvent] = useState<
		MeetingsDataFlattened | undefined
	>(undefined);

	const [reschedulingMeeting, setReschedulingMeeting] = useState<
		MeetingsDataFlattened | undefined
	>(undefined);

	const [
		respondingToRescheduleRequest,
		setRespondingToRescheduleRequest
	] = useState<MeetingRescheduleRequestsData | undefined>(undefined);

	const [signingOffFinishedMeeting, setSigningOffFinishedMeeting] = useState<
		MeetingsDataFlattened | undefined
	>(undefined);

	const [payingForEvent, setPayingForEvent] = useState<
		MeetingsDataFlattened | undefined
	>(undefined);

	const [markingPaid, setMarkingPaid] = useState<
		MeetingsDataFlattened | undefined
	>();

	const meetings = events ? events.filter(isMeeting) : [];
	const meetingRescheduleRequests = events
		? events.filter(isMeetingRescheduleRequest)
		: [];

	return (
		<>
			<Payment
				payingForEvent={payingForEvent}
				setPayingForEvent={setPayingForEvent}
				isWaiting={isWaiting}
				isLoading={isLoading}
				isError={isError}
				events={meetings}
			/>

			<MarkingPaid markingPaid={markingPaid} setMarkingPaid={setMarkingPaid} />

			<SigningOff
				signingOffFinishedMeeting={signingOffFinishedMeeting}
				setSigningOffFinishedMeeting={setSigningOffFinishedMeeting}
				isWaiting={isWaiting}
				isLoading={isLoading}
				isError={isError}
				events={meetings}
			/>

			<Cancellation
				cancellingEvent={cancellingEvent}
				setCancellingEvent={setCancellingEvent}
				isWaiting={isWaiting}
				isLoading={isLoading}
				isError={isError}
				events={meetings}
			/>

			<Rescheduling
				reschedulingMeeting={reschedulingMeeting}
				setReschedulingMeeting={setReschedulingMeeting}
				isWaiting={isWaiting}
				isLoading={isLoading}
				isError={isError}
				events={meetings}
			/>

			<RescheduleResponses
				respondingToRescheduleRequest={respondingToRescheduleRequest}
				setRespondingToRescheduleRequest={setRespondingToRescheduleRequest}
				isWaiting={isWaiting}
				isLoading={isLoading}
				isError={isError}
				events={meetingRescheduleRequests}
			/>

			{isError ? <ErrorBanner /> : null}

			{/* TODO:WV:20230408:Add context-menu options to respond to reschedule requests, and indicate presence of active reschedule request in the table */}
			{!isError ? (
				<MeetingsTable
					isMentor={isMentor}
					events={events}
					uid={uid}
					isWaiting={isWaiting}
					isLoading={isLoading}
					onClickSignOff={setSigningOffFinishedMeeting}
					onClickPay={setPayingForEvent}
					onClickMarkPaid={setMarkingPaid}
					onClickChat={setChatDetails}
					onClickCancel={setCancellingEvent}
					onClickRequestReschedule={setReschedulingMeeting}
					onClickRespondToMeetingRescheduleRequest={
						setRespondingToRescheduleRequest
					}
				/>
			) : null}
		</>
	);
};

export default withStripeElements(MeetingsSection) as React.FunctionComponent;
