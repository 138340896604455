import React from "react";

import { formatPrice } from "services/formatPrice";

import { priceForTooltip } from "./priceForTooltip";
import { Tag } from "./PriceTag.styles";

interface Props {
	currency: string;
	price: number;
	locationCountryCode?: string;

	alternateCurrency?: string;
	approxPriceInAlternateCurrency?: number;
}

const PriceTag: React.FunctionComponent<Props> = ({
	currency,
	price,
	locationCountryCode,
	alternateCurrency,
	approxPriceInAlternateCurrency
}) => {
	return (
		<Tag
			title={
				price === 0
					? ``
					: `${priceForTooltip(price, currency)}${
							alternateCurrency && approxPriceInAlternateCurrency
								? ` (approx. ${priceForTooltip(
										approxPriceInAlternateCurrency,
										alternateCurrency
								  )})`
								: ``
					  }`
			}
		>
			{formatPrice({
				currencyCode: currency,
				amount: price,
				textIfAmountIsZero: "free session",
				locationCountryCode: locationCountryCode ? locationCountryCode : "GB"
			})}
		</Tag>
	);
};

export default PriceTag;
