import React, { useState, useEffect, useContext } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { WizardData } from "../PaymentWizard";

import { FieldOption } from "components/FieldSelect/FieldSelect";
import FieldSelect from "components/FieldSelect";
import { Output as GetPaymentMethodsOutput } from "api/getPaymentMethods";
import { usePaymentMethods } from "hooks/usePaymentMethods";
import {
	useGenerateOptionsFromExistingPaymentMethods,
	valueForNewCardOption
} from "./useGenerateOptionsFromExistingPaymentMethods";
import { PaymentWizardContext } from "../PaymentWizardProvider";

import { FieldSelectContainer } from "./StepChoosePaymentCard.styles";

function StepChoosePaymentCard({
	enableProgressToNextStep,
	disableProgressToNextStep,
	progressToNextStepNow
}: ModalWizardStepRenderProps<WizardData>) {
	const { existingPaymentMethodsRepeatNum } = useContext(PaymentWizardContext);

	const {
		isWaiting,
		isLoading,
		isError,
		output: existingPaymentMethods
	} = usePaymentMethods(existingPaymentMethodsRepeatNum);

	const generateOptionsFromExistingPaymentMethods = useGenerateOptionsFromExistingPaymentMethods();
	const [currentOptions, setCurrentOptions] = useState<FieldOption<string>[]>(
		generateOptionsFromExistingPaymentMethods(existingPaymentMethods)
	);

	const existingPaymentMethodsJSON = JSON.stringify(
		existingPaymentMethods === undefined ? null : existingPaymentMethods
	);
	useEffect(() => {
		const existingPaymentMethodsDecoded: GetPaymentMethodsOutput = JSON.parse(
			existingPaymentMethodsJSON
		);
		setCurrentOptions(
			generateOptionsFromExistingPaymentMethods(
				existingPaymentMethodsDecoded === null
					? undefined
					: existingPaymentMethodsDecoded
			)
		);
	}, [
		existingPaymentMethodsJSON,
		setCurrentOptions,
		generateOptionsFromExistingPaymentMethods
	]);

	useEffect(() => {
		const selectedOption = currentOptions.find(opt => !!opt.selected);
		const selectedOptionValue = selectedOption
			? selectedOption.value
			: undefined;

		if (selectedOptionValue !== undefined) {
			enableProgressToNextStep({
				stripePaymentMethodId:
					selectedOptionValue === valueForNewCardOption
						? undefined
						: selectedOptionValue
			});

			// If the change to available options has come about because the user has deleted
			// their last card, progress them to the next step now.
		} else if (
			currentOptions.length === 1 &&
			currentOptions[0].value === valueForNewCardOption
		) {
			progressToNextStepNow({ stripePaymentMethodId: undefined });
		} else {
			disableProgressToNextStep();
		}
	}, [
		progressToNextStepNow,
		enableProgressToNextStep,
		disableProgressToNextStep,
		currentOptions
	]);

	return (
		<>
			<FieldSelectContainer>
				<FieldSelect
					allowMultipleSelections={false}
					isLoading={isLoading || isWaiting}
					isError={isError}
					currentOptions={currentOptions}
					onNewCurrentOptions={setCurrentOptions}
				/>
			</FieldSelectContainer>
		</>
	);
}

export default StepChoosePaymentCard;
