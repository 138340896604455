import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { MeetingCalendarEvent } from "components/MeetingCalendar/MeetingCalendar";
import { formatDateForApi } from "services/formatDateForApi";
import { ValidationCallback } from "validation/validate";

interface Params {
	meetingId: string;
	reschedulerUid: string;
	message?: string;
	newSessionEvent: Pick<MeetingCalendarEvent, "start" | "end">;
}

type Output = {};
const flagValidationErrors: ValidationCallback<Output> = () => {};

export function sendMeetingRescheduleRequest(
	{ meetingId, reschedulerUid, message, newSessionEvent }: Params,
	auth: Auth
): ApiRequest<Output> {
	return makeApiRequest("meetingRescheduleRequest", {
		auth,
		data: {
			post: {
				meetingId,
				reschedulerUid,
				...(message ? { message } : {}),
				newSessionEvent: {
					startUTC: formatDateForApi(newSessionEvent.start),
					endJustBeforeUTC: formatDateForApi(newSessionEvent.end)
				}
			}
		},
		flagValidationErrors
	});
}
