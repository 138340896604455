import React, { useState, useCallback } from "react";
import {
	faPlus,
	faPen,
	faCheck,
	faTrash,
	faPlusCircle
} from "@fortawesome/free-solid-svg-icons";

import ModalConfirm from "components/ModalConfirm";
import Button from "components/Button";
import Table from "components/Table";
import { thresholdScreenSizeForKeyValueFormat } from "components/Table/Table";
import NewRowWizard from "./NewRowWizard";
import {
	ModalWizardStep,
	OnCompleteOutput
} from "components/ModalWizard/ModalWizard";
import { ValidationCallback } from "validation/validate";
import ErrorBanner from "components/ErrorBanner";
import ModalPleaseWait from "components/ModalPleaseWait";
import { useScreenSizeIsMax } from "hooks/useScreenSizeIsMax";

import {
	EditableTableContainer,
	EditableTableExplanation
} from "./EditableTable.styles";

export type OnEdit<T> = (rows: { old?: T; new?: T }[]) => OnCompleteOutput;

type EditableTableProps<DataInAlreadyExistingRow, DataInFreshlyEnteredRow> = {
	cols: EditableTableColumn<DataInAlreadyExistingRow>[];
	rowIsDisabled?: (row: DataInAlreadyExistingRow) => boolean;
	rows: {
		key: string;
		isReadOnly?: boolean;
		data: DataInAlreadyExistingRow;
	}[];
	onEdit: OnEdit<DataInAlreadyExistingRow>;
	dataDescriptionSingular: string;
	dataDescriptionPlural: string;
	onNew: (data: DataInFreshlyEnteredRow) => OnCompleteOutput;
	newRowWizard?: NewRowWizardConfig<DataInFreshlyEnteredRow>;
	allowDeleteRows: boolean;
	isLoading: boolean;
	isWaiting: boolean;
	isError: boolean;
	waitModalTitle: string;
	waitModalIsShowing: boolean;
	title?: string;
	explanation?: string;
};

type EditableTableColumn<DataInAlreadyExistingRow> = {
	key: string;
	rowsKey?: string;
	heading: string;
	field?: (renderProps: {
		isEditing: boolean;
		onEdit: OnEdit<DataInAlreadyExistingRow>;
		row: DataInAlreadyExistingRow;
		rowKey: keyof DataInAlreadyExistingRow;
		isReadOnly: boolean;
	}) => React.ReactNode;
};

type NewRowWizardConfig<DataInFreshlyEnteredRow> = {
	steps: ModalWizardStep<DataInFreshlyEnteredRow>[];
	validateNewRowData: ValidationCallback<DataInFreshlyEnteredRow>;
	initialData: Partial<DataInFreshlyEnteredRow>;
};

function EditableTable<
	DataInAlreadyExistingRow,
	DataInFreshlyEnteredRow = DataInAlreadyExistingRow
>({
	cols,
	rowIsDisabled,
	rows,
	onEdit,
	dataDescriptionSingular,
	dataDescriptionPlural,
	onNew,
	newRowWizard,
	allowDeleteRows,
	isLoading,
	isWaiting,
	isError,
	waitModalTitle,
	waitModalIsShowing,
	title,
	explanation
}: EditableTableProps<DataInAlreadyExistingRow, DataInFreshlyEnteredRow>) {
	const [newRowWizardIsInProgress, setNewRowWizardIsInProgress] = useState(
		false
	);

	const [rowsEditing, setRowsEditing] = useState<string[]>([]);
	const [rowDeleting, setRowDeleting] = useState<
		DataInAlreadyExistingRow | undefined
	>();

	const handleClickAddNewRow = useCallback(
		(e: any) => {
			e.preventDefault();
			setNewRowWizardIsInProgress(true);
		},
		[setNewRowWizardIsInProgress]
	);

	const tableWouldUseKeyValueFormat = useScreenSizeIsMax(
		thresholdScreenSizeForKeyValueFormat
	);

	const actualRows = rows.length
		? rows.map(({ key: rowKey, data: rowData, isReadOnly }, i) => {
				const isEditingThisRow = rowsEditing.includes(rowKey);
				const onEditThisRow = (
					rows: {
						old?: DataInAlreadyExistingRow | undefined;
						new?: DataInAlreadyExistingRow | undefined;
					}[]
				) => {
					const req = onEdit(rows);
					setRowsEditing(oldRowsEditing =>
						oldRowsEditing.filter(k => k !== rowKey)
					);
					return req;
				};
				const lineThrough = rowIsDisabled ? rowIsDisabled(rowData) : false;
				const data = cols.reduce((agg, cur) => {
					const { key, rowsKey, field } = cur;
					const rowKey = (rowsKey
						? rowsKey
						: key) as keyof DataInAlreadyExistingRow;
					const data =
						rowKey in rowData ? rowData[rowKey as keyof typeof rowData] : "";
					const cellContents = field
						? field({
								onEdit: onEditThisRow,
								isEditing: isEditingThisRow,
								row: rowData,
								rowKey,
								isReadOnly: !!isReadOnly
						  })
						: data;
					return {
						...agg,
						[key]: cellContents
					};
				}, {});

				const editButton = {
					onClick: () =>
						setRowsEditing(oldRowsEditing =>
							oldRowsEditing.includes(rowKey)
								? oldRowsEditing.filter(k => k !== rowKey)
								: [...oldRowsEditing, rowKey]
						),
					label: isEditingThisRow ? "Done" : "Edit",
					icon: isEditingThisRow ? faCheck : faPen
				};

				const action = isReadOnly ? undefined : editButton;

				const menu =
					!isReadOnly && allowDeleteRows
						? [
								...(tableWouldUseKeyValueFormat
									? [
											{
												...editButton,
												isDangerous: false
											}
									  ]
									: []),
								{
									icon: faTrash,
									label: "Delete",
									onClick: () => setRowDeleting(rowData),
									isDangerous: true
								}
						  ]
						: undefined;

				return {
					lineThrough,
					data,
					action,
					menu
				};
		  })
		: [];

	return (
		<>
			<ModalPleaseWait title={waitModalTitle} isOpen={waitModalIsShowing} />

			{title || (!!newRowWizard && tableWouldUseKeyValueFormat) ? (
				<h2>
					{title ? title : ""}
					{!!newRowWizard && tableWouldUseKeyValueFormat ? (
						<>
							<Button
								label="Add entry"
								icon={faPlusCircle}
								onClick={handleClickAddNewRow}
								fitWidth
								isTertiary
							/>
						</>
					) : null}
				</h2>
			) : null}

			{explanation ? (
				<EditableTableExplanation>{explanation}</EditableTableExplanation>
			) : null}

			{isError ? <ErrorBanner /> : null}
			{isError || isWaiting ? null : (
				<EditableTableContainer isLoading={isLoading}>
					<ModalConfirm
						isOpen={!!rowDeleting}
						onOK={() => {
							if (rowDeleting) {
								onEdit([{ old: rowDeleting }]);
							}
							setRowDeleting(undefined);
						}}
						onCancel={() => setRowDeleting(undefined)}
						okText="Delete"
						title="Please confirm deletion"
						helptext="This cannot be undone"
						size="small"
					/>
					<Table
						cols={cols}
						rows={actualRows}
						emptyText={`You have not saved any ${dataDescriptionPlural}`}
					/>
					{!!newRowWizard && !tableWouldUseKeyValueFormat ? (
						<Button
							fitWidth
							onClick={handleClickAddNewRow}
							icon={faPlus}
							label={`Add ${dataDescriptionSingular}`}
						/>
					) : null}

					{newRowWizard ? (
						<NewRowWizard<DataInFreshlyEnteredRow>
							isInProgress={newRowWizardIsInProgress}
							onCancel={() => setNewRowWizardIsInProgress(false)}
							onComplete={data => {
								const req = onNew(data);
								setNewRowWizardIsInProgress(false);
								return req;
							}}
							steps={newRowWizard.steps}
							validateNewRowData={newRowWizard.validateNewRowData}
							initialData={newRowWizard.initialData}
						/>
					) : null}
				</EditableTableContainer>
			)}
		</>
	);
}

export default EditableTable;
