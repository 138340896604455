import React, { useEffect, useState } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import {
	WizardData,
	PackagePrice
} from "components/PageUserProfile/wizards/BuyPackageWizard/BuyPackageWizard";

import { FieldOption } from "components/FieldSelect/FieldSelect";
import FieldSelect from "components/FieldSelect";
import { OutputRow as PackagePricesData } from "api/getPackagePrices";
import { useAccountStatus } from "hooks/useAccountStatus";
import { formatPackagePrice } from "services/formatPackagePrice";
import terminology from "terminology.json";

import { useSessionTypesAndPrices } from "hooks/useSessionTypesAndPrices";
import { FieldSelectContainer } from "./StepChoosePackagePrice.styles";

const defaultCountryCodeUntilAccountStatusLoads = "GB";

function StepChoosePackagePrice({
	setHaveSystemError,
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData: { mentorId, sessionType }
}: ModalWizardStepRenderProps<WizardData>) {
	const {
		isWaiting: sessionTypesAndPricesWaiting,
		isLoading: sessionTypesAndPricesLoading,
		isError: sessionTypesAndPricesError,
		output: sessionTypesAndPrices
	} = useSessionTypesAndPrices(mentorId);

	const packagePrices =
		sessionTypesAndPrices.packagePrices && sessionType
			? sessionTypesAndPrices.packagePrices.filter(
					packagePrice => packagePrice.sessionTypeId === sessionType.id
			  )
			: [];

	const [selectedPackagePrice, setPackagePrice] = useState<
		PackagePrice | undefined
	>();

	useEffect(() => {
		if (sessionTypesAndPricesError) {
			setHaveSystemError(true);
		}
		if (selectedPackagePrice) {
			enableProgressToNextStep({ packagePrice: selectedPackagePrice });
		} else {
			disableProgressToNextStep();
		}
	}, [
		setHaveSystemError,
		enableProgressToNextStep,
		disableProgressToNextStep,
		selectedPackagePrice,
		sessionTypesAndPricesError
	]);

	const { locationCountryCode } = useAccountStatus();

	const [currentOptions, setCurrentOptions] = useState<
		FieldOption<PackagePrice>[]
	>(
		generateOptionsFromPackagePricesData(
			packagePrices,
			locationCountryCode
				? locationCountryCode
				: defaultCountryCodeUntilAccountStatusLoads
		)
	);
	const numCurrentOptions = currentOptions.length;
	const packagePricesStr = JSON.stringify(packagePrices);
	useEffect(() => {
		const prices = JSON.parse(packagePricesStr);
		if (numCurrentOptions === 0) {
			setCurrentOptions(
				generateOptionsFromPackagePricesData(
					prices,
					locationCountryCode
						? locationCountryCode
						: defaultCountryCodeUntilAccountStatusLoads
				)
			);
		}
	}, [
		packagePricesStr,
		numCurrentOptions,
		setCurrentOptions,
		locationCountryCode
	]);

	useEffect(() => {
		const selectedOption = currentOptions.find(opt => !!opt.selected);
		setPackagePrice(selectedOption ? selectedOption.value : undefined);
	}, [currentOptions]);

	return (
		<FieldSelectContainer>
			<FieldSelect
				allowMultipleSelections={false}
				isLoading={sessionTypesAndPricesLoading || sessionTypesAndPricesWaiting}
				isError={sessionTypesAndPricesError}
				currentOptions={currentOptions}
				onNewCurrentOptions={setCurrentOptions}
			/>
		</FieldSelectContainer>
	);
}

export default StepChoosePackagePrice;

function generateOptionsFromPackagePricesData(
	data: PackagePricesData[],
	locationCountryCode: string
) {
	return data
		.sort((a, b) => a.price - b.price)
		.map(packagePrice => {
			const value = getValueFromPackagePrice(packagePrice);
			return {
				id: JSON.stringify(value),
				value,
				text:
					formatPackagePrice({
						packagePrice,
						locationCountryCode
					}) +
					(packagePrice.guestUid
						? ` - ${terminology.mentee}-specific price`
						: "")
			};
		});
}

function getValueFromPackagePrice({
	id,
	price,
	currency,
	sessionDurationMinutes,
	numMeetings
}: PackagePrice) {
	return {
		id,
		price,
		currency,
		sessionDurationMinutes,
		numMeetings
	};
}
