import { useCallback } from "react";
import {
	renderCustomComponent,
	addUserMessage,
	addResponseMessage
} from "react-chat-widget";
import useSound from "use-sound";

// @ts-ignore
import messageReceivedSfx from "resources/message-received.mp3";

import { OutputRow as ChatHistoryData } from "api/getRecentChatHistory";
import ChatWidgetHighlightedMessage from "components/ChatWidget/ChatWidgetHighlightedMessage";
import ChatWidgetTimestamp from "components/ChatWidget/ChatWidgetTimestamp";
import { formatDateForApi } from "services/formatDateForApi";

export interface MessageForChat extends ChatHistoryData {
	isHighlighted: boolean;
}

export function useAddMessageToChat(uid: string | undefined) {
	const [playMessageReceived] = useSound(messageReceivedSfx, { volume: 0.25 });

	const addMessageToChat = useCallback(
		(message: MessageForChat, socket: SocketIOClient.Socket) => {
			if (!uid) {
				throw new Error("Could not determine user ID");
			}

			if (message.isHighlighted) {
				renderCustomComponent(ChatWidgetHighlightedMessage, {
					messageText: message.message,
					isUserMessage: message.senderUid === uid
				});
			} else {
				if (message.senderUid === uid) {
					addUserMessage(message.message, message.id);
				} else {
					addResponseMessage(message.message, message.id);
				}
			}

			renderCustomComponent(ChatWidgetTimestamp, {
				date: message.dateSentUTC
			});

			if (message.senderUid !== uid && !message.isRead) {
				playMessageReceived();
				socket.emit("chatMessageRead", {
					...message,
					dateSentUTC: formatDateForApi(message.dateSentUTC)
				});
			}
		},
		[uid, playMessageReceived]
	);

	return addMessageToChat;
}
