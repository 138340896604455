import React, { useCallback, useContext, useMemo, useState } from "react";
import { XOR } from "ts-xor";
import { StripeCardElement } from "@stripe/stripe-js";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { AlertContext } from "components/AlertProvider/AlertProvider";
import ModalPleaseWait from "components/ModalPleaseWait";
import { ApiRequest } from "api/ApiRequest";
import { makeDummyRequest } from "api/makeDummyRequest";
import ModalWizard from "components/ModalWizard";
import StepChoosePaymentProvider from "./StepChoosePaymentProvider";
import StepChoosePaymentCard from "./StepChoosePaymentCard";
import StepCardDetails from "./StepCardDetails";
import StepInstructions from "./StepInstructions";
import { captureException } from "services/captureException";
import { useAccountStatus } from "hooks/useAccountStatus";
import { ValidationCallback } from "validation/validate";
import { formatPrice } from "services/formatPrice";
import {
	CardBillingDetails,
	Output as UsePayWithNewCardOutput,
	usePayWithNewCard
} from "hooks/usePayWithNewCard";
import { usePayWithSavedPaymentMethod } from "hooks/usePayWithSavedPaymentMethod";
import { isValidCardDetailsFields } from "hooks/usePayWithNewCard";

import { PaymentWizardContext } from "./PaymentWizardProvider";
import { withPaymentWizardProvider } from "./withPaymentWizardProvider";

interface Props {
	mentorId?: string;
	metadata?: XOR<
		{
			meetingId: string;
		},
		{ packageId: string }
	>;
	paymentDetails?: {
		price: number;
		currency: string;
	};
	onCancel: () => void;
	onSuccess: () => void;
	onFailed: () => void;
}

export type PaymentProvider = "card" | "mbway" | "wise" | "custom";

interface CompletedWizardDataBase {
	mentorId: string;
	userHasSavedCards: boolean;
	meetingId?: string;
	packageId?: string;
	card: StripeCardElement;
	paymentDetails: Props["paymentDetails"];
	paymentProvider: PaymentProvider;
}

interface CompletedWizardDataWithSavedCard extends CompletedWizardDataBase {
	stripePaymentMethodId: string | undefined;
}

interface CompletedWizardDataWithNewCard extends CompletedWizardDataBase {
	cardBillingDetails: CardBillingDetails;
	shouldSaveCardDetails: boolean;
}

type CompletedWizardData = XOR<
	CompletedWizardDataWithSavedCard,
	CompletedWizardDataWithNewCard
>;

export type WizardData = Partial<CompletedWizardData>;

const PaymentWizard: React.FunctionComponent<Props> = ({
	mentorId,
	metadata,
	paymentDetails,
	onCancel: onCancelWizard,
	onSuccess,
	onFailed
}) => {
	const [{ uid }] = useContext(AuthContext);
	const [addAlert] = useContext(AlertContext);

	const payWithNewCard = usePayWithNewCard();
	const payWithSavedPaymentMethod = usePayWithSavedPaymentMethod();

	const [showingWaitModal, setShowingWaitModal] = useState(false);

	const handleSubmit = useCallback(
		(data: CompletedWizardData) => {
			const {
				card,
				paymentProvider,
				cardBillingDetails,
				shouldSaveCardDetails,
				stripePaymentMethodId,
				meetingId,
				packageId
			} = data;

			if (!uid) {
				throw new Error("No requester uid");
			}

			let req: ApiRequest<UsePayWithNewCardOutput> | undefined;

			if (paymentProvider === "card") {
				setShowingWaitModal(true);

				if (stripePaymentMethodId) {
					const argBase = {
						stripePaymentMethodId
					};

					const arg = meetingId
						? { ...argBase, meetingId }
						: packageId
						? { ...argBase, packageId }
						: { ...argBase };
					if (!("meetingId" in arg || "packageId" in arg)) {
						throw new Error(
							"Could not find any suitable metadata to send to Stripe"
						);
					}

					req = payWithSavedPaymentMethod(arg);
				} else if (cardBillingDetails) {
					const argBase = {
						card,
						cardBillingDetails,
						shouldSaveCardDetails: shouldSaveCardDetails ? true : false
					};
					const arg = meetingId
						? { ...argBase, meetingId }
						: packageId
						? { ...argBase, packageId }
						: { ...argBase };
					if (!("meetingId" in arg || "packageId" in arg)) {
						throw new Error(
							"Could not find any suitable metadata to send to Stripe"
						);
					}

					req = payWithNewCard(arg);
				} else {
					throw new Error(
						"Payment type was 'card' but no card details could be found"
					);
				}
			} else {
				req = makeDummyRequest<UsePayWithNewCardOutput>({}, "payingOffline");
			}

			const ready = req.ready
				.then(response => {
					setShowingWaitModal(false);
					const {
						stripePaymentIntent = undefined,
						errorMessage = undefined,
						stripeError = undefined
					} = response ? response : {};

					if (
						response === undefined ||
						errorMessage ||
						stripeError ||
						!stripePaymentIntent
					) {
						throw captureException(
							`Payment failed.  ${JSON.stringify({
								errorMessage,
								stripeError,
								stripePaymentIntent
							})}`,
							{
								evtType: "paymentFailed",
								extra: {
									response,
									errorMessage,
									stripeError,
									stripePaymentIntent
								}
							}
						);
					}

					addAlert({ contents: "Payment received - thank you." });
					onSuccess();
				})
				.catch(e => {
					if (e && e.name && e.name !== "AlreadyCapturedError") {
						captureException(e, {
							evtType: "paymentFailed",
							extra: {
								wizardData: data,
								originalError: e
							}
						});
					}
					addAlert({
						title: "Payment problem",
						contents: `Unfortunately there was an error taking your payment.  Please try again and / or contact the teacher to arrange a workaround.`
					});
					onFailed();
				});

			return {
				ready,
				abort: () => (req ? req.abort() : undefined),
				aborted: () => (req ? req.aborted() : false)
			};
		},
		[
			uid,
			payWithNewCard,
			payWithSavedPaymentMethod,
			addAlert,
			onFailed,
			onSuccess,
			setShowingWaitModal
		]
	);

	const validateCompletedData: ValidationCallback<WizardData> = useCallback(
		(input, { flag, checkField }) => {
			const {
				card,
				paymentProvider,
				stripePaymentMethodId,
				cardBillingDetails
			} = input;

			checkField("paymentProvider", {
				type: "string",
				followUpChecks: ({ value, findInvalid }) => {
					if (!["card", "mbway", "wise", "custom"].includes(value)) {
						findInvalid("Invalid payment provider");
					}
				}
			});

			if (paymentProvider === "card") {
				checkField("stripePaymentMethodId", {
					type: "string",
					isRequired: false
				});
				if (cardBillingDetails) {
					if (!card) {
						flag("card", "missing");
					}
					if (!cardBillingDetails.cardDetailsAreComplete) {
						flag("cardBillingDetails", "incomplete card details");
					}
					if (!isValidCardDetailsFields(cardBillingDetails)) {
						flag("cardBillingDetails", "invalid card and/or billing details");
					}
				}
				if (!(stripePaymentMethodId || cardBillingDetails)) {
					const errMsg =
						"must have either stripePaymentMethodId or cardBillingDetails";
					flag("stripePaymentMethodId", errMsg);
					flag("cardBillingDetails", errMsg);
				}
				if (stripePaymentMethodId && cardBillingDetails) {
					const errMsg =
						"cannot have both stripePaymentMethodId and cardBillingDetails";
					flag("stripePaymentMethodId", errMsg);
					flag("cardBillingDetails", errMsg);
				}
			}
		},
		[]
	);

	const { locationCountryCode } = useAccountStatus();

	const nextButtonTextOnCardPaymentSteps = useCallback(
		(wizardData: WizardData) => {
			const { paymentDetails } = wizardData;

			if (paymentDetails) {
				return `Confirm and pay ${
					locationCountryCode
						? formatPrice({
								currencyCode: paymentDetails.currency,
								amount: paymentDetails.price,
								locationCountryCode
						  })
						: paymentDetails.currency + " " + paymentDetails.price
				}`;
			} else {
				return "Confirm and pay";
			}
		},
		[locationCountryCode]
	);

	const steps = useMemo(
		() => [
			{
				key: "payment-provider",
				title: "How would you like to pay?",
				component: StepChoosePaymentProvider
			},

			/* TODO:WV:20230501:Test this step*/
			/* TODO:WV:20230501:I don't think there is any way at present to use a new card if the user has saved card*/
			{
				key: "payment-card",
				title: "Choose payment card",
				component: StepChoosePaymentCard,
				skipThisStep: (wizardData: WizardData) => {
					if (
						wizardData.paymentProvider &&
						wizardData.paymentProvider !== "card"
					) {
						return true;
					}

					if (!wizardData.userHasSavedCards) {
						return true;
					}

					return false;
				},
				nextButtonText: nextButtonTextOnCardPaymentSteps
			},
			{
				key: "card-details",
				title: "Enter card details",
				component: StepCardDetails,
				skipThisStep: (wizardData: WizardData) => {
					if (
						wizardData.paymentProvider &&
						wizardData.paymentProvider !== "card"
					) {
						return true;
					}

					if (wizardData.stripePaymentMethodId) {
						return true;
					}

					return false;
				},
				nextButtonText: nextButtonTextOnCardPaymentSteps
			},
			{
				key: "instructions",
				title: "Instructions",
				component: StepInstructions,
				skipThisStep: (wizardData: WizardData) => {
					if (
						wizardData.paymentProvider &&
						wizardData.paymentProvider === "card"
					) {
						return true;
					}
					return false;
				},
				nextButtonText: () => "OK"
			}
		],
		[nextButtonTextOnCardPaymentSteps]
	);

	const { meetingId = undefined, packageId = undefined } = metadata
		? metadata
		: {};
	const { userHasSavedCards } = useContext(PaymentWizardContext);

	const initialData = useMemo(
		() => ({
			mentorId,
			meetingId,
			packageId,
			paymentDetails,
			userHasSavedCards
		}),
		[mentorId, meetingId, packageId, paymentDetails, userHasSavedCards]
	);

	if (
		!(mentorId && metadata && paymentDetails && userHasSavedCards !== undefined)
	) {
		return null;
	}

	return (
		<>
			<ModalWizard<CompletedWizardData>
				isInProgress={!!paymentDetails}
				cancelButtonText="Cancel payment"
				onCancel={onCancelWizard}
				onComplete={handleSubmit}
				description="Payment"
				validateCompletedData={validateCompletedData}
				initialData={initialData}
				steps={steps}
			/>

			{/* The modal needs to come after the ModalWizard in the DOM so that it overlays it (rather than being obscured by it) */}
			<ModalPleaseWait title="Making payment" isOpen={showingWaitModal} />
		</>
	);
};

export default withPaymentWizardProvider(PaymentWizard);
