import React, { useEffect, useState, useContext } from "react";
import moment from "moment-timezone";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import FieldDate from "components/FieldDate";
import { validateCompleteSelection } from "components/FieldDate/validateCompleteSelection";
import { validate } from "validation/validate";
import { DataInAnyRow } from "components/PageDashboard/ExternalCommitmentsSection/ExternalCommitmentsTable/ExternalCommitmentsTable";
import {
	getEmptySelection,
	Selection,
	getDateFromSelection,
	getSelectionFromDate
} from "components/FieldDate/FieldDateSelection";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

function StepDateFrom({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [currentSelection, setCurrentSelection] = useState<Selection>(
		wizardData.dateStartsInRowTimezone
			? wizardData.dateStartsInRowTimezone
			: wizardData.timezone
			? getSelectionFromDate(
					moment()
						.tz(wizardData.timezone)
						.toDate(),
					wizardData.timezone
			  )
			: getEmptySelection()
	);

	const [{ uid }] = useContext(AuthContext);

	useEffect(() => {
		if (
			!validate(currentSelection, {
				doValidate: validateCompleteSelection,
				auth: { uid }
			})
		) {
			disableProgressToNextStep();
			return;
		}

		enableProgressToNextStep({
			dateStartsInRowTimezone: currentSelection
		});
	}, [
		enableProgressToNextStep,
		disableProgressToNextStep,
		currentSelection,
		uid
	]);

	return (
		<FieldDate
			currentSelection={currentSelection}
			onNewSelection={setCurrentSelection}
			mustBeAfter={
				wizardData.timezone
					? getSelectionFromDate(
							moment()
								.tz(wizardData.timezone)
								.subtract(1, "day")
								.toDate(),
							wizardData.timezone
					  )
					: undefined
			}
			mustBeBefore={
				wizardData.dateEndsJustBeforeInRowTimezone && wizardData.timezone
					? getSelectionFromDate(
							moment(
								getDateFromSelection(
									wizardData.dateEndsJustBeforeInRowTimezone,
									wizardData.timezone
								)
							)
								.add(1, "day")
								.toDate(),
							wizardData.timezone
					  )
					: undefined
			}
		/>
	);
}

export default StepDateFrom;
