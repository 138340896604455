import React from "react";

import Modal from "components/Modal";
import ModalScrollingContents from "components/Modal/ModalScrollingContents";
import ModalButtons from "components/Modal/ModalButtons";
import ModalHeading from "components/Modal/ModalHeading";
import { useModalStyles } from "hooks/useModalStyles";

interface ModalAlertProps {
	isOpen: boolean;
	onDismiss: () => void;
	okText?: string;
	title?: string;
	onContents?: (node: any) => void;
	buttonsFitWidth?: boolean;
}

const ModalAlert: React.FunctionComponent<ModalAlertProps> = ({
	isOpen,
	onDismiss,
	okText = "OK",
	title = "Message",
	onContents,
	buttonsFitWidth = false,
	children
}) => {
	const modalStyles = useModalStyles("small");

	return (
		<Modal isOpen={isOpen} style={modalStyles} onRequestClose={onDismiss}>
			<ModalHeading>{title}</ModalHeading>
			<ModalScrollingContents ref={onContents}>
				{children}
			</ModalScrollingContents>

			<ModalButtons
				onOK={onDismiss}
				okText={okText}
				onCancel={() => undefined}
				cancelText={null}
				fitWidth={buttonsFitWidth}
			/>
		</Modal>
	);
};

export default ModalAlert;
