import React from "react";
import styled from "styled-components";
import Collapsible from "components/Collapsible";
import Anchor from "components/Anchor";
import { Props as CollapsibleProps } from "components/Collapsible/Collapsible";

const NarrowSectionInner = styled(Collapsible)`
	padding-bottom: 2em;
	display: flex;
	flex-direction: column;

	section {
		height: 100%;
	}
`;

type Props = Omit<CollapsibleProps, "tagName"> & {
	anchorName?: string;
};

const NarrowSection: React.FunctionComponent<Props> = ({
	children,
	anchorName,
	className,
	...otherProps
}) => {
	return (
		<div className="col-6 col-12-small">
			{anchorName ? <Anchor id={anchorName} /> : null}
			<NarrowSectionInner
				className={className ? className : ""}
				tagName="section"
				{...otherProps}
			>
				{children}
			</NarrowSectionInner>
		</div>
	);
};

export default NarrowSection;
