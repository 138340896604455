import React, { useEffect, useState } from "react";

import FieldSelect from "components/FieldSelect";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import { DataInAnyRow } from "components/PageDashboard/ExternalCommitmentsSection/ExternalCommitmentsTable/ExternalCommitmentsTable";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import { getBooleanOptions } from "services/getBooleanOptions";

function StepIsExternallyBookedMeeting({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData,
	...rest
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [currentOptions, setCurrentOptions] = useState<FieldOption<boolean>[]>(
		getBooleanOptions()
	);

	useEffect(() => {
		const selectedOption = currentOptions.find(
			currentOptions => !!currentOptions.selected
		);
		if (!selectedOption) {
			disableProgressToNextStep();
			return;
		}

		enableProgressToNextStep({
			isExternallyBookedMeeting: selectedOption.value
		});
	}, [enableProgressToNextStep, disableProgressToNextStep, currentOptions]);

	return (
		<FieldSelect
			allowMultipleSelections={false}
			currentOptions={currentOptions}
			onNewCurrentOptions={setCurrentOptions}
			useCheckboxes={true}
		/>
	);
}

export default StepIsExternallyBookedMeeting;
