// @ts-ignore
import imageScaler from "tiny-canvas-image-scaler";

interface Params {
	dataURL: string;
	quality: number;
	maxWidth: number;
	format: "image/jpeg" | "image/png";
	maxURILength?: number;
}

export async function shrinkImage({
	dataURL,
	quality,
	maxWidth,
	format,
	maxURILength = 3145728
}: Params) {
	let working: string = "";
	do {
		working = await imageScaler.getScaledUrl(dataURL, {
			maxWidth: maxWidth,
			imageQuality: quality,
			imageFormat: format
		});

		if (quality < 0.001) {
			throw new Error("Could not shrink image to an appropriate size");
		}

		quality *= 0.9;
	} while (working.length > maxURILength);
	return working + "";
}
