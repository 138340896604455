import { determineEventType } from "./determineEventType";

import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { OutputRow as MeetingRequestsData } from "api/getMeetingRequests";
import { OutputRow as MeetingRescheduleRequestsData } from "api/getMeetingRescheduleRequests";

export function isMeeting(
	event:
		| MeetingsDataFlattened
		| MeetingRequestsData
		| MeetingRescheduleRequestsData
): event is MeetingsDataFlattened {
	const { isMeeting } = determineEventType(event);
	return isMeeting;
}
