import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { OutputRow as MeetingRequestsData } from "api/getMeetingRequests";
import { OutputRow as MeetingRescheduleRequestsData } from "api/getMeetingRescheduleRequests";

export function determineEventType(
	event:
		| MeetingsDataFlattened
		| MeetingRequestsData
		| MeetingRescheduleRequestsData
) {
	const isMeetingRescheduleRequest = "originalDateStartsUTC" in event;
	const isMeetingRequest = "isAccepted" in event && !isMeetingRescheduleRequest;
	const isMeeting = !(isMeetingRequest || isMeetingRescheduleRequest);

	return {
		isMeeting,
		isMeetingRequest,
		isMeetingRescheduleRequest
	};
}
