import { findTimeElapsedSinceMidnight } from "utils/datesAndTimes/findTimeElapsedSinceMidnight";
import { indexOfLargest } from "utils/indexOfLargest";

export function findLatestTime(
	dates: Date[],
	midnightIsLate: boolean
): moment.Duration | undefined {
	if (dates.length === 0) {
		return undefined;
	}

	const durations = dates.map(findTimeElapsedSinceMidnight);
	if (midnightIsLate) {
		for (const duration of durations) {
			if (duration.hours() === 0) {
				duration.add(24, "hours");
			}
		}
	}

	const ms = durations.map(duration => duration.as("milliseconds"));

	return durations[indexOfLargest(ms)];
}
