import React, { useState, useContext, useCallback } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import ActionButtons from "components/ActionButtons";
import { AlertContext } from "components/AlertProvider/AlertProvider";
import ModalPleaseWait from "components/ModalPleaseWait";
import ImageUploader from "components/ImageUploader";
import ErrorBanner from "components/ErrorBanner";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import dummyAvatarImage from "resources/dummy-avatar.jpeg";
import { saveProfilePicture } from "api/saveProfilePicture";
import { shrinkImage } from "services/shrinkImage";
import { useUserProfile } from "hooks/useUserProfile";

// TODO:WV:20210409:Rename to ProfilePictureSection
const ProfilePhotoSection: React.FunctionComponent = () => {
	const [isSaving, setIsSaving] = useState(false);

	const [{ uid }] = useContext(AuthContext);

	const [addAlert] = useContext(AlertContext);

	const [repeat, setRepeat] = useState(1);
	const { isLoading, isWaiting, isError, output: userProfile } = useUserProfile(
		{ uid, cacheInMemory: false, repeat }
	);

	const onDrop = useCallback(
		async (picture: any) => {
			if (!uid) {
				return;
			}
			setIsSaving(true);

			const profilePictureDataURI = await shrinkImage({
				dataURL: picture,
				quality: 0.9,
				maxWidth: 200,
				format: "image/jpeg"
			});

			try {
				await saveProfilePicture(
					{
						uid,
						profilePictureDataURI
					},
					{ uid }
				).ready;
			} catch (e) {
				addAlert({
					contents:
						"Sorry, your photo could not be saved due to an error.  Please try again later."
				});
			}
			setRepeat(new Date().getTime());
			setIsSaving(false);
		},
		[uid, setIsSaving, addAlert, setRepeat]
	);

	const handleFail = useCallback(
		errMsg => {
			const contents =
				errMsg === "File too big"
					? `Sorry, that photo is too large to upload.  Please try again with a smaller one, or try again using a different browser.`
					: `Sorry, there was an error reading the file.  Please try again later, or using a different browser.`;
			addAlert({
				contents
			});
		},
		[addAlert]
	);

	// TODO:WV:20210409:'Loading' image while loading
	return (
		<>
			<ModalPleaseWait isOpen={isSaving} title="Saving" />
			{isError ? <ErrorBanner /> : null}
			{isLoading || isWaiting ? null : (
				<ImageUploader
					currentImageSrc={
						userProfile && userProfile.avatarUrl
							? userProfile.avatarUrl
							: dummyAvatarImage
					}
					onChange={onDrop}
					cropToAspectRatio={1}
					onFail={handleFail}
					editButton={props => (
						<ActionButtons
							secondary={{
								onClick: e => props.onClick(e as any),
								icon: faPen,
								label: "Edit"
							}}
						/>
					)}
				/>
			)}
		</>
	);
};

export default ProfilePhotoSection;
