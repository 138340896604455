import { ApiRequest } from "api/ApiRequest";

export function makeDummyRequest<T>(
	output: T,
	endpoint: string
): ApiRequest<T> {
	let hasBeenAborted = false;
	return {
		ready: Promise.resolve().then(() => output),
		abort: () => {
			hasBeenAborted = true;
			return undefined;
		},
		aborted: () => hasBeenAborted,
		type: `dummy-${endpoint}`
	};
}
