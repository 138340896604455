import { determineEventType } from "./determineEventType";

import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { OutputRow as MeetingRequestsData } from "api/getMeetingRequests";
import { OutputRow as MeetingRescheduleRequestsData } from "api/getMeetingRescheduleRequests";

export function isMeetingRescheduleRequest(
	event:
		| MeetingsDataFlattened
		| MeetingRequestsData
		| MeetingRescheduleRequestsData
): event is MeetingRescheduleRequestsData {
	const { isMeetingRescheduleRequest } = determineEventType(event);
	return isMeetingRescheduleRequest;
}
