import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
	faComment,
	faIdBadge,
	faTimes
} from "@fortawesome/free-solid-svg-icons";

import { OutputRow as PackagesData } from "api/getPackages";
import { OutputRow as PackageRequestsData } from "api/getPackageRequests";
import { ChatDetails } from "components/Chat/Chat";
import Table from "components/Table";
import terminology from "terminology.json";
import { isPackageRequest } from "./isPackageRequest";

import { PackagesTableContainer } from "./PackagesSection.styles";
import { formatPackageDetails } from "services/formatPackageDetails";

type Product = PackagesData | PackageRequestsData;

interface Props {
	isMentor: boolean | undefined;
	products: Product[] | undefined;
	uid: string | undefined;

	isWaiting: boolean;
	isLoading: boolean;

	onClickPay: (arg: Product) => void;
	onClickMarkPaid: (arg: Product) => void;
	onClickChat: (arg: ChatDetails | undefined) => void;
	onClickCancel: (arg: PackageRequestsData) => void;
}

const PackagesTable: React.FunctionComponent<Props> = ({
	isMentor,
	products,
	uid,
	isWaiting,
	isLoading,
	onClickPay,
	onClickMarkPaid,
	onClickChat,
	onClickCancel
}) => {
	const history = useHistory();

	const cols = [
		{ key: "sessions", heading: "Sessions" },
		{ key: "partner", heading: "With" },
		...(isMentor ? [{ key: "role", heading: "Role" }] : []),
		{ key: "numAvailableCredits", heading: "Credits remaining" },
		{ key: "status", heading: "Status" }
	];

	const rows =
		products && products.length
			? products
					.sort((a, b) => a.id.localeCompare(b.id))
					.map(product => {
						const numAvailableCredits =
							"numAvailableCredits" in product
								? product.numAvailableCredits
								: product.packagePrice.numMeetings;

						const {
							displayNameGuest,
							displayNameHost,
							hostUid,
							guestUid
						} = product;

						const { sessions } =
							uid && product
								? formatPackageDetails(product, uid)
								: { sessions: [] };

						const awaitingPayment =
							"paymentStatus" in product
								? product.paymentStatus === "pending"
								: false;
						const isAccepted =
							"isAccepted" in product ? product.isAccepted : true;

						if (!uid) {
							throw new Error("Not logged in");
						}

						const isHost = hostUid === uid;
						const isGuest = !isHost;

						const partnersName = isHost ? displayNameGuest : displayNameHost;
						const partnersUid = isHost ? guestUid : hostUid;

						const action = (() => {
							if (guestUid === uid) {
								if (awaitingPayment) {
									return {
										onClick: () => onClickPay(product),
										label: "Pay"
									};
								}
							}
							if (hostUid === uid) {
								if (awaitingPayment) {
									return {
										onClick: () => onClickMarkPaid(product),
										label: "Mark paid"
									};
								}
							}
							return undefined;
						})();

						return {
							data: {
								sessions,
								numAvailableCredits,
								partner: (
									<Link to={`/user-id/${partnersUid}`}>
										{partnersName ? partnersName : "(unknown)"}
									</Link>
								),
								...(isMentor ? { role: isHost ? "Host" : "Guest" } : {}),
								status: awaitingPayment
									? "Awaiting payment"
									: isAccepted
									? "Confirmed"
									: "Awaiting confirmation"
							},
							action,
							menu: [
								{
									onClick: () =>
										onClickChat({
											partner: { uid: partnersUid, displayName: partnersName },
											pagination: { cursorType: "after" }
										}),
									label: "Open chat",
									icon: faComment
								},
								...(isGuest
									? [
											{
												onClick: () => {
													history.push(`/user-id/${partnersUid}`);
												},
												label: "View profile",
												icon: faIdBadge
											}
									  ]
									: []),
								...(isPackageRequest(product)
									? [
											{
												onClick: () => onClickCancel(product),
												label: `Cancel ${terminology.package}`,
												icon: faTimes
											}
									  ]
									: [])
							]
						};
					})
			: [];

	return (
		<PackagesTableContainer isLoading={isWaiting || isLoading}>
			<Table
				cols={cols}
				rows={rows}
				emptyText={`You do not have any active ${terminology.packages}`}
				isLoading={isWaiting || isLoading}
			/>
		</PackagesTableContainer>
	);
};

export default PackagesTable;
