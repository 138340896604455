import React from "react";
import styled from "styled-components";
import Collapsible from "components/Collapsible";
import Anchor from "components/Anchor";
import { Props as CollapsibleProps } from "components/Collapsible/Collapsible";

const WideSectionInner = styled(Collapsible)`
	padding-bottom: 2em;
`;

type Props = Omit<CollapsibleProps, "tagName"> & {
	anchorName?: string;
};

const WideSection: React.FunctionComponent<Props> = ({
	children,
	anchorName,
	...otherProps
}) => {
	return (
		<div className="row gtr-uniform">
			<div className="col-12">
				{anchorName ? <Anchor id={anchorName} /> : null}
				<WideSectionInner tagName="section" {...otherProps}>
					{children}
				</WideSectionInner>
			</div>
		</div>
	);
};

export default WideSection;
