import React, { useCallback, useContext } from "react";

import { AlertContext } from "components/AlertProvider/AlertProvider";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useSendData, Method } from "hooks/useSendData";
import { captureException } from "services/captureException";

export function useHandleSubmit<I, O>(
	thingToSubmit: string,
	personWhoWillConfirm: string,
	thingThatWillBeConfirmed: string,
	method: Method<I, O>,
	onSent: () => void,
	onFailed: () => void
) {
	const [addAlert] = useContext(AlertContext);
	const [{ uid }] = useContext(AuthContext);

	const doRequest = useSendData({
		method,
		onFailure: e => {
			// Do nothing here
		}
	});

	const handleSubmit = useCallback(
		(data: I) => {
			const errAlert = {
				title: "Transmission error",
				contents: `Unfortunately there was an error saving your ${thingToSubmit}.  Please try again later, and if the problem persists, consider letting us know.`
			};

			if (!uid) {
				throw new Error("No requester UID");
			}

			const req = doRequest(data, { uid });

			// TODO:WV:20230501:Fix 'set state on unmounted component' error after submitting this form
			const ready = req.ready
				.then(() => {
					addAlert({
						contents: (
							<>
								<p>Booking request received - thank you.</p>
								<p>
									Please now wait a short while for your {personWhoWillConfirm}{" "}
									to confirm the {thingThatWillBeConfirmed}.
								</p>
							</>
						)
					});
					onSent();
				})
				.catch(e => {
					captureException(e, {
						evtType: "bookingRequestFailed",
						extra: {
							data,
							originalError: e
						}
					});
					addAlert(errAlert);
					onFailed();
				});

			return {
				ready,
				abort: () => req.abort(),
				aborted: () => req.aborted()
			};
		},
		[
			addAlert,
			onSent,
			onFailed,
			uid,
			doRequest,
			thingToSubmit,
			personWhoWillConfirm,
			thingThatWillBeConfirmed
		]
	);

	return handleSubmit;
}
