import { useCallback, useContext } from "react";
import flatten from "lodash.flatten";

import { combineAPIRequests } from "api/combineAPIRequests";
import { SearchResults } from "api/SearchResults";
import { getPackages, OutputRow as PackagesData } from "api/getPackages";
import {
	getPackageRequests,
	OutputRow as PackageRequestsData
} from "api/getPackageRequests";
import { useAccountStatus } from "hooks/useAccountStatus";
import { AuthContext } from "components/AuthProvider/AuthProvider";

export function useFetchProducts() {
	const { isMentor } = useAccountStatus();
	const [{ uid }] = useContext(AuthContext);

	const fetchProducts = useCallback(() => {
		if (!uid) {
			throw new Error("No UID");
		}

		const req = combineAPIRequests<
			SearchResults<PackagesData | PackageRequestsData>
		>([
			// Active packages, fully paid (user is guest)
			getPackages(
				{
					uid,
					isHost: false,
					isComplete: false,
					paymentStatus: "ok"
				},
				{ uid }
			),
			getPackages(
				{
					uid,
					isHost: false,
					isComplete: false,
					paymentStatus: "free"
				},
				{ uid }
			),
			// Active packages, fully paid but awaiting confirmation from Stripe via webhook (user is guest)
			getPackages(
				{
					uid,
					isHost: false,
					isComplete: false,
					paymentStatus: "awaiting-webhook"
				},
				{ uid }
			),

			// Active packages, fully paid (user is host)
			...(!!isMentor
				? [
						getPackages(
							{
								uid,
								isHost: true,
								isComplete: false,
								paymentStatus: "ok"
							},
							{ uid }
						),
						getPackages(
							{
								uid,
								isHost: true,
								isComplete: false,
								paymentStatus: "free"
							},
							{ uid }
						),

						/* Fully paid but awaiting confirmation from Stripe via webhook */
						getPackages(
							{
								uid,
								isHost: true,
								isComplete: false,
								paymentStatus: "awaiting-webhook"
							},
							{ uid }
						)
				  ]
				: []),

			// Any packages that require payment (user is guest)
			getPackages(
				{
					uid,
					isHost: false,
					isComplete: false,
					paymentStatus: "pending"
				},
				{ uid }
			),

			// Any packages that require payment (user is host)
			...(isMentor
				? [
						getPackages(
							{
								uid,
								isHost: true,
								isComplete: false,
								paymentStatus: "pending"
							},
							{ uid }
						)
				  ]
				: []),

			// Packages awaiting host confirmation
			getPackageRequests(
				{
					guestUid: uid,
					isAccepted: false,
					isRejected: false
				},
				{ uid }
			)
		]);

		return {
			...req,
			ready: req.ready.then(allresults =>
				flatten(
					allresults.map(result => {
						if (!result) {
							return [];
						}
						return result.page;
					})
				)
			),
			type: "packages-combined"
		};
	}, [uid, isMentor]);

	return fetchProducts;
}
