import { useUserExists } from "hooks/useUserExists";
import { useUserProfile } from "hooks/useUserProfile";

export const useProfileData = (vanityUrl?: string, uid?: string) => {
	const { output: userExistenceData } = useUserExists({ uid, vanityUrl });
	const userKnownToExist = userExistenceData && userExistenceData.userExists;

	const userProfile = useUserProfile(
		userKnownToExist ? { vanityUrl, uid } : {}
	);

	return userProfile;
};
