import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

export interface Output {
	destinationAmount: number | undefined;
}

const flagValidationErrors: ValidationCallback<Output> = (
	input,
	{ flag, checkField }
) => {
	checkField("destinationAmount", {
		type: "number",
		isRequired: false
	});
};

interface GetCurrencyConversionParams {
	sourceCurrency: string;
	sourceAmount: number;
	destinationCurrency: string;
}

// TODO:WV:20210706:Instead, have an endpoint for getting the rate from the server and do the conversion client-side
export function getCurrencyConversion(
	{
		sourceCurrency,
		sourceAmount,
		destinationCurrency
	}: GetCurrencyConversionParams,
	auth: Auth
): ApiRequest<Output> {
	const request = makeApiRequest("currencyConversion", {
		auth,
		cacheInMemory: true,
		data: {
			get: {
				sourceCurrency,
				sourceAmount: sourceAmount + "",
				destinationCurrency
			}
		},
		flagValidationErrors
	});

	return request;
}
