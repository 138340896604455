import styled from "styled-components";

export const SessionType = styled.div`
	margin-bottom: 2em;
	border: 1px solid #eaeaea;
	padding: 1em;
`;

export const SessionTypeVariantsHeading = styled.p`
	margin-bottom: 0.5em;
	font-style: italic;
`;

const listStyles = `
	margin-bottom: 0;
	font-style: italic;
`;

export const SessionTypeVariantsListOfSessionTypes = styled.ul`
	${listStyles}
`;

const listItemStyles = `
	margin-bottom: 0.5em;
	list-style-type: "»";
	ul {
		margin-bottom: 0.75em;
	}
	li {
		margin-bottom: 0;
	}
`;

export const SessionTypeVariantsSessionType = styled.li`
	${listItemStyles};
	&:last-of-type {
		&,
		ul {
			margin-bottom: 0;
		}
	}
`;

export const SessionTypeVariantsListOfPrices = styled.ul`
	${listStyles}
`;

export const SessionTypeVariantsPrice = styled.li`
	${listItemStyles};
`;
