import React from "react";

interface ChatWidgetMoreButtonProps {
	onClick: () => void;
	label: string;
}

const ChatWidgetMoreButton: React.FunctionComponent<
	ChatWidgetMoreButtonProps
> = ({ onClick, label }) => {
	return (
		<p>
			<button
				className="primary"
				onClick={e => {
					e.preventDefault();
					onClick();
				}}
			>
				{label}
			</button>
		</p>
	);
};

export default ChatWidgetMoreButton;
