import { useCallback } from "react";

import { useRemoteResource } from "hooks/useRemoteResource";
import { getUserExists, Output } from "api/getUserExists";
import { makeDummyRequest } from "api/makeDummyRequest";

interface Params {
	vanityUrl?: string;
	uid?: string;
	cacheInMemory?: boolean;

	// Change the value of 'repeat' to retry the request, as per the docs for useRemoteResource
	repeat?: number;
}

export function useUserExists({
	vanityUrl,
	uid,
	cacheInMemory = true,
	repeat = 1
}: Params) {
	const cb = useCallback(
		(arg, auth) => {
			if (!(vanityUrl || uid)) {
				return makeDummyRequest(undefined, "userExists");
			}

			return getUserExists(
				{
					vanityUrl,
					uid,
					cacheInMemory
				},
				auth
			);
		},
		[vanityUrl, uid, cacheInMemory]
	);

	return useRemoteResource<Output | undefined>(cb, false, repeat);
}
