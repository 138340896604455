import React, { useContext } from "react";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { MeetingCalendarEvent } from "components/MeetingCalendar/MeetingCalendar";
import ActionButtons from "components/ActionButtons";
import { ChatContext } from "components/Chat/ChatContext";

import { useProfileData } from "hooks/useProfileData";
import { useMentorIsOfferingMeetings } from "hooks/useMentorIsOfferingMeetings";

import terminology from "terminology.json";

type StartBookingWizard = (e?: MeetingCalendarEvent) => void;

type StartBuyPackageWizard = (
	e: React.MouseEvent<HTMLButtonElement, MouseEvent>
) => void;

interface Props {
	vanityUrl: string;
	uid: string;
	mentorId?: string;
	startBookingWizard: StartBookingWizard;
	startBuyPackageWizard: StartBuyPackageWizard;
	handleClickSendAMessage: (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => void;
	showChatOption: boolean;
	fitWidth: boolean;
}

const UserProfileActionButtons: React.FunctionComponent<Props> = ({
	vanityUrl,
	uid,
	mentorId,
	startBookingWizard,
	startBuyPackageWizard,
	handleClickSendAMessage,
	showChatOption,
	fitWidth
}) => {
	const { output: profileData } = useProfileData(vanityUrl, uid);
	const mentorIsOfferingMeetings = useMentorIsOfferingMeetings(
		vanityUrl,
		uid,
		mentorId
	);

	const [, setChatDetails] = useContext(ChatContext);

	const [{ uid: currentUserUid }] = useContext(AuthContext);

	const bookingPossible =
		profileData &&
		profileData.isMentor &&
		mentorIsOfferingMeetings.output === true;
	const messagesAllowed = profileData && profileData.uid !== currentUserUid;

	if (!(bookingPossible || messagesAllowed)) {
		return null;
	}

	return (
		<ActionButtons
			fitWidth={fitWidth}
			primary={
				bookingPossible
					? {
							onClick: () => startBookingWizard(),
							label: `Book a ${terminology.meeting}`
					  }
					: undefined
			}
			secondary={
				bookingPossible
					? {
							onClick: startBuyPackageWizard,
							label: `Buy a ${terminology.package}`
					  }
					: undefined
			}
			tertiary={
				messagesAllowed && showChatOption
					? currentUserUid
						? {
								onClick: () =>
									setChatDetails({
										partner: profileData,
										pagination: { cursorType: "after" }
									}),
								label: "Send a message"
						  }
						: {
								onClick: handleClickSendAMessage,
								label: "Send a message"
						  }
					: undefined
			}
		/>
	);
};

export default UserProfileActionButtons;
