import { OutputRow as PackagePricesData } from "api/getPackagePrices";

import {
	Params as FormatSessionPriceAndDurationParams,
	formatSessionPriceAndDuration
} from "./formatSessionPriceAndDuration";

interface Params
	extends Omit<FormatSessionPriceAndDurationParams, "singleSessionPrice"> {
	packagePrice: PackagePricesData;
}

export function formatPackagePrice(arg: Params) {
	return `${arg.packagePrice.numMeetings} x ${formatSessionPriceAndDuration({
		...arg,
		singleSessionPrice: arg.packagePrice
	})}`;
}
