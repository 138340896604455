import React, { useContext, useState, useCallback, useMemo } from "react";

import { AlertContext } from "components/AlertProvider/AlertProvider";
import ModalConfirm from "components/ModalConfirm";
import MeetingSummary from "components/MeetingSummary";
import FieldCheckbox from "components/FieldCheckbox";
import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { OutputRow as MeetingRequestsData } from "api/getMeetingRequests";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useSendData } from "hooks/useSendData";
import { ucFirst } from "utils/ucFirst";
import { cancelMeeting } from "api/cancelMeeting";
import { cancelMeetingRequest } from "api/cancelMeetingRequest";
import terminology from "terminology.json";

import { isMeetingRequest } from "./isMeetingRequest";
import { ModalCancelMeetingContents } from "./ModalCancelMeeting.styles";

export interface Props {
	event: MeetingsDataFlattened | MeetingRequestsData | undefined;
	isOpen: boolean;
	onCancel: () => Promise<void>;
	onOK: (arg: { requestedRebook: boolean; hostUid: string }) => Promise<void>;
}

const ModalCancelMeeting: React.FunctionComponent<Props> = ({
	event,
	isOpen,
	onCancel,
	onOK
}) => {
	const [{ uid }] = useContext(AuthContext);
	const [addAlert] = useContext(AlertContext);

	const [message, setMessage] = useState<string | undefined>();
	const [optInRebook, setOptInRebook] = useState<boolean | undefined>();

	const resetForm = useCallback(() => {
		setMessage(undefined);
		setOptInRebook(undefined);
	}, [setMessage, setOptInRebook]);

	const doCancelMeeting = useSendData({ method: cancelMeeting });
	const doCancelMeetingRequest = useSendData({ method: cancelMeetingRequest });

	const { id: eventId, hostUid } = event
		? event
		: { id: undefined, hostUid: undefined };
	const theEventIsAMeetingRequest = event && isMeetingRequest(event);
	const handleOK = useCallback(async () => {
		if (!(eventId && hostUid)) {
			return;
		}

		if (uid !== hostUid && optInRebook === undefined) {
			addAlert({
				contents: "Please indicate if you want to book a replacement meeting"
			});
			return;
		}

		if (theEventIsAMeetingRequest) {
			await doCancelMeetingRequest(
				{
					id: eventId,
					...(message ? { message } : {})
				},
				{ uid }
			).ready;
		} else {
			await doCancelMeeting(
				{
					id: eventId,
					...(message ? { message } : {})
				},
				{ uid }
			).ready;
		}

		await onOK({ requestedRebook: !!optInRebook, hostUid });
		resetForm();
	}, [
		eventId,
		message,
		optInRebook,
		hostUid,
		theEventIsAMeetingRequest,
		addAlert,
		doCancelMeeting,
		doCancelMeetingRequest,
		onOK,
		uid,
		resetForm
	]);

	const handleCancel = useCallback(async () => {
		await onCancel();
		resetForm();
	}, [onCancel, resetForm]);

	const okText = useMemo(
		() => `${hostUid === uid ? `Cancel` : `Amend`} booking`,
		[hostUid, uid]
	);

	if (!uid) {
		return null;
	}

	if (!event) {
		return null;
	}

	return (
		<ModalConfirm
			isOpen={!!event}
			title={`Cancel ${ucFirst(terminology.meeting)}`}
			onCancel={handleCancel}
			onOK={handleOK}
			okText={okText}
		>
			<ModalCancelMeetingContents>
				<MeetingSummary event={event} currentUid={uid} />

				{uid === event.hostUid ? (
					<>
						<h3>Cancel {terminology.meeting}</h3>
						<p>
							If you wish to add an accompanying note, please type it below.
						</p>
						<form>
							<div className="col-12">
								<textarea
									placeholder="Accompanying note"
									value={message}
									onChange={e => setMessage(e.currentTarget.value)}
								/>
							</div>
						</form>
					</>
				) : null}
				{uid !== event.hostUid ? (
					<>
						<h3>What in particular do you want to do?</h3>
						<form>
							<div className="row gtr-uniform">
								<div className="col-6 col-12-small">
									<FieldCheckbox
										id="response-decline"
										name="response"
										checked={optInRebook === false}
										onChange={e => {
											setOptInRebook(
												e.currentTarget.checked ? false : optInRebook
											);
										}}
										labelText="Cancel the booking"
									/>
								</div>

								<div className="col-6 col-12-small">
									<FieldCheckbox
										id="response-accept"
										name="response"
										checked={optInRebook === true}
										onChange={e => {
											setOptInRebook(
												e.currentTarget.checked ? true : optInRebook
											);
										}}
										labelText="Cancel and book a replacement"
									/>
								</div>

								<div className="col-12">
									<textarea
										placeholder="Accompanying note"
										value={message}
										onChange={e => setMessage(e.currentTarget.value)}
									/>
								</div>
							</div>
						</form>
					</>
				) : null}
			</ModalCancelMeetingContents>
		</ModalConfirm>
	);
};

export default ModalCancelMeeting;
