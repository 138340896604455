// NB All Firebase boilerplate will need to be updated when moving to native platforms
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

interface FirebaseEnvironmentConfig {
	apiKey: string;
	authDomain: string;
	databaseURL: string;
	projectId: string;
	storageBucket: string;
	messagingSenderId: string;
	appId: string;
	measurementId?: string;
}

const developmentConfig: FirebaseEnvironmentConfig = {
	apiKey: "AIzaSyBjLTx5Fjzez33rzI_yST0AvsTd8jz8EbA",
	authDomain: "flying-tree-dev-9dcf2.firebaseapp.com",
	databaseURL: "https://flying-tree-dev-9dcf2.firebaseio.com",
	projectId: "flying-tree-dev-9dcf2",
	storageBucket: "flying-tree-dev-9dcf2.appspot.com",
	messagingSenderId: "165826324387",
	appId: "1:165826324387:web:3d8b14bf6c71e8abd71c16"
};

const productionConfig: FirebaseEnvironmentConfig = {
	apiKey: "AIzaSyBlTwfBsLLRkXqV00eWtp5hSHpq8wgcHrI",
	authDomain: "flying-tree-cc149.firebaseapp.com",
	databaseURL: "https://flying-tree-cc149.firebaseio.com",
	projectId: "flying-tree-cc149",
	storageBucket: "flying-tree-cc149.appspot.com",
	messagingSenderId: "49343670989",
	appId: "1:49343670989:web:bfe6abf52611861ec11784",
	measurementId: "G-BEC002XR28"
};

interface FirebaseConfig {
	development: FirebaseEnvironmentConfig;
	production: FirebaseEnvironmentConfig;
	test: FirebaseEnvironmentConfig;
}

const firebaseConfig: FirebaseConfig = {
	development: {
		...developmentConfig
	},
	test: {
		// WV:20230519:Use development environment for tests, at least for the time being
		...developmentConfig
	},
	production: {
		...productionConfig
	}
};

switch (process.env.REACT_APP_FIREBASE_PROJECT) {
	case "production":
		firebase.initializeApp(firebaseConfig.production);
		firebase.analytics();
		break;
	case "development":
		firebase.initializeApp(firebaseConfig.development);
		break;
	case "test":
		firebase.initializeApp(firebaseConfig.test);
		break;
	default:
		throw new Error(`Unknown environment '${process.env.NODE_ENV}'`);
}

// Uncomment the following line to enable Firebase debug logs
//firebase.firestore.setLogLevel("debug");

export default firebase;
