import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { setAppElement } from "components/Modal/Modal";
import Routing from "components/Routing";
import Chat from "components/Chat";
import AlertProvider from "components/AlertProvider";
import ZoomProvider from "components/ZoomProvider";
import AuthProvider from "components/AuthProvider";
import PageError from "components/PageError";
import {
	AlreadyCapturedError,
	CapturedElsewhereError
} from "services/captureException";

if (process.env.NODE_ENV !== "test") {
	setAppElement("#root");
}

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	beforeSend: (event, hint) => {
		if (
			hint &&
			hint.originalException &&
			(hint.originalException instanceof AlreadyCapturedError ||
				hint.originalException instanceof CapturedElsewhereError)
		) {
			return null;
		}
		return event;
	}
});

const App: React.FunctionComponent = () => {
	return (
		<Router>
			<Sentry.ErrorBoundary
				fallback={PageError}
				onError={
					process.env.REACT_APP_LOG_ERROR_BOUNDARY_ERRORS_TO_CONSOLE
						? e => {
								console.log("Error boundary caught error", e);
								console.error(e);
						  }
						: undefined
				}
			>
				<AuthProvider>
					<AlertProvider>
						<ZoomProvider>
							<Chat>
								<Routing />
							</Chat>
						</ZoomProvider>
					</AlertProvider>
				</AuthProvider>
			</Sentry.ErrorBoundary>
		</Router>
	);
};

export default App;
