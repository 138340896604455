import React, { useEffect } from "react";
import { Widget } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";

import ChatWidgetError from "./ChatWidgetError";
import { ChatWidgetContainer } from "./ChatWidget.styles";

interface Props {
	partner?: {
		uid: string;
		displayName: string;
	};
	isError: boolean;
	closeAndReset: () => void;
	handleNewUserMessage: (message: any) => void;
}

const ChatWidgetInner: React.FunctionComponent<Props> = ({
	partner,
	isError,
	closeAndReset,
	handleNewUserMessage
}) => {
	// Set appropriate CSS classes on document.body so that a quirk in the chat widget can be
	// worked around, allowing the browser window to be scrolled after the widget is closed.
	useEffect(() => {
		setBodyClasses(true);
		return () => {
			setBodyClasses(false);
		};
	}, []);

	return isError ? (
		<ChatWidgetError onClose={closeAndReset} />
	) : (
		<ChatWidgetContainer>
			<Widget
				showTimeStamp={false}
				showCloseButton={true}
				fullScreenMode
				handleNewUserMessage={handleNewUserMessage}
				title={partner ? partner.displayName : "Chat"}
				subtitle="Replies may not be immediate.  You will be notified by email if you miss it here."
			/>
		</ChatWidgetContainer>
	);
};

export default ChatWidgetInner;

function setBodyClasses(widgetIsOpen: boolean) {
	if (widgetIsOpen) {
		document.body.classList.add("chat-widget-open");
		document.body.classList.remove("chat-widget-closed");
	} else {
		document.body.classList.add("chat-widget-closed");
		document.body.classList.remove("chat-widget-open");
	}
}
