import {
	ErrorFlagger,
	ValidationCallback,
	validate,
	Utils,
	Auth
} from "validation/validate";
import { SearchResults, SearchResultsWithCount } from "api/SearchResults";
import { ValidationErrors } from "validation/ValidationErrors";

type PageErrors<T> = {
	[entryOffset: number]: ValidationErrors<T>;
};

type PageFlagger<T> = ErrorFlagger<SearchResults<T>["page"][0]>;

export function flagValidationErrorsInSearchResultsWithCount<T>(
	flagValidationErrorsInRow: ValidationCallback<T>,
	input: any,
	utils: Utils<SearchResultsWithCount<T>>,
	auth: Auth
) {
	const flagger: ValidationCallback<SearchResultsWithCount<T>> = (
		input,
		{ flag, checkField }
	) => {
		flagValidationErrorsInSearchResults(
			flagValidationErrorsInRow,
			input,
			utils,
			auth
		);
		checkField("count", { type: "number" });
	};

	return flagger(input, utils, auth);
}

// TODO:WV:20230118:De-dup this with similar functions in getCredits and getContacts
export function flagValidationErrorsInSearchResults<T>(
	flagValidationErrorsInRow: ValidationCallback<T>,
	input: any,
	utils: Utils<SearchResults<T>>,
	auth: Auth
) {
	const flagger: ValidationCallback<SearchResults<T>> = (
		input,
		{ flag, checkField }
	) => {
		const { page, nextPage } = input;

		if (page === undefined) {
			flag("page", "missing");
		} else if (!Array.isArray(page)) {
			flag("page", "invalid");
		} else {
			validatePage<T>(page, "page", flagValidationErrorsInRow, flag, auth);
		}

		if (nextPage !== undefined) {
			if (!Array.isArray(nextPage)) {
				flag("nextPage", "invalid");
			} else {
				if (nextPage !== undefined) {
					validatePage<T>(
						nextPage,
						"nextPage",
						flagValidationErrorsInRow,
						flag,
						auth
					);
				}
			}
		}
	};

	return flagger(input, utils, auth);
}

function validatePage<T>(
	page: any,
	fieldName: keyof SearchResults<T>,
	flagValidationErrorsInRow: ValidationCallback<T>,
	flag: ErrorFlagger<SearchResults<T>>,
	auth: Auth
) {
	const pageErrors: PageErrors<T> = {};
	for (let entryOffset = 0; entryOffset < page.length; entryOffset++) {
		const pageFlag: PageFlagger<T> = (fieldName, error) => {
			if (pageErrors[entryOffset] === undefined) {
				pageErrors[entryOffset] = {};
			}
			pageErrors[entryOffset][fieldName] = error;
		};
		validate(page[entryOffset], {
			doValidate: flagValidationErrorsInRow,
			withErrors: errors => {
				for (const [fieldName, errorMsg] of Object.entries(errors)) {
					pageFlag(
						fieldName as keyof typeof errors,
						errorMsg && typeof errorMsg === "string"
							? errorMsg
							: "unknown error"
					);
				}
			},
			auth
		});
	}

	for (const [pageOffset, errors] of Object.entries(pageErrors)) {
		for (const [fieldNameInPage, errorMessage] of Object.entries(errors)) {
			flag(
				fieldName,
				`Entry ${pageOffset}: ${fieldNameInPage}: ${errorMessage}`
			);
		}
	}
}
