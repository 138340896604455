import styled from "styled-components";

export const Tag = styled.span<{ title: string }>`
	${props =>
		props.title !== ""
			? `
		border-bottom: 1px dotted gray;
		cursor: pointer;
	`
			: ``}
`;
