import React, { useContext } from "react";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useUserProfile } from "hooks/useUserProfile";
import { IntroText, ProfileLink } from "./ProfileUrlSection.styles";

const ProfileUrlSection: React.FunctionComponent = () => {
	const [{ uid }] = useContext(AuthContext);

	const { output: profileData } = useUserProfile({
		uid
	});

	const profileUrlTag = profileData
		? profileData.vanityUrl
			? profileData.vanityUrl
			: uid
		: undefined;

	return (
		<>
			{profileUrlTag ? (
				<>
					<p>
						<IntroText>You can find your full profile at:</IntroText>{" "}
						<ProfileLink to={`/user/${profileUrlTag}`}>
							{process.env.REACT_APP_SITE_URL}/user/{profileUrlTag}
						</ProfileLink>
					</p>
					<p>
						<IntroText>An availability-calendar only version is at:</IntroText>{" "}
						<ProfileLink to={`/user/${profileUrlTag}/availability`}>
							{process.env.REACT_APP_SITE_URL}/user/{profileUrlTag}/availability
						</ProfileLink>
					</p>
				</>
			) : null}
		</>
	);
};

export default ProfileUrlSection;
