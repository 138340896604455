import React, { useState, useEffect } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import {
	WizardData,
	SessionType
} from "components/PageUserProfile/wizards/BuyPackageWizard/BuyPackageWizard";

import { FieldOption } from "components/FieldSelect/FieldSelect";
import FieldSelect from "components/FieldSelect";
import { OutputRow as SessionTypesData } from "api/getSessionTypes";
import { useAccountStatus } from "hooks/useAccountStatus";
import { formatSessionType } from "services/formatSessionType";

import { useSessionTypesAndPrices } from "hooks/useSessionTypesAndPrices";
import { FieldSelectContainer } from "./StepChooseSessionType.styles";

const defaultCountryCodeUntilAccountStatusLoads = "GB";

function StepChooseSessionType({
	enableProgressToNextStep,
	disableProgressToNextStep,
	setHaveSystemError,
	wizardData: { mentorId }
}: ModalWizardStepRenderProps<WizardData>) {
	const {
		isWaiting: sessionTypesAndPricesWaiting,
		isLoading: sessionTypesAndPricesLoading,
		isError: sessionTypesAndPricesError,
		output: sessionTypesAndPrices
	} = useSessionTypesAndPrices(mentorId);

	const [selectedSessionType, setSessionType] = useState<
		SessionType | undefined
	>();

	useEffect(() => {
		if (sessionTypesAndPricesError) {
			setHaveSystemError(true);
		} else if (selectedSessionType) {
			enableProgressToNextStep({ sessionType: selectedSessionType });
		} else {
			disableProgressToNextStep();
		}
	}, [
		setHaveSystemError,
		enableProgressToNextStep,
		disableProgressToNextStep,
		selectedSessionType,
		sessionTypesAndPricesError
	]);

	const { locationCountryCode } = useAccountStatus();

	const sessionTypesWithPackages = sessionTypesAndPrices.sessionTypes.filter(
		sessionType =>
			sessionTypesAndPrices.packagePrices.some(
				packagePrice => packagePrice.sessionTypeId === sessionType.id
			)
	);

	const [currentOptions, setCurrentOptions] = useState<
		FieldOption<SessionType>[]
	>(
		generateOptionsFromSessionTypesData(
			sessionTypesWithPackages,
			locationCountryCode
				? locationCountryCode
				: defaultCountryCodeUntilAccountStatusLoads
		)
	);
	const numCurrentOptions = currentOptions.length;
	const sessionTypesWithPackagesStr = JSON.stringify(sessionTypesWithPackages);
	useEffect(() => {
		const relevantSessionTypes = JSON.parse(sessionTypesWithPackagesStr);
		if (relevantSessionTypes && numCurrentOptions === 0) {
			setCurrentOptions(
				generateOptionsFromSessionTypesData(
					relevantSessionTypes,
					locationCountryCode
						? locationCountryCode
						: defaultCountryCodeUntilAccountStatusLoads
				)
			);
		}
	}, [
		sessionTypesWithPackagesStr,
		numCurrentOptions,
		setCurrentOptions,
		locationCountryCode
	]);

	useEffect(() => {
		const selectedOption = currentOptions.find(opt => !!opt.selected);
		setSessionType(selectedOption ? selectedOption.value : undefined);
	}, [currentOptions]);

	return (
		<FieldSelectContainer>
			<FieldSelect
				allowMultipleSelections={false}
				isLoading={sessionTypesAndPricesLoading || sessionTypesAndPricesWaiting}
				isError={sessionTypesAndPricesError}
				currentOptions={currentOptions}
				onNewCurrentOptions={setCurrentOptions}
			/>
		</FieldSelectContainer>
	);
}

export default StepChooseSessionType;

function generateOptionsFromSessionTypesData(
	data: SessionTypesData[],
	locationCountryCode: string
) {
	return data
		.sort((a, b) => a.sessionTitle.localeCompare(b.sessionTitle))
		.map(sessionType => {
			const value = getValueFromSessionType(sessionType);
			return {
				id: JSON.stringify(value),
				value,
				text: formatSessionType({ sessionType })
			};
		});
}

function getValueFromSessionType({
	id,
	sessionTitle,
	sessionDescription
}: SessionType) {
	return {
		id,
		sessionTitle,
		sessionDescription
	};
}
