import { formatSessionDuration } from "services/formatSessionDuration";

export function getSessionPriceOptions(selectedValue?: number) {
	return [30, 45, 60].map(sessionDurationMinutes => {
		return {
			id: sessionDurationMinutes + "",
			value: sessionDurationMinutes,
			text: formatSessionDuration({ sessionDurationMinutes }),
			selected: !!selectedValue && selectedValue === sessionDurationMinutes
		};
	});
}
