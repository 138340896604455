import React, { useState, useCallback } from "react";
import Cropper, { CropperProps } from "react-easy-crop";

import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";

import ModalConfirm from "components/ModalConfirm";

import {
	CropperContainer,
	SliderContainer,
	SliderLabel
} from "./ModalCropper.styles";

interface Crop {
	x: number;
	y: number;
}

type Area = Parameters<NonNullable<CropperProps["onCropComplete"]>>[0];

interface Value {
	relative: Area;
	absolute: Area;
}

interface ModalCropperProps {
	onCancel?: () => void;
	onOK: (value: Value) => void;
	title: string;
	helptext?: string;
	imageDataURI: string | undefined;
	cropToAspectRatio?: number;
}

const ModalCropper: React.FunctionComponent<ModalCropperProps> = ({
	onCancel = () => undefined,
	onOK,
	title,
	helptext,
	imageDataURI,
	cropToAspectRatio = 1
}) => {
	const initialCropX = 0;
	const initialCropY = 0;
	const [crop, setCrop] = useState<Crop>({ x: initialCropX, y: initialCropY });

	const initialZoom = 1;
	const [zoom, setZoom] = useState(initialZoom);

	const [value, setValue] = useState<Value | undefined>();

	const handleOK = useCallback(() => {
		if (value) {
			onOK(value);
		}
	}, [onOK, value]);

	const handleCancel = useCallback(() => {
		onCancel();
	}, [onCancel]);

	return (
		<>
			<ModalConfirm
				isOpen
				onOK={handleOK}
				onCancel={handleCancel}
				title={title}
				helptext={helptext}
			>
				<CropperContainer>
					<Cropper
						image={imageDataURI}
						crop={crop}
						zoom={zoom}
						aspect={cropToAspectRatio}
						onCropChange={setCrop}
						onCropComplete={(a, b) => {
							setValue({ relative: a, absolute: b });
						}}
						onZoomChange={setZoom}
					/>
				</CropperContainer>

				<SliderContainer>
					<SliderLabel>Use the slider to Zoom in and out</SliderLabel>
					<Slider
						min={0}
						max={200}
						value={zoom * 100}
						onChange={newRangeValue => {
							setZoom(newRangeValue / 100);
						}}
					/>
				</SliderContainer>
			</ModalConfirm>
		</>
	);
};

export default ModalCropper;
