export function readLocalFile(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.addEventListener(
			"load",
			function() {
				const { result } = reader;
				if (typeof result !== "string") {
					// TODO:WV:20210409:Handle failure better?
					reject("Unexpected result, or error, when reading file");
					return;
				}
				resolve(result);
			},
			false
		);

		reader.readAsDataURL(file);
	});
}
