import { makeApiRequest, Auth } from "api/makeApiRequest";
import { userProfileFromApiToLocal } from "api/userProfileFromApiToLocal";
import { validate, ValidationCallback } from "validation/validate";

interface NonMentorProfileData {
	uid: string;
	vanityUrl: string;
	displayName: string;
	locationCountryCode?: string;
	locationCountryName?: string;
	locationDistrict?: string;
	avatarUrl?: string;
	videoUrl?: string;
	videoPosterUrl?: string;
	isMentor: boolean;
	lastMeetingDateUTC?: Date;
}

export interface Term {
	sortindex: number;
	category: string;
	text: string;
}

export interface MentorProfileData extends NonMentorProfileData {
	mentorProfileAboutMe?: string;
	terms: Term[];
	hasLinkedZoomAccount: boolean;
	canAcceptPayments: boolean;
	hasSkypeId: boolean;

	paymentProvidersMBWay: string | null;
	paymentProvidersWiseEmail: string | null;
	paymentProvidersWiseTelephone: string | null;
	paymentProvidersCustom: string | null;
}

export type Output = NonMentorProfileData & Partial<MentorProfileData>;

export type RawApiOutput<T> = Omit<
	T,
	"avatarUrl" | "videoUrl" | "videoPosterUrl" | "lastMeetingDateUTC"
> & {
	avatarVersion: string | null;
	videoVersion: string | null;
	sortindex?: number;
	lastMeetingDateUTC?: string;
};

interface ThingWithTerms {
	terms: Term[];
}

export const validateTerms: ValidationCallback<ThingWithTerms> = (
	input,
	{ flag }
) => {
	const { terms } = input;

	if (!terms) {
		flag("terms", "missing");
		return;
	}

	if (!Array.isArray(terms)) {
		flag("terms", "not an array");
		return;
	}

	for (const [termOffset, term] of terms.entries() as any) {
		const errorMessagePrefix = `Term ${termOffset}`;

		if (typeof term !== "object") {
			flag("terms", `${errorMessagePrefix}: not an object`);
			continue;
		}

		const { sortindex, category, text } = term;

		if (sortindex === undefined) {
			flag("terms", `${errorMessagePrefix}: no sortindex`);
		} else if (typeof sortindex !== "number") {
			flag("terms", `${errorMessagePrefix}: sortindex not a number`);
		}

		if (category === undefined) {
			flag("terms", `${errorMessagePrefix}: no category`);
		} else if (typeof category !== "string") {
			flag("terms", `${errorMessagePrefix}: category not a string`);
		}

		if (text === undefined) {
			flag("terms", `${errorMessagePrefix}: no text`);
		} else if (typeof text !== "string") {
			flag("terms", `${errorMessagePrefix}: text not a string`);
		}
	}
};

export const flagValidationErrors: ValidationCallback<RawApiOutput<Output>> = (
	input,
	{ flag, checkField },
	auth
) => {
	const { sortindex } = input;

	checkField("uid", { type: "string" });
	checkField("vanityUrl", { type: "string" });
	checkField("displayName", { type: "string" });
	checkField("locationCountryCode", { type: "string", isRequired: false });
	checkField("locationCountryName", { type: "string", isRequired: false });
	checkField("locationDistrict", { type: "string", isRequired: false });
	checkField("mentorProfileAboutMe", { type: "string", isRequired: false });

	validate(input, {
		doValidate: validateTerms,
		withErrors: errors => {
			flag("terms", errors.terms ? errors.terms : "unknown error");
		},
		auth
	});

	checkField("hasLinkedZoomAccount", { type: "boolean", isRequired: false });
	checkField("canAcceptPayments", { type: "boolean", isRequired: false });
	checkField("hasSkypeId", { type: "boolean", isRequired: false });
	if (input.avatarVersion !== undefined) {
		if (
			!(typeof input.avatarVersion === "string" || input.avatarVersion === null)
		) {
			flag("avatarVersion", "must be null or a string");
		}
	}
	checkField("isMentor", { type: "boolean", isRequired: false });
	checkField("lastMeetingDateUTC", { type: "datestring", isRequired: false });

	if (sortindex && !(typeof sortindex === "number")) {
		flag("sortindex", "invalid");
	}

	checkField("paymentProvidersMBWay", {
		type: "string",
		canBeNull: true,
		isRequired: false
	});
	checkField("paymentProvidersWiseEmail", {
		type: "string",
		canBeNull: true,
		isRequired: false
	});
	checkField("paymentProvidersWiseTelephone", {
		type: "string",
		canBeNull: true,
		isRequired: false
	});
	checkField("paymentProvidersCustom", {
		type: "string",
		canBeNull: true,
		isRequired: false
	});
};

interface GetUserProfileParams {
	vanityUrl?: string;
	uid?: string;
	cacheInMemory: boolean;
}

export function getUserProfile(
	{ vanityUrl, uid, cacheInMemory }: GetUserProfileParams,
	auth: Auth
) {
	const request = makeApiRequest<RawApiOutput<Output>>("userProfile", {
		auth,
		data: {
			get: {
				...(uid ? { uid } : {}),
				...(vanityUrl ? { vanityUrl } : {})
			}
		},
		cacheInMemory,
		flagValidationErrors
	});

	return {
		ready: request.ready.then(response => {
			if (!response) {
				return undefined;
			}
			return userProfileFromApiToLocal(response);
		}),
		abort: () => request.abort(),
		aborted: () => request.aborted(),
		type: request.type
	};
}
