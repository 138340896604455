import React, { ElementType, ReactNode, useState, useCallback } from "react";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Button from "components/Button";

import { useCollapsible } from "./useCollapsible";

export type Props = {
	tagName: ElementType<{ className?: string }>;
	className?: string;
	isCollapsedByDefault?: boolean;
	title: string;
	lazyRender?: boolean;
	children?: ReactNode;
};

const Collapsible: React.FunctionComponent<Props> = ({
	tagName: Tag,
	className,
	isCollapsedByDefault = true,
	lazyRender = true,
	title,
	children
}) => {
	const { ref, display, onToggle, isCollapsed } = useCollapsible(
		isCollapsedByDefault
	);

	const [hasBeenOpened, setHasBeenOpened] = useState(!isCollapsedByDefault);

	const toggle = useCallback(() => {
		onToggle();

		// hasBeenOpened logically always true after first toggle, because it will either be true after the toggle, or was true before it
		setHasBeenOpened(true);
	}, [onToggle, setHasBeenOpened]);

	return (
		<Tag className={className}>
			{title ? (
				<Button
					isCollapsibleSectionHeading
					iconOnLeft={false}
					onClick={toggle}
					icon={isCollapsed ? faCaretUp : faCaretDown}
					label={title}
				/>
			) : null}
			<div>
				<div
					ref={ref}
					style={{
						display
					}}
				>
					{lazyRender && !hasBeenOpened ? null : children}
				</div>
			</div>
		</Tag>
	);
};

export default Collapsible;
