import { on, emit } from "../../services/pubSub";
import { getRandomInt } from "../../utils/getRandomInt";

import { ApiRequestData, makeApiRequest, Auth } from "./index";

export interface BatchedRequest {
	endpoint: string;
	data: ApiRequestData;
}

interface BatchResponse {
	responsesToParts: { i: number; responseData: unknown }[];
}

export interface BatchResponsePubSubMessage {
	batchId: string;
	response: BatchResponse | undefined;
}

export class Batch {
	requests: BatchedRequest[];
	timer: number | null;
	gatheringTime: number;
	auth: Auth;
	uniqid: string;

	isDispatched: boolean;

	constructor(auth: Auth) {
		this.auth = auth;
		this.requests = [];
		this.timer = null;
		this.gatheringTime = 10;
		this.uniqid = new Date().getTime() + getRandomInt(100000) + "";
		this.isDispatched = false;
	}

	add(req: BatchedRequest): Promise<unknown> {
		if (this.isDispatched) {
			throw new Error("Cannot add to a batch once it has been dispatched");
		}

		this.requests.push(req);
		if (this.timer === null) {
			this.timer = window.setTimeout(() => this.dispatch(), this.gatheringTime);
		}
		const thisReqIndex = this.requests.length - 1;

		const uniqid = this.uniqid + "";
		return new Promise((resolve, reject) => {
			const unbind = on(
				"batchReceived",
				({ batchId, response }: BatchResponsePubSubMessage) => {
					if (batchId !== uniqid) {
						return;
					}
					if (response === undefined) {
						return;
					}
					const responseToPart = response.responsesToParts.find(
						part => part.i === thisReqIndex
					);
					if (!responseToPart) {
						return;
					}

					unbind();

					// TODO:WV:20230330:Handle reject in appropriate circumstances
					resolve(responseToPart.responseData);
				}
			);
		});
	}

	async dispatch() {
		this.isDispatched = true;
		if (this.timer) {
			window.clearTimeout(this.timer);
		}
		this.timer = null;
		const response = await this.sendToServer();
		emit("batchReceived", { batchId: this.uniqid, response });
		emit("batchDone", this.uniqid);
	}

	async sendToServer() {
		const auth = this.auth;
		const req = makeApiRequest<BatchResponse>("batch", {
			data: {
				post: {
					requests: JSON.stringify(
						this.requests.map((req, i) => ({
							endpoint: req.endpoint,
							query: req.data.get,
							i
						}))
					)
				}
			},

			cacheInMemory: false,
			waitForTimezone: true,

			flagValidationErrors: (input, { flag, checkField }) => {
				if (input.responsesToParts === undefined) {
					flag("responsesToParts", "missing");
					return;
				}
				if (!Array.isArray(input.responsesToParts)) {
					flag("responsesToParts", "not an array");
					return;
				}
				for (const part of input.responsesToParts) {
					if (part.i === undefined) {
						flag(
							"responsesToParts",
							"at least one part was missing an 'i' property"
						);
						return;
					}
					if (typeof part.i !== "number") {
						flag(
							"responsesToParts",
							"at least one 'i' property was not a number"
						);
						return;
					}
					if (part.responseData === undefined) {
						flag(
							"responsesToParts",
							"at least one part was missing a 'responseData' property"
						);
						return;
					}
				}
			},
			auth,
			enableBatching: false
		});

		return await req.ready;
	}
}
