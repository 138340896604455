import { Output as ProfileData } from "api/getUserProfile";

export function getTermsByCategory(
	profileData: ProfileData | undefined,
	category: string
) {
	const output =
		profileData && profileData.terms
			? [...profileData.terms.filter(t => t.category === category)].sort(
					(a, b) => a.sortindex - b.sortindex
			  )
			: [];

	return output;
}
