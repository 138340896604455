import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

export interface Term {
	category: string;
	value: string;
	text: string;
}

type Output = Term[];

const flagValidationErrors: ValidationCallback<Output> = (input, { flag }) => {
	if (!Array.isArray(input)) {
		flag(0, "not an array");
	}

	for (const [rowOffset, term] of input.entries()) {
		const { category, value, text } = term;

		if (category === undefined) {
			flag(rowOffset, "category is missing");
		} else if (!(typeof category === "string")) {
			flag(rowOffset, "category is invalid");
		}

		if (value === undefined) {
			flag(rowOffset, "value is missing");
		} else if (!(typeof value === "string")) {
			flag(rowOffset, "value is invalid");
		}

		if (text === undefined) {
			flag(rowOffset, "text is missing");
		} else if (!(typeof text === "string")) {
			flag(rowOffset, "text is invalid");
		}
	}
};

export function getMentorSearchTerms(arg: {}, auth: Auth): ApiRequest<Output> {
	return makeApiRequest("mentorSearchTerms", {
		flagValidationErrors,
		auth
	});
}
