import { useContext, useCallback } from "react";

import { Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { AlertContext } from "components/AlertProvider/AlertProvider";

export type Method<I, O> = (args: I, auth: Auth) => ApiRequest<O>;

type Params<I, O> = {
	method: Method<I, O>;
	onFailure?: (e: any) => void;
};

export function useSendData<I, O>({ method, onFailure }: Params<I, O>) {
	const [addAlert] = useContext(AlertContext);

	const makeRequest: Method<I, O> = useCallback(
		(input: I, auth: Auth) => {
			const req = method(input, auth);

			const ready = req.ready.catch(e => {
				onFailure
					? onFailure(e)
					: addAlert({
							title: `Transmission error`,
							contents: `Unfortunately there was a problem saving your data.  Please try again later, and if the problem persists, consider letting us know.`
					  });
				throw e;
			});

			return {
				ready,
				abort: () => req.abort(),
				aborted: () => req.aborted(),
				type: req.type
			};
		},
		[addAlert, method, onFailure]
	);

	return makeRequest;
}
