import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	uid: string;
}

type Output = {};
const flagValidationErrors: ValidationCallback<Output> = () => {};

export function stripeRequestConnectedAccount({ uid }: Params, auth: Auth) {
	return makeApiRequest("stripeRequestConnectedAccount", {
		auth,
		data: {
			post: {
				uid
			}
		},
		flagValidationErrors
	});
}
