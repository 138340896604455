import { useCallback, useState, useEffect, useContext } from "react";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { getStripeDashboardUrl, Output } from "api/getStripeDashboardUrl";
import { ApiRequest } from "api/ApiRequest";

export function useOpenStripeDashboard() {
	const [dashboardUrlRequest, setDashboardUrlRequest] = useState<
		ApiRequest<Output> | undefined
	>();

	useEffect(() => {
		if (!dashboardUrlRequest) {
			return;
		}
		dashboardUrlRequest.ready.then(response => {
			if (response) {
				window.open(response.url);
			}
		});
		return () => dashboardUrlRequest.abort();
	}, [dashboardUrlRequest]);

	const [{ uid }] = useContext(AuthContext);
	const openDashboard = useCallback(() => {
		if (!uid) {
			return;
		}
		const req = getStripeDashboardUrl({ uid }, { uid });
		setDashboardUrlRequest(req);
	}, [setDashboardUrlRequest, uid]);

	return openDashboard;
}
