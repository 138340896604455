import React from "react";
import FieldCheckbox from "components/FieldCheckbox";
import { useScreenSizeIsMax } from "hooks/useScreenSizeIsMax";

interface TimeOptionProps {
	value: number;
	isSelected: boolean;
	isDisabled: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TimeOption: React.FunctionComponent<TimeOptionProps> = ({
	value,
	isSelected,
	isDisabled,
	onChange
}) => {
	const isTabletOrSmaller = useScreenSizeIsMax("tablet");

	// Use smaller format on small screens, to prevent the labels confusingly squashing up against the next checkbox in the list.
	const takeUpLessSpace = isTabletOrSmaller;

	return (
		<div className="col-2">
			<FieldCheckbox
				id={`fld_time-${value}`}
				name={`fld_time-${value}`}
				checked={isSelected}
				onChange={onChange}
				labelText={(value < 10 ? "0" : "") + value}
				disabled={isDisabled}
				takeUpLessSpace={takeUpLessSpace}
			/>
		</div>
	);
};

export default TimeOption;
