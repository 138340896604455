import { captureException } from "services/captureException";

import { ValidationErrors } from "./ValidationErrors";
import { getFieldChecker, FieldChecker } from "./getFieldChecker";

export type ErrorFlagger<T> = (fieldName: keyof T, error: string) => void;

export type Utils<T> = {
	flag: ErrorFlagger<T>;
	checkField: FieldChecker<T>;
};

export interface Auth {
	uid?: string;
}

export type ValidationCallback<T> = (
	input: any,
	utils: Utils<T>,
	auth: Auth
) => void;

type ValidateParams<T> = {
	doValidate: ValidationCallback<T>;
	withErrors?: (errors: ValidationErrors<T>) => void;
	auth: Auth;
};

export function validate<T>(
	input: unknown,
	{ doValidate, withErrors, auth }: ValidateParams<T>
): input is T {
	const validationErrors: ValidationErrors<T> = {};

	const flag: ErrorFlagger<T> = (fieldName, error) => {
		validationErrors[fieldName] = error;
	};

	if (typeof input !== "object") {
		throw captureException(
			new Error("Input was not an object so could not be validated"),
			{ evtType: "inputNotAnObject", extra: { input } }
		);
	}

	doValidate(input, { flag, checkField: getFieldChecker(input, flag) }, auth);

	const haveValidationErrors = Object.keys(validationErrors).length !== 0;

	if (haveValidationErrors && withErrors) {
		withErrors(validationErrors);
	}

	return !haveValidationErrors;
}

export function validationErrorsToString<T>(
	validationErrors: ValidationErrors<T>
) {
	return Object.entries(validationErrors)
		.map(([fieldName, errMsg]) => `${fieldName}: ${errMsg}`)
		.join(", ");
}
