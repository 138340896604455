// Adapted from nanoevents source code because webpack has issues with this library

import { AccountStatus } from "services/accountStatus/accountStatus";
import { BatchResponsePubSubMessage } from "api/makeApiRequest/batch";
import { DebounceCompletePubSubMessage } from "api/makeApiRequest/index";

interface EventMap {
	batchReceived: BatchResponsePubSubMessage;
	batchDone: string;
	debounceComplete: DebounceCompletePubSubMessage;
	accountStatusChanged: Partial<AccountStatus>;
}

type EventType = keyof EventMap;
type Payload<T extends EventType> = EventMap[T];
type Callback<Arg> = (arg: Arg) => void;

type BoundEvents = { [T in EventType]: Callback<Payload<T>>[] };

const createNanoEvents = () => {
	const events: BoundEvents = {
		batchReceived: [],
		batchDone: [],
		debounceComplete: [],
		accountStatusChanged: []
	};

	const emit = <T extends EventType>(event: T, arg: Payload<T>) => {
		let callbacks = events[event] || [];
		for (let i = 0, length = callbacks.length; i < length; i++) {
			(callbacks[i] as Callback<Payload<T>>)(arg);
		}
	};

	const on = <T extends EventType>(event: T, cb: Callback<Payload<T>>) => {
		if (!events[event]) {
			events[event] = [];
		}
		(events[event] as Callback<Payload<T>>[]).push(cb);

		const unbind = () => {
			if (!events[event]) {
				events[event] = [];
			}
			const newEvents = (events[event] as Callback<Payload<T>>[]).filter(
				i => cb !== i
			);
			(events[event] as Callback<Payload<T>>[]) = newEvents;
		};

		return unbind;
	};

	return {
		emit,
		on
	};
};

const { emit: trigger, on: bind } = createNanoEvents();

export const emit = trigger;
export const on = bind;
