import { useCallback } from "react";

import { useRemoteResource } from "hooks/useRemoteResource";
import { getContacts, OutputRow } from "api/getContacts";
import { makeDummyRequest } from "api/makeDummyRequest";
import { SearchResultsWithCountAndPagination } from "api/SearchResults";

interface Params {
	uid?: string;
	doWait?: boolean;
	repeatNumber?: number;
	cursor?: string;
	cursorType?: "after" | "before";
}

export function useContacts({
	uid,
	doWait = false,
	repeatNumber = 1,
	cursor,
	cursorType = "after"
}: Params) {
	const cb = useCallback(
		(arg, auth) => {
			if (uid === undefined) {
				return makeDummyRequest(
					{
						page: [],
						count: 0,
						cursor: undefined,
						cursorType: "after"
					} as SearchResultsWithCountAndPagination<OutputRow, Date>,
					"mentees"
				);
			}

			return getContacts(
				{
					uid,
					cursor: cursor ? new Date(parseInt(cursor)) : undefined,
					cursorType
				},
				auth
			);
		},
		[uid, cursor, cursorType]
	);

	return useRemoteResource(cb, doWait, repeatNumber, true);
}
