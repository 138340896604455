import { useCallback } from "react";

import { useRemoteResource } from "hooks/useRemoteResource";
import { getUserProfile, Output as ProfileData } from "api/getUserProfile";
import { makeDummyRequest } from "api/makeDummyRequest";

interface Params {
	vanityUrl?: string;
	uid?: string;
	cacheInMemory?: boolean;

	// Change the value of 'repeat' to retry the request, as per the docs for useRemoteResource
	repeat?: number;
}

export function useUserProfile({
	vanityUrl,
	uid,
	cacheInMemory = true,
	repeat = 1
}: Params) {
	const cb = useCallback(
		(arg, auth) => {
			if (!(vanityUrl || uid)) {
				return makeDummyRequest(undefined, "userprofile");
			}

			const data = getUserProfile(
				{
					vanityUrl,
					uid,
					cacheInMemory
				},
				auth
			);

			return data;
		},
		[vanityUrl, uid, cacheInMemory]
	);

	return useRemoteResource<ProfileData | undefined>(cb, false, repeat);
}
