import React, { useState, useEffect } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { CompletedWizardData } from "../BookingWizard";

import {
	useAvailableMediums,
	Medium,
	Output as UseAvailableMediumsOutput
} from "hooks/useAvailableMediums";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import FieldSelect from "components/FieldSelect";
import { formatMedium } from "services/formatMedium";
import SelectedEventSummary from "../SelectedEventSummary";

import { FieldSelectContainer } from "./StepChooseMedium.styles";

function StepChooseMedium({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData: { mentorId, selectedDurationAndPrice, eventToBook }
}: ModalWizardStepRenderProps<CompletedWizardData>) {
	if (!selectedDurationAndPrice) {
		throw new Error("No selected duration and price");
	}

	const { isWaiting, isLoading, isError, output } = useAvailableMediums({
		mentorId,
		selectedDurationAndPrice
	});

	const availableOptions = generateOptionsFromAvailableMediums(output);

	const [currentOptions, setCurrentOptions] = useState<FieldOption<Medium>[]>(
		availableOptions
	);

	const numCurrentOptions = currentOptions.length;
	useEffect(() => {
		if (output && numCurrentOptions === 0) {
			setCurrentOptions(generateOptionsFromAvailableMediums(output));
		}
	}, [output, numCurrentOptions, setCurrentOptions]);

	useEffect(() => {
		const selectedOption = currentOptions.find(opt => !!opt.selected);
		const selectedOptionValue = selectedOption
			? selectedOption.value
			: undefined;

		if (selectedOptionValue !== undefined) {
			enableProgressToNextStep({ medium: selectedOptionValue });
		} else {
			disableProgressToNextStep();
		}
	}, [enableProgressToNextStep, disableProgressToNextStep, currentOptions]);

	return (
		<>
			<SelectedEventSummary eventToBook={eventToBook} />
			<FieldSelectContainer>
				<FieldSelect
					allowMultipleSelections={false}
					isLoading={isLoading || isWaiting}
					isError={isError}
					currentOptions={currentOptions}
					onNewCurrentOptions={setCurrentOptions}
				/>
			</FieldSelectContainer>
		</>
	);
}

export default StepChooseMedium;

function generateOptionsFromAvailableMediums(
	output?: UseAvailableMediumsOutput
) {
	if (!output) {
		return [];
	}

	// TODO:WV:20220921:Logos.  Explanation (rather than omission) for disabled options
	const availableMediums = Object.entries(output).filter(
		([id, { available }]) => !!available
	);

	return availableMediums.map(
		([key, { available, currentUserHas, mentorHas, name }]) => {
			const id = key as Medium;
			return {
				id,
				value: id,
				text: formatMedium(name)
			};
		}
	);
}
