import { useCallback } from "react";

// This is intended for use with lists of checkboxes in modals (e.g. ModalFieldSelect in Checkbox mode)
export function useScrollToInitialPosition() {
	return useCallback(node => {
		if (!node) {
			return;
		}

		if (!node.querySelector) {
			return;
		}

		const selectedInput = node.querySelector("input[checked]");
		if (selectedInput) {
			node.scrollTop = selectedInput.offsetTop;
		}

		const scrollToDefault = node.querySelector(
			"input[data-is-scroll-to-default]"
		);
		if (scrollToDefault) {
			node.scrollTop = scrollToDefault.offsetTop;
		}
	}, []);
}
