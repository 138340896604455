import { useCallback, useContext } from "react";

import { useRemoteResource } from "hooks/useRemoteResource";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { SearchResults } from "api/SearchResults";
import { makeDummyRequest } from "api/makeDummyRequest";
import { getRecentChatHistory, OutputRow } from "api/getRecentChatHistory";

import { ChatDetails } from "./Chat";

export function useRemoteChatMessages(
	uid: string | undefined,
	partnerUID: string | undefined,
	pagination: ChatDetails["pagination"],
	highlightedMessageId: string | undefined,
	haveSocket: boolean
) {
	const [{ isSignedIn }] = useContext(AuthContext);
	const doWait = !(isSignedIn && haveSocket);

	const cursorUnix = pagination.cursor
		? pagination.cursor.getTime()
		: undefined;
	const { cursorType: cursorTypeOuter } = pagination;

	return useRemoteResource<SearchResults<OutputRow>>(
		useCallback(() => {
			if (!partnerUID) {
				return makeDummyRequest<SearchResults<OutputRow>>(
					{
						page: [],
						nextPage: []
					},
					"chathistory"
				);
			}

			if (!uid) {
				throw new Error("No auth UID");
			}
			if (partnerUID === uid) {
				throw new Error("You are not allowed to chat with yourself");
			}

			const cursor = cursorUnix ? new Date(cursorUnix) : undefined;
			const cursorType = cursorTypeOuter;

			// TODO:WV:20230319:Find out why the server logs failed attempts to re-send old messages when scrolling through the chat history
			const req = getRecentChatHistory(
				{
					uid1: uid,
					uid2: partnerUID,
					cursor,
					cursorType
				},
				{ uid }
			);

			return req;
		}, [partnerUID, cursorUnix, cursorTypeOuter, uid]),
		doWait
	);
}
