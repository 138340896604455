import { useState, useCallback, useContext } from "react";

import { AuthContext } from "components/AuthProvider/AuthProvider";

export const useMessageDialog = (vanityUrl: string, uid: string) => {
	const [messageWizardInProgress, setMessageDialogInProgress] = useState(false);

	const [{ isSignedIn, promptForLogin }] = useContext(AuthContext);

	const handleEndMessageDialog = useCallback(
		() => setMessageDialogInProgress(false),
		[setMessageDialogInProgress]
	);

	const handleClickSendAMessage = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			if (isSignedIn === true) {
				setMessageDialogInProgress(true);
			}
			if (isSignedIn === false) {
				promptForLogin();
			}
		},
		[isSignedIn, setMessageDialogInProgress, promptForLogin]
	);

	return {
		handleEndMessageDialog,
		handleClickSendAMessage,
		messageWizardInProgress,
		setMessageDialogInProgress
	};
};
