import React, { useState, useCallback, useContext, useEffect } from "react";

import ICalFeedsToImportTable from "components/PageDashboard/ICalFeedsToImportSection/ICalFeedsToImportTable";
import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { useRemoteResource } from "hooks/useRemoteResource";
import { useSendData } from "hooks/useSendData";
import { SearchResults } from "api/SearchResults";
import { saveICalFeedsToImport as sst } from "api/saveICalFeedsToImport";
import {
	getICalFeedsToImport,
	OutputRow as ICalFeedsToImportData
} from "api/getICalFeedsToImport";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { DataInAnyRow } from "./ICalFeedsToImportTable/ICalFeedsToImportTable";

const ICalFeedsToImportSection: React.FunctionComponent = () => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);

	const [, setDashboardContextState] = useContext(DashboardContext);

	const fetchICalFeedsToImport = useCallback(() => {
		if (!uid) {
			throw new Error("No uid");
		}
		return getICalFeedsToImport(
			{
				uid
			},
			{ uid }
		);
	}, [uid]);

	const doWait = isSignedIn === undefined || !uid;

	const [fetchIterationNumber, setFetchIterationNumber] = useState(1);

	const {
		isWaiting,
		isLoading,
		isError,
		output: iCalFeedsToImport
	} = useRemoteResource<SearchResults<ICalFeedsToImportData>>(
		fetchICalFeedsToImport,
		doWait,
		fetchIterationNumber,
		true
	);

	const [waitModalIsShowing, setWaitModalIsShowing] = useState(false);
	const [waitModalTitle, setWaitModalTitle] = useState("");
	const saveICalFeedsToImport = useSendData({ method: sst });

	const haveICalFeedsToImport =
		iCalFeedsToImport === undefined
			? undefined
			: iCalFeedsToImport.page.length !== 0;

	useEffect(() => {
		setDashboardContextState(oldState => ({
			...oldState,
			haveICalFeedsToImport
		}));
	}, [haveICalFeedsToImport, setDashboardContextState]);

	const onEditICalFeedsToImport = useCallback(
		(rows: { old?: DataInAnyRow; new?: DataInAnyRow }[]) => {
			const title = rows.every(row => !row.new) ? "Deleting" : "Saving";

			setWaitModalTitle(title);
			setWaitModalIsShowing(true);

			if (!uid) {
				throw new Error("No Uid");
			}

			const req = saveICalFeedsToImport(
				{
					uid,
					rows
				},
				{ uid }
			);

			const ready = req.ready.then(response => {
				setFetchIterationNumber(new Date().getTime());
				setWaitModalIsShowing(false);
			});

			return {
				ready,
				abort: () => req.abort(),
				aborted: () => req.aborted()
			};
		},
		[
			uid,
			setWaitModalTitle,
			setWaitModalIsShowing,
			setFetchIterationNumber,
			saveICalFeedsToImport
		]
	);

	// TODO:WV:20201009:Get the user started, maybe by asking for price during onboarding and / or adding a tutorial / walkthrough of how to set them up
	return (
		<ICalFeedsToImportTable
			onEdit={onEditICalFeedsToImport}
			iCalFeedsToImport={iCalFeedsToImport ? iCalFeedsToImport.page : []}
			isLoading={isLoading}
			isWaiting={isWaiting || !iCalFeedsToImport}
			isError={isError}
			waitModalTitle={waitModalTitle}
			waitModalIsShowing={waitModalIsShowing}
		/>
	);
};

export default ICalFeedsToImportSection;
