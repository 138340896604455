import React, { useEffect, useState, useCallback, useMemo } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import ToggleableButton from "components/ToggleableButton";
import ModalConfirm from "components/ModalConfirm";
import { EditButtonRenderProps } from "components/ModalField/ModalField";
import { trimText } from "utils/trimText";

interface ModalFieldTextAreaProps {
	onCancel?: () => void;
	onOK: (newValue: string) => void;
	title: string;
	value: string;
	isUnlocked?: boolean;
	formatValue?: (value: string) => string;
	editButton?: (props: EditButtonRenderProps) => React.ReactNode;
	rows?: number;
}

const ModalFieldTextArea: React.FunctionComponent<ModalFieldTextAreaProps> = ({
	onCancel = () => undefined,
	onOK,
	title,
	value,
	isUnlocked = true,
	formatValue,
	editButton,
	rows = 4
}) => {
	const [currentValue, setCurrentValue] = useState<string>(value);

	const reset = useCallback(() => {
		setCurrentValue(value);
	}, [value, setCurrentValue]);

	const [isOpen, setIsOpen] = useState(false);
	const toggleIsOpen = useCallback(() => {
		setIsOpen(oldIsOpen => !oldIsOpen);
	}, [setIsOpen]);

	useEffect(() => {
		reset();
	}, [isOpen, reset]);

	const handleOK = useCallback(() => {
		onOK(currentValue);
		setIsOpen(false);
	}, [onOK, currentValue, setIsOpen]);

	const handleCancel = useCallback(() => {
		onCancel();
		setIsOpen(false);
	}, [onCancel, setIsOpen]);

	const formattedValue = useMemo(() => {
		if (formatValue) {
			return formatValue(value);
		}

		return trimText({
			text: value ? value : "",
			max: 100,
			addEllipsis: true
		}).newText;
	}, [formatValue, value]);

	return (
		<>
			<ToggleableButton
				isEditing={isUnlocked}
				icon={faPen}
				onClick={toggleIsOpen}
				allowWrap
				label={
					trimText({
						text: formattedValue ? formattedValue : "",
						max: 100,
						addEllipsis: true
					}).newText
				}
			/>

			<ModalConfirm
				isOpen={isOpen}
				onOK={handleOK}
				onCancel={handleCancel}
				title={title}
				size="big"
			>
				<textarea
					value={currentValue}
					onChange={e => {
						setCurrentValue(e.currentTarget.value);
					}}
					rows={rows}
				/>
			</ModalConfirm>
		</>
	);
};

export default ModalFieldTextArea;
