import React, { useState, useEffect } from "react";

import FieldSelect from "components/FieldSelect";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import { DataInFreshlyEnteredRow } from "components/PageDashboard/AvailabilitySection/AvailabilityTable/AvailabilityTable";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import { useDefaultTimezone } from "hooks/useDefaultTimezone";
import { getTimezoneOptions } from "services/getTimezoneOptions";

function StepTimezone({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData,
	...rest
}: ModalWizardStepRenderProps<DataInFreshlyEnteredRow>) {
	const defaultTimezone = useDefaultTimezone();

	const [currentOptions, setCurrentOptions] = useState<FieldOption<string>[]>(
		getTimezoneOptions(
			wizardData.timezone ? wizardData.timezone : defaultTimezone
		)
	);

	useEffect(() => {
		const selectedOption = currentOptions.find(
			currentOptions => !!currentOptions.selected
		);
		if (selectedOption) {
			enableProgressToNextStep({
				timezone: selectedOption.value + ""
			});
		} else {
			disableProgressToNextStep();
		}
	}, [enableProgressToNextStep, disableProgressToNextStep, currentOptions]);

	return (
		<FieldSelect
			allowMultipleSelections={false}
			currentOptions={currentOptions}
			onNewCurrentOptions={setCurrentOptions}
			useCheckboxes={false}
		/>
	);
}

export default StepTimezone;
