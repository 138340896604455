export function deDup(
	arr: any[],
	getValue: (element: any) => string | number = e => e
) {
	const vals = arr.map(getValue);
	const uniqueVals = Array.from(new Set(vals));
	const objectsByVal: any = uniqueVals.reduce(
		(agg, cur) => ({
			...agg,
			[cur]: arr.find(a => getValue(a) === cur)
		}),
		{}
	);
	return uniqueVals.map(val => objectsByVal[val]);
}
