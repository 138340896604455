import React, { useContext, useCallback } from "react";

import ModalConfirm from "components/ModalConfirm";
import MeetingSummary from "components/MeetingSummary";
import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { OutputRow as MeetingRequestsData } from "api/getMeetingRequests";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useSendData } from "hooks/useSendData";
import { markMeetingPaid } from "api/markMeetingPaid";
import terminology from "terminology.json";

import { ModalMarkMeetingPaidContents } from "./ModalMarkMeetingPaid.styles";

interface Props {
	meeting: MeetingsDataFlattened | MeetingRequestsData | undefined;
	isOpen: boolean;
	onCancel: () => Promise<void>;
	onOK: () => Promise<void>;
}

const ModalMarkMeetingPaid: React.FunctionComponent<Props> = ({
	meeting,
	isOpen,
	onCancel,
	onOK
}) => {
	const [{ uid }] = useContext(AuthContext);

	const doMarkMeetingPaid = useSendData({ method: markMeetingPaid });

	const { id: meetingId } = meeting ? meeting : { id: undefined };
	const handleOK = useCallback(async () => {
		if (!meetingId) {
			return;
		}

		await doMarkMeetingPaid(
			{
				id: meetingId
			},
			{ uid }
		).ready;

		await onOK();
	}, [meetingId, doMarkMeetingPaid, onOK, uid]);

	if (!uid) {
		return null;
	}

	if (!meeting) {
		return null;
	}

	return (
		<ModalConfirm
			isOpen={!!meeting}
			title={`Mark ${terminology.meeting} paid - please confirm`}
			onCancel={onCancel}
			onOK={handleOK}
			okText="Yes please"
		>
			<ModalMarkMeetingPaidContents>
				<MeetingSummary event={meeting} currentUid={uid} />
			</ModalMarkMeetingPaidContents>
		</ModalConfirm>
	);
};

export default ModalMarkMeetingPaid;
