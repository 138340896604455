import React from "react";

import PageContent from "components/PageContent";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";
import Markdown from "components/Markdown";

// @ts-ignore
import safeguardingPolicy from "./safeguarding-policy.md";

const PageSafeguardingPolicy: React.FunctionComponent = () => {
	usePageTitlePrefix("Safeguarding Policy");

	return (
		<PageContent>
			<header className="major">
				<h1>Safeguarding Policy</h1>
			</header>
			<Markdown source={safeguardingPolicy} sourceType="file" replaceTokens />
		</PageContent>
	);
};

export default PageSafeguardingPolicy;
