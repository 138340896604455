import React, { useContext, useState, useCallback } from "react";

import { AlertContext } from "components/AlertProvider/AlertProvider";
import ModalConfirm from "components/ModalConfirm";
import FieldCheckbox from "components/FieldCheckbox";
import { OutputRow as MeetingRescheduleRequestsData } from "api/getMeetingRescheduleRequests";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useSendData } from "hooks/useSendData";
import { respondToMeetingRescheduleRequest } from "api/respondToMeetingRescheduleRequest";

import RescheduleRequestSummary from "./RescheduleRequestSummary";
import { ModalRespondToRescheduleRequestContents } from "./ModalRespondToRescheduleRequest.styles";

export interface Props {
	rescheduleRequest: MeetingRescheduleRequestsData | undefined;
	isOpen: boolean;
	onCancel: () => Promise<void>;
	onOK: () => Promise<void>;
}

const ModalRespondToRescheduleRequest: React.FunctionComponent<Props> = ({
	rescheduleRequest,
	isOpen,
	onCancel,
	onOK
}) => {
	const [{ uid }] = useContext(AuthContext);
	const [addAlert] = useContext(AlertContext);

	const [response, setResponse] = useState<boolean | undefined>();
	const [message, setMessage] = useState<string | undefined>();

	const resetForm = useCallback(() => {
		setMessage(undefined);
	}, [setMessage]);

	const doRespondToRescheduleRequest = useSendData({
		method: respondToMeetingRescheduleRequest
	});

	const { id: meetingRescheduleRequestId } = rescheduleRequest
		? rescheduleRequest
		: { id: undefined };

	const handleOK = useCallback(async () => {
		if (!meetingRescheduleRequestId) {
			return;
		}

		if (response === undefined) {
			addAlert({ contents: "Please indicate 'yes' or 'no'" });
			return;
		}

		await doRespondToRescheduleRequest(
			{
				id: meetingRescheduleRequestId,
				response,
				...(message ? { message } : {})
			},
			{ uid }
		).ready;

		await onOK();
		resetForm();
	}, [
		meetingRescheduleRequestId,
		doRespondToRescheduleRequest,
		response,
		message,
		addAlert,
		onOK,
		uid,
		resetForm
	]);

	const handleCancel = useCallback(async () => {
		await onCancel();
		resetForm();
	}, [onCancel, resetForm]);

	if (!uid) {
		return null;
	}

	if (!rescheduleRequest) {
		return null;
	}

	return (
		<ModalConfirm
			isOpen={!!rescheduleRequest}
			title="Approve or decline reschedule request"
			onCancel={handleCancel}
			onOK={handleOK}
			okText="Submit response"
		>
			<ModalRespondToRescheduleRequestContents>
				<RescheduleRequestSummary event={rescheduleRequest} currentUid={uid} />
				<h3>Are you happy to approve this change?</h3>
				<form>
					<div className="row gtr-uniform">
						<div className="col-6 col-12-small">
							<FieldCheckbox
								id="response-decline"
								name="response-decline"
								checked={response === false}
								labelText="No"
								onChange={e =>
									setResponse(e.currentTarget.checked ? false : response)
								}
							/>
						</div>

						<div className="col-6 col-12-small">
							<FieldCheckbox
								id="response-accept"
								name="response-accept"
								checked={response === true}
								labelText="Yes"
								onChange={e =>
									setResponse(e.currentTarget.checked ? true : response)
								}
							/>
						</div>

						<div className="col-12">
							<textarea
								placeholder="Accompanying note"
								value={message}
								onChange={e => setMessage(e.currentTarget.value)}
							/>
						</div>
					</div>
				</form>
			</ModalRespondToRescheduleRequestContents>
		</ModalConfirm>
	);
};

export default ModalRespondToRescheduleRequest;
