import React, { useState, useContext } from "react";

import { ChatContext } from "components/Chat/ChatContext";
import { OutputRow as PackagesData } from "api/getPackages";
import { OutputRow as PackageRequestsData } from "api/getPackageRequests";
import { isPackageRequest } from "./isPackageRequest";
import { useRemoteResource } from "hooks/useRemoteResource";
import { useAccountStatus } from "hooks/useAccountStatus";
import ErrorBanner from "components/ErrorBanner";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { withStripeElements } from "hocs/withStripeElements";

import Cancellation from "./Cancellation";
import MarkingPaid from "./MarkingPaid";
import Payment from "./Payment";
import PackagesTable from "./PackagesTable";

import { useFetchProducts } from "./useFetchProducts";

const PackagesSection: React.FunctionComponent = () => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);
	const [{ packagesSectionRefreshNum: refreshNum }] = useContext(
		DashboardContext
	);
	const { isMentor } = useAccountStatus();
	const [, setChatDetails] = useContext(ChatContext);

	const fetchProducts = useFetchProducts();

	const doWait = isSignedIn === undefined;

	const { isWaiting, isLoading, isError, output: products } = useRemoteResource<
		(PackagesData | PackageRequestsData)[]
	>(fetchProducts, doWait, refreshNum, true);

	const [cancellingPackageRequest, setCancellingPackageRequest] = useState<
		PackageRequestsData | undefined
	>(undefined);

	const [payingForEvent, setPayingForEvent] = useState<
		PackagesData | PackageRequestsData | undefined
	>(undefined);

	const [markingPaid, setMarkingPaid] = useState<
		PackagesData | PackageRequestsData | undefined
	>();

	return (
		<>
			<Payment
				payingForProduct={payingForEvent}
				setPayingForProduct={setPayingForEvent}
				isWaiting={isWaiting}
				isLoading={isLoading}
				isError={isError}
				products={products}
			/>

			<MarkingPaid markingPaid={markingPaid} setMarkingPaid={setMarkingPaid} />

			<Cancellation
				cancellingPackageRequest={cancellingPackageRequest}
				setCancellingPackageRequest={setCancellingPackageRequest}
				isWaiting={isWaiting}
				isLoading={isLoading}
				isError={isError}
				packageRequests={products ? products.filter(isPackageRequest) : []}
			/>

			{isError ? <ErrorBanner /> : null}
			{!isError ? (
				<PackagesTable
					isMentor={isMentor}
					products={products}
					uid={uid}
					isWaiting={isWaiting}
					isLoading={isLoading}
					onClickPay={setPayingForEvent}
					onClickMarkPaid={setMarkingPaid}
					onClickChat={setChatDetails}
					onClickCancel={setCancellingPackageRequest}
				/>
			) : null}
		</>
	);
};

export default withStripeElements(PackagesSection) as React.FunctionComponent;
