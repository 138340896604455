import React from "react";
import { Link } from "react-router-dom";

import PageContent from "components/PageContent";

import Sections from "./Sections";
import Section from "./Section";
import Banner from "./Banner";
import terminology from "terminology.json";

import decorativeImage1 from "resources/decorative-image-1.jpg";
import decorativeImage2 from "resources/decorative-image-2.jpg";
import decorativeImage3 from "resources/decorative-image-3.jpg";
import decorativeImage4 from "resources/decorative-image-4.jpg";
import decorativeImage5 from "resources/decorative-image-5.jpg";

const PageHome: React.FunctionComponent = () => {
	return (
		<PageContent type="home">
			<Banner
				mainHeading="Zero Commission Language Teaching Platform"
				subHeading="Calendar • Booking • Integrations • No commission"
				moreLinkText="Full details"
				moreArrowAltText="Learn more"
				moreLinksTargetId="what-is-aldeia"
			/>
			<Sections>
				<Section
					title="What is Aldeia?"
					imageSRC={decorativeImage1}
					id="what-is-aldeia"
				>
					<p>
						Aldeia is an online calendar and billing system for people who teach
						languages online.
					</p>
					<p>
						It is like other online teaching (lesson scheduling) platforms, but
						does not charge commission for lessons (instead, there is a{" "}
						<a href="#pricing" className="scrolly">
							small monthly fee
						</a>
						) .
					</p>
					<p>
						It integrates with other calendars and platforms via ical, and
						allows your students to pay by credit card if they want to.
					</p>
				</Section>

				<Section title="How it works" imageSRC={decorativeImage2}>
					<p>It is similar to other platforms you may be familiar with.</p>
					<p>
						Your profile contains an availability calendar and booking form. You
						can log in to manage it. Your students can log in to book and manage
						their classes.
					</p>
					<p>
						Experienced teachers can also apply to be listed in the Aldeia
						teacher directory (inclusion is at our discretion and not
						guaranteed).
					</p>
				</Section>

				<Section
					title={`Why use ${terminology.siteName}`}
					imageSRC={decorativeImage3}
				>
					<p>
						Aldeia makes admin and billing as easy as an online teaching
						platform does, but without the steep fees.
					</p>
					<p>
						It has an intuitive and easy-to-use system for managing your
						availability.
					</p>
					<p>It is built, maintained, and used by a language teacher.</p>
				</Section>

				<Section title="Features" imageSRC={decorativeImage4}>
					<ul>
						<li>Online booking interface</li>
						<li>Credit card payment form</li>
						<li>iCal import and export</li>
						<li>Easy availability management</li>
						<li>Lesson packages &amp; associated discounts</li>
						<li>Student-specific pricing</li>
					</ul>
				</Section>

				<Section title="Pricing" imageSRC={decorativeImage5} id="pricing">
					<p>
						The monthly subscription of 2.50€ covers all the features of{" "}
						{terminology.siteName}.
					</p>
					<p>
						For those who have made succesful applications to be listed in the
						teacher directory, there is an additional fee of 1€ per month.
					</p>
					<p>
						If students choose to pay by credit card, our payments provider will
						charge the teacher a small commission on each card transaction.
					</p>
				</Section>

				<Section title="Interested?" isCentered>
					<p>
						If you think it could be useful to you, please{" "}
						<Link to={`/becoming-a-${terminology.mentor}`}>request a demo</Link>
						. It will be good to meet you.
					</p>
				</Section>
			</Sections>
		</PageContent>
	);
};

export default PageHome;
