import styled from "styled-components";

export const CheckBox = styled.span<{
	checked: boolean;
	disabled?: boolean;
	takeUpLessSpace?: boolean;
}>`
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	text-transform: none !important;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;

	border-radius: 4px;
	border: solid 1px;
	content: "";
	display: inline-block;
	font-size: 0.8em;
	height: 2.0625em;
	left: 0;
	line-height: 2.0625em;
	position: absolute;
	text-align: center;
	top: 0;
	width: 2.0625em;

	border-radius: 4px;

	background: rgba(144, 144, 144, 0.075);
	border-color: #eaeaea;

	${({ disabled }) =>
		disabled
			? `
		opacity:0.2;
	`
			: ``};

	${({ checked, takeUpLessSpace }) =>
		checked
			? `
			background-color: #0070b2;
			border-color: #0070b2;
			color: #ffffff;
			${takeUpLessSpace ? `opacity:0.5` : ``}
		`
			: ``};
`;
