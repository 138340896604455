import { useState, useCallback, useContext } from "react";

import { useSendData } from "hooks/useSendData";
import { deletePaymentMethod } from "api/deletePaymentMethod";
import { useRemoteResource } from "hooks/useRemoteResource";

import { PaymentWizardContext } from "../PaymentWizardProvider";

export function useDeleteCard() {
	const doDelete = useSendData({ method: deletePaymentMethod });

	const [paymentMethodIdToDelete, setPaymentMethodIdToDelete] = useState<
		string | undefined
	>();
	const { setExistingPaymentMethodsRepeatNum } = useContext(
		PaymentWizardContext
	);

	const [isDeleting, setIsDeleting] = useState(false);

	useRemoteResource(
		useCallback(
			(arg, auth) => {
				if (!(auth && auth.uid)) {
					throw new Error("Not logged in");
				}
				if (!paymentMethodIdToDelete) {
					throw new Error("No payment method ID to delete");
				}

				setIsDeleting(true);

				const req = doDelete(
					{
						uid: auth.uid,
						paymentMethodId: paymentMethodIdToDelete
					},
					auth
				);

				const ready = req.ready.then(() => {
					// Short delay to wait for the async removal of the payment method on the server
					// TODO:WV:20230502:Do this in a way that is less of a bodge (e.g. do synchronously on server)
					setTimeout(() => {
						setExistingPaymentMethodsRepeatNum(oldNum =>
							oldNum ? oldNum + 1 : 1
						);

						setIsDeleting(false);
					}, 500);
				});

				return {
					ready: ready,
					abort: () => req.abort(),
					aborted: req.aborted,
					type: req.type
				};
			},
			[
				paymentMethodIdToDelete,
				doDelete,
				setExistingPaymentMethodsRepeatNum,
				setIsDeleting
			]
		),
		!paymentMethodIdToDelete
	);

	return {
		setPaymentMethodIdToDelete,
		paymentMethodIdToDelete,
		isDeleting
	};
}
