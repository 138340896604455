import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	id: string;
	message?: string;
}

type Output = {};

const flagValidationErrors: ValidationCallback<Output> = (input, flag) => {};

export function markPackagePaid(
	{ id, message }: Params,
	auth: Auth
): ApiRequest<{}> {
	const req = makeApiRequest("packageMarkPaid", {
		data: {
			post: {
				id
			}
		},
		flagValidationErrors,
		auth
	});

	return req;
}
