import React, { useState, useCallback, useMemo } from "react";

import ZoomLinker from "components/ZoomLinker";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import ModalFieldSelect from "components/ModalFieldSelect";
import { useSaveDataAndRefreshAccountDetailsFromApi } from "hooks/useSaveDataAndRefreshAccountDetailsFromApi";
import { useAccountStatus } from "hooks/useAccountStatus";
import ModalPleaseWait from "components/ModalPleaseWait";
import terminology from "terminology.json";

import { IntroText } from "./ZoomApiSection.styles";

const ZoomApiSection: React.FunctionComponent = () => {
	const { hasLinkedZoomAccount, hasPaidZoomAccount } = useAccountStatus();

	const [isSaving, setIsSaving] = useState(false);

	const submitDataToAPI = useSaveDataAndRefreshAccountDetailsFromApi(
		"saveAccountSettings",
		"saving your settings",
		setIsSaving,
		async () => {}
	);

	const handleOKPaidZoomAccount = useCallback(
		(newOptions: FieldOption<boolean>[]) => {
			const selectedOption = newOptions.find(
				option => option.selected === true
			);
			if (!!selectedOption) {
				const hasPaidZoomAccount = selectedOption.value;
				submitDataToAPI({ data: { hasPaidZoomAccount } });
			}
		},
		[submitDataToAPI]
	);

	const options = useMemo(
		() => [
			{
				id: "no",
				value: false,
				text: "No, it isn't",
				selected: !hasPaidZoomAccount
			},
			{
				id: "yes",
				value: true,
				text: "Yes, it is",
				selected: hasPaidZoomAccount
			}
		],
		[hasPaidZoomAccount]
	);

	return (
		<>
			<ModalPleaseWait isOpen={isSaving} title="Saving" />
			<IntroText>
				You can link your Zoom ID here if you want the option of holding your
				lessons over Zoom.
			</IntroText>
			<ZoomLinker />
			{hasLinkedZoomAccount ? (
				<>
					<p>
						Is yours a paid Zoom account? (if not, Zoom will be unavailable for
						{terminology.meetings} longer than 40 minutes).
					</p>
					<ModalFieldSelect
						requireSelection
						allowMultipleSelections={false}
						onOK={handleOKPaidZoomAccount}
						options={options}
						title="Is yours a paid Zoom account?"
						isUnlocked
					/>
				</>
			) : null}
		</>
	);
};

export default ZoomApiSection;
