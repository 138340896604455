import { loadStripe, Stripe } from "@stripe/stripe-js";

type StripePromise = Promise<Stripe | null>;

let cache: StripePromise | undefined;

export function getStripePromise(): Promise<Stripe | null> {
	if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
		throw new Error("No Stripe Key configured");
	}

	if (cache === undefined) {
		cache = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
	}
	return cache;
}
