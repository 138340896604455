import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import Button from "components/Button";

export interface ActionButtonProps {
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	to?: string;
	icon?: IconProp;
	label: string;
	isDisabled?: boolean;
	fitWidth: boolean;
	isPrimary?: boolean;
	isSecondary?: boolean;
	isTertiary?: boolean;
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = ({
	onClick,
	to,
	icon,
	label,
	isDisabled,
	fitWidth,
	isPrimary = false,
	isSecondary = false,
	isTertiary = false
}) => {
	const history = useHistory();

	const onClickParam = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
			to ? history.push(to) : onClick ? onClick(e) : undefined,
		[to, onClick, history]
	);

	return (
		<Button
			onClick={onClickParam}
			icon={icon}
			label={label}
			fitWidth={fitWidth}
			disabled={isDisabled}
			isPrimary={isPrimary}
			isSecondary={isSecondary}
			isTertiary={isTertiary}
		/>
	);
};

export default ActionButton;
