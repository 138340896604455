import moment from "moment";

import { formatDateForApi } from "services/formatDateForApi";

import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";
import { SearchResults } from "api/SearchResults";
import { flagValidationErrorsInSearchResults } from "validation/flagValidationErrorsInSearchResults";

interface RawApiOutputRow {
	id: string;
	dateStartsUTC: string;
	dateEndsJustBeforeUTC: string;
	timezone: string;
	description?: string;
	source: string;
	isExternallyBookedMeeting: boolean;
}

const flagValidationErrorsInRow: ValidationCallback<RawApiOutputRow> = (
	input,
	{ flag, checkField },
	{ uid }
) => {
	checkField("id", { type: "string" });
	checkField("dateStartsUTC", { type: "datestring" });
	checkField("dateEndsJustBeforeUTC", { type: "datestring" });
	checkField("isExternallyBookedMeeting", { type: "boolean" });

	const isOwnMeeting: boolean = !!uid && (!!input.uid && input.uid === uid);

	if (isOwnMeeting) {
		checkField("timezone", { type: "string" });
		checkField("description", { type: "string", isRequired: false });
		checkField("source", { type: "string" });
	}
};

const flagValidationErrors: ValidationCallback<
	SearchResults<RawApiOutputRow>
> = (input, flag, auth) =>
	flagValidationErrorsInSearchResults(
		flagValidationErrorsInRow,
		input,
		flag,
		auth
	);

export interface OutputRow
	extends Omit<RawApiOutputRow, "dateStartsUTC" | "dateEndsJustBeforeUTC"> {
	dateStartsUTC: Date;
	dateEndsJustBeforeUTC: Date;
}

interface GetExternalCommitmentsParams {
	id?: string;
	uid?: string;
	earliestStartDateUTC?: Date;
	mustStartBeforeUTC?: Date;
	mustEndAfterUTC?: Date;
	mustEndBeforeUTC?: Date;
	source?: string;
	cacheInMemory?: boolean;
}

export function getExternalCommitments(
	{
		id,
		uid,
		earliestStartDateUTC,
		mustStartBeforeUTC,
		mustEndAfterUTC,
		mustEndBeforeUTC,
		source,
		cacheInMemory
	}: GetExternalCommitmentsParams,
	auth: Auth
): ApiRequest<SearchResults<OutputRow>> {
	const req = makeApiRequest("externalCommitments", {
		cacheInMemory,
		auth,
		data: {
			get: {
				...(id ? { id } : {}),
				...(uid ? { uid } : {}),
				...(earliestStartDateUTC
					? { earliestStartDateUTC: formatDateForApi(earliestStartDateUTC) }
					: {}),
				...(mustStartBeforeUTC
					? { mustStartBeforeUTC: formatDateForApi(mustStartBeforeUTC) }
					: {}),
				...(mustEndAfterUTC
					? { mustEndAfterUTC: formatDateForApi(mustEndAfterUTC) }
					: {}),
				...(mustEndBeforeUTC
					? { mustEndBeforeUTC: formatDateForApi(mustEndBeforeUTC) }
					: {}),
				...(source ? { source } : {})
			}
		},
		flagValidationErrors
	});

	return {
		ready: req.ready.then(results => {
			return {
				page: results
					? results.page.map(row => {
							const { dateStartsUTC, dateEndsJustBeforeUTC, ...rest } = row;

							return {
								...rest,
								dateStartsUTC: moment.utc(dateStartsUTC).toDate(),
								dateEndsJustBeforeUTC: moment
									.utc(dateEndsJustBeforeUTC)
									.toDate()
							};
					  })
					: []
			};
		}),
		abort: () => req.abort(),
		aborted: () => req.aborted(),
		type: req.type
	};
}
