import { useCallback } from "react";

import { SearchResultsWithCountAndPagination } from "api/SearchResults";
import { FieldOption } from "components/FieldSelect/FieldSelect";

type IsSelectedEntry<T> = (entry: T, selectedValue: string) => boolean;
type CursorValue<T> = (entry: T) => string | null;

export type Pagination<
	CursorDataType = string,
	CursorFieldDataType = string
> = {
	cursor?: CursorDataType;
	cursorType: "after" | "before";
	cursorField?: CursorFieldDataType;
};

type getOptionsFromPage<T> = (
	page: T[],
	selectedValue: string | null
) => FieldOption<string | null>[];

export const usePaginatedOptions = <T>(
	selectedValue: string | null,
	lastFetch: SearchResultsWithCountAndPagination<T, Date> | undefined,
	isSelectedEntry: IsSelectedEntry<T>,
	cursorValue: CursorValue<T>,
	pagination: Pagination,
	setPagination: React.Dispatch<React.SetStateAction<Pagination>>,
	getOptionsFromPage: getOptionsFromPage<T>
) => {
	const [lastEntry] = lastFetch ? lastFetch.page.slice(-1) : [];
	const lastEntryCursor = lastEntry ? cursorValue(lastEntry) : undefined;

	const haveNextPage =
		!!lastFetch && !!lastFetch.nextPage && lastFetch.nextPage.length !== 0;

	const goToNextPage = useCallback(() => {
		if (lastEntryCursor) {
			setPagination({
				cursor: lastEntryCursor,
				cursorType: "after"
			});
		}
	}, [setPagination, lastEntryCursor]);

	const havePreviousPage =
		!!lastFetch && !!lastFetch.prevPage && lastFetch.prevPage.length !== 0;

	const [firstEntry] = !!lastFetch && !!lastFetch.page ? lastFetch.page : [];
	const firstEntryCursor = firstEntry ? cursorValue(firstEntry) : undefined;
	const goToPreviousPage = useCallback(() => {
		if (firstEntryCursor) {
			setPagination({
				cursor: firstEntryCursor,
				cursorType: "before"
			});
		}
	}, [setPagination, firstEntryCursor]);

	const options = getOptionsFromPage(
		lastFetch ? lastFetch.page : [],
		selectedValue
	);

	return {
		goToNextPage,
		goToPreviousPage,
		haveNextPage,
		havePreviousPage,
		options
	};
};
