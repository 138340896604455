import React from "react";

import { AnchorInner } from "./Anchor.styles";

interface Props {
	id: string;
}

const Anchor: React.FunctionComponent<Props> = ({ id }) => {
	return <AnchorInner id={id} />;
};

export default Anchor;
