import { OutputRow as SingleSessionPricesData } from "api/getSingleSessionPrices";
import { formatPrice } from "services/formatPrice";
import { formatSessionDuration } from "services/formatSessionDuration";

export interface Params {
	singleSessionPrice: SingleSessionPricesData;
	locationCountryCode: string;
}

export function formatSessionPriceAndDuration({
	singleSessionPrice,
	locationCountryCode
}: Params) {
	return `${formatSessionDuration({
		sessionDurationMinutes: singleSessionPrice.sessionDurationMinutes
	})} (${formatPrice({
		currencyCode: singleSessionPrice.currency,
		amount: singleSessionPrice.price,
		textIfAmountIsZero: "free session",
		locationCountryCode
	})})`;
}
