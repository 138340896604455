import React from "react";

import PageContent from "components/PageContent";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";
import Markdown from "components/Markdown";

// @ts-ignore
import termsOfUse from "./terms-of-use.md";

const PageTermsOfUse: React.FunctionComponent = () => {
	usePageTitlePrefix("Terms of Use");

	return (
		<PageContent type="printabledocument" bypassTermsWall>
			<header className="major">
				<h1>Terms of Use</h1>
			</header>
			<Markdown
				source={termsOfUse}
				sourceType="file"
				replaceTokens
				encodeRawHtmlEntities={false}
			/>
		</PageContent>
	);
};

export default PageTermsOfUse;
