import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import firebase from "setupFirebase";

// NB uiConfig is a function rather than an object literal for the sake of testing
// (firebase.auth needs to be overridden and it does not work correctly if defined
// here as an object literal rather than a function that can be called while
// rendering the component)
const uiConfig = () => ({
	signInFlow: "popup",
	signInOptions: [
		firebase.auth.EmailAuthProvider.PROVIDER_ID,
		{
			provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
			customParameters: { prompt: "select_account" }
		}
	],
	credentialHelper: "none",
	callbacks: {
		// Avoid redirects after sign-in.
		signInSuccessWithAuthResult: () => false
	}
});

const LoginOrSignup: React.FunctionComponent = () => {
	return (
		<StyledFirebaseAuth uiConfig={uiConfig()} firebaseAuth={firebase.auth()} />
	);
};

export default LoginOrSignup;
