import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

import { DataInFreshlyEnteredRow } from "components/PageDashboard/AvailabilitySection/AvailabilityTable/AvailabilityTable";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import FieldHourOfDay from "components/FieldHourOfDay";

function StepTimeFrom({
	enableProgressToNextStep,
	disableProgressToNextStep
}: ModalWizardStepRenderProps<DataInFreshlyEnteredRow>) {
	const [selectedTime, setSelectedTime] = useState<number | undefined>();

	useEffect(() => {
		if (selectedTime === undefined) {
			disableProgressToNextStep();
		} else {
			enableProgressToNextStep({
				timeFirstAvailableLocaltime: moment.duration({
					hours: selectedTime
				})
			});
		}
	}, [enableProgressToNextStep, disableProgressToNextStep, selectedTime]);

	return (
		<FieldHourOfDay selectedTime={selectedTime} onChange={setSelectedTime} />
	);
}

export default StepTimeFrom;
