import React, { useCallback, useContext } from "react";
import { useHistory } from "react-router";

import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { useHandleItemFromURL } from "hooks/useHandleItemFromURL";
import { useStripQueryStringFromAddressBar } from "hooks/useStripQueryStringFromAddressBar";

import ModalSignMeetingOff, {
	Props as ModalSignMeetingOffProps
} from "./ModalSignMeetingOff";

interface Props {
	signingOffFinishedMeeting: MeetingsDataFlattened | undefined;
	setSigningOffFinishedMeeting: React.Dispatch<
		React.SetStateAction<MeetingsDataFlattened | undefined>
	>;
	isWaiting: boolean;
	isLoading: boolean;
	isError: boolean;
	events: MeetingsDataFlattened[] | undefined;
}

const SigningOff: React.FunctionComponent<Props> = ({
	setSigningOffFinishedMeeting,
	signingOffFinishedMeeting,
	isWaiting,
	isLoading,
	isError,
	events
}) => {
	const stripQueryStringFromAddressBar = useStripQueryStringFromAddressBar();
	useHandleItemFromURL<MeetingsDataFlattened>({
		key: "meetingSignOff",
		itemsList: {
			isWaiting,
			isLoading,
			isError,
			items: events
		},
		handleFoundItem: useCallback(
			event => {
				setSigningOffFinishedMeeting(event);
				stripQueryStringFromAddressBar();
			},
			[setSigningOffFinishedMeeting, stripQueryStringFromAddressBar]
		)
	});

	const handleCancelSignMeetingOff = useCallback(async () => {
		setSigningOffFinishedMeeting(undefined);
	}, [setSigningOffFinishedMeeting]);

	const [, setDashboardContextState] = useContext(DashboardContext);

	const history = useHistory();

	const handleMeetingSignedOffOK = useCallback(
		async ({
			requestedRefund,
			requestedRebook,
			hostUid
		}: Parameters<ModalSignMeetingOffProps["onOK"]>[0]) => {
			setSigningOffFinishedMeeting(undefined);
			setDashboardContextState(oldState => ({
				...oldState,
				meetingsSectionRefreshNum: new Date().getTime()
			}));

			if (requestedRebook) {
				// TODO:WV:20200819:Before doing the following, show a message explaining what is happening (and perhaps add a heading to the booking modal to make it clearer as well)
				// TODO:WV:20210510:Only do it if the data transmission was successful (and possibly in the other location as well)
				history.push(`/user-id/${hostUid}?showBookingInterface=1`);
			}
		},
		[setDashboardContextState, setSigningOffFinishedMeeting, history]
	);

	return (
		<ModalSignMeetingOff
			meeting={signingOffFinishedMeeting}
			isOpen={!!signingOffFinishedMeeting}
			onCancel={handleCancelSignMeetingOff}
			onOK={handleMeetingSignedOffOK}
		/>
	);
};

export default SigningOff;
