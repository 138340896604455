import React, { useState, useCallback } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { DataInAnyRow } from "components/PageDashboard/SessionTypesSection/SessionTypesTable/SessionTypesTable";

function StepSessionDescription({
	enableProgressToNextStep,
	disableProgressToNextStep
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [currentValue, setCurrentValue] = useState("");

	const handleNewValue = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			const sessionDescription = e.currentTarget.value;
			setCurrentValue(e.currentTarget.value);

			const haveValidDescription =
				!!sessionDescription && !/^[\s]+$/.test(sessionDescription);

			if (!haveValidDescription) {
				disableProgressToNextStep({
					alert: {
						title: "One thing missing",
						contents: "Please enter a short description."
					}
				});
				return;
			}

			enableProgressToNextStep({
				sessionDescription
			});
		},
		[enableProgressToNextStep, disableProgressToNextStep, setCurrentValue]
	);

	return <textarea value={currentValue} onChange={handleNewValue} rows={4} />;
}

export default StepSessionDescription;
