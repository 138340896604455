import styled from "styled-components";

export const HelpText = styled.div`
	margin: 0 0 1em 0;
`;

export const TextFieldContainer = styled.div`
	margin: 0 0 1em 0;
`;

export const Losenge = styled.span<{ isDeleted: boolean }>`
	border: 1px solid #eaeaea;
	background-color: #f7f7f7;
	border-radius: 0.4em;
	padding: 0.1em 2em 0.1em 0.8em;
	margin: 0 1em 1em 0;
	display: inline-block;
	position: relative;
	${props => (props.isDeleted ? `text-decoration:line-through` : ``)};
`;

export const DeleteTagButton = styled.button`
	&,
	&:hover,
	&:active {
		border: none;
		box-shadow: none;
	}
	background: transparent;
	padding: 0.1em 0.7em 0.1em 0.8em;
	margin: 0;
	line-height: normal;
	height: auto;
	display: inline-block;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
`;
