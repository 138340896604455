import React from "react";

import Button from "components/Button";

interface FilterButtonProps {
	disabled: boolean;
	onClick: (e: MouseEvent) => void;
	title: string;
	numActive: number;
}

const FilterButton: React.FunctionComponent<FilterButtonProps> = ({
	disabled,
	onClick,
	title,
	numActive
}) => {
	return (
		<li>
			<Button
				disabled={disabled}
				onClick={onClick}
				label={`${title}${numActive ? ` ∿ ${numActive}` : ``}`}
			/>
		</li>
	);
};

export default FilterButton;
