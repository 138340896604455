import React from "react";
import styled from "styled-components";

import { useUserProfile } from "hooks/useUserProfile";
import dummyAvatarImage from "resources/dummy-avatar.jpeg";
import terminology from "terminology.json";

const StyledImage = styled.img`
	border-radius: 50%;
	width: 50px;
	vertical-align: middle;
	margin-left: 0.75em;
`;

interface Props {
	vanityUrl?: string;
	uid?: string;
}

const Avatar: React.FunctionComponent<Props> = ({ vanityUrl, uid }) => {
	const { output: profileData } = useUserProfile({ vanityUrl, uid });

	return (
		<StyledImage
			src={
				profileData && profileData.avatarUrl
					? profileData.avatarUrl
					: dummyAvatarImage
			}
			alt={`${terminology.mentor}${
				profileData && profileData.displayName
					? `: ${profileData.displayName}`
					: ``
			}`}
		/>
	);
};

export default Avatar;
