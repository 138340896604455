import React, { useEffect, useState, useCallback } from "react";
import moment from "moment-timezone";

import { DataInAnyRow } from "components/PageDashboard/ExternalCommitmentsSection/ExternalCommitmentsTable/ExternalCommitmentsTable";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import FieldHourOfDay from "components/FieldHourOfDay";
import { getDateFromSelection } from "components/FieldDate/FieldDateSelection";
import { isInteger } from "utils/isInteger";

function StepTimeFrom({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [selectedTime, setSelectedTime] = useState<number | undefined>();

	useEffect(() => {
		if (selectedTime === undefined) {
			disableProgressToNextStep();
			return;
		}
		if (isNaN(selectedTime)) {
			disableProgressToNextStep();
			return;
		}
		if (!isInteger(selectedTime)) {
			disableProgressToNextStep();
			return;
		}

		enableProgressToNextStep({
			timeStartsInRowTimezone: moment.duration(selectedTime, "hours")
		});
	}, [enableProgressToNextStep, disableProgressToNextStep, selectedTime]);

	const {
		dateStartsInRowTimezone,
		dateEndsJustBeforeInRowTimezone,
		timeEndsJustBeforeInRowTimezone,
		timezone
	} = wizardData;

	const checkDisabled = useCallback(
		(time: number) => {
			if (dateStartsInRowTimezone === undefined) {
				return false;
			}

			if (timezone === undefined) {
				return false;
			}

			const thisDateTime = moment(
				getDateFromSelection(
					{ ...dateStartsInRowTimezone, hours: time },
					timezone
				)
			);

			const currentHour = moment()
				.tz(timezone)
				.startOf("hour");

			if (thisDateTime.isSameOrBefore(currentHour)) {
				return true;
			}

			if (dateEndsJustBeforeInRowTimezone === undefined) {
				return false;
			}
			if (timeEndsJustBeforeInRowTimezone === undefined) {
				return false;
			}

			const dateTimeEndsJustBefore = moment(
				getDateFromSelection(
					{
						...dateEndsJustBeforeInRowTimezone,
						hours: timeEndsJustBeforeInRowTimezone.hours()
					},
					timezone
				)
			);

			return thisDateTime.isSameOrAfter(dateTimeEndsJustBefore);
		},
		[
			dateStartsInRowTimezone,
			dateEndsJustBeforeInRowTimezone,
			timeEndsJustBeforeInRowTimezone,
			timezone
		]
	);

	return (
		<FieldHourOfDay
			selectedTime={selectedTime}
			onChange={setSelectedTime}
			isDisabled={checkDisabled}
		/>
	);
}

export default StepTimeFrom;
