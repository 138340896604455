import { useCallback } from "react";

import { ValidationCallback } from "validation/validate";

import {
	ModalWizardStep,
	OnCompleteOutput
} from "components/ModalWizard/ModalWizard";
import { useResettableState } from "hooks/useResettableState";

import { getVisibleSteps } from "./getVisibleSteps";
import { getCurrentStepIndex } from "./getCurrentStepIndex";
import { getStepCount } from "./getStepCount";
import { getNextButtonText } from "./getNextButtonText";
import { useStepNavigation } from "./useStepNavigation";
import { useWizardComplete } from "./useWizardComplete";

type Params<CompletedWizardData> = {
	steps: ModalWizardStep<CompletedWizardData>[];
	initialData: Partial<CompletedWizardData>;
	onCancel: () => void;
	onComplete: (wizardData: CompletedWizardData) => OnCompleteOutput;
	validateCompletedData: ValidationCallback<CompletedWizardData>;
};

export type ModalWizardFormState<CompletedWizardData> = {
	wizardData: Partial<CompletedWizardData>;
	formIsComplete: boolean;
	currentStepKey: string;
	completedSteps: string[];
};

export function useModalWizard<CompletedWizardData>({
	steps,
	initialData,
	onCancel,
	onComplete,
	validateCompletedData
}: Params<CompletedWizardData>) {
	const [formState, setFormState, resetWizard] = useResettableState<
		ModalWizardFormState<CompletedWizardData>
	>({
		wizardData: { ...initialData },
		formIsComplete: false,
		currentStepKey: getVisibleSteps(steps, initialData)[0].key,
		completedSteps: []
	});

	const cancelWizard = useCallback(() => {
		resetWizard();
		onCancel();
	}, [onCancel, resetWizard]);

	const allVisibleSteps = getVisibleSteps(steps, formState.wizardData);

	const currentStepIndex = getCurrentStepIndex(
		allVisibleSteps,
		formState.currentStepKey
	);
	const handleCloseModal = useCallback(() => {
		if (currentStepIndex === 0) {
			cancelWizard();
		}
	}, [currentStepIndex, cancelWizard]);

	useWizardComplete(onComplete, formState, resetWizard, validateCompletedData);

	const {
		stepNumber,
		totalNumSteps,
		anyVisibleLaterStepsMightBeSkippable
	} = getStepCount(allVisibleSteps, formState);

	const {
		onOK,
		enableProgressToNextStep,
		disableProgressToNextStep,
		haveSystemError,
		setHaveSystemError,
		handleMoveToNextStep
	} = useStepNavigation(allVisibleSteps, currentStepIndex, setFormState);

	return {
		currentStep: allVisibleSteps[currentStepIndex],
		cancelWizard,
		handleCloseModal,
		onOK,
		anyVisibleLaterStepsMightBeSkippable,
		haveSystemError,
		allVisibleSteps,
		nextButtonText: getNextButtonText(allVisibleSteps, formState),
		wizardData: formState.wizardData,
		setHaveSystemError,
		handleMoveToNextStep,
		disableProgressToNextStep,
		enableProgressToNextStep,
		stepNumber,
		totalNumSteps
	};
}
