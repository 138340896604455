export function abortableFetch(input: RequestInfo, init: RequestInit = {}) {
	const controller = new AbortController();
	const signal = controller.signal;

	return {
		abort: () => controller.abort(),
		aborted: () => controller.signal.aborted,
		ready: fetch(input, { ...init, signal })
	};
}
