import React, { useState, useCallback } from "react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import ModalAlert from "components/ModalAlert";
import Button from "components/Button";
import ToggleableButton from "components/ToggleableButton";

import { MenuButtonContainer } from "./ModalMenu.styles";

export interface ModalMenuItem {
	onClick: () => void;
	label: string;
	icon?: IconProp;
	isDangerous?: boolean;
}

type ModalMenuProps = {
	items: ModalMenuItem[];
	title: string;
	isUnlocked?: boolean;
};

function ModalMenu({
	items,
	title,
	isUnlocked = true,
	...rest
}: ModalMenuProps) {
	const [isOpen, setIsOpen] = useState(false);
	const toggleIsOpen = useCallback(() => {
		setIsOpen(oldIsOpen => !oldIsOpen);
	}, [setIsOpen]);

	return (
		<>
			<ToggleableButton
				isEditing={isUnlocked}
				icon={faCaretDown}
				onClick={toggleIsOpen}
			/>

			<ModalAlert
				isOpen={isOpen}
				title={title}
				okText={"Close"}
				onDismiss={() => setIsOpen(false)}
				buttonsFitWidth
			>
				{items.map((item, i) => (
					<MenuButtonContainer key={i} isLast={i === items.length - 1}>
						<Button
							fitWidth
							isNarrow
							isDangerous={!!item.isDangerous}
							onClick={() => {
								item.onClick();
								setIsOpen(false);
							}}
							allowWrap={true}
							icon={item.icon}
							label={item.label}
							isSecondary
						/>
					</MenuButtonContainer>
				))}
			</ModalAlert>
		</>
	);
}

export default ModalMenu;
