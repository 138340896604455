import React from "react";

import { SimpleTableInner } from "./SimpleTable.styles";

interface Props {
	rows: React.ReactNode[][];
}

const SimpleTable: React.FunctionComponent<Props> = ({ rows }) => {
	return (
		<SimpleTableInner className="alt">
			<tbody>
				{rows.map((row, i) => (
					<tr key={i}>
						{row.map((cell, j) => (
							<td key={j}>{cell}</td>
						))}
					</tr>
				))}
			</tbody>
		</SimpleTableInner>
	);
};

export default SimpleTable;
