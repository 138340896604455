import React from "react";
import Modal from "react-modal";

type Props = React.ComponentProps<typeof Modal>;

const AldeiaModal: React.FunctionComponent<Props> = ({ children, ...rest }) => {
	return (
		<Modal ariaHideApp={process.env.NODE_ENV !== "test"} {...rest}>
			{children}
		</Modal>
	);
};

export const setAppElement = Modal.setAppElement;

export default AldeiaModal;
