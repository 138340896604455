import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useProfileData } from "hooks/useProfileData";

const StyledVideo = styled.video`
	width: 100%;
	margin-bottom: 1.5em;
`;

const ProfileVideo: React.FunctionComponent = () => {
	const { vanityUrl, uid } = useParams<{ vanityUrl: string; uid: string }>();

	const { output: profileData } = useProfileData(vanityUrl, uid);

	const haveVideo = !!profileData && !!profileData.videoUrl;
	return haveVideo ? (
		<StyledVideo
			src={
				profileData && profileData.videoUrl ? profileData.videoUrl : undefined
			}
			poster={
				profileData && profileData.videoPosterUrl
					? profileData.videoPosterUrl
					: undefined
			}
			controls
		/>
	) : null;
};

export default ProfileVideo;
