export function safeTsNotNullish<T>(arg: T | undefined | null): T {
	if (arg === undefined) {
		throw new Error("Argument was undefined");
	}

	if (arg === null) {
		throw new Error("Argument was null");
	}

	return arg;
}
