import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTwitter,
	faFacebookF,
	faYoutube,
	faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { FooterIconLinkElement } from "./FooterIconLink.styles";

type LinkStyle = "twitter" | "facebook" | "youtube" | "instagram" | "email";

interface ButtonIconProps {
	href: string;
	linkstyle: LinkStyle;
}

const ButtonIcon: React.FunctionComponent<ButtonIconProps> = ({
	href,
	linkstyle
}) => {
	const { icon, label } = getLinkStyle(linkstyle);

	return (
		<FooterIconLinkElement href={href} linkstyle={linkstyle}>
			<FontAwesomeIcon icon={icon} />
			<span className="label">{label}</span>
		</FooterIconLinkElement>
	);
};

export default ButtonIcon;

function getLinkStyle(linkstyle: LinkStyle) {
	switch (linkstyle) {
		case "twitter":
			return {
				label: "Twitter",
				icon: faTwitter
			};
		case "facebook":
			return {
				label: "Facebook",
				icon: faFacebookF
			};
		case "youtube":
			return {
				label: "YouTube",
				icon: faYoutube
			};
		case "instagram":
			return {
				label: "Instagram",
				icon: faInstagram
			};
		case "email":
			return {
				label: "Email",
				icon: faEnvelope
			};
		default:
			throw new Error("Unknown brand");
	}
}
