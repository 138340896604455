import { useCallback } from "react";

import { getLocationCountries } from "api/getLocationCountries";
import { useRemoteResource } from "./useRemoteResource";
import { FieldOption } from "components/FieldSelect/FieldSelect";

export function useLocationCountryOptions() {
	const fetchLocationCountryOptions = useCallback((arg, auth) => {
		const req = getLocationCountries({ cacheInMemory: true }, auth);
		return {
			ready: req.ready.then(response => {
				const options = response
					? response
							.map(({ code, name }) => {
								return {
									id: code,
									value: code,
									text: name
								};
							})
							.sort((a, b) => a.text.localeCompare(b.text))
					: [];
				return options;
			}),
			abort: () => {
				req.abort();
			},
			aborted: () => req.aborted(),
			type: req.type
		};
	}, []);

	const options = useRemoteResource<FieldOption<string>[]>(
		fetchLocationCountryOptions,
		false
	);

	return options;
}
