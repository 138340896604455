import styled from "styled-components";

export const CardDetailsFormSection = styled.div`
	margin-top: 1em;
	margin-bottom: 2em;
`;

export const ImportantNote = styled.div`
	margin-top: 2em;
	border: 1px solid #aaa;
	padding: 1em;
`;
