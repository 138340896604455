import React from "react";

import { OutputRow as PackagesData } from "api/getPackages";
import { OutputRow as PackageRequestsData } from "api/getPackageRequests";
import { formatPackageDetails } from "services/formatPackageDetails";

import SimpleTable from "components/SimpleTable";

interface Props {
	product: PackagesData | PackageRequestsData;
	currentUid: string;
}

const PackageSummary: React.FunctionComponent<Props> = ({
	product,
	currentUid
}) => {
	const { partnersName, sessions } = formatPackageDetails(product, currentUid);

	const rows = [
		[<strong>Sessions</strong>, sessions],
		[<strong>With</strong>, partnersName]
	];

	return <SimpleTable rows={rows} />;
};

export default PackageSummary;
