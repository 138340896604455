import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	locationCountryCode: string;
	cacheInMemory: boolean;
}

type Output = {
	locationDistrict: string;
}[];

const flagValidationErrors: ValidationCallback<Output> = (input, { flag }) => {
	if (!Array.isArray(input)) {
		flag(0, "not an array");
	}

	for (const [rowOffset, value] of input.entries()) {
		const { locationDistrict } = value;

		if (locationDistrict === undefined) {
			flag(rowOffset, "locationDistrict is missing");
		} else if (!(typeof locationDistrict === "string")) {
			flag(rowOffset, "locationDistrict is invalid");
		}
	}
};

export function getLocationDistricts(
	{ locationCountryCode, cacheInMemory }: Params,
	auth: Auth
) {
	return makeApiRequest("districts", {
		auth,
		cacheInMemory,
		data: {
			get: {
				locationCountryCode
			}
		},
		flagValidationErrors
	});
}
