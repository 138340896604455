import React, { useCallback } from "react";

import FieldCheckbox from "components/FieldCheckbox";

interface Props {
	isChecked: boolean;
	onChange: (newValue: boolean) => void;
}

const AcceptTerms: React.FunctionComponent<Props> = ({
	isChecked,
	onChange
}) => {
	const handleChangeCheckbox = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onChange(e.currentTarget.checked);
		},
		[onChange]
	);

	return (
		<>
			<p>
				Please confirm that you have read and accept our{" "}
				<a href="/terms" target="_blank">
					terms of use
				</a>{" "}
				and{" "}
				<a href="/privacy-policy" target="_blank">
					privacy policy
				</a>
				.
			</p>
			<p>
				<FieldCheckbox
					id="chk_terms"
					name="chk_terms"
					labelText="I have read and accept the terms of use and privacy policy"
					checked={isChecked}
					onChange={handleChangeCheckbox}
				/>
			</p>
		</>
	);
};

export default AcceptTerms;
