import React, { useEffect, useState, useContext } from "react";
import moment from "moment-timezone";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import FieldDate from "components/FieldDate";
import { validateEmptySelection } from "components/FieldDate/validateEmptySelection";
import { validateCompleteSelection } from "components/FieldDate/validateCompleteSelection";
import { validate } from "validation/validate";
import { DataInAnyRow } from "components/PageDashboard/ExternalCommitmentsSection/ExternalCommitmentsTable/ExternalCommitmentsTable";

import {
	getEmptySelection,
	Selection,
	isEmpty,
	getSelectionFromDate,
	getDateFromSelection
} from "components/FieldDate/FieldDateSelection";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

function StepDateTo({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [currentSelection, setCurrentSelection] = useState<Selection>(
		wizardData.dateEndsJustBeforeInRowTimezone
			? wizardData.dateEndsJustBeforeInRowTimezone
			: wizardData.dateStartsInRowTimezone
			? wizardData.dateStartsInRowTimezone
			: getEmptySelection()
	);
	const [{ uid }] = useContext(AuthContext);

	useEffect(() => {
		if (
			!(
				validate(currentSelection, {
					doValidate: validateEmptySelection,
					auth: { uid }
				}) ||
				validate(currentSelection, {
					doValidate: validateCompleteSelection,
					auth: { uid }
				})
			)
		) {
			disableProgressToNextStep();
			return;
		}

		enableProgressToNextStep({
			dateEndsJustBeforeInRowTimezone: isEmpty(currentSelection)
				? undefined
				: currentSelection
		});
		return;
	}, [
		enableProgressToNextStep,
		disableProgressToNextStep,
		currentSelection,
		uid
	]);

	return (
		<FieldDate
			currentSelection={currentSelection}
			onNewSelection={setCurrentSelection}
			mustBeAfter={
				wizardData.dateStartsInRowTimezone && wizardData.timezone
					? getSelectionFromDate(
							moment(
								getDateFromSelection(
									wizardData.dateStartsInRowTimezone,
									wizardData.timezone
								)
							)
								.subtract(1, "day")
								.toDate(),
							wizardData.timezone
					  )
					: undefined
			}
		/>
	);
}

export default StepDateTo;
