import styled from "styled-components";

export const AlertInner = styled.div`
	border: 2px solid #83bbde;
	padding: 1em;
	margin-bottom: 2em;
	font-weight: bold;

	display: table;
	width: 100%;
`;

export const AlertInnerInner = styled.div`
	display: table-row;
`;

export const IconWrapper = styled.span`
	padding-right: 1em;

	display: table-cell;
`;

export const ContentsWrapper = styled.span`
	display: table-cell;
`;

export const ContentsItem = styled.div<{ isLast?: boolean }>`
	${props => (props.isLast ? `` : `margin-bottom:1em;`)};
`;

export const DismissButtonContainer = styled.div`
	margin: 1.25em 0 0.5em 0;
`;
