import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	hostUid: string;
	guestUid: string;
	packagePriceId: string;
	message: string;
}

type Output = {};
const flagValidationErrors: ValidationCallback<Output> = () => {};

export function sendPackageRequest(
	{ hostUid, guestUid, message, packagePriceId }: Params,
	auth: Auth
): ApiRequest<Output> {
	return makeApiRequest("packageRequest", {
		auth,
		data: {
			post: {
				hostUid,
				guestUid,
				message,
				packagePriceId
			}
		},
		flagValidationErrors
	});
}
