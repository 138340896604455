import styled from "styled-components";

import ActionButtons from "components/ActionButtons";

export const ModalButtonsWrapper = styled.div`
	border-top: 1px solid #eaeaea;
	padding-top: 1em;
`;

export const ModalButtonsInner = styled(ActionButtons)`
	margin-bottom: 0;
`;
