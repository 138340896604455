import React from "react";

import Modal from "components/Modal";
import ModalScrollingContents from "components/Modal/ModalScrollingContents";
import ModalButtons from "components/Modal/ModalButtons";
import ModalHeading from "components/Modal/ModalHeading";
import ModalHelpText from "components/Modal/ModalHelpText";
import { useModalStyles, ModalSize } from "hooks/useModalStyles";

interface ModalConfirmProps {
	isOpen: boolean;
	isHidden?: boolean;
	onCancel: () => void;
	onOK: () => void;
	okText?: string | null;
	cancelText?: string | null;
	title: string;
	helptext?: string;
	onContents?: (node: any) => void;
	size?: ModalSize;
	includePaddingUnderContents?: boolean;
	OKButtonIsDisabled?: boolean;
}

const ModalConfirm: React.FunctionComponent<ModalConfirmProps> = ({
	isOpen,
	isHidden = false,
	onCancel,
	onOK,
	okText = "OK",
	cancelText = "Cancel",
	title,
	helptext,
	onContents,
	size = "medium",
	includePaddingUnderContents = true,
	OKButtonIsDisabled = false,
	children
}) => {
	const modalStyles = useModalStyles(size, isHidden);

	return (
		<Modal isOpen={isOpen} style={modalStyles} onRequestClose={onCancel}>
			<ModalHeading>{title}</ModalHeading>
			{helptext ? <ModalHelpText>{helptext}</ModalHelpText> : null}
			<ModalScrollingContents
				onContents={onContents}
				includePaddingBottom={includePaddingUnderContents}
			>
				{children}
			</ModalScrollingContents>
			<ModalButtons
				onOK={onOK}
				okText={okText}
				cancelText={cancelText}
				onCancel={onCancel}
				OKButtonIsDisabled={OKButtonIsDisabled}
			/>
		</Modal>
	);
};

export default ModalConfirm;
