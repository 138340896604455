import React from "react";
import styled from "styled-components";

import ErrorBanner from "components/ErrorBanner";

const ChatWidgetErrorContainer = styled.div`
	bottom: 0;
	display: flex;
	flex-direction: column;
	margin: 0 20px 20px 0;
	max-width: 370px;
	position: fixed;
	right: 0;
	width: 90vw;
	z-index: 9999;

	.error-banner {
		background: white;
	}
`;

interface ChatWidgetErrorProps {
	onClose: () => void;
}

const ChatWidgetError: React.FunctionComponent<ChatWidgetErrorProps> = ({
	onClose
}) => {
	return (
		<ChatWidgetErrorContainer>
			<ErrorBanner>
				Sorry, there was an error loading the chat widget. Please try again
				later.
				{/* TODO:WV:20231027:Do this properly */}
				<button onClick={onClose}>Close this banner</button>
			</ErrorBanner>
		</ChatWidgetErrorContainer>
	);
};

export default ChatWidgetError;
