import React, { useEffect, useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

import { AlertContext } from "components/AlertProvider/AlertProvider";
import ModalAlert from "components/ModalAlert";
import ModalAcceptTerms from "components/ModalAcceptTerms";
import { useScreenSizeIsMax } from "hooks/useScreenSizeIsMax";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import logoImage from "resources/logo192.png";
import { ucFirst } from "utils/ucFirst";
import terminology from "terminology.json";

import { initTemplateBehaviours } from "./initTemplateBehaviours";
import { HeaderLogo, MenuDisplayName } from "./PageContent.styles";

import Footer from "./Footer";

export interface Props {
	type?:
		| "generic"
		| "admin"
		| "home"
		| "authwall"
		| "termswall"
		| "printabledocument"
		| "resources";
	bypassTermsWall?: boolean;
}

const PageContent: React.FunctionComponent<Props> = ({
	type: suppliedType = "generic",
	bypassTermsWall = false,
	children
}) => {
	useEffect(initTemplateBehaviours, []);

	const isSmallScreen = useScreenSizeIsMax("small");

	const [
		{
			isSignedIn,
			displayName,
			hasAcceptedTerms,
			termsWallIsDisabled,
			logOut,
			promptForLogin
		}
	] = useContext(AuthContext);

	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const handleClickLogin = useCallback(
		(e?: any) => {
			if (e) {
				e.preventDefault();
			}
			promptForLogin();
			setMenuIsOpen(false);
		},
		[setMenuIsOpen, promptForLogin]
	);

	const handleClickLogout = useCallback(
		(
			e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
		) => {
			e.preventDefault();
			logOut();
		},
		[logOut]
	);

	const toggleSideMenu = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			e.preventDefault();
			setMenuIsOpen(oldState => !oldState);
		},
		[setMenuIsOpen]
	);

	const handleMenuStateChange = useCallback(
		({ isOpen }: { isOpen: boolean }) => {
			const menuClass = "menu-visible";
			if (isOpen) {
				document.body.classList.add(menuClass);
			} else {
				document.body.classList.remove(menuClass);
			}

			// If the menu was somehow closed, or opened, by a method other than one that explicitly sets the state (e.g. by clicking the overlay) then update the state here to match
			if (isOpen !== menuIsOpen) {
				setMenuIsOpen(isOpen);
			}
		},
		[menuIsOpen, setMenuIsOpen]
	);

	const showWelcome = !isSmallScreen;

	const [
		,
		removeAlert,
		{
			alerts: [alert]
		}
	] = useContext(AlertContext);

	const type =
		isSignedIn && !hasAcceptedTerms && !termsWallIsDisabled && !bypassTermsWall
			? "termswall"
			: suppliedType;

	const showDisplayNameInPageHeader = !isSmallScreen;

	return (
		<div id="page-wrapper" className={`page-${type}`}>
			{alert ? (
				<ModalAlert
					key={alert.id}
					isOpen
					onDismiss={() => removeAlert(alert)}
					title={alert.title}
				>
					{alert.contents}
				</ModalAlert>
			) : null}

			{type === "resources" ? null : (
				<header id="header">
					<Link to="/" className="logo">
						<HeaderLogo src={logoImage} />
						{terminology.siteName}
					</Link>

					{type === "authwall" || type === "termswall" ? null : (
						<nav>
							<ul>
								{isSignedIn ? (
									<>
										<li>
											{showWelcome ? "Welcome" : null}
											{displayName && showDisplayNameInPageHeader
												? `${showWelcome ? `, ` : ``}${displayName}`
												: ``}
										</li>
										<li className="menu-not-mobile">
											<a
												href="/"
												id="logout-button"
												onClick={handleClickLogout}
												className="logout-button"
											>
												Log out
											</a>
										</li>
										<li className="menu-mobile-only">
											<a href="#menu" onClick={toggleSideMenu}>
												Menu
											</a>
										</li>
									</>
								) : null}
								{isSignedIn ? null : (
									<>
										<li className="menu-not-mobile">
											<a
												href="/"
												id="login-button"
												onClick={handleClickLogin}
												className="login-button"
											>
												Log in / sign up
											</a>
										</li>
										<li className="menu-not-mobile">
											<Link to={`/${terminology.mentorsInUrls}`}>
												{ucFirst(terminology.mentor)} directory
											</Link>
										</li>
										<li className="menu-not-mobile">
											<Link to={`/becoming-a-${terminology.mentorInUrls}`}>
												Request a demo
											</Link>
										</li>
										<li className="menu-mobile-only">
											<a
												href={menuIsOpen ? "#close-menu" : "#menu"}
												className={menuIsOpen ? "close-menu" : ""}
												onClick={toggleSideMenu}
											>
												{menuIsOpen ? "" : "Menu"}
											</a>
										</li>
									</>
								)}
							</ul>
						</nav>
					)}
				</header>
			)}

			{type === "generic" ||
			type === "admin" ||
			type === "printabledocument" ||
			type === "resources" ? (
				<div id="wrapper">
					<section id="main" className="main">
						<div className="inner">{children}</div>
					</section>
				</div>
			) : null}

			{type === "home" || type === "authwall" ? children : null}

			{type === "termswall" ? (
				<>
					{children} <ModalAcceptTerms />
				</>
			) : null}

			{type === "authwall" || type === "termswall" ? null : (
				<Footer pageType={type} />
			)}

			{type === "authwall" || type === "termswall" ? null : (
				<Menu
					id="menu"
					right
					isOpen={menuIsOpen}
					disableAutoFocus
					noTransition
					onStateChange={handleMenuStateChange}
					customCrossIcon={
						<img alt="close-icon" src="/assets/css/images/close.svg" />
					}
				>
					{showDisplayNameInPageHeader && displayName ? (
						<MenuDisplayName>{displayName}</MenuDisplayName>
					) : null}

					<Link to="/">Home</Link>

					{isSignedIn ? null : (
						<Link to={`/${terminology.mentorsInUrls}`}>
							{ucFirst(terminology.mentor)} directory
						</Link>
					)}
					{isSignedIn ? null : (
						<Link to={`/becoming-a-${terminology.mentorInUrls}`}>
							Request a demo
						</Link>
					)}

					<Link to="/contact">Contact us</Link>

					{isSignedIn ? null : (
						<button
							data-menu-button-id="login-button"
							className="button fit login-button"
							onClick={handleClickLogin}
						>
							Log in / sign up
						</button>
					)}
					{!isSignedIn ? null : (
						<button
							data-menu-button-id="logout-button"
							className="button fit logout-button"
							onClick={handleClickLogout}
						>
							Log out
						</button>
					)}
				</Menu>
			)}
		</div>
	);
};

export default PageContent;
