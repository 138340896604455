import moment from "moment-timezone";

import { formatDateForApi } from "./formatDateForApi";
import { formatAsTime } from "../utils/datesAndTimes/formatAsTime";

export function formatDateOrTimeForApiIfIsADateOrATime(v: any) {
	return v instanceof Date
		? formatDateForApi(v)
		: moment.isDuration(v)
		? formatAsTime(v, { includeSeconds: true })
		: v;
}
