import React from "react";

import IDCard from "components/IDCard";
import ReadMoreText from "components/ReadMoreText";
import ErrorBanner from "components/ErrorBanner";
import { useUserProfile } from "hooks/useUserProfile";
import { getTermsByCategory } from "services/getTermsByCategory";
import { nullIfEmpty } from "utils/nullIfEmpty";
import terminology from "terminology.json";
import {
	ProfileInfoWrapper,
	ProfileInfoItem,
	ProfileInfoHeading,
	ProfileBio
} from "./UserProfileSummary.styles";

import dummyAvatarImage from "resources/dummy-avatar.jpeg";

interface UserProfileSummaryProps {
	uid?: string;
	vanityUrl?: string;
	bioType?: "full" | "short" | "none";
}

const UserProfileSummary: React.FunctionComponent<UserProfileSummaryProps> = ({
	uid,
	vanityUrl,
	bioType = "none"
}) => {
	const { isWaiting, isLoading, isError, output: profileData } = useUserProfile(
		{ vanityUrl, uid }
	);

	const canhelpwith = getTermsByCategory(profileData, "canhelpwith");
	const specialistknowledge = getTermsByCategory(
		profileData,
		"specialistknowledge"
	);

	if (isWaiting || isLoading) {
		return <p>...</p>;
	}

	const bio =
		bioType !== "none" && !!profileData
			? nullIfEmpty(profileData.mentorProfileAboutMe)
			: null;

	if (isError) {
		return <ErrorBanner />;
	}

	return (
		<>
			<ProfileInfoWrapper>
				<IDCard
					imageSrc={
						profileData && profileData.avatarUrl
							? profileData.avatarUrl
							: dummyAvatarImage
					}
					imageAlt={`${terminology.mentor}${
						profileData && profileData.displayName
							? `: ${profileData.displayName}`
							: ``
					}`}
					smallScreenStyle="stack"
					videoPoster={
						profileData && profileData.videoPosterUrl
							? profileData.videoPosterUrl
							: undefined
					}
					videoSrc={
						profileData && profileData.videoUrl
							? profileData.videoUrl
							: undefined
					}
				>
					<h2>
						{profileData && profileData.displayName
							? profileData.displayName
							: "..."}
					</h2>

					<ul className="profileInfo alt">
						{profileData &&
						profileData.locationDistrict &&
						profileData.locationCountryName ? (
							<ProfileInfoItem>
								<ProfileInfoHeading>Location</ProfileInfoHeading>
								{profileData.locationDistrict},{" "}
								{profileData.locationCountryName}
							</ProfileInfoItem>
						) : null}

						{canhelpwith.length ? (
							<ProfileInfoItem>
								<ProfileInfoHeading>Teaches</ProfileInfoHeading>
								{canhelpwith.map(t => t.text).join(", ")}
							</ProfileInfoItem>
						) : null}

						{specialistknowledge.length ? (
							<ProfileInfoItem>
								<ProfileInfoHeading>Speaks</ProfileInfoHeading>
								{specialistknowledge.map(t => t.text).join(", ")}
							</ProfileInfoItem>
						) : null}
					</ul>
				</IDCard>
			</ProfileInfoWrapper>
			{bio ? (
				<ProfileBio>
					{bioType === "full" ? bio : <ReadMoreText text={bio} max={220} />}{" "}
				</ProfileBio>
			) : null}
		</>
	);
};

export default UserProfileSummary;
