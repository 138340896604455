import React, { useState } from "react";

import { usePaymentMethods } from "hooks/usePaymentMethods";

export const PaymentWizardContext = React.createContext<{
	existingPaymentMethodsRepeatNum: number;
	setExistingPaymentMethodsRepeatNum: React.Dispatch<
		React.SetStateAction<number>
	>;
	userHasSavedCards: boolean | undefined;
}>({
	existingPaymentMethodsRepeatNum: 1,
	setExistingPaymentMethodsRepeatNum: arg => undefined,
	userHasSavedCards: false
});

const PaymentWizardProvider: React.FunctionComponent = ({ children }) => {
	const [
		existingPaymentMethodsRepeatNum,
		setExistingPaymentMethodsRepeatNum
	] = useState<number>(1);

	const existingPaymentMethods = usePaymentMethods(
		existingPaymentMethodsRepeatNum
	);

	const userHasSavedCards =
		existingPaymentMethods.output === undefined
			? undefined
			: existingPaymentMethods.output.paymentMethods.length !== 0;

	const contextData = {
		existingPaymentMethodsRepeatNum,
		setExistingPaymentMethodsRepeatNum,
		userHasSavedCards
	};

	return (
		<PaymentWizardContext.Provider value={contextData}>
			{children}
		</PaymentWizardContext.Provider>
	);
};

export default PaymentWizardProvider;
