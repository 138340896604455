import React, { useMemo, useCallback } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { useScreenSizeIsMax } from "hooks/useScreenSizeIsMax";
import KeyValueTable from "components/KeyValueTable";
import ModalMenu from "components/ModalMenu";
import { ModalMenuItem } from "components/ModalMenu/ModalMenu";
import { safeTsNotNullish } from "utils/safeTsNotNullish";
import Button from "components/Button";
import {
	Card,
	CardInner,
	StyledTable,
	StyledTableRow,
	StyledTableCell,
	StyledTableFooter,
	TableActionButtonWrapper,
	SmallScreenMenuButtonWrapper
} from "./Table.styles";

interface TableAction {
	onClick: () => void;
	label: string;
	icon?: IconProp;
}

type TableProps = {
	cols: TableColumnPublic[];
	isLoading?: boolean;
	loadingContent?: React.ReactNode;
	rows: {
		lineThrough?: boolean;
		data: { [k: string]: React.ReactNode };
		action?: TableAction;
		menu?: ModalMenuItem[];
	}[];
	emptyText: string;
	footer?: React.ReactNode;
	actionColumnWidth?: number;
};

interface TableColumnPublic {
	key: string;
	heading: string;
}

interface TableColumnPrivate extends TableColumnPublic {
	isActionColumn: boolean;
	isMenuColumn: boolean;
}

export const thresholdScreenSizeForKeyValueFormat = "large";

const Table: React.FunctionComponent<TableProps> = ({
	cols,
	isLoading = false,
	loadingContent = (
		<div>
			<em>Loading...</em>
		</div>
	),
	rows,
	emptyText,
	footer,
	actionColumnWidth = 10
}) => {
	const isNotLargestScreen = useScreenSizeIsMax(
		thresholdScreenSizeForKeyValueFormat
	);

	const actualColumns = useMemo(() => {
		const baseColumns = cols.map(col => ({
			...col,
			isActionColumn: false,
			isMenuColumn: false
		}));
		const haveActionColumn = rows.some(row => !!row.action);
		const haveMenuColumn = rows.some(row => !!row.menu);

		return [
			...baseColumns,
			...(haveActionColumn
				? [
						{
							key: "action",
							heading: "",
							isActionColumn: true,
							isMenuColumn: false
						}
				  ]
				: []),
			...(haveMenuColumn
				? [
						{
							key: "menu",
							heading: "",
							isActionColumn: false,
							isMenuColumn: true
						}
				  ]
				: [])
		];
	}, [cols, rows]);

	const getColumnHeading = useCallback(
		({
			heading,
			isActionColumn,
			isMenuColumn
		}: TableColumnPrivate): string | null => {
			if (!!isActionColumn || !!isMenuColumn) {
				return null;
			}
			return heading;
		},
		[]
	);

	const useKeyValueFormat = isNotLargestScreen;
	if (useKeyValueFormat) {
		if (!rows.length || isLoading) {
			return <Card>{isLoading ? loadingContent : emptyText}</Card>;
		}

		return (
			<>
				{rows.map(({ data, action, menu, lineThrough }, i) => {
					// TODO:WV:20210323:How to handle the footer in this format?
					return (
						<Card key={i}>
							<CardInner>
								{menu ? (
									<SmallScreenMenuButtonWrapper>
										<ModalMenu title="Actions" items={menu} />
									</SmallScreenMenuButtonWrapper>
								) : null}
								<KeyValueTable
									supportsLongEntries={true}
									data={actualColumns.map(column => {
										return {
											key: column.key,
											row: [
												getColumnHeading(column),
												column.isActionColumn && action ? (
													<TableActionButtonWrapper hasTopMargin>
														<Button
															leftAlign={false}
															fitWidth
															onClick={e => {
																e.preventDefault();
																return safeTsNotNullish(action).onClick();
															}}
															icon={safeTsNotNullish(action).icon}
															label={safeTsNotNullish(action).label}
														/>
													</TableActionButtonWrapper>
												) : (
													data[column.key]
												)
											]
										};
									})}
								/>
							</CardInner>
						</Card>
					);
				})}
			</>
		);
	}

	return (
		<StyledTable>
			<thead>
				<tr>
					{actualColumns.map(column => (
						<th key={column.key}>{getColumnHeading(column)}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{!rows.length || isLoading ? (
					<tr>
						<td colSpan={actualColumns.length}>
							{isLoading ? loadingContent : emptyText}
						</td>
					</tr>
				) : null}

				{rows.length
					? rows.map(({ data, lineThrough, action, menu }, i) => {
							return (
								<StyledTableRow lineThrough={lineThrough} key={i}>
									{actualColumns.map(
										({ key, isActionColumn, isMenuColumn }) => {
											const cellContents =
												isActionColumn && action ? (
													<Button
														leftAlign={false}
														fitWidth
														onClick={e => {
															e.preventDefault();
															return safeTsNotNullish(action).onClick();
														}}
														icon={safeTsNotNullish(action).icon}
														label={safeTsNotNullish(action).label}
													/>
												) : isMenuColumn && menu ? (
													<ModalMenu title="Actions" items={menu} />
												) : key in data ? (
													data[key as keyof typeof data]
												) : (
													""
												);

											return (
												<StyledTableCell
													width={
														!!isActionColumn
															? actionColumnWidth
															: !!isMenuColumn
															? 4
															: undefined
													}
													key={key}
												>
													{cellContents}
												</StyledTableCell>
											);
										}
									)}
								</StyledTableRow>
							);
					  })
					: null}
			</tbody>
			{footer ? (
				<StyledTableFooter>
					<tr>
						<td colSpan={actualColumns.length}>{footer}</td>
					</tr>
				</StyledTableFooter>
			) : null}
		</StyledTable>
	);
};

export default Table;
