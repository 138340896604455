import React from "react";

import UserProfileSummary from "components/UserProfileSummary";
import SectionBox from "components/SectionBox";
import { StyledLink } from "./MentorProfilePreviewLink.styles";

interface MentorProfilePreviewLinkProps {
	vanityUrl: string;
}

const MentorProfilePreviewLink: React.FunctionComponent<
	MentorProfilePreviewLinkProps
> = ({ vanityUrl }) => {
	return (
		<StyledLink to={`/user/${vanityUrl}`}>
			<SectionBox>
				<UserProfileSummary vanityUrl={vanityUrl} bioType="short" />
			</SectionBox>
		</StyledLink>
	);
};

export default MentorProfilePreviewLink;
