import styled from "styled-components";

export const CropperContainer = styled.div`
	position: relative;
	width: 100%;
	height: 200px;
	background: #333333;
`;

export const SliderContainer = styled.div`
	margin-top: 1.5em;
	.rangeslider-horizontal .rangeslider__fill {
		background-color: #0070b2;
	}
	.rangeslider__labels {
		display: none;
	}
`;

export const SliderLabel = styled.p`
	font-weight: 500;
	margin-bottom: 1em;
`;
