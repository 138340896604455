import * as Sentry from "@sentry/react";

import { SentryEventContext } from "./SentryEventContext";

export const captureMessage = (
	message: string,
	{ evtType, extra }: SentryEventContext
) => {
	console.log("Captured message", message, evtType, extra);
	Sentry.captureMessage(message, {
		tags: { evtType },
		...(extra ? { extra } : {})
	});
};
