import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { MeetingCalendarEvent } from "components/MeetingCalendar/MeetingCalendar";
import { formatDateForApi } from "services/formatDateForApi";
import { ValidationCallback } from "validation/validate";
import { Medium } from "hooks/useAvailableMediums";

type Params = {
	hostUid: string;
	guestUid: string;
	paymentTokenId: string;
	paymentTokenType: "singleSessionPrice" | "credit";
	message: string;
	medium: Medium;
	sessionEvent: MeetingCalendarEvent;
};

type Output = {};
const flagValidationErrors: ValidationCallback<Output> = () => {};

export function sendMeetingRequest(
	{
		hostUid,
		guestUid,
		message,
		paymentTokenId,
		paymentTokenType,
		medium,
		sessionEvent
	}: Params,
	auth: Auth
): ApiRequest<Output> {
	return makeApiRequest("meetingRequest", {
		auth,
		data: {
			post: {
				hostUid,
				guestUid,
				message,
				paymentTokenId,
				paymentTokenType,
				medium,
				sessionEvent: {
					startUTC: formatDateForApi(sessionEvent.start),
					endJustBeforeUTC: formatDateForApi(sessionEvent.end)
				}
			}
		},
		flagValidationErrors
	});
}
