import React from "react";
import moment from "moment-timezone";

import { convertToUsersTimezone } from "utils/datesAndTimes/convertToUsersTimezone";

interface ChatWidgetTimestampProps {
	date: Date;
}

const ChatWidgetTimestamp: React.FunctionComponent<
	ChatWidgetTimestampProps
> = ({ date }) => {
	const mDate = convertToUsersTimezone(moment(date));
	const isToday = mDate
		.clone()
		.startOf("day")
		.isSame(convertToUsersTimezone(moment()).startOf("day"));
	return (
		<span className="rcw-timestamp">
			{mDate.format(`${!isToday ? `D MMMM YYYY, ` : ``}HH:mm`)}
		</span>
	);
};

export default ChatWidgetTimestamp;
