import React from "react";

import PriceTag from "./PriceTag";
import terminology from "terminology.json";

interface Props {
	currency: string;
	price: number;
	locationCountryCode?: string;
	isMenteeSpecific: boolean;
	isPackage: boolean;
	numMeetingsInPackage?: number;

	alternateCurrency: string;
	approxPriceInAlternateCurrency?: number;
}

const SessionTypeVariant: React.FunctionComponent<Props> = ({
	currency,
	price,
	locationCountryCode,
	alternateCurrency,
	approxPriceInAlternateCurrency,
	isMenteeSpecific,
	isPackage,
	numMeetingsInPackage
}) => {
	if (isPackage && numMeetingsInPackage === undefined) {
		throw new Error("Package had undefined numMeetingsInPackage");
	}

	const numMeetings = numMeetingsInPackage ? numMeetingsInPackage : 1;

	return (
		<>
			{isPackage && numMeetingsInPackage
				? `Pack of ${numMeetingsInPackage} classes: `
				: `Single class: `}
			<PriceTag
				currency={currency}
				price={price / numMeetings}
				locationCountryCode={locationCountryCode ? locationCountryCode : "GB"}
				approxPriceInAlternateCurrency={
					approxPriceInAlternateCurrency
						? approxPriceInAlternateCurrency / numMeetings
						: undefined
				}
				alternateCurrency={alternateCurrency}
			/>
			{isPackage && numMeetingsInPackage ? <> per class</> : null}

			{isPackage || isMenteeSpecific ? (
				<>
					{" "}
					(
					{isPackage ? (
						<>
							<PriceTag
								currency={currency}
								price={price}
								locationCountryCode={
									locationCountryCode ? locationCountryCode : "GB"
								}
								approxPriceInAlternateCurrency={approxPriceInAlternateCurrency}
								alternateCurrency={alternateCurrency}
							/>{" "}
							total
						</>
					) : null}
					{isMenteeSpecific ? ` - ${terminology.mentee}-specific price` : null})
				</>
			) : null}
		</>
	);
};

export default SessionTypeVariant;
