import React, { useState, useCallback, useContext } from "react";
import moment from "moment-timezone";

import {
	getDateFromSelection,
	getSelectionFromDate
} from "components/FieldDate/FieldDateSelection";
import ExternalCommitmentsTable from "components/PageDashboard/ExternalCommitmentsSection/ExternalCommitmentsTable";
import { DataInAnyRow } from "components/PageDashboard/ExternalCommitmentsSection/ExternalCommitmentsTable/ExternalCommitmentsTable";
import { useRemoteResource } from "hooks/useRemoteResource";
import { useSendData } from "hooks/useSendData";
import { SearchResults } from "api/SearchResults";
import { saveExternalCommitments } from "api/saveExternalCommitments";
import {
	getExternalCommitments,
	OutputRow as ExternalCommitmentsData
} from "api/getExternalCommitments";
import { AuthContext } from "components/AuthProvider/AuthProvider";

const ExternalCommitmentsSection: React.FunctionComponent = () => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);

	const fetchExternalCommitments = useCallback(() => {
		if (!uid) {
			throw new Error("No uid");
		}
		return getExternalCommitments(
			{
				uid,
				earliestStartDateUTC: new Date()
			},
			{ uid }
		);
	}, [uid]);

	const doWait = isSignedIn === undefined || !uid;

	const [fetchIterationNumber, setFetchIterationNumber] = useState(1);

	const {
		isWaiting,
		isLoading,
		isError,
		output: commitments
	} = useRemoteResource<SearchResults<ExternalCommitmentsData>>(
		fetchExternalCommitments,
		doWait,
		fetchIterationNumber,
		true
	);

	const [waitModalIsShowing, setWaitModalIsShowing] = useState(false);
	const [waitModalTitle, setWaitModalTitle] = useState("");

	const doSaveExternalCommitments = useSendData({
		method: saveExternalCommitments
	});

	const onEditExternalCommitments = useCallback(
		(
			rows: {
				old?: DataInAnyRow;
				new?: DataInAnyRow;
			}[]
		) => {
			const title = rows.every(row => !row.new) ? "Deleting" : "Saving";

			setWaitModalTitle(title);
			setWaitModalIsShowing(true);

			if (!uid) {
				throw new Error("No UID");
			}

			const req = doSaveExternalCommitments(
				{
					uid,
					rows: rows.map(row => {
						const { old, new: newRow } = row;

						return {
							old: old ? convertRowFromTableToApi(old) : undefined,
							new: newRow ? convertRowFromTableToApi(newRow) : undefined
						};
					})
				},
				{ uid }
			);

			const ready = req.ready.then(response => {
				setFetchIterationNumber(new Date().getTime());
				setWaitModalIsShowing(false);
			});

			return {
				ready,
				abort: () => req.abort(),
				aborted: () => req.aborted()
			};
		},
		[
			uid,
			setWaitModalTitle,
			setWaitModalIsShowing,
			setFetchIterationNumber,
			doSaveExternalCommitments
		]
	);

	const dataForTable = commitments
		? commitments.page.map(convertRowFromApiToTable)
		: [];

	return (
		<ExternalCommitmentsTable
			onEdit={onEditExternalCommitments}
			commitments={dataForTable}
			isLoading={isLoading}
			isWaiting={isWaiting || !commitments}
			isError={isError}
			waitModalTitle={waitModalTitle}
			waitModalIsShowing={waitModalIsShowing}
		/>
	);
};

export default ExternalCommitmentsSection;

function convertRowFromTableToApi(row: DataInAnyRow): ExternalCommitmentsData {
	const {
		dateStartsInRowTimezone,
		timeStartsInRowTimezone,
		dateEndsJustBeforeInRowTimezone,
		timeEndsJustBeforeInRowTimezone,
		...rest
	} = row;
	return {
		...rest,
		dateStartsUTC: moment
			.tz(
				getDateFromSelection(dateStartsInRowTimezone, row.timezone),
				row.timezone
			)
			.hours(timeStartsInRowTimezone.hours())
			.startOf("hour")
			.utc()
			.toDate(),
		dateEndsJustBeforeUTC: moment
			.tz(
				getDateFromSelection(dateEndsJustBeforeInRowTimezone, row.timezone),
				row.timezone
			)
			.hours(timeEndsJustBeforeInRowTimezone.hours())
			.startOf("hour")
			.utc()
			.toDate()
	};
}

function convertRowFromApiToTable(row: ExternalCommitmentsData): DataInAnyRow {
	const { dateStartsUTC, dateEndsJustBeforeUTC } = row;
	const output = {
		...row,
		dateStartsInRowTimezone: getSelectionFromDate(dateStartsUTC, row.timezone),
		dateEndsJustBeforeInRowTimezone: getSelectionFromDate(
			dateEndsJustBeforeUTC,
			row.timezone
		),
		timeStartsInRowTimezone: moment.duration(
			moment.tz(dateStartsUTC, row.timezone).hours() * 60 +
				moment.tz(dateStartsUTC, row.timezone).minutes(),
			"minutes"
		),
		timeEndsJustBeforeInRowTimezone: moment.duration(
			moment.tz(dateEndsJustBeforeUTC, row.timezone).hours() * 60 +
				moment.tz(dateEndsJustBeforeUTC, row.timezone).minutes(),
			"minutes"
		)
	};

	return output;
}
