import styled from "styled-components";

export const ReadMoreTextWrapper = styled.span<{
	wasShortened: boolean;
	onClick?: () => void;
}>`
	${props =>
		props.wasShortened
			? `

		${props.onClick ? `cursor: pointer;` : ``};


		&::after {
			content: " ...Read more";
			display:inline-block;
			padding-left:5px;
			font-family: monospace;
			font-size:smaller;
			background:transparent;
			white-space: nowrap;
		}
	`
			: ``};
}
`;
