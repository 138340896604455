interface Params {
	uri: string;
	x: number;
	y: number;
	width: number;
	height: number;
}

export function cropImage({
	uri,
	x,
	y,
	width,
	height
}: Params): Promise<string> {
	return new Promise((resolve, reject) => {
		const image = new Image();

		image.onload = function() {
			const canvas = document.createElement("canvas");
			const scaleX = image.naturalWidth / image.width;
			const scaleY = image.naturalHeight / image.height;
			canvas.width = width;
			canvas.height = height;
			const ctx = canvas.getContext("2d");

			if (!ctx) {
				reject("Could not create canvas rendering context");
				return;
			}

			ctx.drawImage(
				image,
				x * scaleX,
				y * scaleY,
				width * scaleX,
				height * scaleY,
				0,
				0,
				width,
				height
			);

			const base64Image = canvas.toDataURL(getMimeType(uri));
			resolve(base64Image);
		};

		image.src = uri;
	});
}

function getMimeType(dataURL: string) {
	return dataURL.substring(dataURL.indexOf(":") + 1, dataURL.indexOf(";"));
}
