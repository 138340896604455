interface TrimTextParams {
	text: string;
	max: number;
	addEllipsis?: boolean;
}

export function trimText({ text, max, addEllipsis }: TrimTextParams) {
	const trimmedText = text.substr(0, max);
	const wasShortened = trimmedText.length < text.length;

	const newText =
		wasShortened && addEllipsis ? trimmedText + "..." : trimmedText;

	return {
		newText,
		wasShortened
	};
}
