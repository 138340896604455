import React from "react";

import PageContent from "components/PageContent";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";
import Markdown from "components/Markdown";

// @ts-ignore
import cookiePolicy from "./cookie-policy.md";

const PageCookiePolicy: React.FunctionComponent = () => {
	usePageTitlePrefix("Cookie Policy");

	return (
		<PageContent>
			<header className="major">
				<h1>Cookie Policy</h1>
			</header>
			<Markdown source={cookiePolicy} sourceType="file" replaceTokens />
		</PageContent>
	);
};

export default PageCookiePolicy;
