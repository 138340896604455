import React from "react";

import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { OutputRow as MeetingRequestsData } from "api/getMeetingRequests";
import { formatMeetingDetailsForAttendees } from "services/formatMeetingDetailsForAttendees";

import SimpleTable from "components/SimpleTable";

interface MeetingSummaryProps {
	event: MeetingsDataFlattened | MeetingRequestsData;
	currentUid: string;
}

const MeetingSummary: React.FunctionComponent<MeetingSummaryProps> = ({
	event,
	currentUid
}) => {
	const {
		startDate,
		startTime,
		endTime,
		partnersName,
		medium
	} = formatMeetingDetailsForAttendees(event, currentUid);

	const rows = [
		[<strong>Date</strong>, startDate],
		[<strong>Time</strong>, `${startTime} until ${endTime}`],
		[<strong>With</strong>, partnersName],
		[<strong>Using</strong>, medium]
	];

	return <SimpleTable rows={rows} />;
};

export default MeetingSummary;
