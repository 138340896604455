import React from "react";

import PageContent from "components/PageContent";
import ContactForm from "components/ContactForm";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";

const PageContact: React.FunctionComponent = () => {
	usePageTitlePrefix("Contact Us");

	return (
		<PageContent>
			<header className="major">
				<h1>Contact us</h1>
			</header>
			<p>
				Please fill out your details, along with any other questions or comments
				below, and we'll reply as soon as possible.
			</p>

			<ContactForm>
				<div className="row gtr-uniform">
					<div className="col-6 col-12-xsmall">
						<input
							type="hidden"
							name="enquiryType"
							id="enquiryType"
							value="Standard contact form submission"
						/>
						<input
							type="text"
							name="name"
							id="name"
							defaultValue=""
							placeholder="Name"
							required
						/>
					</div>
					<div className="col-6 col-12-xsmall">
						<input
							type="email"
							name="email"
							id="email"
							defaultValue=""
							placeholder="Email"
							required
						/>
					</div>
					<div className="col-12">
						<textarea
							name="message"
							id="message"
							placeholder="Your query"
							rows={6}
							required
						></textarea>
					</div>
					<div className="col-12">
						<ul className="actions">
							<li>
								<input type="submit" value="Send Message" className="primary" />
							</li>
							<li>
								<input type="reset" value="Reset" />
							</li>
						</ul>
					</div>
				</div>
			</ContactForm>
		</PageContent>
	);
};

export default PageContact;
