import mapvalues from "lodash.mapvalues";

import { makeApiRequest, Auth } from "api/makeApiRequest";
import { OutputRow as ExternalCommitmentsData } from "api/getExternalCommitments";
import { formatDateOrTimeForApiIfIsADateOrATime } from "services/formatDateOrTimeForApiIfIsADateOrATime";
import { ValidationCallback } from "validation/validate";

interface Params {
	uid: string;
	rows: {
		old?: ExternalCommitmentsData | undefined;
		new?: ExternalCommitmentsData | undefined;
	}[];
}

type Output = {};
const flagValidationErrors: ValidationCallback<Output> = (input, flag) =>
	typeof input === "object" && Object.keys(input).length === 0;

// TODO:WV:20200103:Enforce our API's limit of 50 rows
export function saveExternalCommitments({ uid, rows }: Params, auth: Auth) {
	return makeApiRequest<{ status: "ok" }>("externalCommitments", {
		auth,
		data: {
			post: {
				uid,
				rows: rows.map(r => ({
					...(r.old
						? {
								old: mapvalues(r.old, formatDateOrTimeForApiIfIsADateOrATime)
						  }
						: {}),
					...(r.new
						? {
								new: mapvalues(r.new, formatDateOrTimeForApiIfIsADateOrATime)
						  }
						: {})
				}))
			}
		},
		flagValidationErrors
	});
}
