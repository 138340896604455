import styled from "styled-components";

interface LabelProps {
	disabled?: boolean;
}

export const Label = styled.label.attrs((props: LabelProps) => ({
	"data-disabled": props.disabled === true ? "disabled" : ""
}))<LabelProps>`
	text-decoration: none;
	cursor: pointer;
	display: inline-block;
	font-size: 1em;
	font-weight: 300;
	padding-left: 2.4em;
	padding-right: 2.75em;
	position: relative;
	color: #8e8e8e;
	${props =>
		props.disabled
			? `
		opacity: 0.2;
	`
			: ``};
`;

interface LabelContentsProps {
	takeUpLessSpace?: boolean;
	checked?: boolean;
}

export const LabelContents = styled.span<LabelContentsProps>`
	${({ takeUpLessSpace, checked }) =>
		takeUpLessSpace
			? `
		position: relative;
		left: -2.2em;
		${checked ? `color: white;` : ``};
	`
			: ``}
`;
