import React, { useContext, useState, useCallback, useMemo } from "react";

import ModalConfirm from "components/ModalConfirm";
import PackageSummary from "components/PackageSummary";
import { OutputRow as PackageRequestsData } from "api/getPackageRequests";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useSendData } from "hooks/useSendData";
import { ucFirst } from "utils/ucFirst";
import { cancelPackageRequest } from "api/cancelPackageRequest";
import terminology from "terminology.json";

import { ModalCancelPackageRequestContents } from "./ModalCancelPackageRequest.styles";

export interface Props {
	packageRequest: PackageRequestsData | undefined;
	isOpen: boolean;
	onCancel: () => Promise<void>;
	onOK: (arg: { hostUid: string }) => Promise<void>;
}

const ModalCancelPackageRequest: React.FunctionComponent<Props> = ({
	packageRequest,
	isOpen,
	onCancel,
	onOK
}) => {
	const [{ uid }] = useContext(AuthContext);

	const [message, setMessage] = useState<string | undefined>();

	const resetForm = useCallback(() => {
		setMessage(undefined);
	}, [setMessage]);

	const doCancelPackageRequest = useSendData({ method: cancelPackageRequest });

	const { id: packageRequestId, hostUid } = packageRequest
		? packageRequest
		: { id: undefined, hostUid: undefined };
	const handleOK = useCallback(async () => {
		if (!(packageRequestId && hostUid)) {
			return;
		}

		await doCancelPackageRequest(
			{
				id: packageRequestId,
				...(message ? { message } : {})
			},
			{ uid }
		).ready;

		await onOK({ hostUid });
		resetForm();
	}, [
		packageRequestId,
		message,
		hostUid,
		doCancelPackageRequest,
		onOK,
		uid,
		resetForm
	]);

	const handleCancel = useCallback(async () => {
		await onCancel();
		resetForm();
	}, [onCancel, resetForm]);

	const okText = useMemo(
		() => `${hostUid === uid ? `Cancel` : `Amend`} booking`,
		[hostUid, uid]
	);

	if (!uid) {
		return null;
	}

	if (!packageRequest) {
		return null;
	}

	return (
		<ModalConfirm
			isOpen={!!packageRequest}
			title={ucFirst(terminology.meeting)}
			onCancel={handleCancel}
			onOK={handleOK}
			okText={okText}
		>
			<ModalCancelPackageRequestContents>
				<PackageSummary product={packageRequest} currentUid={uid} />
				<h3>Cancel {terminology.packageRequest}</h3>
				<p>If you wish to add an accompanying note, please type it below.</p>
				<form>
					<div className="col-12">
						<textarea
							placeholder="Accompanying note"
							value={message}
							onChange={e => setMessage(e.currentTarget.value)}
						/>
					</div>
				</form>
			</ModalCancelPackageRequestContents>
		</ModalConfirm>
	);
};

export default ModalCancelPackageRequest;
