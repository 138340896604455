import moment from "moment";

import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";
import { SearchResults } from "api/SearchResults";
import { flagValidationErrorsInSearchResults } from "validation/flagValidationErrorsInSearchResults";
import { isValidTime } from "utils/datesAndTimes/isValidTime";

interface RawApiOutputRow {
	id: string;
	dayOfWeekLocaltime: number;
	timeFirstAvailableLocaltime: string;
	timeLastAvailableJustBeforeLocaltime: string;
	timezone: string;
}

const flagValidationErrorsInRow: ValidationCallback<RawApiOutputRow> = (
	input,
	{ flag, checkField }
) => {
	const {
		timeFirstAvailableLocaltime,
		timeLastAvailableJustBeforeLocaltime
	} = input;

	checkField("id", { type: "string" });
	checkField("dayOfWeekLocaltime", { type: "number" });
	checkField("timezone", { type: "string" });

	let timeFirstAvailableLocaltimeIsValid: boolean | undefined;
	if (timeFirstAvailableLocaltime === undefined) {
		flag("timeFirstAvailableLocaltime", "missing");
	} else if (!(typeof timeFirstAvailableLocaltime === "string")) {
		flag("timeFirstAvailableLocaltime", "invalid");
	} else if (!isValidTime(timeFirstAvailableLocaltime)) {
		flag("timeFirstAvailableLocaltime", "invalid value");
	} else {
		timeFirstAvailableLocaltimeIsValid = true;
	}

	let timeLastAvailableJustBeforeLocaltimeIsValid: boolean | undefined;
	if (timeLastAvailableJustBeforeLocaltime === undefined) {
		flag("timeLastAvailableJustBeforeLocaltime", "missing");
	} else if (!(typeof timeLastAvailableJustBeforeLocaltime === "string")) {
		flag("timeLastAvailableJustBeforeLocaltime", "not a string");
	} else if (!isValidTime(timeLastAvailableJustBeforeLocaltime)) {
		flag("timeLastAvailableJustBeforeLocaltime", "invalid value");
	} else {
		timeLastAvailableJustBeforeLocaltimeIsValid = true;
	}

	if (
		timeFirstAvailableLocaltimeIsValid &&
		timeLastAvailableJustBeforeLocaltimeIsValid
	) {
		const now = moment.utc();
		if (
			!moment
				.utc(
					`${now.format(`YYYY-MM-DD`)} ${timeLastAvailableJustBeforeLocaltime}`
				)
				.isAfter(`${now.format(`YYYY-MM-DD`)} ${timeFirstAvailableLocaltime}`)
		) {
			flag(
				"timeLastAvailableJustBeforeLocaltime",
				"time-first-available must be before time-last-available"
			);
			flag(
				"timeFirstAvailableLocaltime",
				"time-first-available must be before time-last-available"
			);
		}
	}
};

const flagValidationErrors: ValidationCallback<
	SearchResults<RawApiOutputRow>
> = (input, utils, auth) =>
	flagValidationErrorsInSearchResults(
		flagValidationErrorsInRow,
		input,
		utils,
		auth
	);

export interface OutputRow
	extends Omit<
		RawApiOutputRow,
		"timeFirstAvailableLocaltime" | "timeLastAvailableJustBeforeLocaltime"
	> {
	timeFirstAvailableLocaltime: moment.Duration;
	timeLastAvailableJustBeforeLocaltime: moment.Duration;
}

interface GetAvailabilityParams {
	uid: string;
	cacheInMemory?: boolean;
}

export function getAvailability(
	{ uid, cacheInMemory }: GetAvailabilityParams,
	auth: Auth
): ApiRequest<SearchResults<OutputRow>> {
	const request = makeApiRequest("mentorAvailability", {
		auth,
		cacheInMemory,
		data: {
			get: {
				uid
			}
		},
		flagValidationErrors
	});

	return {
		ready: request.ready.then(results => {
			if (!results) {
				return { page: [] };
			}
			return {
				page: results.page.map(row => ({
					...row,
					timeFirstAvailableLocaltime: moment.duration(
						row.timeFirstAvailableLocaltime
					),
					timeLastAvailableJustBeforeLocaltime: moment.duration(
						row.timeLastAvailableJustBeforeLocaltime
					)
				}))
			};
		}),
		abort: () => request.abort(),
		aborted: () => request.aborted(),
		type: request.type
	};
}
