import { useEffect, useContext } from "react";
import queryString from "query-string";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useStripQueryStringFromAddressBar } from "hooks/useStripQueryStringFromAddressBar";
import { MeetingCalendarEvent } from "components/MeetingCalendar/MeetingCalendar";

type SetBookingWizardInProgress = (
	value: React.SetStateAction<{
		isInProgress: boolean;
		selectedEvent?: MeetingCalendarEvent;
		currentUserUid?: string;
	}>
) => void;

export const useShowBookingInterface = (
	setBookingWizardInProgress: SetBookingWizardInProgress
) => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);

	const stripQueryStringFromAddressBar = useStripQueryStringFromAddressBar();
	useEffect(() => {
		const { showBookingInterface } = queryString.parse(window.location.search);
		if (showBookingInterface && isSignedIn) {
			stripQueryStringFromAddressBar();
			setBookingWizardInProgress({
				isInProgress: true,
				currentUserUid: uid
			});
		}
	}, [
		setBookingWizardInProgress,
		isSignedIn,
		stripQueryStringFromAddressBar,
		uid
	]);
};
