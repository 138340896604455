import { useCallback, useContext } from "react";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useRemoteResource } from "hooks/useRemoteResource";
import { getPaymentMethods } from "api/getPaymentMethods";

export function usePaymentMethods(repeatNum: number = 1) {
	const [{ uid }] = useContext(AuthContext);
	return useRemoteResource(
		useCallback((arg, auth) => {
			const currentUserId = auth ? auth.uid : undefined;
			if (!currentUserId) {
				throw new Error("Not logged in");
			}
			return getPaymentMethods({ uid: currentUserId }, auth);
		}, []),
		!uid,
		repeatNum,
		true
	);
}
