import { ValidationCallback } from "validation/validate";

export interface PackagePrice {
	id: string;
	price: number;
	currency: string;
	sessionDurationMinutes: number;
	sessionTypeId: string;
	numMeetings: number;
}

export const flagValidationErrorsInPackagePrice: ValidationCallback<
	PackagePrice
> = (input, { checkField }) => {
	checkField("id", { type: "string" });
	checkField("price", { type: "number" });
	checkField("currency", { type: "string" });
	checkField("sessionDurationMinutes", { type: "number" });
	checkField("sessionTypeId", { type: "string" });
	checkField("numMeetings", { type: "number" });
};
