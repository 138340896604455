import React, { useEffect, useState, useCallback, useMemo } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import ToggleableButton from "components/ToggleableButton";
import ModalConfirm from "components/ModalConfirm";
import { EditButtonRenderProps } from "components/ModalField/ModalField";
import FieldText from "components/FieldText";

interface ModalFieldTextProps {
	onCancel?: () => void;
	onOK: (newValue: string) => void;
	title: string;
	helptext?: string;
	value: string;
	isUnlocked?: boolean;
	formatValue?: (value: string) => string;
	editButton?: (props: EditButtonRenderProps) => React.ReactNode;
	subType?: "text" | "tel" | "number";
	validate?: (value: string) => boolean;
	placeholder?: string;
}

const ModalFieldText: React.FunctionComponent<ModalFieldTextProps> = ({
	onCancel = () => undefined,
	onOK,
	title,
	helptext,
	value,
	isUnlocked = true,
	formatValue = value => value,
	editButton,
	subType = "text",
	validate,
	placeholder
}) => {
	const [currentValue, setCurrentValue] = useState<string>(value);

	const reset = useCallback(() => {
		setCurrentValue(value);
	}, [value, setCurrentValue]);

	const [isOpen, setIsOpen] = useState(false);
	const toggleIsOpen = useCallback(() => {
		setIsOpen(oldIsOpen => !oldIsOpen);
	}, [setIsOpen]);

	useEffect(() => {
		reset();
	}, [isOpen, reset]);

	const [
		shouldHighlightValidationError,
		setShouldHighlightValidationError
	] = useState(false);

	const handleOK = useCallback(() => {
		if (!!validate && !validate(currentValue)) {
			setShouldHighlightValidationError(true);
			return;
		}
		setShouldHighlightValidationError(false);
		onOK(currentValue);
		setIsOpen(false);
	}, [onOK, currentValue, setIsOpen, validate]);

	const handleKeyUp = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			setShouldHighlightValidationError(false);
			if (e.keyCode === 13) {
				handleOK();
			}
		},
		[handleOK]
	);

	const handleCancel = useCallback(() => {
		onCancel();
		setIsOpen(false);
	}, [onCancel, setIsOpen]);

	const displayValue = useMemo(() => formatValue(value), [value, formatValue]);

	return (
		<>
			{editButton ? (
				editButton({ isUnlocked, onClick: toggleIsOpen })
			) : (
				<ToggleableButton
					isEditing={isUnlocked}
					icon={faPen}
					onClick={toggleIsOpen}
					label={displayValue}
				/>
			)}

			<ModalConfirm
				isOpen={isOpen}
				onOK={handleOK}
				onCancel={handleCancel}
				title={title}
				helptext={helptext}
			>
				<FieldText
					subType={subType}
					value={currentValue}
					onChange={e => {
						setCurrentValue(e.currentTarget.value);
					}}
					onKeyUp={handleKeyUp}
					highlightValidationError={shouldHighlightValidationError}
					placeholder={placeholder}
				/>
			</ModalConfirm>
		</>
	);
};

export default ModalFieldText;
