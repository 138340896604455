import moment from "moment-timezone";

import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { OutputRow as MeetingRequestsData } from "api/getMeetingRequests";
import { convertToUsersTimezone } from "utils/datesAndTimes/convertToUsersTimezone";
import { formatMedium } from "services/formatMedium";

export function formatMeetingDetailsForAttendees(
	event: MeetingsDataFlattened | MeetingRequestsData,
	currentUid: string,
	useShortFormat: boolean = false
) {
	const {
		dateStartsUTC,
		dateEndsJustBeforeUTC,
		hostUid,
		guestUid,
		medium
	} = event;

	const start = convertToUsersTimezone(moment.utc(dateStartsUTC));
	const end = convertToUsersTimezone(moment.utc(dateEndsJustBeforeUTC));

	const partnersUid = hostUid === currentUid ? guestUid : hostUid;
	const partnersName =
		hostUid === currentUid ? event.displayNameGuest : event.displayNameHost;

	return {
		hostUid,
		guestUid,
		medium: formatMedium(medium),
		startDate: start.format(
			useShortFormat ? "YYYY/MM/DD" : "dddd MMMM Do YYYY"
		),
		startTime: start.format("HH:mm"),
		endTime: end.format("HH:mm"),
		partnersName: partnersName ? partnersName : "(unknown)",
		identifier: `${event.hostUid}-${event.guestUid}-${start.format(
			"X"
		)}-${end.format("X")}`,
		partnersUid
	};
}
