import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ProtectedRoute from "components/ProtectedRoute";
import PublicOnlyRoute from "components/PublicOnlyRoute";

import PageInterest from "components/PageInterest";
import PageHome from "components/PageHome";
import PageContact from "components/PageContact";
import PageBecomingAMentor from "components/PageBecomingAMentor";
import PageTermsOfUse from "components/PageTermsOfUse";
import PagePrivacyPolicy from "components/PagePrivacyPolicy";
import PageSafeguardingPolicy from "components/PageSafeguardingPolicy";
import PageCookiePolicy from "components/PageCookiePolicy";
import PageDashboard from "components/PageDashboard";
import PageUserProfile from "components/PageUserProfile";
import PageMentorAvailability from "components/PageMentorAvailability";
import PageMentorsIndex from "components/PageMentorsIndex";
import PageContactFormSubmission from "components/PageContactFormSubmission";
import ZoomCallback from "components/ZoomCallback";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import PageDocumentation from "components/PageDocumentation";
import PageSupport from "components/PageSupport";
import terminology from "terminology.json";
import { useScrollToTopOnRouteChange } from "hooks/useScrollToTopOnRouteChange";

const App: React.FunctionComponent = () => {
	useScrollToTopOnRouteChange();

	const [{ haveSignInStatus }] = useContext(AuthContext);
	if (!haveSignInStatus) {
		return null;
	}

	return (
		<Switch>
			<PublicOnlyRoute exact path="/">
				<PageHome />
			</PublicOnlyRoute>

			<PublicOnlyRoute exact path="/interest">
				<PageInterest />
			</PublicOnlyRoute>

			<Route exact path="/contact">
				<PageContact />
			</Route>

			<Route exact path={`/${terminology.mentorsInUrls}`}>
				<PageMentorsIndex />
			</Route>

			<Route exact path={`/becoming-a-${terminology.mentorInUrls}`}>
				<PageBecomingAMentor />
			</Route>

			<Route exact path="/terms">
				<PageTermsOfUse />
			</Route>

			<Route exact path="/cookie-policy">
				<PageCookiePolicy />
			</Route>

			<Route exact path="/privacy-policy">
				<PagePrivacyPolicy />
			</Route>

			<Route exact path="/safeguarding-policy">
				<PageSafeguardingPolicy />
			</Route>

			<Route exact path="/user/:vanityUrl">
				<PageUserProfile />
			</Route>

			<Route exact path="/user-id/:uid">
				<PageUserProfile />
			</Route>

			<Route exact path="/user/:vanityUrl/availability">
				<PageMentorAvailability />
			</Route>

			<Route exact path="/user-id/:uid/availability">
				<PageMentorAvailability />
			</Route>

			<Route exact path="/documentation">
				<PageDocumentation />
			</Route>

			<Route exact path="/support">
				<PageSupport />
			</Route>

			<Route exact path="/zoom_callback">
				<ZoomCallback />
			</Route>

			<ProtectedRoute path="/dashboard" failureStyle="prompt">
				<PageDashboard />
			</ProtectedRoute>

			<ProtectedRoute
				exact
				path="/contact-form-submission/:id"
				failureStyle="prompt"
			>
				<PageContactFormSubmission />
			</ProtectedRoute>

			<Redirect to="/" />
		</Switch>
	);
};

export default App;
