import { useEffect } from "react";

// TODO:WV:20231120:Use a separate hook for replacing the whole title, instead of this second parameter
export function usePageTitlePrefix(
	prefix: string,
	removeBaseTitle: boolean = false
) {
	useEffect(() => {
		const titles = document.getElementsByTagName("title");
		if (!titles.length) {
			return;
		}
		const title = titles[0];

		const originalText = title.innerText + "";

		const baseTitle = title.getAttribute("data-base-title");
		if (!baseTitle) {
			throw new Error(
				"No base title - please supply a data-base-title attribute to the title tag to add a prefix to the page title"
			);
		}
		title.innerText = removeBaseTitle ? prefix : `${prefix} | ${baseTitle}`;

		return () => {
			title.innerText = originalText;
		};
	}, [prefix, removeBaseTitle]);
}
