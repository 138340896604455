export function getAllDatesBetween(first: Date, before: Date): Date[] {
	const arr: Date[] = [];
	const dt = new Date(first);

	while (dt < before) {
		arr.push(new Date(dt));
		addOneDay(dt);
	}

	return arr;
}

function addOneDay(dt: Date) {
	dt.setDate(dt.getDate() + 1);
}
