import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";
import { ucFirst } from "utils/ucFirst";

import { useProfileData } from "hooks/useProfileData";

import terminology from "terminology.json";

export const useUserProfilePageTitlePrefix = (
	vanityUrl: string,
	uid: string
) => {
	const { output: profileData } = useProfileData(vanityUrl, uid);

	const { isMentor, displayName } = profileData
		? profileData
		: { isMentor: undefined, displayName: undefined };

	usePageTitlePrefix(
		`${isMentor ? ucFirst(terminology.mentor) : `User`} Profile${
			displayName ? ` | ${displayName}` : ``
		}`
	);
};
