import React, { useEffect, useState, useCallback, useMemo } from "react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ModalConfirm from "components/ModalConfirm";
import ToggleableButton from "components/ToggleableButton";
import FieldText from "components/FieldText";
import { EditButtonRenderProps } from "components/ModalField/ModalField";

import {
	TextFieldContainer,
	HelpText,
	Losenge,
	DeleteTagButton
} from "./ModalFieldTag.styles";

interface ModalFieldTagProps {
	onCancel?: () => void;
	onOK: (newTags: string[]) => void;
	initialTags: string[];
	title: string;
	isUnlocked?: boolean;
	formatSelectedTag?: (selectedTag: string) => string;
	editButton?: (props: EditButtonRenderProps) => React.ReactNode;
	allowWrapTextInButton?: boolean;
	useTallButton?: boolean;
	useTertiaryButton?: boolean;
}

interface CurrentTag {
	text: string;
	isDeleted: boolean;
}

function ModalFieldTag({
	onCancel = () => undefined,
	onOK,
	title,
	initialTags,
	isUnlocked = true,
	formatSelectedTag = selectedTag => selectedTag,
	editButton,
	allowWrapTextInButton = true,
	useTallButton = false,
	useTertiaryButton
}: ModalFieldTagProps) {
	const initialTagsWithDeletedFlag = useMemo(
		() => initialTags.map(text => ({ text, isDeleted: false })),
		[initialTags]
	);
	const [currentTags, setCurrentTags] = useState<CurrentTag[]>(
		initialTagsWithDeletedFlag
	);

	const [textFieldValue, setTextFieldValue] = useState<string>("");

	const reset = useCallback(() => {
		setCurrentTags(initialTagsWithDeletedFlag);
	}, [initialTagsWithDeletedFlag, setCurrentTags]);

	const [isOpen, setIsOpen] = useState(false);
	const toggleIsOpen = useCallback(
		(e: any) => {
			setIsOpen(oldIsOpen => !oldIsOpen);
			e.preventDefault();
		},
		[setIsOpen]
	);
	useEffect(() => {
		reset();
	}, [isOpen, reset]);

	// Remove duplicate tags from array, keep the LAST COPY of the duplicate element; remove the others (this is to prevent confusion when re-adding deleted elements by typing them in, and to allow reording by delete + re-type)
	const dedupTags = useCallback((tags: CurrentTag[]): CurrentTag[] => {
		const output: CurrentTag[] = [];
		for (const tag of tags) {
			const existingIndex = output.findIndex(t => t.text === tag.text);
			if (existingIndex !== -1) {
				output.splice(existingIndex, 1);
			}
			output.push(tag);
		}
		return output;
	}, []);

	const handleOK = useCallback(() => {
		const newTags = textFieldValue.split(/\s*,\s*/);
		const finalTags = dedupTags(
			[
				...currentTags.filter(tag => !tag.isDeleted),
				...newTags.map(text => ({ text, isDeleted: false }))
			].filter(tag => tag.text !== "")
		);

		onOK(finalTags.map(tag => tag.text));
		setIsOpen(false);
		setTextFieldValue("");
	}, [onOK, currentTags, setIsOpen, textFieldValue, dedupTags]);

	const handleCancel = useCallback(() => {
		onCancel();
		setIsOpen(false);
	}, [onCancel, setIsOpen]);

	const handleTextFieldChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setTextFieldValue(e.currentTarget.value);
		},
		[setTextFieldValue]
	);

	const handleTextFieldKeyUp = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === "Enter") {
				// NB do not try to extract e.currentTarget.value within the setCurrentTags callback; it will result in an 'undefined' error due to its asynchronous nature
				const newTags = e.currentTarget.value.split(/\s*,\s*/);
				setCurrentTags(oldTags =>
					dedupTags([
						...oldTags,
						...newTags.map(text => ({ text, isDeleted: false }))
					])
				);

				setTextFieldValue("");
			}
			e.preventDefault();
		},
		[setTextFieldValue, setCurrentTags, dedupTags]
	);

	const handleClickDeleteTag = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			// NB do not try to extract e.currentTarget.value within the setCurrentTags callback; it will result in an 'undefined' error due to its asynchronous nature
			const tagToDelete = e.currentTarget.value;
			setCurrentTags(oldTags =>
				oldTags.map(tag => ({
					...tag,
					isDeleted: tag.text === tagToDelete ? true : tag.isDeleted
				}))
			);
		},
		[setCurrentTags]
	);

	const handleClickRestoreTag = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			// NB do not try to extract e.currentTarget.value within the setCurrentTags callback; it will result in an 'undefined' error due to its asynchronous nature
			const tagToDRestore = e.currentTarget.value;
			setCurrentTags(oldTags =>
				oldTags.map(tag => ({
					...tag,
					isDeleted: tag.text === tagToDRestore ? false : tag.isDeleted
				}))
			);
		},
		[setCurrentTags]
	);

	const displayValue = useMemo(() => {
		return initialTags.length
			? initialTags.map(formatSelectedTag).join(", ")
			: title;
	}, [initialTags, formatSelectedTag, title]);

	return (
		<>
			{editButton ? (
				editButton({ isUnlocked, onClick: toggleIsOpen })
			) : (
				<ToggleableButton
					isEditing={isUnlocked}
					icon={faCaretDown}
					onClick={toggleIsOpen}
					label={displayValue}
					allowWrap={allowWrapTextInButton}
					isTall={useTallButton}
					isTertiary={useTertiaryButton}
				/>
			)}
			<ModalConfirm
				isOpen={isOpen}
				title={title}
				onCancel={handleCancel}
				onOK={handleOK}
			>
				<HelpText>
					Please enter new tags separated by commas, and then press the{" "}
					<em>return</em> key or <em>ok</em>.
				</HelpText>
				<TextFieldContainer>
					<FieldText
						value={textFieldValue}
						onChange={handleTextFieldChange}
						onKeyUp={handleTextFieldKeyUp}
					/>
				</TextFieldContainer>
				<div>
					{currentTags.map(tag => (
						<Losenge key={tag.text} isDeleted={tag.isDeleted}>
							{tag.text}
							<DeleteTagButton
								value={tag.text}
								onClick={
									tag.isDeleted ? handleClickRestoreTag : handleClickDeleteTag
								}
							>
								<FontAwesomeIcon icon={tag.isDeleted ? faUndo : faTimes} />
							</DeleteTagButton>
						</Losenge>
					))}
				</div>
			</ModalConfirm>
		</>
	);
}

export default ModalFieldTag;
