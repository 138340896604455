import { useRef, useEffect, useContext } from "react";
import { validate, ValidationCallback } from "validation/validate";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { captureException } from "services/captureException";
import { OnCompleteOutput } from "components/ModalWizard/ModalWizard";
import { ModalWizardFormState } from "./useModalWizard";

export function useWizardComplete<CompletedWizardData>(
	onComplete: (wizardData: CompletedWizardData) => OnCompleteOutput,
	formState: ModalWizardFormState<CompletedWizardData>,
	resetWizard: () => void,
	validateCompletedData: ValidationCallback<CompletedWizardData>
) {
	const [{ uid }] = useContext(AuthContext);

	const onCompleteRef = useRef(onComplete);
	onCompleteRef.current = onComplete;
	const { wizardData } = formState;
	const wizardDataRef = useRef(wizardData);
	wizardDataRef.current = wizardData;
	const resetWizardRef = useRef(resetWizard);
	resetWizardRef.current = resetWizard;
	const { formIsComplete } = formState;
	useEffect(() => {
		const completedData = wizardDataRef.current;
		if (formIsComplete) {
			if (
				validate(completedData, {
					doValidate: validateCompletedData,
					withErrors: validationErrors => {
						throw captureException(new Error("Invalid wizard data"), {
							evtType: "invalidWizardData",
							extra: {
								validationErrors
							}
						});
					},
					auth: { uid }
				})
			) {
				const req = onCompleteRef.current(completedData);
				req.ready.then(resetWizardRef.current);
				return () => req.abort();
			}
		}
	}, [
		formIsComplete,
		onCompleteRef,
		wizardDataRef,
		resetWizardRef,
		validateCompletedData,
		uid
	]);
}
