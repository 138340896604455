import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { CheckBox } from "./LabelCheckBox.styles";

interface OptionLabelCheckBoxProps {
	checked: boolean;
	disabled?: boolean;
	takeUpLessSpace?: boolean;
}

const LabelCheckBox: React.FunctionComponent<OptionLabelCheckBoxProps> = ({
	checked,
	disabled = false,
	takeUpLessSpace = false
}) => {
	return (
		<CheckBox
			checked={checked}
			disabled={disabled}
			takeUpLessSpace={takeUpLessSpace}
		>
			{checked && !takeUpLessSpace ? <FontAwesomeIcon icon={faCheck} /> : null}
		</CheckBox>
	);
};

export default LabelCheckBox;
