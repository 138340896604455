import React from "react";

import Modal from "components/Modal";
import { ModalHeading } from "./ModalPleaseWait.styles";
import { useModalStyles } from "hooks/useModalStyles";

interface ModalPleaseWaitProps {
	isOpen: boolean;
	title: string;
	message?: string;
}

const ModalPleaseWait: React.FunctionComponent<ModalPleaseWaitProps> = ({
	isOpen,
	title,
	message = "Please wait..."
}) => {
	const modalStyles = useModalStyles("small");

	return (
		<Modal isOpen={isOpen} style={modalStyles}>
			<ModalHeading>{title}</ModalHeading>
			{message}
		</Modal>
	);
};

export default ModalPleaseWait;
