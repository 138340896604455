export type StripeAccountPayoutsStatus =
	| "account-unlinked"
	| "enabled"
	| "disabled";

export function isValidStripeAccountPayoutsStatus(
	input: unknown
): input is StripeAccountPayoutsStatus {
	return (
		input === "account-unlinked" || input === "enabled" || input === "disabled"
	);
}
