import { AccountStatus } from "./accountStatus";
import { on } from "../pubSub";

// This promise waits for the account setting to loaded, and resolves to them once they have
export const getAccountStatusPromise = (function() {
	let rejected = false;
	let accountStatusPromise = new Promise<Partial<AccountStatus>>(
		(resolve, reject) => {
			const noAccountStatusTimeout = window.setTimeout(() => {
				reject("No account settings after one minute");
				rejected = true;
			}, 60000);

			on("accountStatusChanged", (newSettings: Partial<AccountStatus>) => {
				if (!rejected) {
					window.clearTimeout(noAccountStatusTimeout);
					resolve(newSettings);
				}
			});
		}
	);

	on("accountStatusChanged", (newSettings: Partial<AccountStatus>) => {
		if (!rejected) {
			accountStatusPromise = Promise.resolve(newSettings);
		}
	});

	return () => accountStatusPromise;
})();
