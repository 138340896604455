import React from "react";

import PageContent from "components/PageContent";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";
import Markdown from "components/Markdown";

// @ts-ignore
import documentation from "./documentation.md";

const PageDocumentation: React.FunctionComponent = () => {
	usePageTitlePrefix("Documentation");

	return (
		<PageContent type="printabledocument">
			<header className="major">
				<h1>Documentation</h1>
			</header>
			<Markdown
				source={documentation}
				sourceType="file"
				replaceTokens
				encodeRawHtmlEntities={false}
			/>
		</PageContent>
	);
};

export default PageDocumentation;
