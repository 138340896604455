import { ValidationCallback } from "validation/validate";

export interface SingleSessionPrice {
	id: string;
	price: number;
	currency: string;
	sessionDurationMinutes: string;
}

export const flagValidationErrorsInSingleSessionPrice: ValidationCallback<
	SingleSessionPrice
> = (input, { checkField }) => {
	checkField("id", { type: "string" });
	checkField("price", { type: "number" });
	checkField("currency", { type: "string" });
	checkField("sessionDurationMinutes", { type: "number" });
};
