import { useProfileData } from "hooks/useProfileData";
import { useSessionTypesAndPrices } from "hooks/useSessionTypesAndPrices";

export const useMentorIsOfferingMeetings = (
	vanityUrl: string,
	uid: string,
	mentorId?: string
) => {
	const {
		isError: sessionTypesIsError,
		isWaiting: sessionTypesIsWaiting,
		isLoading: sessionTypesIsLoading,
		output: sessionTypesAndPrices
	} = useSessionTypesAndPrices(mentorId);

	const {
		isError: profileDataIsError,
		isWaiting: profileDataIsWaiting,
		isLoading: profileDataIsLoading,
		output: profileData
	} = useProfileData(vanityUrl, uid);

	const isError = sessionTypesIsError || profileDataIsError;
	const isWaiting = sessionTypesIsWaiting || profileDataIsWaiting;
	const isLoading = sessionTypesIsLoading || profileDataIsLoading;

	const output =
		isError || isWaiting || isLoading
			? undefined
			: !!sessionTypesAndPrices.sessionTypes &&
			  sessionTypesAndPrices.sessionTypes.length !== 0 &&
			  !!profileData;

	return {
		isError,
		isWaiting,
		isLoading,
		output
	};
};
