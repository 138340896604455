import moment from "moment-timezone";

export interface CompleteSelection {
	year: number;

	// Month is zero-based, JS date-object style
	month: number;
	date: number;
}

export type Selection = Partial<CompleteSelection>;

export type EmptySelection = {
	[k in keyof CompleteSelection]: undefined;
};

export function getEmptySelection(): EmptySelection {
	return {
		year: undefined,
		month: undefined,
		date: undefined
	};
}

export function getSelectionFromDate(
	date: Date,
	timezone: string
): CompleteSelection {
	const [year, month, day] = moment(date)
		.tz(timezone)
		.format("YYYY|MM|DD")
		.split("|");

	return {
		year: parseInt(year),
		month: parseInt(month) - 1,
		date: parseInt(day)
	};
}

export function isComplete(
	selection: Selection
): selection is CompleteSelection {
	return (
		selection.year !== undefined &&
		selection.month !== undefined &&
		selection.date !== undefined
	);
}

export function isEmpty(selection: Selection): selection is EmptySelection {
	return (
		selection.year === undefined &&
		selection.month === undefined &&
		selection.date === undefined
	);
}

export function equals(a: Selection, b: Selection) {
	return toMySql(a) === toMySql(b);
}

function toMySql(selection: Selection) {
	return `${selection.year}-${selection.month}-${selection.date}`;
}

export function getDateFromSelection(
	selection: CompleteSelection & { hours?: number },
	timezone: string
): Date {
	return moment.tz(selection, timezone).toDate();
}
