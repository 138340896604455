import React, { useState, useCallback, useContext, useEffect } from "react";
import flatten from "lodash.flatten";

import PackagePricesTable from "components/PageDashboard/PackagePricesSection/PackagePricesTable";
import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { useSendData } from "hooks/useSendData";
import { useSessionTypes } from "hooks/useSessionTypes";
import { usePackagePrices } from "hooks/usePackagePrices";
import { savePackagePrices as ssp } from "api/savePackagePrices";
import { OutputRow as PackagePricesData } from "api/getPackagePrices";
import { AuthContext } from "components/AuthProvider/AuthProvider";

export interface PackagePriceWithSessionType {
	id: string;
	price: number;
	currency: string;
	numMeetings: number;
	sessionDurationMinutes: number;
	isEnabled: boolean;
	sessionTypeId: string;
	sessionDescription: string;
	sessionTitle: string;
	series: string;
	guestUid: string | null;
}

const PackagePricesSection: React.FunctionComponent = () => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);

	const [dashboardContextState, setDashboardContextState] = useContext(
		DashboardContext
	);

	const { sessionTypesRefreshNum } = dashboardContextState;
	const {
		isWaiting: sessionTypesWaiting,
		isLoading: sessionTypesLoading,
		isError: sessionTypesError,
		output: sessionTypes
	} = useSessionTypes({
		mentorId: uid,
		showDisabled: true,
		doWait: isSignedIn === undefined || !uid,
		repeatNumber: sessionTypesRefreshNum,
		persistBetweenLoads: true
	});

	// We should refetch prices whever the session-types change, or the prices are edited.
	// So, set up a repeatNum for prices, which can be changed within this script, but also
	// update it whenever the sessionTypesRefreshNum changes.
	const [pricesRefreshNum, setPricesRefreshNum] = useState(
		sessionTypesRefreshNum
	);

	const {
		isWaiting: packagePricesWaiting,
		isLoading: packagePricesLoading,
		isError: packagePricesError,
		output: packagePrices
	} = usePackagePrices({
		mentorId: uid,
		showDisabled: true,
		doCurrencyConversion: false,
		doWait: isSignedIn === undefined || !uid,

		// NB! This depends on both pricesRefreshNum and sessionTypesRefreshNum so that this list
		// of session-prices updates whenever either type of object is edited.
		repeatNumber: pricesRefreshNum + sessionTypesRefreshNum,

		persistBetweenLoads: true,
		sessionTypeIds: sessionTypes
			? sessionTypes.page.map(sessionType => sessionType.id)
			: [],
		includePackagePricesForGuestUid: "all"
	});

	const [waitModalIsShowing, setWaitModalIsShowing] = useState(false);
	const [waitModalTitle, setWaitModalTitle] = useState("");
	const savePackagePrices = useSendData({ method: ssp });

	const havePackagePrices =
		packagePrices === undefined ? undefined : packagePrices.page.length !== 0;

	useEffect(() => {
		setDashboardContextState(oldState => ({
			...oldState,
			havePackagePrices
		}));
	}, [havePackagePrices, setDashboardContextState]);

	const onEditPackagePrices = useCallback(
		(rows: { old?: PackagePricesData; new?: PackagePricesData }[]) => {
			const title = rows.every(row => !row.new) ? "Deleting" : "Saving";

			setWaitModalTitle(title);
			setWaitModalIsShowing(true);

			if (!uid) {
				throw new Error("No Uid");
			}

			const req = savePackagePrices(
				{
					uid,
					rows
				},
				{ uid }
			);

			const ready = req.ready.then(response => {
				setPricesRefreshNum(oldState => oldState + 1);
				setWaitModalIsShowing(false);
			});

			return {
				ready,
				abort: () => req.abort(),
				aborted: () => req.aborted()
			};
		},
		[
			uid,
			setWaitModalTitle,
			setWaitModalIsShowing,
			setPricesRefreshNum,
			savePackagePrices
		]
	);

	// NB this should be done this way round (find packagePrices for each sessionType, rather than iterate through packagePrices and add the appropriate sessionType)
	// because otherwise there is a risk of a problem if a packagePrice has been loaded, but not its corresponding sessionType.
	const packagePricesWithSessionTypes = sessionTypes
		? flatten(
				sessionTypes.page.map(sessionType => {
					const {
						id: sessionTypeId,
						sessionDescription,
						sessionTitle
					} = sessionType;
					const packagePricesWithThisSessionType = (packagePrices
						? packagePrices.page
						: []
					).filter(
						packagePrice => packagePrice.sessionTypeId === sessionTypeId
					);

					return packagePricesWithThisSessionType.map(packagePrice => {
						const {
							id,
							price,
							currency,
							sessionDurationMinutes,
							isEnabled,
							sessionTypeId,
							series,
							guestUid,
							numMeetings
						} = packagePrice;

						return {
							id,
							price,
							currency,
							sessionDurationMinutes,
							isEnabled,
							sessionTypeId,
							sessionDescription,
							sessionTitle,
							series,
							guestUid,
							numMeetings
						};
					});
				})
		  )
		: [];

	// TODO:WV:20201009:Get the user started, maybe by asking for price during onboarding and / or adding a tutorial / walkthrough of how to set them up
	return (
		<PackagePricesTable
			onEdit={onEditPackagePrices}
			packagePricesWithSessionTypes={
				packagePricesWithSessionTypes ? packagePricesWithSessionTypes : []
			}
			isLoading={sessionTypesLoading || packagePricesLoading}
			isWaiting={sessionTypesWaiting || packagePricesWaiting}
			isError={sessionTypesError || packagePricesError}
			waitModalTitle={waitModalTitle}
			waitModalIsShowing={waitModalIsShowing}
		/>
	);
};

export default PackagePricesSection;
