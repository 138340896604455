import { useCallback, useContext } from "react";

import { AlertContext } from "components/AlertProvider/AlertProvider";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useUserProfile } from "hooks/useUserProfile";
import { validate, ValidationCallback } from "validation/validate";

type SetWizardInProgress<T> = (
	value: React.SetStateAction<{
		isInProgress: boolean;
		selectedItem?: T;
		currentUserUid?: string;
	}>
) => void;

export function useStartWizard<T>(
	thingOnSale: string,
	setWizardInProgress: SetWizardInProgress<T>,
	vanityUrl?: string,
	uid?: string,
	doValidate?: ValidationCallback<T>
) {
	const [{ isSignedIn, uid: currentUserUid }] = useContext(AuthContext);

	const { output: currentUserProfileData } = useUserProfile({
		uid: currentUserUid
	});
	const {
		vanityUrl: currentUserVanityUrl,
		uid: currentUserUidFromProfile
	} = currentUserProfileData
		? currentUserProfileData
		: { vanityUrl: undefined, uid: undefined };
	const haveDownloadedCurrentUsersProfile =
		currentUserUidFromProfile !== undefined;

	const [addAlert] = useContext(AlertContext);

	return useCallback(
		(e?: T) => {
			if (isSignedIn && haveDownloadedCurrentUsersProfile) {
				const sameUid = uid && uid === currentUserUid;
				const sameVanityUrl = vanityUrl && vanityUrl === currentUserVanityUrl;
				if (sameUid || sameVanityUrl) {
					addAlert({
						contents: `You cannot book a ${thingOnSale} with yourself, sorry.`
					});
					return;
				}
			}

			const selectedItem =
				e !== undefined &&
				doValidate &&
				validate(e, {
					doValidate,
					auth: { uid }
				})
					? e
					: undefined;

			setWizardInProgress({
				isInProgress: true,
				selectedItem,
				currentUserUid
			});
		},
		[
			setWizardInProgress,
			currentUserUid,
			uid,
			addAlert,
			currentUserVanityUrl,
			haveDownloadedCurrentUsersProfile,
			isSignedIn,
			vanityUrl,
			doValidate,
			thingOnSale
		]
	);
}
