import React, { useCallback, useMemo } from "react";
import moment from "moment-timezone";
import range from "lodash.range";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { captureException } from "services/captureException";
import { getBooleanOptions } from "services/getBooleanOptions";
import { OutputRow as ExternalCommitmentsData } from "api/getExternalCommitments";
import { ValidationCallback } from "validation/validate";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import ModalFieldSelect from "components/ModalFieldSelect";
import ModalFieldHourOfDay from "components/ModalFieldHourOfDay";
import { OnCompleteOutput } from "components/ModalWizard/ModalWizard";
import ModalFieldDate from "components/ModalFieldDate";
import ModalFieldText from "components/ModalFieldText";
import {
	Selection,
	CompleteSelection,
	getEmptySelection,
	isComplete,
	isEmpty
} from "components/FieldDate/FieldDateSelection";
import { getTimezoneOptions } from "services/getTimezoneOptions";
import EditableTable from "components/EditableTable";
import { useStickySort } from "hooks/useStickySort";
import StepTimeFrom from "./NewRowWizard/StepTimeFrom";
import StepTimeTo from "./NewRowWizard/StepTimeTo";
import StepDateFrom from "./NewRowWizard/StepDateFrom";
import StepDateTo from "./NewRowWizard/StepDateTo";
import StepTimezone from "./NewRowWizard/StepTimezone";
import StepDescription from "./NewRowWizard/StepDescription";
import StepIsExternallyBookedMeeting from "./NewRowWizard/StepIsExternallyBookedMeeting";
import terminology from "terminology.json";
import { formatAsTime } from "utils/datesAndTimes/formatAsTime";

export interface DataInAnyRow
	extends Omit<
		ExternalCommitmentsData,
		"dateStartsUTC" | "dateEndsJustBeforeUTC"
	> {
	dateStartsInRowTimezone: CompleteSelection;
	dateEndsJustBeforeInRowTimezone: CompleteSelection;
	timeStartsInRowTimezone: moment.Duration;
	timeEndsJustBeforeInRowTimezone: moment.Duration;
}

interface ExternalCommitmentsTableProps {
	commitments: DataInAnyRow[];
	isLoading: boolean;
	isWaiting: boolean;
	isError: boolean;
	waitModalTitle: string;
	waitModalIsShowing: boolean;
	onEdit: (
		rows: {
			old?: DataInAnyRow;
			new?: DataInAnyRow;
		}[]
	) => OnCompleteOutput;
}

const ExternalCommitmentsTable: React.FunctionComponent<
	ExternalCommitmentsTableProps
> = ({
	commitments,
	onEdit: onEditGlobal,
	isLoading,
	isWaiting,
	isError,
	waitModalTitle,
	waitModalIsShowing
}) => {
	const onNewRowWizardComplete = useCallback(
		(data: DataInAnyRow) => {
			return onEditGlobal([
				{
					new: {
						...data
					}
				}
			]);
		},
		[onEditGlobal]
	);

	const sortedExternalCommitmentsPeriods = useStickySort(
		commitments,
		useCallback((a: DataInAnyRow, b: DataInAnyRow) => {
			const aStart = moment(a.dateStartsInRowTimezone)
				.toDate()
				.getTime();
			const bStart = moment(b.dateStartsInRowTimezone)
				.toDate()
				.getTime();

			if (aStart !== bStart) {
				return aStart - bStart;
			}

			if (a.timeStartsInRowTimezone !== b.timeStartsInRowTimezone) {
				return (
					a.timeStartsInRowTimezone.as("minutes") -
					b.timeStartsInRowTimezone.as("minutes")
				);
			}

			const aEnd = moment(a.dateEndsJustBeforeInRowTimezone)
				.toDate()
				.getTime();
			const bEnd = moment(b.dateEndsJustBeforeInRowTimezone)
				.toDate()
				.getTime();

			if (aEnd !== bEnd) {
				return aEnd - bEnd;
			}

			if (
				a.timeEndsJustBeforeInRowTimezone !== b.timeEndsJustBeforeInRowTimezone
			) {
				return (
					a.timeEndsJustBeforeInRowTimezone.as("minutes") -
					b.timeEndsJustBeforeInRowTimezone.as("minutes")
				);
			}

			return 0;
		}, [])
	);

	const rows = useMemo(
		() =>
			sortedExternalCommitmentsPeriods.map(data => ({
				key: data.id,
				data,
				actionWhenEditing: {
					icon: faTrash,
					label: "Delete",
					onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
						e.preventDefault();
						onEditGlobal([{ old: data }]);
					}
				},
				isReadOnly: data.source !== "manual"
			})),
		[sortedExternalCommitmentsPeriods, onEditGlobal]
	);

	const newRowWizard = useMemo(() => {
		const steps = [
			{
				key: "timezone",
				title: "What timezone will you set the times in?",
				component: StepTimezone,
				doScrollToInitialPosition: true
			},
			{
				key: "date-from",
				title: "What date does the commitment start on?",
				component: StepDateFrom
			},
			{
				key: "time-from",
				title: "What time does the commitment start at?",
				component: StepTimeFrom
			},
			{
				key: "date-to",
				title: "What date does the commitment end on?",
				component: StepDateTo
			},
			{
				key: "time-to",
				title: "What time does the commitment end at?",
				component: StepTimeTo
			},
			{
				key: "description",
				title: "What will you be doing?  (this is private to you)",
				component: StepDescription
			},
			{
				key: "isExternallyBookedMeeting",
				title: `Is this a teaching appointment?`,
				component: StepIsExternallyBookedMeeting
			}
		];

		const validateNewRowData: ValidationCallback<DataInAnyRow> = (
			input,
			{ flag, checkField }
		) => {
			const {
				timeStartsInRowTimezone,
				timeEndsJustBeforeInRowTimezone
			} = input;

			checkField("dateStartsInRowTimezone", {
				type: "completedatefieldselection"
			});
			checkField("dateEndsJustBeforeInRowTimezone", {
				type: "completedatefieldselection",
				isRequired: false
			});
			checkField("timezone", { type: "string" });

			if (!timeStartsInRowTimezone) {
				flag("timeStartsInRowTimezone", "mising");
			} else if (!moment.isDuration(timeStartsInRowTimezone)) {
				flag("timeStartsInRowTimezone", "not a moment duration");
			}

			if (!timeEndsJustBeforeInRowTimezone) {
				flag("timeEndsJustBeforeInRowTimezone", "mising");
			} else if (!moment.isDuration(timeEndsJustBeforeInRowTimezone)) {
				flag("timeEndsJustBeforeInRowTimezone", "not a moment duration");
			}

			checkField("isExternallyBookedMeeting", { type: "boolean" });
		};

		const initialData = {};

		return {
			steps,
			validateNewRowData,
			initialData
		};
	}, []);

	return (
		<EditableTable<DataInAnyRow>
			allowDeleteRows
			rows={rows}
			onEdit={onEditGlobal}
			dataDescriptionSingular="external commitment"
			dataDescriptionPlural="external commitments"
			explanation={`You can use this section to record periods when you will not be available for ${terminology.siteName} ${terminology.meetings}.`}
			onNew={onNewRowWizardComplete}
			newRowWizard={newRowWizard}
			isLoading={isLoading}
			isWaiting={isWaiting}
			isError={isError}
			waitModalTitle={waitModalTitle}
			waitModalIsShowing={waitModalIsShowing}
			cols={[
				{
					key: "timezone",
					heading: "Timezone",
					field: ({ row, rowKey, isEditing, onEdit }) => (
						<ModalFieldSelect
							isUnlocked={isEditing}
							formatSelectedOption={formatSelectedTimezone}
							title="Timezone"
							requireSelection
							onOK={newOptions => {
								const selectedOption = newOptions.find(val => !!val.selected);
								if (!selectedOption) {
									throw captureException(new Error("No selected option"), {
										evtType: "noSelectedOption"
									});
								}

								const newTimezone = selectedOption.value + "";
								const newData = {
									...row,
									[rowKey]: newTimezone
								};
								onEdit([{ old: row, new: newData }]);
							}}
							options={getTimezoneOptions(row.timezone)}
							allowMultipleSelections={false}
						/>
					)
				},
				{
					key: "dateStartsInRowTimezone",
					heading: "Start date",
					field: ({ row, rowKey, isEditing, onEdit }) => (
						<ModalFieldDate
							isUnlocked={isEditing}
							formatSelection={selection =>
								formatSelection(selection, isEditing)
							}
							title="Valid from"
							requireSelectedDate
							onOK={newDate => {
								if (!newDate) {
									throw captureException(new Error("No selected date"), {
										evtType: "noSelectedDate"
									});
								}

								if (isEmpty(newDate)) {
									throw captureException(new Error("Selected date was empty"), {
										evtType: "selectedDateWasEmpty"
									});
								}

								const newData = {
									...row,
									[rowKey]: newDate
								};

								onEdit([{ old: row, new: newData }]);
							}}
							allowEmptyValue={false}
							value={row.dateStartsInRowTimezone}
							mustBeBefore={row.dateEndsJustBeforeInRowTimezone}
						/>
					)
				},
				{
					key: "timeStartsInRowTimezone",
					heading: "Start time",
					field: ({ row, rowKey, isEditing, onEdit, isReadOnly }) =>
						isReadOnly ? (
							/* Show text rather than the hour-of-day field in read-only mode, because imported commitments may not start and end on the hour */ formatAsTime(
								row.timeStartsInRowTimezone
							)
						) : (
							<ModalFieldHourOfDay
								isUnlocked={isEditing}
								title="Available from"
								value={row.timeStartsInRowTimezone.hours()}
								requireSelectedTime
								isDisabled={(value: number | undefined) => {
									if (row.dateStartsInRowTimezone === undefined) {
										return false;
									}

									if (value === undefined) {
										return false;
									}

									if (row.dateEndsJustBeforeInRowTimezone === undefined) {
										return false;
									}

									if (row.timeEndsJustBeforeInRowTimezone === undefined) {
										return false;
									}

									if (row.timezone === undefined) {
										return false;
									}

									const startDateTime = moment
										.tz(row.dateStartsInRowTimezone, row.timezone)
										.hours(value)
										.startOf("hour");
									const endDateTime = moment
										.tz(row.dateEndsJustBeforeInRowTimezone, row.timezone)
										.hours(row.timeEndsJustBeforeInRowTimezone.hours())
										.startOf("hour");

									return startDateTime.isSameOrAfter(endDateTime);
								}}
								onOK={(newValue: number | undefined) => {
									if (!newValue) {
										throw captureException(new Error("No new value"), {
											evtType: "noNewValue"
										});
									}

									const newData = {
										...row,
										[rowKey]: moment.duration({
											hours: newValue
										})
									};
									onEdit([{ old: row, new: newData }]);
								}}
							/>
						)
				},
				{
					key: "dateEndsJustBeforeInRowTimezone",
					heading: "End date",
					field: ({ row, rowKey, isEditing, onEdit }) => (
						<ModalFieldDate
							isUnlocked={isEditing}
							formatSelection={selection =>
								formatSelection(selection, isEditing)
							}
							title="Valid until"
							onOK={newDate => {
								const newData = {
									...row,
									[rowKey]: newDate && isComplete(newDate) ? newDate : undefined
								};

								onEdit([{ old: row, new: newData }]);
							}}
							allowEmptyValue={true}
							value={
								row.dateEndsJustBeforeInRowTimezone
									? row.dateEndsJustBeforeInRowTimezone
									: getEmptySelection()
							}
							mustBeAfter={row.dateStartsInRowTimezone}
						/>
					)
				},
				{
					key: "timeEndsJustBeforeInRowTimezone",
					heading: "End time",
					field: ({ row, rowKey, isEditing, onEdit, isReadOnly }) =>
						isReadOnly ? (
							/* Show text rather than the hour-of-day field in read-only mode, because imported commitments may not start and end on the hour */ formatAsTime(
								row.timeEndsJustBeforeInRowTimezone
							)
						) : (
							<ModalFieldHourOfDay
								isUnlocked={isEditing}
								title="Available until"
								value={row.timeEndsJustBeforeInRowTimezone.hours()}
								requireSelectedTime
								isDisabled={(value: number | undefined) => {
									if (row.dateStartsInRowTimezone === undefined) {
										return false;
									}

									if (row.timeStartsInRowTimezone === undefined) {
										return false;
									}

									if (row.dateEndsJustBeforeInRowTimezone === undefined) {
										return false;
									}

									if (value === undefined) {
										return false;
									}

									if (row.timezone === undefined) {
										return false;
									}

									const startDateTime = moment
										.tz(row.dateStartsInRowTimezone, row.timezone)
										.hours(row.timeStartsInRowTimezone.hours())
										.startOf("hour");
									const endDateTime = moment
										.tz(row.dateEndsJustBeforeInRowTimezone, row.timezone)
										.hours(value)
										.startOf("hour");

									return endDateTime.isSameOrBefore(startDateTime);
								}}
								onOK={(newValue: number | undefined) => {
									if (!newValue) {
										throw captureException(new Error("No new value"), {
											evtType: "noNewValue"
										});
									}

									const newData = {
										...row,
										[rowKey]: moment.duration({
											hours: newValue
										})
									};
									onEdit([{ old: row, new: newData }]);
								}}
							/>
						)
				},
				{
					key: "description",
					heading: "Description",
					field: ({ row, rowKey, isEditing, onEdit }) => (
						<ModalFieldText
							isUnlocked={isEditing}
							title="Description"
							helptext="What will you be doing?  (this is private to you)"
							onOK={newValue => {
								const newData = {
									...row,
									[rowKey]: newValue
								};
								onEdit([{ old: row, new: newData }]);
							}}
							value={row.description ? row.description : ""}
						/>
					)
				},
				{
					key: "isExternallyBookedMeeting",
					heading: `Is teaching appointment?`,
					field: ({ row, rowKey, isEditing, onEdit }) => (
						<ModalFieldSelect
							isUnlocked={isEditing}
							title={`Is this a ${terminology.meeting} booked outside this system?`}
							requireSelection
							onOK={newOptions => {
								const selectedOption = newOptions.find(val => !!val.selected);
								if (!selectedOption) {
									throw captureException(new Error("No selected option"), {
										evtType: "noSelectedOption"
									});
								}

								const newData = {
									...row,
									[rowKey]: !!selectedOption.value
								};
								onEdit([{ old: row, new: newData }]);
							}}
							options={getBooleanOptions(!!row.isExternallyBookedMeeting)}
							allowMultipleSelections={false}
							useCheckboxes={true}
						/>
					)
				}
			]}
		/>
	);
};

export default ExternalCommitmentsTable;

export function getDayOfWeekOptions(selectedValues?: number[]) {
	return range(1, 8).map(dayOfWeek => ({
		id: dayOfWeek + "",
		value: dayOfWeek,
		text: formatDayOfWeek(dayOfWeek),
		selected: !!selectedValues && selectedValues.includes(dayOfWeek)
	}));
}

function formatSelectedTimezone(opt: FieldOption<string>) {
	return opt.value.indexOf("/") === -1 ? opt.value : opt.value.split("/")[1];
}

function formatDayOfWeek(dayOfWeek: number) {
	return moment()
		.isoWeekday(dayOfWeek)
		.format("ddd");
}

function formatSelection(
	selection: Selection,
	isShortVersion: boolean = false
) {
	if (!isComplete(selection)) {
		return "";
	}

	const formatString = isShortVersion ? `DD/MMM/YYYY` : `ddd Do MMM YYYY`;
	return moment.utc(selection).format(formatString);
}
