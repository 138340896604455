import React from "react";

import PageContent from "components/PageContent";

const PagePrivacyPolicy: React.FunctionComponent = () => {
	return (
		<PageContent>
			<header className="major">
				<h1>
					Kaboom{" "}
					<span role="img" aria-label="explosion">
						💥
					</span>
				</h1>
			</header>
			<p>Oh no! There was an error somewhere.</p>
			<p>
				We're very sorry about this; it's been logged, and will be fixed as soon
				as possible.
			</p>

			{/* NB using 'Link' instead of 'a' in the line below doesn't work; the page will still show the error boundary after it is clicked. */}
			<p>
				If you don't see it resolved, please <a href="/contact">let us know</a>.
			</p>
		</PageContent>
	);
};

export default PagePrivacyPolicy;
