import React, { useState, useEffect, useCallback, useMemo } from "react";
import MarkdownToJSX from "markdown-to-jsx";

import { safeTsNotNullish } from "utils/safeTsNotNullish";
import terminology from "terminology.json";

import { MarkdownOutput } from "./Markdown.styles";

interface MarkdownProps {
	source: string;
	sourceType: "file" | "string";
	replaceTokens?: boolean;
	autoLinkPronunciationExamples?: boolean;
	encodeRawHtmlEntities?: boolean;
}

const Markdown: React.FunctionComponent<MarkdownProps> = ({
	source,
	sourceType = "string",
	replaceTokens = false,
	autoLinkPronunciationExamples = false,
	encodeRawHtmlEntities = true
}) => {
	const [markdownContents, setMarkdownContents] = useState("");

	const handleTokens = useCallback(
		str => {
			return replaceTokens ? swapTokens(str) : str;
		},
		[replaceTokens]
	);

	const handleCustomTags = useCallback(
		str => {
			return autoLinkPronunciationExamples
				? swapPronunciationExamples(str)
				: str;
		},
		[autoLinkPronunciationExamples]
	);

	useEffect(() => {
		const rawMarkdownPromise =
			sourceType === "string"
				? Promise.resolve(source)
				: fetch(source).then(response => response.text());

		const processedMarkdownPromise = rawMarkdownPromise
			.then(handleTokens)
			.then(handleCustomTags);

		processedMarkdownPromise.then(setMarkdownContents);
	}, [
		source,
		sourceType,
		replaceTokens,
		setMarkdownContents,
		handleCustomTags,
		handleTokens
	]);

	const slugify = useCallback(str => {
		if (/^[0-9.]+/.test(str)) {
			return str.replace(/^([0-9.]+)(.*?)$/, "$1");
		}
		return str.replace(/[^0-9.a-zA-Z]/g, "-").toLowerCase();
	}, []);

	// TODO:WV:20210413:Split the 'overrides' components into their own files
	const options = useMemo(
		() => ({
			slugify,
			disableParsingRawHTML: encodeRawHtmlEntities,
			overrides: {
				WithContents: ({ children }: any) => {
					const childrenArray = React.Children.toArray(children);

					const progenyContainingHeadings: React.ReactNode[] = [];

					for (const child of childrenArray) {
						if (typeof child !== "object") {
							continue;
						}
						if ("type" in child && child.type === "h2") {
							progenyContainingHeadings.push(child);
						}

						if ("props" in child) {
							const { props } = child as Record<"props", { [k: string]: any }>;
							if (typeof props === "object" && props !== null) {
								if ("children" in props) {
									for (const grandChild of React.Children.toArray(
										(props as Record<"children", typeof React.Children>)
											.children
									)) {
										if (typeof grandChild !== "object") {
											continue;
										}
										if ("type" in grandChild && grandChild.type === "h2") {
											progenyContainingHeadings.push(grandChild);
										}
									}
								}
							}
						}
					}

					const isValidDescendent = (
						descendent: any
					): descendent is { props: { id: string; children: any[] } } => {
						return (
							typeof descendent === "object" &&
							descendent !== null &&
							"props" in descendent &&
							descendent.props.id &&
							typeof descendent.props.id === "string" &&
							descendent.props.children &&
							React.Children.toArray(descendent.props.children).length === 1
						);
					};

					const contents = progenyContainingHeadings
						.filter(descendent => {
							if (!isValidDescendent(descendent)) {
								return false;
							}
							return true;
						})
						.map(descendent => {
							if (!isValidDescendent(descendent)) {
								throw new Error("Invalid descendent found");
							}

							const { children, id } = descendent.props;
							const text = React.Children.toArray(children)[0];
							return {
								id,
								text
							};
						});

					return (
						<>
							<ul className="contents">
								{contents.map(content => (
									<li key={`contents-${content.id}`}>
										<a href={`#${content.id}`}>{content.text}</a>
									</li>
								))}
							</ul>
							{children}
						</>
					);
				},
				PrintButton: () => (
					<button
						style={{ float: "right" }}
						className="print-button"
						onClick={() => {
							window.print();
						}}
					>
						Print page / save to PDF
					</button>
				)
			}
		}),
		[slugify, encodeRawHtmlEntities]
	);

	return (
		<MarkdownOutput>
			<MarkdownToJSX options={options}>{markdownContents}</MarkdownToJSX>
		</MarkdownOutput>
	);
};

export default Markdown;

function swapTokens(markdown: string) {
	function _replace(
		_markdown: string,
		values: { [k: string]: string | undefined },
		namespace: string
	) {
		const rgx = new RegExp(`%${namespace ? `${namespace}.` : ""}([^%]+)%`, "g");
		return _markdown.replace(rgx, (match, p1) => {
			if (values[p1]) {
				return safeTsNotNullish(values[p1]);
			}
			throw new Error(`Unknown ${namespace} token ${12}`);
		});
	}

	let cur: string = markdown;
	cur = _replace(cur, process.env, "environment");
	cur = _replace(cur, terminology, "terminology");

	return cur;
}

function swapPronunciationExamples(markdown: string) {
	const rgx = new RegExp(`{([^}]+)}`, "g");
	return markdown.replace(rgx, (match, p1) => {
		return `[${p1}](https://youglish.com/pronounce/${encodeURIComponent(
			p1
		)}/english/uk)`;
	});
}
