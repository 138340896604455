import React, { useEffect, useCallback, useState, useContext } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

import Button from "components/Button";
import { ZoomContext } from "components/ZoomProvider/ZoomProvider";
import ModalPleaseWait from "components/ModalPleaseWait";
import { getAuthToken } from "services/getAuthToken";
import { useAccountStatus } from "hooks/useAccountStatus";
import { useSaveDataAndRefreshAccountDetailsFromApi } from "hooks/useSaveDataAndRefreshAccountDetailsFromApi";
import { AuthContext } from "components/AuthProvider/AuthProvider";

const ZoomLinker: React.FunctionComponent = () => {
	const location = useLocation();
	const [{ isSignedIn }] = useContext(AuthContext);
	const [zoomState, setZoomState] = useContext(ZoomContext);
	const { hasLinkedZoomAccount } = useAccountStatus();
	const [isSaving, setIsSaving] = useState(false);
	const [isUnlinking, setIsUnlinking] = useState(false);

	const { pathname } = location;
	const stateForZoom = {
		pathname
	};

	const stateString = Buffer.from(
		JSON.stringify(stateForZoom),
		"binary"
	).toString("base64");
	const handleClickLinkZoomAccount = useCallback(() => {
		if (!isSignedIn) {
			return;
		}
		getAuthToken().then(token => {
			if (!token) {
				return;
			}

			const redirect_uri =
				window.location.protocol +
				"//" +
				window.location.host +
				"/zoom_callback";

			if (!process.env.REACT_APP_ZOOM_CLIENT_ID) {
				throw new Error("No Zoom client ID configured");
			}

			const authURL = `https://zoom.us/oauth/authorize?${queryString.stringify({
				response_type: "code",
				client_id: process.env.REACT_APP_ZOOM_CLIENT_ID,
				state: stateString,
				redirect_uri
			})}`;
			window.location.assign(authURL);
		});
	}, [isSignedIn, stateString]);

	const handleClickUnlinkZoomAccount = useCallback(() => {
		setIsUnlinking(true);
	}, [setIsUnlinking]);

	const unlinkAndRefresh = useSaveDataAndRefreshAccountDetailsFromApi<
		undefined
	>("zoomUnlink", "unlinking your zoom account", setIsSaving);

	useEffect(() => {
		if (isUnlinking) {
			return unlinkAndRefresh({
				onComplete: async () => {
					setIsUnlinking(false);
				},
				data: undefined
			});
		}
	}, [unlinkAndRefresh, isUnlinking, setIsUnlinking]);

	// WV:20220921:This function is for working around a possible caching issue on the Hostarmada hosting
	const waitBeforeRefreshing = useCallback(
		() => new Promise<void>((resolve, reject) => setTimeout(resolve, 500)),
		[]
	);

	const finishOauthAndRefresh = useSaveDataAndRefreshAccountDetailsFromApi<{
		code: string;
	}>(
		"zoomFinishOAuth",
		"linking your zoom account",
		setIsSaving,
		undefined,
		waitBeforeRefreshing
	);

	useEffect(() => {
		const { code = undefined } = zoomState ? zoomState : {};

		if (code && typeof code === "string") {
			return finishOauthAndRefresh({
				data: { code },
				onComplete: async () => {
					setZoomState(oldState => {
						const { code: c = undefined, ...rest } = oldState ? oldState : {};
						if (c) {
							// Do nothing; this avoids typescript complaining about unused variable
						}

						return rest;
					});
				}
			});
		}
	}, [finishOauthAndRefresh, zoomState, setZoomState, pathname]);

	// TODO:WV:20200814:Improve this help text.  Handle zoom failures (e.g. user disabled their account).  Provide detailed small text about our system having access to read and write all their zoom meetings (not just this-service-related ones)
	return (
		<>
			<ModalPleaseWait isOpen={isSaving} title="Saving" />
			{hasLinkedZoomAccount ? (
				<p>
					Your zoom account is already <strong>linked</strong>, so there's
					nothing to do here; to remove the link, use the button below.
				</p>
			) : null}
			{!hasLinkedZoomAccount ? (
				<p>
					To link your account, or create a new account and link it, please use
					the button below.
				</p>
			) : null}
			{
				<ul className="actions">
					<li>
						<Button
							isPrimary={false}
							onClick={
								hasLinkedZoomAccount
									? handleClickUnlinkZoomAccount
									: handleClickLinkZoomAccount
							}
							icon={faVideo}
							label={`${hasLinkedZoomAccount ? "Unlink" : "Link"} Zoom Account`}
						/>
					</li>
				</ul>
			}
		</>
	);
};

export default ZoomLinker;
