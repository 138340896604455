import { ModalWizardStep } from "components/ModalWizard/ModalWizard";

export function getVisibleSteps<CompletedWizardData>(
	steps: ModalWizardStep<CompletedWizardData>[],
	wizardData: Partial<CompletedWizardData>
) {
	return steps.filter(step =>
		step.skipThisStep ? !step.skipThisStep(wizardData, steps, step.key) : true
	);
}
