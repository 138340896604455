import React, { useEffect, useState } from "react";

import FieldSelect from "components/FieldSelect";
import { FieldOption } from "components/FieldSelect/FieldSelect";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import { getSessionPriceOptions } from "services/getSessionPriceOptions";
import { DataInAnyRow } from "components/PageDashboard/SingleSessionPricesSection/SingleSessionPricesTable/SingleSessionPricesTable";

function StepSessionDuration({
	enableProgressToNextStep,
	disableProgressToNextStep
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [currentOptions, setCurrentOptions] = useState<FieldOption<number>[]>(
		getSessionPriceOptions()
	);

	useEffect(() => {
		const selectedOption = currentOptions.find(
			currentOptions => !!currentOptions.selected
		);
		if (!selectedOption) {
			disableProgressToNextStep();
			return;
		}

		enableProgressToNextStep({
			sessionDurationMinutes: selectedOption.value
		});
	}, [enableProgressToNextStep, disableProgressToNextStep, currentOptions]);

	return (
		<FieldSelect
			allowMultipleSelections={false}
			currentOptions={currentOptions}
			onNewCurrentOptions={setCurrentOptions}
		/>
	);
}

export default StepSessionDuration;
