import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	id: string;
	message?: string;
}

type Output = {};
const flagValidationErrors: ValidationCallback<Output> = () => {};

export function cancelPackageRequest(
	{ id, message }: Params,
	auth: Auth
): ApiRequest<{}> {
	return makeApiRequest("packageRequestCancel", {
		data: {
			post: {
				id,
				...(message ? { message } : {})
			}
		},
		flagValidationErrors,
		auth
	});
}
