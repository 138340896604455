import React from "react";

const Sections: React.FunctionComponent = ({ children }) => {
	return (
		<div id="wrapper">
			{React.Children.toArray(children).map((child, i) =>
				typeof child === "string" ||
				typeof child === "number" ||
				isEmptyObject(child)
					? child
					: React.cloneElement(child, { _instanceNumber: i, key: i })
			)}
		</div>
	);
};

export default Sections;

type EmptyObject = {};

function isEmptyObject(arg: unknown): arg is EmptyObject {
	return (
		typeof arg === "object" && arg !== null && Object.keys(arg).length === 0
	);
}
