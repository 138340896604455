import React, { useCallback, useRef } from "react";
import range from "lodash.range";
import moment from "moment";

import { safeTsNotNullish } from "utils/safeTsNotNullish";
import {
	FieldDateDropDown,
	DatePartOption,
	FieldDateDayOfMonth
} from "./FieldDate.styles";

import { useScreenSizeIsMin } from "hooks/useScreenSizeIsMin";

import { Selection, isComplete } from "./FieldDateSelection";

export interface FieldDateProps {
	mustBeAfter?: Selection;
	mustBeBefore?: Selection;

	currentSelection: Selection;
	onNewSelection: React.Dispatch<React.SetStateAction<Selection>>;
	isNotValid?: boolean;
}

const FieldDate: React.FunctionComponent<FieldDateProps> = ({
	mustBeAfter,
	mustBeBefore,
	currentSelection,
	onNewSelection,
	isNotValid = false
}) => {
	const haveMustBeAfter = !!mustBeAfter && isComplete(mustBeAfter);
	const haveMustBeBefore = !!mustBeBefore && isComplete(mustBeBefore);

	const dayOptions = useRef<number[]>(range(1, 32));
	const monthOptions = useRef<number[]>(range(0, 12));

	const earliestYearIfNoSelection: number = haveMustBeAfter
		? safeTsNotNullish(safeTsNotNullish(mustBeAfter).year)
		: new Date().getFullYear();

	const earliestYear = currentSelection.year
		? Math.min(earliestYearIfNoSelection, currentSelection.year)
		: earliestYearIfNoSelection;
	const latestYearIfNoSelection: number = haveMustBeBefore
		? safeTsNotNullish(safeTsNotNullish(mustBeBefore).year)
		: new Date().getFullYear();
	const latestYear =
		(currentSelection.year
			? Math.max(latestYearIfNoSelection, currentSelection.year)
			: latestYearIfNoSelection) + 2;
	const yearOptions = useRef<number[]>(range(earliestYear, latestYear));

	const handleDateChange = useCallback(
		(
			e: React.ChangeEvent<HTMLSelectElement>,
			datePart: "date" | "month" | "year"
		) => {
			e.preventDefault();

			const newValue =
				e.currentTarget.value === undefined || e.currentTarget.value === ""
					? undefined
					: parseInt(e.currentTarget.value);
			onNewSelection(prev => ({ ...prev, [datePart]: newValue }));
		},
		[onNewSelection]
	);

	const handleNewDay = useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) => {
			handleDateChange(e, "date");
		},
		[handleDateChange]
	);

	const handleNewMonth = useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) => {
			handleDateChange(e, "month");
		},
		[handleDateChange]
	);

	const handleNewYear = useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) => {
			handleDateChange(e, "year");
		},
		[handleDateChange]
	);

	const screenSizeIsNotTiny = useScreenSizeIsMin("tablet");

	const isValidAndInAllowedRange = useCallback(
		(selection: Selection) => {
			if (!isComplete(selection)) {
				const haveYearOnly =
					selection.year !== undefined &&
					selection.month === undefined &&
					selection.date === undefined;
				const haveYearAndMonth =
					selection.year !== undefined &&
					selection.month !== undefined &&
					selection.date === undefined;
				const haveYearAndDay =
					selection.year !== undefined &&
					selection.month === undefined &&
					selection.date !== undefined;

				if (haveYearOnly) {
					if (
						haveMustBeAfter &&
						safeTsNotNullish(selection.year) <
							safeTsNotNullish(safeTsNotNullish(mustBeAfter).year)
					) {
						return false;
					}
					if (
						haveMustBeBefore &&
						safeTsNotNullish(selection.year) >
							safeTsNotNullish(safeTsNotNullish(mustBeBefore).year)
					) {
						return false;
					}
					return true;
				}

				if (haveYearAndMonth) {
					const startOfMonth = moment(selection).startOf("month");
					const endOfMonth = moment(selection).endOf("month");

					if (haveMustBeBefore) {
						if (!startOfMonth.isBefore(safeTsNotNullish(mustBeBefore))) {
							return false;
						}
					}
					if (haveMustBeAfter) {
						if (!endOfMonth.isAfter(safeTsNotNullish(mustBeAfter))) {
							return false;
						}
					}
					return true;
				}

				if (haveYearAndDay) {
					const possibleOptions = range(0, 13).map(n =>
						moment({ ...selection, month: n })
					);
					return possibleOptions.some(opt => {
						if (!opt.isValid()) {
							return false;
						}
						if (haveMustBeBefore) {
							if (!opt.isBefore(safeTsNotNullish(mustBeBefore))) {
								return false;
							}
						}
						if (haveMustBeAfter) {
							if (!opt.isAfter(safeTsNotNullish(mustBeAfter))) {
								return false;
							}
						}
						return true;
					});
				}
				return true;
			}

			const mNewDate = moment(selection);

			if (!mNewDate.isValid()) {
				return false;
			}

			if (haveMustBeAfter && !mNewDate.isAfter(safeTsNotNullish(mustBeAfter))) {
				return false;
			}
			if (
				haveMustBeBefore &&
				!mNewDate.isBefore(safeTsNotNullish(mustBeBefore))
			) {
				return false;
			}

			return true;
		},
		[haveMustBeAfter, haveMustBeBefore, mustBeAfter, mustBeBefore]
	);

	if (
		haveMustBeAfter &&
		isComplete(currentSelection) &&
		!moment(currentSelection).isAfter(mustBeAfter)
	) {
		throw new Error("Current date out of range (too early)");
	}

	if (
		haveMustBeBefore &&
		isComplete(currentSelection) &&
		!moment(currentSelection).isBefore(mustBeBefore)
	) {
		throw new Error("Current date out of range (too late)");
	}

	if (
		haveMustBeAfter &&
		haveMustBeBefore &&
		moment(mustBeAfter).isSameOrAfter(mustBeBefore)
	) {
		throw new Error(
			"mustBeAfter and mustBeBefore are the same as each other or in the wrong order"
		);
	}

	return (
		<>
			{/* On small screens, hide the day of the week to prevent date field wrapping onto a second line */}
			{screenSizeIsNotTiny ? (
				<FieldDateDayOfMonth>
					{isComplete(currentSelection)
						? moment(currentSelection).format("ddd")
						: null}
				</FieldDateDayOfMonth>
			) : null}
			<FieldDateDropDown
				value={currentSelection.date ? currentSelection.date : ""}
				onChange={handleNewDay}
				isNotValid={isNotValid}
			>
				<DatePartOption></DatePartOption>
				{dayOptions.current.map(n => (
					<DatePartOption
						key={`day-${n}`}
						value={n}
						disabled={
							!isValidAndInAllowedRange({ ...currentSelection, date: n })
						}
					>
						{moment(currentSelection)
							.date(n)
							.format("Do")}
					</DatePartOption>
				))}
			</FieldDateDropDown>

			<FieldDateDropDown
				value={currentSelection ? currentSelection.month : ""}
				onChange={handleNewMonth}
				isNotValid={isNotValid}
			>
				<DatePartOption></DatePartOption>
				{monthOptions.current.map(n => (
					<DatePartOption
						key={`month-${n}`}
						value={n}
						disabled={
							!isValidAndInAllowedRange({ ...currentSelection, month: n })
						}
					>
						{moment(currentSelection)
							.month(n)
							.format("MMMM")}
					</DatePartOption>
				))}
			</FieldDateDropDown>

			<FieldDateDropDown
				value={currentSelection ? currentSelection.year : ""}
				onChange={handleNewYear}
				isNotValid={isNotValid}
			>
				<DatePartOption></DatePartOption>
				{yearOptions.current.map(n => (
					<DatePartOption
						key={`year-${n}`}
						value={n}
						disabled={
							!isValidAndInAllowedRange({ ...currentSelection, year: n })
						}
					>
						{n}
					</DatePartOption>
				))}
			</FieldDateDropDown>
		</>
	);
};

export default FieldDate;
