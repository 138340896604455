import React from "react";
import TimeOption from "./TimeOption";

import { FieldHourOfDayContainer } from "./FieldHourOfDay.styles";

interface FieldHourOfDayProps {
	selectedTime?: number;
	onChange: (newValue: number | undefined) => void;
	isDisabled?: (value: number) => boolean;
}

const FieldHourOfDay: React.FunctionComponent<FieldHourOfDayProps> = ({
	selectedTime,
	onChange,
	isDisabled = value => false
}) => {
	const times = Array.from(Array(24).keys());

	return (
		<FieldHourOfDayContainer>
			<div className="row gtr-uniform">
				{times.map(time => (
					<TimeOption
						key={`fld_time_${time}`}
						value={time}
						onChange={e => onChange(e.currentTarget.checked ? time : undefined)}
						isSelected={selectedTime === time}
						isDisabled={isDisabled(time)}
					/>
				))}
			</div>
		</FieldHourOfDayContainer>
	);
};

export default FieldHourOfDay;
