import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	uid: string;
	paymentMethodId: string;
}

type Output = {};
const flagValidationErrors: ValidationCallback<Output> = () => {};

export function deletePaymentMethod(
	{ uid, paymentMethodId }: Params,
	auth: Auth
): ApiRequest<Output> {
	return makeApiRequest("deletePaymentMethod", {
		auth,
		data: {
			post: {
				uid,
				paymentMethodId
			}
		},
		flagValidationErrors
	});
}
