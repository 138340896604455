import React, { useEffect, useState, useCallback, useMemo } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";

import ToggleableButton from "components/ToggleableButton";
import ModalConfirm from "components/ModalConfirm";
import { EditButtonRenderProps } from "components/ModalField/ModalField";
import FieldHourOfDay from "components/FieldHourOfDay";
import { formatAsTime } from "utils/datesAndTimes/formatAsTime";

interface ModalFieldHourOfDayProps {
	onCancel?: () => void;
	onOK: (newValue: number | undefined) => void;
	title: string;
	helptext?: string;
	value: number | undefined;
	requireSelectedTime: boolean;
	isUnlocked?: boolean;
	formatSelection?: (value: number | undefined) => string;
	editButton?: (props: EditButtonRenderProps) => React.ReactNode;
	isDisabled?: (value: number) => boolean;
}

const ModalFieldHourOfDay: React.FunctionComponent<
	ModalFieldHourOfDayProps
> = ({
	onCancel = () => undefined,
	onOK,
	title,
	helptext,
	value,
	requireSelectedTime,
	isUnlocked = true,
	formatSelection = value =>
		value === undefined ? "" : formatAsTime(moment.duration(value, "hours")),
	editButton,
	isDisabled
}) => {
	const [selectedTime, setSelectedTime] = useState<number | undefined>();

	const reset = useCallback(() => {
		setSelectedTime(value);
	}, [value, setSelectedTime]);

	const [isOpen, setIsOpen] = useState(false);
	const toggleIsOpen = useCallback(() => {
		setIsOpen(oldIsOpen => !oldIsOpen);
	}, [setIsOpen]);

	useEffect(() => {
		reset();
	}, [isOpen, reset]);

	const handleOK = useCallback(() => {
		if (!!requireSelectedTime && selectedTime === undefined) {
			return;
		}
		onOK(selectedTime);
		setIsOpen(false);
	}, [onOK, selectedTime, setIsOpen, requireSelectedTime]);

	const handleCancel = useCallback(() => {
		onCancel();
		setIsOpen(false);
	}, [onCancel, setIsOpen]);

	const displayValue = useMemo(() => formatSelection(selectedTime), [
		selectedTime,
		formatSelection
	]);

	return (
		<>
			{editButton ? (
				editButton({ isUnlocked, onClick: toggleIsOpen })
			) : (
				<ToggleableButton
					isEditing={isUnlocked}
					icon={faPen}
					onClick={toggleIsOpen}
					label={displayValue}
				/>
			)}

			<ModalConfirm
				isOpen={isOpen}
				onOK={handleOK}
				onCancel={handleCancel}
				title={title}
				helptext={helptext}
			>
				<FieldHourOfDay
					selectedTime={selectedTime}
					onChange={newValue => {
						setSelectedTime(newValue);
					}}
					isDisabled={isDisabled}
				/>
			</ModalConfirm>
		</>
	);
};

export default ModalFieldHourOfDay;
