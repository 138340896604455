import React, { useState, useCallback, useMemo } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import ActionButtons from "components/ActionButtons";
import ModalFieldText from "components/ModalFieldText";
import ModalFieldTextArea from "components/ModalFieldTextArea";
import ModalFieldSelect from "components/ModalFieldSelect";
import ModalFieldTag from "components/ModalFieldTag";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import ModalPleaseWait from "components/ModalPleaseWait";
import KeyValueTable from "components/KeyValueTable";
import { getCurrencyOptions } from "services/getCurrencyOptions";
import { captureException } from "services/captureException";
import { getTimezoneOptions } from "services/getTimezoneOptions";
import { useAccountStatus } from "hooks/useAccountStatus";
import { useSaveDataAndRefreshAccountDetailsFromApi } from "hooks/useSaveDataAndRefreshAccountDetailsFromApi";
import { useLocationCountryOptions } from "hooks/useLocationCountryOptions";
import { useLocationDistrictOptions } from "hooks/useLocationDistrictOptions";
import { AcountSettingsTableWrapper } from "./AccountSettingsSection.styles";

const AccountSettingsSection: React.FunctionComponent = () => {
	const {
		displayName,
		timezone,
		locationDistrict,
		locationCountryCode,
		mentorProfileAboutMe,
		isMentor,
		terms,
		defaultCurrencyCode
	} = useAccountStatus();

	const [isSaving, setIsSaving] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const [tempLocationCountryCode, setTempLocationCountryCode] = useState<
		string | undefined
	>(undefined);

	const selectedCountryCode = tempLocationCountryCode
		? tempLocationCountryCode
		: locationCountryCode;
	const selectedDistrict = tempLocationCountryCode
		? undefined
		: locationDistrict;

	const {
		isWaiting: locationDistrictWaiting,
		isLoading: locationDistrictLoading,
		isError: locationDistrictError,
		output: locationDistrictOptions
	} = useLocationDistrictOptions({ selectedCountryCode });

	const {
		isWaiting: locationCountryWaiting,
		isLoading: locationCountryLoading,
		isError: locationCountryError,
		output: locationCountryOptions
	} = useLocationCountryOptions();

	const submitDataToAPI = useSaveDataAndRefreshAccountDetailsFromApi(
		"saveAccountSettings",
		"saving your settings",
		setIsSaving,
		async () => setTempLocationCountryCode(undefined)
	);

	const handleOKDisplayName = useCallback(
		(displayName: string) => {
			submitDataToAPI({ data: { displayName } });
		},
		[submitDataToAPI]
	);

	const handleOKMentorProfileAboutMe = useCallback(
		(mentorProfileAboutMe: string) => {
			submitDataToAPI({ data: { mentorProfileAboutMe } });
		},
		[submitDataToAPI]
	);

	const handleOKTimezone = useCallback(
		(newOptions: FieldOption<string>[]) => {
			const selectedOption = newOptions.find(val => !!val.selected);
			if (!selectedOption) {
				throw captureException(new Error("No selected option"), {
					evtType: "noSelectedOption"
				});
			}

			submitDataToAPI({
				data: { timezone: selectedOption.value + "" }
			});
		},
		[submitDataToAPI]
	);

	const handleOKDefaultCurrencyCode = useCallback(
		(newOptions: FieldOption<string>[]) => {
			const selectedOption = newOptions.find(val => !!val.selected);
			if (!selectedOption) {
				throw captureException(new Error("No selected option"), {
					evtType: "noSelectedOption"
				});
			}

			submitDataToAPI({
				data: { defaultCurrencyCode: selectedOption.value + "" }
			});
		},
		[submitDataToAPI]
	);

	const handleOKLocationDistrict = useCallback(
		(newOptions: FieldOption<string>[]) => {
			const selectedOption = newOptions.find(val => !!val.selected);
			if (!selectedOption) {
				throw captureException(new Error("No selected option"), {
					evtType: "noSelectedOption"
				});
			}

			if (!selectedCountryCode) {
				throw captureException(new Error("No selected country code"), {
					evtType: "noSelectedCountryCode"
				});
			}

			submitDataToAPI({
				data: {
					locationDistrict: selectedOption.value + "",
					locationCountryCode: selectedCountryCode
				}
			});
		},
		[selectedCountryCode, submitDataToAPI]
	);

	const handleOKLocationCountry = useCallback(
		(newOptions: FieldOption<string>[]) => {
			const selectedOption = newOptions.find(val => !!val.selected);
			if (!selectedOption) {
				throw captureException(new Error("No selected option"), {
					evtType: "noSelectedOption"
				});
			}

			setTempLocationCountryCode(selectedOption.value + "");
		},
		[setTempLocationCountryCode]
	);

	const handleOKCanHelpWith = useCallback(
		(newTags: string[]) => {
			if (!terms) {
				throw captureException(new Error("Old terms not loaded"), {
					evtType: "oldTermsNotLoadedCanHelpWith"
				});
			}

			const newTerms = [
				...terms.filter(term => term.category !== "canhelpwith"),
				...newTags.map((tag, i) => ({
					sortindex: i + 1,
					category: "canhelpwith",
					text: tag
				}))
			];

			submitDataToAPI({
				data: { terms: newTerms }
			});
		},
		[terms, submitDataToAPI]
	);

	const handleOKSpecialistKnowledge = useCallback(
		(newTags: string[]) => {
			if (!terms) {
				throw captureException(new Error("Old terms not loaded"), {
					evtType: "oldTermsNotLoadedSpecialistKnowledge"
				});
			}

			const newTerms = [
				...terms.filter(term => term.category !== "specialistknowledge"),
				...newTags.map((tag, i) => ({
					sortindex: i + 1,
					category: "specialistknowledge",
					text: tag
				}))
			];

			submitDataToAPI({
				data: { terms: newTerms }
			});
		},
		[terms, submitDataToAPI]
	);

	const locationDistrictOptionsWithSelection = useMemo(
		() =>
			locationDistrictOptions
				? locationDistrictOptions
						.map(opt => ({
							...opt,
							selected: opt.value === selectedDistrict
						}))
						.sort((a: FieldOption<string>, b: FieldOption<string>) =>
							Intl.Collator().compare(a.text, b.text)
						)
				: [],
		[locationDistrictOptions, selectedDistrict]
	);

	const locationCountryOptionsWithSelection = useMemo(
		() =>
			locationCountryOptions
				? locationCountryOptions
						.map(opt => ({
							...opt,
							selected: opt.value === selectedCountryCode
						}))
						.sort((a: FieldOption<string>, b: FieldOption<string>) =>
							Intl.Collator().compare(a.text, b.text)
						)
				: [],
		[locationCountryOptions, selectedCountryCode]
	);

	return (
		<>
			<ModalPleaseWait isOpen={isSaving} title="Saving" />

			{timezone ? (
				<>
					<AcountSettingsTableWrapper>
						<KeyValueTable
							data={[
								{
									row: [
										"Display name",
										<ModalFieldText
											isUnlocked={isEditing}
											title="Display name"
											onOK={handleOKDisplayName}
											value={displayName ? displayName : ""}
										/>
									],
									key: "displayName"
								},
								{
									row: [
										"Timezone",
										<ModalFieldSelect
											isUnlocked={isEditing}
											title="New timezone"
											requireSelection
											onOK={handleOKTimezone}
											options={getTimezoneOptions(timezone)}
											allowMultipleSelections={false}
										/>
									],
									key: "timezone"
								},
								{
									row: [
										"Location",
										<>
											<ModalFieldSelect
												isUnlocked={isEditing}
												title="District"
												requireSelection
												onOK={handleOKLocationDistrict}
												options={locationDistrictOptionsWithSelection}
												allowMultipleSelections={false}
												isLoading={
													locationDistrictWaiting || locationDistrictLoading
												}
												isError={locationDistrictError}
											/>
											{isEditing ? null : ","}{" "}
											<ModalFieldSelect
												isUnlocked={isEditing}
												title="Country"
												requireSelection
												onOK={handleOKLocationCountry}
												options={locationCountryOptionsWithSelection}
												allowMultipleSelections={false}
												isLoading={
													locationCountryWaiting || locationCountryLoading
												}
												isError={locationCountryError}
											/>
										</>
									],
									key: "location"
								},
								{
									row: [
										"Default currency",
										<ModalFieldSelect
											isUnlocked={isEditing}
											title="New currency"
											requireSelection
											onOK={handleOKDefaultCurrencyCode}
											options={getCurrencyOptions(defaultCurrencyCode)}
											allowMultipleSelections={false}
										/>
									],
									key: "defaultCurrencyCode"
								},
								...(isMentor
									? [
											{
												row: [
													"Teaches",
													<ModalFieldTag
														isUnlocked={isEditing}
														title="Teaches"
														onOK={handleOKCanHelpWith}
														initialTags={
															terms
																? terms
																		.filter(
																			term => term.category === "canhelpwith"
																		)
																		.sort((a, b) => a.sortindex - b.sortindex)
																		.map(term => term.text)
																: []
														}
													/>
												] as [React.ReactNode, React.ReactNode],
												key: "canhelpwith"
											},
											{
												row: [
													"Speaks",
													<ModalFieldTag
														isUnlocked={isEditing}
														title="Speaks"
														onOK={handleOKSpecialistKnowledge}
														initialTags={
															terms
																? terms
																		.filter(
																			term =>
																				term.category === "specialistknowledge"
																		)
																		.sort((a, b) => a.sortindex - b.sortindex)
																		.map(term => term.text)
																: []
														}
													/>
												] as [React.ReactNode, React.ReactNode],

												key: "specialistknowledge"
											},
											{
												row: [
													"About Me",
													<ModalFieldTextArea
														isUnlocked={isEditing}
														title="About me"
														onOK={handleOKMentorProfileAboutMe}
														value={
															mentorProfileAboutMe ? mentorProfileAboutMe : ""
														}
														rows={8}
													/>
												] as [React.ReactNode, React.ReactNode],

												key: "mentorProfileAboutMe"
											}
									  ]
									: [])
							]}
						/>
					</AcountSettingsTableWrapper>

					<ActionButtons
						secondary={{
							onClick: () => setIsEditing(isEditing ? false : true),
							icon: faPen,
							label: isEditing ? "Done" : "Edit"
						}}
					/>
				</>
			) : (
				<p>Loading account settings...</p>
			)}
		</>
	);
};

export default AccountSettingsSection;
