import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { useScreenSizeIsMax } from "hooks/useScreenSizeIsMax";

import ActionButton from "./ActionButton";
import { FullWidthActionButtons } from "./ActionButtons.styles";

export interface ActionButtonsProps {
	primary?: ActionButtonConfig;
	secondary?: ActionButtonConfig;
	tertiary?: ActionButtonConfig;
	fitWidth?: boolean;
	highlightPrimary?: boolean;
	primaryAlwaysFirst?: boolean;
}

interface ActionButtonConfig {
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	to?: string;
	icon?: IconProp;
	label: string;
	isDisabled?: boolean;
}

const ActionButtons: React.FunctionComponent<ActionButtonsProps> = ({
	primary,
	secondary,
	tertiary,
	fitWidth = false,
	highlightPrimary = true,
	primaryAlwaysFirst = false
}) => {
	const screenIsLargeOrSmallerButNotDesktopSize = useScreenSizeIsMax("large");

	const tertiaryButton = tertiary ? (
		<ActionButton {...tertiary} isTertiary fitWidth={fitWidth} />
	) : null;
	const secondaryButton = secondary ? (
		<ActionButton {...secondary} isSecondary fitWidth={fitWidth} />
	) : null;
	const primaryButton = primary ? (
		<ActionButton {...primary} isPrimary fitWidth={fitWidth} />
	) : null;

	if (fitWidth) {
		const buttons = (primaryAlwaysFirst
			? [primaryButton, secondaryButton, tertiaryButton]
			: [tertiaryButton, secondaryButton, primaryButton]
		).filter(element => element !== undefined);

		return (
			<FullWidthActionButtons>
				{buttons[0]}
				{buttons[1] === undefined ? null : buttons[1]}
				{buttons[2] === undefined ? null : buttons[2]}
			</FullWidthActionButtons>
		);
	}

	return (
		<ul className="actions">
			{screenIsLargeOrSmallerButNotDesktopSize || primaryAlwaysFirst ? (
				<>
					{primaryButton ? <li>{primaryButton}</li> : null}
					{secondaryButton ? <li>{secondaryButton}</li> : null}
					{tertiaryButton ? <li>{tertiaryButton}</li> : null}
				</>
			) : (
				<>
					{tertiaryButton ? <li>{tertiaryButton}</li> : null}
					{secondaryButton ? <li>{secondaryButton}</li> : null}
					{primaryButton ? <li>{primaryButton}</li> : null}
				</>
			)}
		</ul>
	);
};

export default ActionButtons;
