import moment from "moment-timezone";

import { formatDateForApi } from "services/formatDateForApi";

import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";
import { SearchResults } from "api/SearchResults";
import { flagValidationErrorsInSearchResults } from "validation/flagValidationErrorsInSearchResults";

interface RawApiOutputRow {
	id: string;
	meetingId: string;
	reschedulerUid: string;
	hostUid: string;
	guestUid: string;
	dateStartsUTC: string;
	originalDateStartsUTC: string;
	dateEndsJustBeforeUTC: string;
	originalDateEndsJustBeforeUTC: string;
	privateMessageContents?: string;
	privateMessageDatesentUTC?: string;
	privateMessageId?: string;
	isAccepted: boolean;
	isRejected: boolean;
	displayNameGuest: string;
	displayNameHost: string;
}

const flagValidationErrorsInRow: ValidationCallback<RawApiOutputRow> = (
	input,
	{ flag, checkField },
	{ uid }
) => {
	const {
		privateMessageDatesentUTC,
		privateMessageId,
		isAccepted,
		isRejected,
		displayNameGuest,
		displayNameHost
	} = input;

	checkField("id", { type: "string" });
	checkField("meetingId", { type: "string" });
	checkField("reschedulerUid", { type: "string" });
	checkField("hostUid", { type: "string" });
	checkField("guestUid", { type: "string" });
	checkField("dateStartsUTC", { type: "datestring" });
	checkField("dateEndsJustBeforeUTC", { type: "datestring" });
	checkField("originalDateStartsUTC", { type: "datestring" });
	checkField("originalDateEndsJustBeforeUTC", { type: "datestring" });
	checkField("privateMessageContents", { type: "string", isRequired: false });

	if (
		privateMessageDatesentUTC &&
		!(typeof privateMessageDatesentUTC === "string")
	) {
		flag("privateMessageDatesentUTC", "invalid");
	}

	if (privateMessageId && !(typeof privateMessageId === "string")) {
		flag("privateMessageId", "invalid");
	}

	if (isAccepted === undefined) {
		flag("isAccepted", "missing");
	} else if (!(typeof isAccepted === "boolean")) {
		flag("isAccepted", "invalid");
	}

	if (isRejected === undefined) {
		flag("isRejected", "missing");
	} else if (!(typeof isRejected === "boolean")) {
		flag("isRejected", "invalid");
	}

	if (displayNameGuest === undefined) {
		flag("displayNameGuest", "missing");
	} else if (!(typeof displayNameGuest === "string")) {
		flag("displayNameGuest", "invalid");
	}

	if (displayNameHost === undefined) {
		flag("displayNameHost", "missing");
	} else if (!(typeof displayNameHost === "string")) {
		flag("displayNameHost", "invalid");
	}
};

const flagValidationErrors: ValidationCallback<
	SearchResults<RawApiOutputRow>
> = (input, flag, auth) =>
	flagValidationErrorsInSearchResults(
		flagValidationErrorsInRow,
		input,
		flag,
		auth
	);

export interface OutputRow
	extends Omit<
		RawApiOutputRow,
		| "dateStartsUTC"
		| "dateEndsJustBeforeUTC"
		| "originalDateStartsUTC"
		| "originalDateEndsJustBeforeUTC"
		| "privateMessageDatesentUTC"
	> {
	dateStartsUTC: Date;
	dateEndsJustBeforeUTC: Date;
	originalDateStartsUTC: Date;
	originalDateEndsJustBeforeUTC: Date;
	privateMessageDatesentUTC?: Date;
}

interface Params {
	hostUid?: string;
	guestUid?: string;
	earliestStartDateUTC: Date;
	mustEndBeforeUTC?: Date;
	isAccepted?: boolean;
	isRejected?: boolean;

	cacheInMemory?: boolean;
}

export function getMeetingRescheduleRequests(
	{
		hostUid,
		guestUid,
		earliestStartDateUTC,
		mustEndBeforeUTC,
		cacheInMemory,
		isAccepted = true,
		isRejected
	}: Params,
	auth: Auth
): ApiRequest<SearchResults<OutputRow>> {
	const req = makeApiRequest("meetingRescheduleRequests", {
		auth,
		cacheInMemory,
		data: {
			get: {
				hostUid,
				guestUid,
				earliestStartDateUTC: formatDateForApi(earliestStartDateUTC),
				...(mustEndBeforeUTC
					? { mustEndBeforeUTC: formatDateForApi(mustEndBeforeUTC) }
					: {}),
				...(isAccepted !== undefined
					? { isAccepted: isAccepted ? "1" : "0" }
					: {}),
				...(isRejected !== undefined
					? { isRejected: isRejected ? "1" : "0" }
					: {})
			}
		},
		flagValidationErrors
	});

	// TODO:WV:20230408:Implement a way of fetching pages other than page 1 (for this and all other paginated API requests)
	return {
		ready: req.ready.then(response => {
			return {
				page: response
					? response.page.map(row => {
							const {
								dateStartsUTC,
								privateMessageDatesentUTC,
								dateEndsJustBeforeUTC,
								originalDateStartsUTC,
								originalDateEndsJustBeforeUTC,
								isAccepted,
								isRejected,
								...rest
							} = row;
							return {
								...rest,
								dateStartsUTC: moment.utc(dateStartsUTC).toDate(),
								...(privateMessageDatesentUTC
									? {
											privateMessageDatesentUTC: moment
												.utc(privateMessageDatesentUTC)
												.toDate()
									  }
									: {}),
								dateEndsJustBeforeUTC: moment
									.utc(dateEndsJustBeforeUTC)
									.toDate(),
								originalDateStartsUTC: moment
									.utc(originalDateStartsUTC)
									.toDate(),
								originalDateEndsJustBeforeUTC: moment
									.utc(originalDateEndsJustBeforeUTC)
									.toDate(),
								isAccepted: !!isAccepted,
								isRejected: !!isRejected
							};
					  })
					: []
			};
		}),
		abort: () => req.abort(),
		aborted: () => req.aborted(),
		type: req.type
	};
}
