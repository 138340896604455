import { useContext } from "react";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useSessionTypes } from "hooks/useSessionTypes";
import { useSingleSessionPrices } from "hooks/useSingleSessionPrices";
import { usePackagePrices } from "hooks/usePackagePrices";
import { useAccountStatus } from "hooks/useAccountStatus";

export const useSessionTypesAndPrices = (
	mentorId?: string,
	omitSessionTypesWithNoActivePrices?: false
) => {
	const [{ isSignedIn, uid, haveSignInStatus }] = useContext(AuthContext);

	const { _populated: haveAccountStatus } = useAccountStatus();

	const {
		isWaiting: sessionTypesWaiting,
		isLoading: sessionTypesLoading,
		isError: sessionTypesError,
		output: sessionTypesOutput
	} = useSessionTypes({
		mentorId,
		doWait:
			!haveSignInStatus ||
			(haveSignInStatus && isSignedIn && !haveAccountStatus)
	});

	const {
		isWaiting: singleSessionPricesWaiting,
		isLoading: singleSessionPricesLoading,
		isError: singleSessionPricesError,
		output: singleSessionPricesOutput
	} = useSingleSessionPrices({
		mentorId,
		sessionTypeIds: sessionTypesOutput
			? sessionTypesOutput.page.map(entry => entry.id)
			: [],
		includePricesForGuestUid: uid,
		doWait:
			!haveSignInStatus ||
			(haveSignInStatus && isSignedIn && !haveAccountStatus)
	});

	const {
		isWaiting: packagePricesWaiting,
		isLoading: packagePricesLoading,
		isError: packagePricesError,
		output: packagePricesOutput
	} = usePackagePrices({
		mentorId,
		sessionTypeIds: sessionTypesOutput
			? sessionTypesOutput.page.map(entry => entry.id)
			: [],
		includePackagePricesForGuestUid: uid,
		doWait:
			!haveSignInStatus ||
			(haveSignInStatus && isSignedIn && !haveAccountStatus)
	});

	const sessionTypes = sessionTypesOutput
		? omitSessionTypesWithNoActivePrices
			? sessionTypesOutput.page.filter(
					sessionType =>
						(singleSessionPricesOutput &&
							singleSessionPricesOutput.page.some(
								singleSessionPrice =>
									singleSessionPrice.sessionTypeId === sessionType.id
							)) ||
						(packagePricesOutput &&
							packagePricesOutput.page.some(
								packagePrice => packagePrice.sessionTypeId === sessionType.id
							))
			  )
			: sessionTypesOutput.page
		: [];
	const singleSessionPrices = singleSessionPricesOutput
		? singleSessionPricesOutput.page
		: [];
	const packagePrices = packagePricesOutput ? packagePricesOutput.page : [];

	return {
		isWaiting:
			sessionTypesWaiting || singleSessionPricesWaiting || packagePricesWaiting,
		isLoading:
			sessionTypesLoading || singleSessionPricesLoading || packagePricesLoading,
		isError:
			sessionTypesError || singleSessionPricesError || packagePricesError,
		output: {
			sessionTypes,
			singleSessionPrices,
			packagePrices
		}
	};
};
