import moment from "moment-timezone";

import { formatDateForApi } from "services/formatDateForApi";
import { makeApiRequest, Auth } from "api/makeApiRequest";
import { SearchResults } from "api/SearchResults";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";
import { flagValidationErrorsInSearchResults } from "validation/flagValidationErrorsInSearchResults";

export interface OutputRow {
	id: string;
	senderUid: string;
	recipientUid: string;
	message: string;
	dateSentUTC: Date;
	isRead: boolean;
}

interface RawApiOutputRow extends Omit<OutputRow, "dateSentUTC"> {
	dateSentUTC: string;
}

interface Params {
	uid1: string;
	uid2: string;
	cursor?: Date;
	cursorType?: "before" | "after";
}

const flagValidationErrorsInRow: ValidationCallback<RawApiOutputRow> = (
	input,
	{ flag, checkField }
) => {
	checkField("id", { type: "string" });
	checkField("senderUid", { type: "string" });
	checkField("recipientUid", { type: "string" });
	checkField("message", { type: "string" });
	checkField("dateSentUTC", { type: "datestring" });
	checkField("isRead", { type: "boolean" });
};

const flagValidationErrors: ValidationCallback<
	SearchResults<RawApiOutputRow>
> = (input, flag, auth) =>
	flagValidationErrorsInSearchResults(
		flagValidationErrorsInRow,
		input,
		flag,
		auth
	);

export function getRecentChatHistory(
	{ uid1, uid2, cursor, cursorType }: Params,
	auth: Auth
): ApiRequest<SearchResults<OutputRow>> {
	const req = makeApiRequest("recentChatHistory", {
		auth,
		data: {
			get: {
				uid1,
				uid2,
				...(cursor
					? {
							cursor: formatDateForApi(cursor)
					  }
					: {}),
				...(cursorType
					? {
							cursorType
					  }
					: {})
			}
		},
		flagValidationErrors
	});

	return {
		ready: req.ready.then(results => {
			if (!results) {
				return {
					page: []
				};
			}

			return {
				page: convertPageForApp(results.page, uid1, uid2),
				nextPage: results.nextPage
					? convertPageForApp(results.nextPage, uid1, uid2)
					: [],
				prevPage: results.prevPage
					? convertPageForApp(results.prevPage, uid1, uid2)
					: []
			};
		}),
		abort: () => req.abort(),
		aborted: () => req.aborted(),
		type: req.type
	};
}

function convertPageForApp(
	page: RawApiOutputRow[],
	uid1: string,
	uid2: string
) {
	return page
		.map(({ id, dateSentUTC, senderUid, message, isRead }) => ({
			id,
			dateSentUTC: moment.utc(dateSentUTC).toDate(),
			senderUid,
			message,
			isRead,
			recipientUid: uid1 === senderUid ? uid2 : uid1
		}))
		.sort((a, b) => a.dateSentUTC.getTime() - b.dateSentUTC.getTime());
}
