import React from "react";

interface Props {
	truncate?: boolean;
	maxParagraphsToShow?: number;
	onClickReadMore?: (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => void;
}

const TextWithLineBreaks: React.FunctionComponent<Props> = ({
	children,
	truncate = false,
	maxParagraphsToShow = 0,
	onClickReadMore = () => {}
}) => {
	const paragraphs =
		typeof children === "string" ? children.split(/[\r\n]+/) : [];

	if (paragraphs.length === 0) {
		return null;
	}

	return (
		<>
			{(truncate ? paragraphs.slice(0, maxParagraphsToShow) : paragraphs).map(
				(para, i) => {
					const paragraphNumber = i + 1;
					return (
						<p key={`para-${i}`}>
							{para}
							{truncate &&
							paragraphNumber === maxParagraphsToShow &&
							paragraphs.length > paragraphNumber ? (
								<a href="/" onClick={onClickReadMore}>
									{" "}
									...Read more
								</a>
							) : null}
						</p>
					);
				}
			)}
		</>
	);
};

export default TextWithLineBreaks;
