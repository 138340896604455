import React, { useState, useCallback } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import { WizardData } from "../RescheduleMeetingWizard";
import { TextareaContainer } from "./StepConfirmAndAddMessage.styles";
import SelectedEventSummary from "../SelectedEventSummary";

function StepConfirmAndAddMessage({
	enableProgressToNextStep,
	wizardData
}: ModalWizardStepRenderProps<WizardData>) {
	const [message, setMessage] = useState("");
	const { newSessionEvent } = wizardData;

	const handleNewValue = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			const newValue = e.currentTarget.value;
			setMessage(newValue);
			enableProgressToNextStep({ message: newValue });
		},
		[enableProgressToNextStep, setMessage]
	);

	return (
		<>
			<SelectedEventSummary eventToBook={newSessionEvent} />
			<TextareaContainer>
				<textarea
					placeholder="Your message"
					rows={4}
					value={message}
					onChange={handleNewValue}
				/>
			</TextareaContainer>
		</>
	);
}

export default StepConfirmAndAddMessage;
