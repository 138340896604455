import moment from "moment-timezone";

import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	id: string;
}

interface RawOutput {
	id: string;
	email: string;
	enquiryType: string;
	dateReceivedUTC: string;
	data: KeyValues;
}

interface KeyValues {
	[k: string]: string;
}

interface ConvertedOutput extends Omit<RawOutput, "dateReceivedUTC"> {
	dateReceivedUTC: Date;
}

const flagValidationErrors: ValidationCallback<RawOutput> = (
	input,
	{ flag, checkField }
) => {
	checkField("id", { type: "string" });
	checkField("email", { type: "string" });
	checkField("enquiryType", { type: "string" });
	checkField("dateReceivedUTC", { type: "string" });

	if (typeof input.data !== "object") {
		flag("data", "not an object");
	} else {
		for (const i in input.data) {
			if (typeof i !== "string") {
				flag("data", "at least one key was not a string");
			} else if (typeof input.data[i] !== "string") {
				flag("data", "at least one value was not a string");
			}
		}
	}
};

export function getContactFormSubmission(
	{ id }: Params,
	auth: Auth
): ApiRequest<ConvertedOutput> {
	const req = makeApiRequest("contactFormSubmission", {
		auth,
		data: {
			get: {
				id
			}
		},
		flagValidationErrors
	});

	return {
		ready: req.ready.then(retrievedData => {
			if (!retrievedData) {
				return retrievedData;
			}

			const { dateReceivedUTC, ...rest } = retrievedData;
			return {
				...rest,
				dateReceivedUTC: moment.utc(dateReceivedUTC).toDate()
			};
		}),
		abort: () => req.abort(),
		aborted: () => req.aborted(),
		type: req.type
	};
}
