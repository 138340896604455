import { useRef, useCallback, useState } from "react";
import socketIOClient from "socket.io-client";

import { getAuthToken } from "services/getAuthToken";
import { useEffectWithCustomComparator } from "hooks/useEffectWithCustomComparator";
import { useAccountStatus } from "hooks/useAccountStatus";
import { safeTsNotNullish } from "utils/safeTsNotNullish";

interface Params {
	uid: string | undefined;
	partnerUID: string | undefined;

	onMessageReceived: (message: any, socket: SocketIOClient.Socket) => void;
}

export function useChatSocket(params: Params) {
	const { _populated, timezone } = useAccountStatus();
	const socket = useRef<SocketIOClient.Socket | undefined>(undefined);
	const [currentSocket, setCurrentSocket] = useState<
		SocketIOClient.Socket | undefined
	>(undefined);

	useEffectWithCustomComparator<Params>(
		useCallback(
			({ uid, partnerUID, onMessageReceived }) => {
				if (!uid) {
					return;
				}
				if (!partnerUID) {
					return;
				}

				if (!_populated) {
					return;
				}

				if (!timezone) {
					return;
				}

				let aborted = false;

				getAuthToken().then(authToken => {
					if (aborted) {
						return;
					}

					if (!authToken) {
						return;
					}

					socket.current = socketIOClient(
						`${process.env.REACT_APP_API_URL}/users/${uid}`,
						{
							query: {
								token: authToken,
								timezone
							}
						}
					);

					socket.current.on("chatMessage", (message: any) =>
						onMessageReceived(message, safeTsNotNullish(socket.current))
					);

					setCurrentSocket(socket.current);
				});

				return () => {
					aborted = true;
					if (socket.current) {
						socket.current.disconnect();
					}
					socket.current = undefined;
					setCurrentSocket(socket.current);
				};
			},
			[_populated, timezone]
		),
		params,
		({ uid, partnerUID }) => `${uid}-${partnerUID}`
	);

	return currentSocket;
}
