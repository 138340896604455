import styled from "styled-components";

import { Props } from "./FieldText";

export const TextInput = styled.input<Props>`
	${props =>
		!!props.isInline
			? `
		width:auto !important;
		display:inline !important;
	`
			: ``}
	${props =>
		!!props.addRightMargin
			? `
		margin-right:1em;
	`
			: ``}
	${props =>
		!!props.addLeftMargin
			? `
		margin-left:1em;
	`
			: ``}

	${props =>
		!!props.highlightValidationError
			? `
		border:1px solid #ff6961 !important;
	`
			: ``}

`;
