import { Medium } from "hooks/useAvailableMediums";

export function formatMedium(medium: Medium) {
	switch (medium) {
		case "google-meet":
			return "Google Meet";
		default:
			return medium[0].toUpperCase() + medium.slice(1);
	}
}
