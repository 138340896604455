import styled from "styled-components";

import bannerImg from "resources/banner.jpg";

export const BannerSection = styled.section`
	@media (max-width: 480px) {
		& {
			background-image: url(${bannerImg});
		}
	}
`;
