import styled from "styled-components";

export const TermsAndConditions = styled.p`
	color: #aaaaaa;
	font-size: 0.8em;
`;

export const HeaderLogo = styled.img`
	height: 55%;
	vertical-align: middle;
	margin-right: 0.75em;
`;

export const MenuDisplayName = styled.span`
	color: inherit;
	display: block;
	line-height: 2.5em;
	padding: 1em 0;
`;
