import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

export function useStripQueryStringFromAddressBar() {
	const history = useHistory();
	const { search, pathname } = useLocation();

	const stripQueryString = useCallback(() => {
		if (search !== "") {
			history.replace({
				pathname
			});
		}
	}, [history, search, pathname]);

	return stripQueryString;
}
