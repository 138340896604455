import styled from "styled-components";

// TODO:WV:20240425:Find a way to use this with contacts and messages as well (e.g. add it to all tables)
export const MeetingRequestsTableContainer = styled.div<{ isLoading: boolean }>`
	${props => (props.isLoading ? `opacity:0.2` : ``)};
`;

export const RespondModalContents = styled.div`
	padding: 0 1em;
`;
