import { CompleteSelection } from "components/FieldDate/FieldDateSelection";

import { ValidationCallback } from "validation/validate";

export const validateCompleteSelection: ValidationCallback<
	CompleteSelection
> = (input, { flag, checkField }) => {
	checkField("year", { type: "number", isRequired: true });
	checkField("month", { type: "number", isRequired: true });
	checkField("date", { type: "number", isRequired: true });
};
