import React, {
	useCallback,
	useContext,
	useState,
	useRef,
	useEffect
} from "react";
import { faComment, faIdBadge } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { AlertContext } from "components/AlertProvider/AlertProvider";
import {
	getPackageRequests,
	OutputRow as PackageRequestsData
} from "api/getPackageRequests";
import { ApiRequest } from "api/ApiRequest";
import { respondToPackageRequest } from "api/respondToPackageRequest";
import { useRemoteResource } from "hooks/useRemoteResource";
import { useHandleItemFromURL } from "hooks/useHandleItemFromURL";
import { useSendData } from "hooks/useSendData";
import { useStripQueryStringFromAddressBar } from "hooks/useStripQueryStringFromAddressBar";
import ErrorBanner from "components/ErrorBanner";
import ReadMoreText from "components/ReadMoreText";
import { ChatContext } from "components/Chat/ChatContext";
import PackageSummary from "components/PackageSummary";
import ModalConfirm from "components/ModalConfirm";
import FieldCheckbox from "components/FieldCheckbox";
import Table from "components/Table";
import { formatPackageDetails } from "services/formatPackageDetails";
import { captureException } from "services/captureException";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { DashboardContext } from "components/PageDashboard/PageDashboard";
import terminology from "terminology.json";

import {
	PackageRequestsTableContainer,
	RespondModalContents
} from "./PackageRequestsSection.styles";

const PackageRequestsSection: React.FunctionComponent = () => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);
	const [, setDashboardContextState] = useContext(DashboardContext);

	const [addAlert] = useContext(AlertContext);
	const [, setChatDetails] = useContext(ChatContext);

	const [repeatNum, setRepeatNum] = useState(1);
	const {
		isWaiting,
		isLoading,
		isError,
		output: packageRequests
	} = useRemoteResource<PackageRequestsData[]>(
		useCallback(() => {
			if (!uid) {
				throw new Error("No UID");
			}

			const req = getPackageRequests(
				{
					hostUid: uid,
					isAccepted: false,
					isRejected: false
				},
				{ uid }
			);

			return {
				...req,
				ready: req.ready.then(result => {
					if (!result) {
						return [];
					}
					return result.page;
				})
			};
		}, [uid]),
		isSignedIn === undefined,
		repeatNum,
		true
	);

	const [respondingToPackageRequest, setRespondingToPackageRequest] = useState<
		PackageRequestsData | undefined
	>(undefined);
	const [packageRequestResponse, setPackageRequestResponse] = useState<
		boolean | undefined
	>(undefined);
	const [packageRequestResponseNote, setPackageRequestResponseNote] = useState<
		string | undefined
	>(undefined);

	const packageRequestResponseApiRequest = useRef<
		ApiRequest<unknown> | undefined
	>(undefined);
	const [
		packageRequestResponseApiRequestDispatchTime,
		setPackageRequestResponseApiRequestDispatchTime
	] = useState<number | undefined>(undefined);
	useEffect(() => {
		const { current: req } = packageRequestResponseApiRequest;
		if (packageRequestResponseApiRequestDispatchTime && req) {
			req.ready.then(response => {
				addAlert({ contents: "Response received!  Thank you" });

				setPackageRequestResponse(undefined);
				setPackageRequestResponseNote(undefined);
				setRespondingToPackageRequest(undefined);
				setRepeatNum(new Date().getTime());
				setDashboardContextState(oldState => ({
					...oldState,
					meetingsSectionRefreshNum: new Date().getTime()
				}));
			});

			return () => {
				req.abort();
			};
		}
	}, [
		addAlert,
		packageRequestResponseApiRequest,
		packageRequestResponseApiRequestDispatchTime,
		setPackageRequestResponse,
		setPackageRequestResponseNote,
		setRespondingToPackageRequest,
		setRepeatNum,
		setDashboardContextState
	]);

	const stripQueryStringFromAddressBar = useStripQueryStringFromAddressBar();
	useHandleItemFromURL<PackageRequestsData>({
		key: "packagerequest",
		itemsList: {
			isWaiting,
			isLoading,
			isError,
			items: packageRequests
		},
		handleFoundItem: useCallback(
			packageRequest => {
				if (!("hostUid" in packageRequest)) {
					throw captureException(new Error("Meeting in URL lacks hostUid"), {
						evtType: "meetingInURLNoHostUid",
						extra: { packageRequestInURL: packageRequest }
					});
				} else {
					setRespondingToPackageRequest(packageRequest);
				}
				stripQueryStringFromAddressBar();
			},
			[setRespondingToPackageRequest, stripQueryStringFromAddressBar]
		)
	});

	const history = useHistory();

	const sendResponse = useSendData({ method: respondToPackageRequest });

	const cols = [
		{ key: "sessions", heading: "Sessions" },
		{ key: "partner", heading: "With" },
		{ key: "message", heading: "Message" }
	];

	const rows =
		packageRequests && packageRequests.length && uid
			? packageRequests.map(packageRequest => {
					const {
						hostUid,
						partnersName,
						partnersUid,
						sessions
					} = formatPackageDetails(packageRequest, uid, true);

					return {
						data: {
							partner: (
								<Link to={`/user-id/${partnersUid}`}>{partnersName}</Link>
							),
							sessions,
							message: packageRequest.privateMessageContents ? (
								<>
									{" "}
									<ReadMoreText
										text={packageRequest.privateMessageContents}
										max={20}
										onClick={() => {
											setChatDetails({
												partner: {
													uid: partnersUid,
													displayName: partnersName
												},
												pagination: {
													cursorType: "after",
													cursor: packageRequest.privateMessageDatesentUTC
														? new Date(
																packageRequest.privateMessageDatesentUTC.getTime() +
																	1
														  )
														: undefined
												},
												highlightedMessageId: packageRequest.privateMessageId
											});
										}}
									/>{" "}
								</>
							) : null
						},
						action: {
							onClick: () => setRespondingToPackageRequest(packageRequest),
							label: "Respond"
						},
						menu: [
							{
								onClick: () =>
									setChatDetails({
										partner: { uid: partnersUid, displayName: partnersName },
										pagination: { cursorType: "after" }
									}),
								label: "Open chat",
								icon: faComment
							},
							...(hostUid === uid
								? []
								: [
										{
											onClick: () => {
												history.push(`/user-id/${partnersUid}`);
											},
											label: "View profile",
											icon: faIdBadge
										}
								  ])
						]
					};
			  })
			: [];

	return (
		<>
			<ModalConfirm
				isOpen={!!respondingToPackageRequest}
				title="Invitation"
				onCancel={() => {
					setPackageRequestResponse(undefined);
					setPackageRequestResponseNote(undefined);
					setRespondingToPackageRequest(undefined);
				}}
				onOK={async () => {
					if (packageRequestResponse === undefined) {
						addAlert({ contents: "Please indicate 'yes' or 'no'" });
						return;
					}

					if (!respondingToPackageRequest) {
						throw new Error("No packageRequest to accept");
					}

					packageRequestResponseApiRequest.current = sendResponse(
						{
							id: respondingToPackageRequest.id,
							response: packageRequestResponse ? true : false,
							...(packageRequestResponseNote
								? { message: packageRequestResponseNote }
								: {})
						},
						{ uid }
					);

					setPackageRequestResponseApiRequestDispatchTime(new Date().getTime());
				}}
				okText="Send response"
			>
				{respondingToPackageRequest && uid
					? (() => {
							return (
								<RespondModalContents>
									<PackageSummary
										product={respondingToPackageRequest}
										currentUid={uid}
									/>
									<h3>Are you happy to go ahead with this?</h3>
									<form>
										<div className="row gtr-uniform">
											<div className="col-6 col-12-small">
												<FieldCheckbox
													id="response-decline"
													name="response-decline"
													checked={packageRequestResponse === false}
													labelText="No"
													onChange={e =>
														setPackageRequestResponse(
															e.currentTarget.checked
																? false
																: packageRequestResponse
														)
													}
												/>
											</div>

											<div className="col-6 col-12-small">
												<FieldCheckbox
													id="response-accept"
													name="response-accept"
													checked={packageRequestResponse === true}
													labelText="Yes"
													onChange={e =>
														setPackageRequestResponse(
															e.currentTarget.checked
																? true
																: packageRequestResponse
														)
													}
												/>
											</div>

											<div className="col-12">
												<textarea
													placeholder="Accompanying note"
													value={packageRequestResponseNote}
													onChange={e =>
														setPackageRequestResponseNote(e.currentTarget.value)
													}
												/>
											</div>
										</div>
									</form>
								</RespondModalContents>
							);
					  })()
					: null}
			</ModalConfirm>

			{isError ? <ErrorBanner /> : null}
			{!isError ? (
				<PackageRequestsTableContainer isLoading={isLoading || isWaiting}>
					<Table
						cols={cols}
						rows={rows}
						emptyText={`There are no ${terminology.packageRequests} that need your attention`}
						isLoading={isLoading || isWaiting}
					/>
				</PackageRequestsTableContainer>
			) : null}
		</>
	);
};

export default PackageRequestsSection;
