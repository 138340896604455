import React, { useCallback } from "react";

import { TextInput } from "./FieldText.styles";

export interface Props {
	subType?: "text" | "tel" | "number";
	value: string | number;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	isInline?: boolean;
	addRightMargin?: boolean;
	addLeftMargin?: boolean;
	id?: string;
	name?: string;
	placeholder?: string;
	autoComplete?: string;
	highlightValidationError?: boolean;
	testid?: string;
}

const TextField: React.FunctionComponent<Props> = ({
	subType = "text",
	value,
	onChange,
	onKeyUp,
	isInline,
	addRightMargin,
	addLeftMargin,
	id,
	name,
	placeholder,
	autoComplete,
	highlightValidationError = false,
	testid
}) => {
	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.currentTarget;
			if (
				subType !== "number" ||
				(!value || /^[0-9]+(\.[0-9]*)?$/.test(value))
			) {
				onChange(e);
			}
		},
		[onChange, subType]
	);

	return (
		<>
			<TextInput
				id={id}
				name={name}
				type={subType}
				isInline={isInline}
				addRightMargin={addRightMargin}
				addLeftMargin={addLeftMargin}
				value={value}
				onChange={handleChange}
				onKeyUp={onKeyUp}
				placeholder={placeholder}
				autoComplete={autoComplete}
				highlightValidationError={highlightValidationError}
				data-testid={testid}
			/>
		</>
	);
};

export default TextField;
