import React from "react";
import styled from "styled-components";
import {
	faChevronRight,
	faChevronLeft,
	faStepBackward
} from "@fortawesome/free-solid-svg-icons";

import {
	MeetingCalendarProps,
	getStartDateAsString,
	getEndDateAsString
} from "components/MeetingCalendar/MeetingCalendar";
import Button from "components/Button";

interface Props
	extends Pick<
		MeetingCalendarProps,
		"displaymode" | "onNavigate" | "weekOffset" | "firstDateLocaltime"
	> {
	titleShort: React.ReactNode;
	titleLong: React.ReactNode;
}

const StyledHeading = styled.h2`
	text-align-last: justify;
	margin-bottom: 0;

	.calendarHeading {
		display: inline-block;
		text-align: left;
	}

	.navButtonsContainer {
		display: inline-block;

		button {
			margin-left: 1em;

			/* Prevent the bootstrap row beneath part covering the buttons because of its negative top margin */
			position: relative;
			z-index: 5;
		}
	}
`;

const MeetingCalendarHeadingBar: React.FunctionComponent<Props> = ({
	onNavigate,
	weekOffset,
	firstDateLocaltime,
	displaymode,
	titleShort,
	titleLong
}) => {
	const dateRangeSummary = `${getStartDateAsString({
		firstDateLocaltime,
		weekOffset,
		format: "MMM D"
	})} - ${getEndDateAsString({
		firstDateLocaltime,
		weekOffset,
		format: "MMM D"
	})}`;

	return (
		<StyledHeading>
			<span className="calendarHeading">
				{displaymode === "compact" ? (
					titleShort
				) : (
					<>
						{titleLong} ({dateRangeSummary})
					</>
				)}
			</span>
			{/*NB removing the following space spoils the right-alignment of the nav buttons (I don't quite understand why)*/}{" "}
			<div className="navButtonsContainer">
				<Button
					isBorderlessIconOnlyButton
					icon={faStepBackward}
					onClick={() => onNavigate(0)}
				/>
				<Button
					isBorderlessIconOnlyButton
					icon={faChevronLeft}
					onClick={() => onNavigate(Math.max(0, weekOffset - 1))}
				/>
				<Button
					isBorderlessIconOnlyButton
					icon={faChevronRight}
					onClick={() => onNavigate(Math.max(0, weekOffset + 1))}
				/>
			</div>
		</StyledHeading>
	);
};

export default MeetingCalendarHeadingBar;
