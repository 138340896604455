// Full list of currencies that Stripe supports: https://stripe.com/docs/currencies
const currencyCodes: { [code: string]: CurrencyDetails } = {
	GBP: {
		isZeroDecimalCurrency: false,
		symbol: { character: "£", position: () => "before" },
		name: "British Pound",
		countryCodes: ["GB"]
	},
	USD: {
		isZeroDecimalCurrency: false,
		symbol: { character: "$", position: () => "before" },
		name: "United States Dollar",
		countryCodes: ["US"]
	},
	AUD: {
		isZeroDecimalCurrency: false,
		symbol: { character: "$", position: () => "before" },
		name: "Australian Dollar",
		countryCodes: ["AU"]
	},
	NZD: {
		isZeroDecimalCurrency: false,
		symbol: { character: "$", position: () => "before" },
		name: "New Zealand Dollar",
		countryCodes: ["NZ"]
	},
	CAD: {
		isZeroDecimalCurrency: false,
		symbol: { character: "$", position: () => "before" },
		name: "Canadian Dollar",
		countryCodes: ["CA"]
	},
	EUR: {
		isZeroDecimalCurrency: false,
		symbol: {
			character: "€",
			position: ({ locationCountryCode }) => {
				if (
					locationCountryCode &&
					["AT", "CY", "IE", "MT", "NL", "GB"].includes(locationCountryCode)
				) {
					return "before";
				}

				return "after";
			}
		},
		name: "Euro",
		countryCodes: [
			"BE",
			"DE",
			"EE",
			"IE",
			"EL",
			"ES",
			"FR",
			"IT",
			"CY",
			"LV",
			"LT",
			"LU",
			"MT",
			"NL",
			"AT",
			"PT",
			"SL",
			"SK",
			"FI"
		]
	},
	JPY: {
		isZeroDecimalCurrency: true,
		symbol: { character: "¥", position: () => "before" },
		name: "Japanese Yen",
		countryCodes: ["JP"]
	},
	INR: {
		isZeroDecimalCurrency: false,
		symbol: { character: "₹", position: () => "before" },
		name: "Indian Rupee",
		countryCodes: ["IN"]
	}
};

interface CurrencySymbol {
	character: string;
	position: ({
		locationCountryCode
	}: CurrencySymbolPositionParams) => "before" | "after";
}

interface CurrencySymbolPositionParams {
	locationCountryCode?: string;
}

interface CurrencyDetails {
	isZeroDecimalCurrency: boolean;
	symbol: CurrencySymbol;
	name: string;
	countryCodes: string[];
}

export const getAvailableCurrencies = () => currencyCodes;
