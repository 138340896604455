import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import { ZoomContext } from "components/ZoomProvider/ZoomProvider";
import { captureException } from "services/captureException";

const ZoomCallback: React.FunctionComponent = () => {
	const history = useHistory();

	const { code, state } = queryString.parse(window.location.search);

	let pathNameFromURL: string | undefined;
	let eventToBookFromURL: string | undefined;
	if (state) {
		if (state && typeof state === "string") {
			try {
				const stateJSON = atob(state);
				const { pathname: pn, eventToBook: etb } = JSON.parse(stateJSON);
				pathNameFromURL = pn;
				eventToBookFromURL = etb;
			} catch (e) {
				throw captureException(new Error("Error parsing state from URL"), {
					evtType: "zoomErrorParsingStateFromURL",
					extra: { originalError: e }
				});
			}
		}
	}
	const destinationPathName = pathNameFromURL ? pathNameFromURL : "/dashboard";

	const [, setZoomState] = useContext(ZoomContext);

	useEffect(() => {
		const newZoomState = {
			...(code && typeof code === "string" ? { code } : {}),
			...(eventToBookFromURL && typeof eventToBookFromURL === "string"
				? { bookingWizard: { eventToBook: eventToBookFromURL } }
				: {})
		};

		setZoomState(newZoomState);
		history.replace({
			pathname: destinationPathName
		});
	}, [code, eventToBookFromURL, destinationPathName, setZoomState, history]);

	return null;
};

export default ZoomCallback;
