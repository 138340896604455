import React, { useCallback, useMemo, useContext } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import ModalFieldSelect from "components/ModalFieldSelect";
import ModalFieldText from "components/ModalFieldText";
import { OnCompleteOutput } from "components/ModalWizard/ModalWizard";
import EditableTable from "components/EditableTable";
import { getCurrencyOptions } from "services/getCurrencyOptions";
import { getSessionPriceOptions } from "services/getSessionPriceOptions";
import { ValidationCallback } from "validation/validate";
import { useStickySort } from "hooks/useStickySort";
import { useSessionTypes } from "hooks/useSessionTypes";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { DashboardContext } from "components/PageDashboard/PageDashboard";
import StepSessionType from "./NewRowWizard/StepSessionType";
import StepPrice from "./NewRowWizard/StepPrice";
import StepSessionDuration from "./NewRowWizard/StepSessionDuration";
import StepGuestUid from "./NewRowWizard/StepGuestUid";
import { SingleSessionPriceWithSessionType } from "../SingleSessionPricesSection";
import terminology from "terminology.json";
import { ucFirst } from "utils/ucFirst";

import FieldMenteeUid from "../../../EditableTable/Fields/FieldMenteeUid/index";

interface SingleSessionPricesTableProps {
	singleSessionPricesWithSessionTypes: SingleSessionPriceWithSessionType[];
	isLoading: boolean;
	isWaiting: boolean;
	isError: boolean;
	waitModalTitle: string;
	waitModalIsShowing: boolean;
	onEdit: (
		rows: {
			old?: SingleSessionPriceWithSessionType;
			new?: SingleSessionPriceWithSessionType;
		}[]
	) => OnCompleteOutput;
}

export type DataInAnyRow = SingleSessionPriceWithSessionType;

const SingleSessionPricesTable: React.FunctionComponent<
	SingleSessionPricesTableProps
> = ({
	singleSessionPricesWithSessionTypes,
	isLoading,
	isWaiting,
	isError,
	onEdit: onEditGlobal,
	waitModalTitle,
	waitModalIsShowing
}) => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);

	const [dashboardContextState] = useContext(DashboardContext);

	const onNewRowWizardComplete = useCallback(
		(data: SingleSessionPriceWithSessionType) => {
			return onEditGlobal([
				{
					new: {
						...data,
						isEnabled: true
					}
				}
			]);
		},
		[onEditGlobal]
	);

	const sortedSingleSessionPrices = useStickySort(
		singleSessionPricesWithSessionTypes,
		useCallback(
			(
				a: SingleSessionPriceWithSessionType,
				b: SingleSessionPriceWithSessionType
			) => {
				const titleComparison = a.sessionTitle.localeCompare(b.sessionTitle);
				if (titleComparison === 0) {
					return a.price - b.price;
				}
				return titleComparison;
			},
			[]
		),
		"series"
	);

	const {
		isWaiting: sessionTypesWaiting,
		isLoading: sessionTypesLoading,
		isError: sessionTypesError,
		output: sessionTypes
	} = useSessionTypes({
		mentorId: uid,
		showDisabled: true,
		doWait: isSignedIn === undefined || !uid,
		repeatNumber: dashboardContextState.sessionTypesRefreshNum,
		persistBetweenLoads: true
	});

	const rows = useMemo(
		() =>
			sortedSingleSessionPrices.map(data => ({
				key: data.id,
				data,
				actionWhenEditing: {
					icon: faTrash,
					label: "Delete",
					onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
						e.preventDefault();
						onEditGlobal([{ old: data }]);
					}
				}
			})),
		[sortedSingleSessionPrices, onEditGlobal]
	);

	const newRowWizard = useMemo(() => {
		const steps = [
			{
				key: "sessionTypeId",
				title: "What session type does this apply to?",
				component: StepSessionType,
				doScrollToInitialPosition: true
			},
			{
				key: "price",
				title: "How much does this session cost?",
				component: StepPrice
			},
			{
				key: "session-duration",
				title: "How long does this session last?",
				component: StepSessionDuration
			},
			{
				key: "guestUid",
				title: `Is this price reserved for a particular ${terminology.mentee}?`,
				component: StepGuestUid
			}
		];
		const validateNewRowData: ValidationCallback<DataInAnyRow> = (
			data,
			{ checkField }
		) => {
			checkField("sessionTypeId", { type: "string" });
			checkField("price", { type: "number" });
			checkField("currency", { type: "string" });
			checkField("sessionDurationMinutes", { type: "number" });
		};
		return {
			steps,
			validateNewRowData,
			initialData: {}
		};
	}, []);

	return (
		<>
			<EditableTable<DataInAnyRow>
				allowDeleteRows
				rows={rows}
				onEdit={onEditGlobal}
				dataDescriptionSingular="session price"
				dataDescriptionPlural="session prices"
				onNew={onNewRowWizardComplete}
				newRowWizard={newRowWizard}
				rowIsDisabled={row => !row.isEnabled}
				isLoading={isLoading || sessionTypesLoading}
				isWaiting={isWaiting || sessionTypesWaiting}
				isError={isError || sessionTypesError}
				waitModalTitle={waitModalTitle}
				waitModalIsShowing={waitModalIsShowing}
				cols={[
					{
						key: "sessionTypeId",
						heading: "Session type",
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<ModalFieldSelect
								isUnlocked={isEditing}
								title="Session type"
								requireSelection
								onOK={newOptions => {
									const selectedOption = newOptions.find(val => !!val.selected);
									if (!selectedOption) {
										throw new Error("No selected option");
									}

									const newData = {
										...row,
										[rowKey]: selectedOption.value
									};
									onEdit([{ old: row, new: newData }]);
								}}
								options={
									sessionTypes
										? sessionTypes.page.map(({ id, sessionTitle }) => ({
												id,
												value: id,
												text: sessionTitle,
												selected: id === row.sessionTypeId
										  }))
										: []
								}
								allowMultipleSelections={false}
							/>
						)
					},
					{
						key: "currency",
						heading: "Currency",
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<ModalFieldSelect
								isUnlocked={isEditing}
								title="Currency"
								requireSelection
								onOK={newOptions => {
									const selectedOption = newOptions.find(val => !!val.selected);
									if (!selectedOption) {
										throw new Error("No selected option");
									}

									const newData = {
										...row,
										[rowKey]: selectedOption.value
									};
									onEdit([{ old: row, new: newData }]);
								}}
								options={getCurrencyOptions(row.currency)}
								allowMultipleSelections={false}
							/>
						)
					},
					{
						/* Number fields are rubbish.  Replace with a suitable field, or use a text with proper validation. */
						key: "price",
						heading: "Price",
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<ModalFieldText
								isUnlocked={isEditing}
								title="Price"
								subType="number"
								onOK={newValue => {
									const newData = {
										...row,
										[rowKey]: newValue ? parseFloat(newValue) : 0
									};
									onEdit([{ old: row, new: newData }]);
								}}
								value={row.price + ""}
								formatValue={v => (v === "0" ? "FREE" : v)}
								helptext="For a free session, set the price to 0"
							/>
						)
					},
					{
						key: "sessionDurationMinutes",
						heading: "Duration",
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<ModalFieldSelect
								isUnlocked={isEditing}
								title="Session duration"
								requireSelection
								onOK={newOptions => {
									const selectedOption = newOptions.find(val => !!val.selected);
									if (!selectedOption) {
										throw new Error("No selected option");
									}

									const newData = {
										...row,
										[rowKey]: parseInt(selectedOption.value + "")
									};
									onEdit([{ old: row, new: newData }]);
								}}
								options={getSessionPriceOptions(row.sessionDurationMinutes)}
								allowMultipleSelections={false}
							/>
						)
					},
					{
						key: "guestUid",
						heading: ucFirst(terminology.mentee),
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<FieldMenteeUid
								row={row}
								rowKey={rowKey}
								isEditing={isEditing}
								onEdit={onEdit}
							/>
						)
					},
					{
						key: "isEnabled",
						heading: "Enabled",
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<ModalFieldSelect
								isUnlocked={isEditing}
								title="Enabled"
								requireSelection
								onOK={newOptions => {
									const selectedOption = newOptions.find(val => !!val.selected);
									if (!selectedOption) {
										throw new Error("No selected option");
									}

									const newData = {
										...row,
										[rowKey]: selectedOption.value !== 0
									};

									onEdit([{ old: row, new: newData }]);
								}}
								options={[
									{
										id: "no",
										value: 0,
										text: "No",
										selected: !row.isEnabled
									},
									{
										id: "yes",
										value: 1,
										text: "Yes",
										selected: !!row.isEnabled
									}
								]}
								allowMultipleSelections={false}
							/>
						)
					}
				]}
			/>
		</>
	);
};

export default SingleSessionPricesTable;
