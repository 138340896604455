import firebase from "setupFirebase";
import { ApiRequestData } from "./index";

type PoJo<T> = {
	[k: string]: T;
};

const items: PoJo<PoJo<any>> = {};

// Clear items when the authenticated user changes (some API endpoints' output depends on the authenticated user)
const auth = firebase.auth();
let lastUid: string | undefined;
auth.onAuthStateChanged(user => {
	const thisUid = user ? user.uid : undefined;
	if (thisUid !== lastUid) {
		cacheClear();
	}
	lastUid = user ? user.uid + "" : undefined;
});

interface RequestDefinition {
	endpoint: string;
	data?: ApiRequestData;
}

export const cacheClear = ({
	endpoint,
	data
}: Omit<RequestDefinition, "endpoint"> & {
	endpoint?: RequestDefinition["endpoint"];
} = {}) => {
	if (!endpoint) {
		const keys = Object.keys(items);
		for (const k of keys) {
			delete items[k];
		}
		return;
	}

	if (!items[endpoint]) {
		return;
	}
	if (!data) {
		delete items[endpoint];
	}

	const dataJSON = JSON.stringify(data);
	delete items[endpoint][dataJSON];
};

export const cacheGet = ({ endpoint, data }: RequestDefinition) => {
	const dataJSON = JSON.stringify(data);
	if (items[endpoint] && items[endpoint][dataJSON]) {
		return items[endpoint][dataJSON];
	}
	return undefined;
};

export const cacheSet = ({
	endpoint,
	data,
	value
}: RequestDefinition & { value: any }) => {
	const dataJSON = JSON.stringify(data);
	if (!items[endpoint]) {
		items[endpoint] = {};
	}
	items[endpoint][dataJSON] = value;
};
