import * as Sentry from "@sentry/react";

import { SentryEventContext } from "./SentryEventContext";

export class CapturedElsewhereError extends Error {
	constructor(message: string) {
		super(message); // (1)
		this.name = "CapturedElsewhereError"; // (2)
	}
}

export class AlreadyCapturedError extends Error {
	constructor(message: string) {
		super(message); // (1)
		this.name = "AlreadyCapturedError"; // (2)
	}
}

export const captureException = (
	e: any,
	{ evtType, extra }: SentryEventContext
): AlreadyCapturedError => {
	console.log("Captured exception", e, evtType, extra);
	console.error("Stack trace");
	Sentry.captureException(e, {
		tags: { evtType },
		...(extra ? { extra } : {})
	});
	return new AlreadyCapturedError(e.message);
};
