import React from "react";
import { Elements } from "@stripe/react-stripe-js";

import { getStripePromise } from "services/getStripePromise";

export function withStripeElements<PublicProps>(
	Component: React.FunctionComponent<PublicProps>
) {
	const Inner: React.FunctionComponent<PublicProps> = props => {
		if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
			throw new Error("No Stripe Key configured");
		}

		return (
			<Elements stripe={getStripePromise()}>
				<Component {...props} />
			</Elements>
		);
	};

	return Inner;
}
