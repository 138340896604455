import styled from "styled-components";

import { Props } from "./SectionBox";

export const SectionBoxInner = styled.section<Props>`
	border-radius: 4px;
	border: 1px solid ${props => (props.highlight ? `#83bbde` : `#eaeaea`)};
	margin-bottom: 2em;
	padding: 1.5em;

	${props => {
		// If the right column is fixed, it is moved down by 3.5em to escape from
		// behind the fixed header.  So, add extra bottom padding to this box to prevent
		// the fixed right column from protruding out of it.
		return props.containsFixedRightColumn ? `padding-bottom:5em` : ``;
	}};

	& > :last-child,
	& > :last-child > :last-child,
	& > :last-child > :last-child > :last-child {
		margin-bottom: 0;
	}
`;
