import styled from "styled-components";

export const ProfileInfoWrapper = styled.div`
	margin-bottom: 1em;
`;

export const ProfileInfoItem = styled.li`
	line-height: 1.5;
	margin: 0 0 1em 0;
	letter-spacing: 0.25em;
	text-transform: uppercase;
	color: #777777;
	font-size: 0.8em;
	&:last-child {
		margin-bottom: 0;
	}
`;

export const ProfileInfoHeading = styled.span`
	display: block;
	font-weight: 500;
	&::after {
		content: ": ";
	}
`;

export const ProfileBio = styled.p`
	text-align: left;
`;
