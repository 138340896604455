import React, { useCallback } from "react";

import ModalScrollingContents from "components/Modal/ModalScrollingContents";
import { useScrollToInitialPosition } from "hooks/useScrollToInitialPosition";

import StepHeader from "./StepHeader";
import { StepHeaderProps } from "./StepHeader/StepHeader";
import StepFooter from "./StepFooter";
import { StepFooterProps } from "./StepFooter/StepFooter";

export type StepProps = Omit<
	StepHeaderProps,
	"stepNumber" | "totalNumSteps"
> & {
	isError?: boolean;
	showFooter?: boolean;
	children?: any;
	doScrollToInitialPosition?: boolean;
	stepNumber: number;
	totalNumSteps: number;
	onClickNext?: () => boolean | undefined | void;
} & StepFooterProps;

function Step({
	/* Header props */
	title,
	wizardDescription,
	anyVisibleLaterStepsMightBeSkippable,

	/* Footer props */
	onCancel,
	cancelButtonText,
	onOK,

	/* Other */
	nextButtonText,
	doScrollToInitialPosition = false,
	isError = false,
	showFooter = true,
	stepNumber,
	totalNumSteps,
	onClickNext,
	children
}: StepProps) {
	const scrollToInitialPosition = useScrollToInitialPosition();

	const onNext = useCallback(() => {
		if (onClickNext && !onClickNext()) {
			return;
		}
		return onOK();
	}, [onOK, onClickNext]);

	return (
		<>
			<StepHeader
				anyVisibleLaterStepsMightBeSkippable={
					anyVisibleLaterStepsMightBeSkippable
				}
				stepNumber={stepNumber}
				totalNumSteps={totalNumSteps}
				title={title}
				wizardDescription={wizardDescription}
			/>
			<ModalScrollingContents
				onContents={
					doScrollToInitialPosition ? scrollToInitialPosition : undefined
				}
			>
				{children}
			</ModalScrollingContents>
			{showFooter ? (
				<StepFooter
					onCancel={onCancel}
					onOK={onNext}
					nextButtonText={isError ? null : nextButtonText}
					cancelButtonText={cancelButtonText}
				/>
			) : null}
		</>
	);
}

export default Step;
