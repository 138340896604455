/*
 * This file was adapted from
 * https://github.com/abhijitghogre/use-collapsible/blob/main/index.ts
 *
 * (cannot import via NPM due to a react version mismatch)
 */

import { useCallback, useState } from "react";

export const useCollapsible = (isCollapsedOnLoad: boolean = false) => {
	const [isCollapsed, setIsCollapsed] = useState(false);

	const onToggle = () => {
		setIsCollapsed(!isCollapsed);
	};

	const ref = useCallback(
		(node: HTMLElement | null) => {
			if (node) {
				if (isCollapsedOnLoad) {
					setIsCollapsed(true);
				}
			}
		},
		[isCollapsedOnLoad]
	);

	return {
		ref,
		isCollapsed,
		display: isCollapsed ? "none" : "block",
		onToggle
	};
};
