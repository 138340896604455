import { useCallback } from "react";

import { getLocationDistricts } from "api/getLocationDistricts";
import { useRemoteResource } from "./useRemoteResource";
import { FieldOption } from "components/FieldSelect/FieldSelect";

interface Params {
	selectedCountryCode?: string;
}

export function useLocationDistrictOptions({ selectedCountryCode }: Params) {
	const fetchLocationDistrictOptions = useCallback(
		(arg, auth) => {
			if (!selectedCountryCode) {
				throw new Error("No selected country name");
			}
			const req = getLocationDistricts(
				{
					locationCountryCode: selectedCountryCode,
					cacheInMemory: true
				},
				auth
			);

			return {
				ready: req.ready.then(response => {
					if (!response) {
						return [];
					}
					const options = response.map(({ locationDistrict }) => ({
						id: locationDistrict,
						value: locationDistrict,
						text: locationDistrict
					}));
					return options;
				}),
				abort: () => {
					req.abort();
				},
				aborted: () => req.aborted(),
				type: req.type
			};
		},
		[selectedCountryCode]
	);

	const options = useRemoteResource<FieldOption<string>[]>(
		fetchLocationDistrictOptions,
		!selectedCountryCode
	);

	return options;
}
