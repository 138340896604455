import styled from "styled-components";

export const StyledTable = styled.table`
	width: 100%;
	tbody tr {
		border-left: 1px solid #eaeaea;
		border-right: 1px solid #eaeaea;
	}
	margin: 0 0 1em 0;
`;

export const StyledTableRow = styled.tr<{ lineThrough?: boolean }>`
	${props => (!!props.lineThrough ? `text-decoration:line-through;` : ``)}
`;

export const StyledTableCell = styled.td<{ width?: number }>`
	min-width: ${props => props.width}em;
	max-width: ${props => props.width}em;
	width: ${props => props.width}em;
`;

export const StyledTableFooter = styled.tfoot`
	td {
		padding-left: 0;
		padding-right: 0;
	}
	button {
		text-align: left;
	}
`;

export const Card = styled.section`
	border-color: #eaeaea;
	border-radius: 4px;
	border: solid 1px;
	margin-bottom: 2em;
	padding: 1.5em;
	background-color: rgba(220, 220, 220, 0.075);

	/* Position: relative for positioning the menu button */
	position: relative;
`;

export const CardInner = styled.div`
	overflow: hidden;
`;

export const SmallScreenMenuButtonWrapper = styled.div`
	position: absolute;
	top: 1em;
	right: 1em;
`;

export const TableActionButtonWrapper = styled.div<{ hasTopMargin?: boolean }>`
	${props => (props.hasTopMargin ? `margin-top:1em;` : ``)};
`;
