import { on } from "../../services/pubSub";

import { Auth } from "./index";
import { Batch, BatchedRequest } from "./batch";

interface Params {
	req: BatchedRequest;
	auth: Auth;
}

const batches: { [k: string]: Batch } = {};

on("batchDone", (batchId: string) => {
	currentBatchId = undefined;
	delete batches[batchId];
});

let currentBatchId: string | undefined;

export function sendBatched({ req, auth }: Params): Promise<unknown> {
	updateCurrentBatch({ auth });

	const batch = currentBatchId ? batches[currentBatchId] : new Batch(auth);
	currentBatchId = batch.uniqid;
	if (batches[currentBatchId] === undefined) {
		batches[currentBatchId] = batch;
	}
	return batch.add(req);
}

function updateCurrentBatch({ auth }: Pick<Params, "auth">) {
	const currentBatch = currentBatchId ? batches[currentBatchId] : undefined;

	if (currentBatchId && currentBatch === undefined) {
		if (currentBatch === undefined) {
			throw new Error("Current batch not found");
		}
	}

	if (currentBatch) {
		if (!currentBatch.isDispatched && currentBatch.auth.uid !== auth.uid) {
			currentBatch.dispatch();
		}
		if (currentBatch.isDispatched) {
			currentBatchId = undefined;
		}
	}
}
