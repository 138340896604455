import React from "react";
import styled from "styled-components";

const ErrorContainer = styled.p`
	color: #ff6666;
`;

const ChatWidgetErrorSendingLastMessage: React.FunctionComponent = () => {
	return (
		<ErrorContainer>
			There was an error sending the last message. It may not have been sent
			successfully.
		</ErrorContainer>
	);
};

export default ChatWidgetErrorSendingLastMessage;
