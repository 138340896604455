import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ValidationCallback } from "validation/validate";

interface Params {
	defaultDisplayName: string | undefined;
}

type Output = {
	user: { uid: string; displayName: string; hasAcceptedTerms: boolean };
};
const flagValidationErrors: ValidationCallback<Output> = (input, { flag }) => {
	if (!input.user) {
		flag("user", "missing");
	} else if (typeof input.user !== "object") {
		flag("user", "not an object");
	} else if (!input.user.uid) {
		flag("user", "uid missing");
	} else if (typeof input.user.uid !== "string") {
		flag("user", "uid not a string");
	} else if (!input.user.displayName) {
		flag("user", "displayName missing");
	} else if (typeof input.user.displayName !== "string") {
		flag("user", "displayName not a string");
	} else if (input.user.hasAcceptedTerms === undefined) {
		flag("user", "hasAcceptedTerms missing");
	} else if (typeof input.user.hasAcceptedTerms !== "boolean") {
		flag("user", "hasAcceptedTerms not a boolean");
	}
};

export function signIn({ defaultDisplayName }: Params, auth: Auth) {
	return makeApiRequest<Output>("signIn", {
		auth,
		data: {
			post: {
				defaultDisplayName
			}
		},
		flagValidationErrors
	});
}
