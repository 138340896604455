/*
 * This file contains some jquery code that was used by the original HTML5 template that
 * this site's design was adapted from.  It should run after the first render by React.
 *
 * The original template was:
 * Formula by Pixelarity
 * pixelarity.com | hello@pixelarity.com
 * License: pixelarity.com/license
 *
 */

import { getJQuery } from "services/getJQuery";

const breakpointDefinitions = {
	xlarge: ["1281px", "1680px"],
	large: ["981px", "1280px"],
	medium: ["737px", "980px"],
	small: ["481px", "736px"],
	xsmall: ["361px", "480px"],
	xxsmall: [null, "360px"]
};

type Breakpoints = {
	(arg: typeof breakpointDefinitions): void;
	on: (rangeDefinition: string, callback: () => void) => void;
};

type WindowWithExtras = typeof window & {
	breakpoints?: Breakpoints;
	browser?: {
		name: string;
	};
};

export const initTemplateBehaviours = () => {
	const $ = getJQuery();

	var $window = $(window);
	var $body = $("body");

	const breakpoints = (window as WindowWithExtras)["breakpoints"];

	if (breakpoints === undefined) {
		throw new Error("Global 'breakpoints' object not found");
	}

	breakpoints(breakpointDefinitions);

	// Play initial animations
	window.setTimeout(function() {
		$body.removeClass("is-preload");
	}, 100);

	// Make the banner work
	var $banner = $("#banner");
	var $header = $("#header");

	if ($banner.length > 0) {
		const browser = (window as WindowWithExtras)["browser"];
		if (browser === undefined) {
			throw new Error("Browser object not found");
		}
		// IE: Height fix.
		if (browser.name === "ie") {
			breakpoints.on(">small", function() {
				$banner.css("height", "100vh");
			});

			breakpoints.on("<=small", function() {
				$banner.css("height", "");
			});
		}

		$banner.find(".more").addClass("scrolly");

		$header.addClass("with-banner").addClass("alt");

		$banner.scrollex({
			mode: "top",
			top: "-100vh",
			bottom: 10,
			enter: function() {
				$header.addClass("alt");
			},
			leave: function() {
				$header.removeClass("alt");
			}
		});
	}

	// Scrolly.
	$(".scrolly").scrolly();

	// Initial scroll.
	$window.trigger("scroll");
};
