interface Option {
	value: string;
	text: string;
}

export function getOptions(
	availableOptions: Option[],
	selectedOptionValues?: string[]
) {
	// TODO:WV:20210624:Optimise by re-sorting only when the available options change
	const opts = availableOptions
		.map(({ value, text }) => {
			return {
				id: value,
				value,
				text,
				selected: !!selectedOptionValues && selectedOptionValues.includes(value)
			};
		})
		.sort((a, b) => Intl.Collator().compare(a.text, b.text));

	return opts;
}
