import { ModalWizardStep } from "components/ModalWizard/ModalWizard";
import { ModalWizardFormState } from "./useModalWizard";

export function getNextButtonText<CompletedWizardData>(
	allVisibleSteps: ModalWizardStep<CompletedWizardData>[],
	formState: ModalWizardFormState<CompletedWizardData>
) {
	const currentStepIndex = allVisibleSteps.findIndex(
		el => el.key === formState.currentStepKey
	);

	if (currentStepIndex === -1) {
		throw new Error(
			`Current step ('${formState.currentStepKey}') not found in list of visible steps`
		);
	}

	const isLastStep = currentStepIndex === allVisibleSteps.length - 1;

	const currentStep = allVisibleSteps[currentStepIndex];
	const { nextButtonText: nextButtonTextFunction } = currentStep;

	const nextButtonText =
		nextButtonTextFunction !== undefined
			? nextButtonTextFunction(
					formState.wizardData,
					allVisibleSteps,
					formState.currentStepKey
			  )
			: isLastStep
			? "Submit"
			: "Next";

	return nextButtonText;
}
