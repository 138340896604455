import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";

import firebase from "setupFirebase";

import { signIn } from "api/signIn";
import { respondToAuthChange } from "services/accountStatus";
import ModalLoginOrSignup from "components/ModalLoginOrSignup";

interface AuthState {
	haveSignInStatus: boolean;
	isSignedIn: boolean;
	hasAcceptedTerms: boolean;
	setHasAcceptedTerms: React.Dispatch<React.SetStateAction<boolean>>;
	termsWallIsDisabled: boolean;
	setTermsWallIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	logOut: () => void;
	promptForLogin: () => void;
	uid?: string;
	displayName?: string;
}

const auth = firebase.auth();

export const AuthContext = React.createContext<[AuthState]>([
	{
		haveSignInStatus: false,
		isSignedIn: false,
		hasAcceptedTerms: false,
		setHasAcceptedTerms: () => undefined,
		termsWallIsDisabled: false,
		setTermsWallIsDisabled: () => undefined,
		logOut: () => undefined,
		promptForLogin: () => undefined
	}
]);

const AuthProvider: React.FunctionComponent = ({ children }) => {
	const [haveFirebaseSignInStatus, setHaveFirebaseSignInStatus] = useState(
		false
	);
	const [firebaseDisplayName, setFirebaseDisplayName] = useState<
		string | undefined
	>();
	const [isSignedInToFirebase, setIsSignedInToFirebase] = useState(false);
	const [haveLocalSignInStatus, setHaveLocalSignInStatus] = useState(false);
	const [isSignedInLocally, setIsSignedInLocally] = useState(false);
	const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
	const [termsWallIsDisabled, setTermsWallIsDisabled] = useState(false);
	const [uid, setUid] = useState<string>();
	const [displayName, setDisplayName] = useState<string>();

	useEffect(() => {
		return auth.onAuthStateChanged(firebaseUser => {
			// TODO:WV:20210425:Make sure there will not be a catastrophe if this ReactDOM.unstable_batchedUpdates is deprecated / removed
			ReactDOM.unstable_batchedUpdates(() => {
				const isSignedInToFirebase = !!firebaseUser;
				setHaveFirebaseSignInStatus(true);
				setFirebaseDisplayName(
					!!firebaseUser && !!firebaseUser.displayName
						? firebaseUser.displayName
						: undefined
				);
				setIsSignedInToFirebase(isSignedInToFirebase);

				if (!isSignedInToFirebase) {
					setHaveLocalSignInStatus(true);
					setIsSignedInLocally(false);
					setUid(undefined);
					setDisplayName(undefined);
				}
			});
		});
	}, [
		setHaveFirebaseSignInStatus,
		setFirebaseDisplayName,
		setIsSignedInToFirebase,
		setHaveLocalSignInStatus,
		setUid,
		setDisplayName
	]);

	// Update account status in response to changes to UID.
	// TODO:WV:20210425:This needs to be here as part of a hacky solution to something or other, which should be tidied up
	useEffect(() => {
		const req = respondToAuthChange(uid);
		return () => {
			if (req) {
				req.abort();
			}
		};
	}, [uid]);

	useEffect(() => {
		// TODO:WV:20210425:Make sure the below will not result in multiple calls to signIn() API method
		if (
			haveFirebaseSignInStatus &&
			isSignedInToFirebase &&
			!isSignedInLocally
		) {
			// TODO:WV:20210425:Find a way to cache the result of signIn in sessionStorage, until the user logs out of Firebase
			const req = signIn({ defaultDisplayName: firebaseDisplayName }, { uid });
			req.ready.then(result => {
				if (!result) {
					return;
				}
				// TODO:WV:20210425:Make sure there will not be a catastrophe if this ReactDOM.unstable_batchedUpdates is deprecated / removed
				ReactDOM.unstable_batchedUpdates(() => {
					setHaveLocalSignInStatus(true);
					setIsSignedInLocally(true);
					setUid(result.user.uid);
					setDisplayName(result.user.displayName);
					setHasAcceptedTerms(result.user.hasAcceptedTerms);
				});
			});
			return () => req.abort();
		}
	}, [
		haveFirebaseSignInStatus,
		firebaseDisplayName,
		isSignedInToFirebase,
		isSignedInLocally,
		setHaveLocalSignInStatus,
		setIsSignedInLocally,
		setUid,
		setDisplayName,
		uid,
		setHasAcceptedTerms
	]);

	const logOut = useCallback(() => {
		auth.signOut();
	}, []);

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const promptForLogin = useCallback(() => {
		setModalIsOpen(true);
	}, [setModalIsOpen]);

	const isSignedIn = isSignedInToFirebase && isSignedInLocally;
	useEffect(() => {
		// Must check for isSignedIn before calling setModalIsOpen(false) otherwise it is not possible to show a login modal immediately after logging out (such as in ProtectedRoute)
		if (isSignedIn) {
			setModalIsOpen(false);
		}
	}, [setModalIsOpen, isSignedIn]);

	const handleModalRequestClosed = useCallback(() => {
		setModalIsOpen(false);
	}, [setModalIsOpen]);

	const publicAuthState = {
		haveSignInStatus: haveFirebaseSignInStatus && haveLocalSignInStatus,
		isSignedIn,
		hasAcceptedTerms,
		setHasAcceptedTerms,
		termsWallIsDisabled,
		setTermsWallIsDisabled,
		uid,
		displayName,
		logOut,
		promptForLogin
	};

	return (
		<AuthContext.Provider value={[publicAuthState]}>
			{children}
			<ModalLoginOrSignup
				isOpen={modalIsOpen}
				onRequestClose={handleModalRequestClosed}
			/>
		</AuthContext.Provider>
	);
};

export default AuthProvider;
