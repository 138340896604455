import { useState, useEffect, useCallback } from "react";

export function useSetMessageSendingFieldsDisabled() {
	const [senderDisabled, setSenderDisabled] = useState(true);
	useEffect(() => {
		document.body.classList[senderDisabled ? "add" : "remove"](
			"chat-sender-disabled"
		);
	}, [senderDisabled]);

	return useCallback((newValue: boolean) => setSenderDisabled(newValue), [
		setSenderDisabled
	]);
}
