import React from "react";
import { Link } from "react-router-dom";
import { trimText } from "utils/trimText";

import { ReadMoreTextWrapper } from "./ReadMoreText.styles";

interface ReadMoreTextProps {
	text: string;
	max: number;
	href?: string;
	onClick?: () => void;
}

const ReadMoreText: React.FunctionComponent<ReadMoreTextProps> = ({
	text,
	max,
	href,
	onClick
}) => {
	if (href && onClick) {
		throw new Error("Please supply either href or onClick but not both");
	}

	const { newText, wasShortened } = trimText({ text, max });

	if (href) {
		return (
			<ReadMoreTextWrapper wasShortened={wasShortened}>
				<Link to={href}>{newText}</Link>
			</ReadMoreTextWrapper>
		);
	}

	return (
		<ReadMoreTextWrapper
			wasShortened={wasShortened}
			onClick={wasShortened ? onClick : undefined}
		>
			{newText}
		</ReadMoreTextWrapper>
	);
};

export default ReadMoreText;
