import styled from "styled-components";

export const MarkdownOutput = styled.div`
	.highlighted-section {
		border: 1px solid black;
		margin: 2em 1em 2em 1em;
		padding: 1em;
	}
	.contents {
		list-style: none;
		padding: 0;
		margin: 0 2em 3em 2em;

		li {
			margin-bottom: 2em;
		}
	}
	table {
		margin-bottom: 2em;
	}
`;
